import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import Loading from "../Loading/loading";
import { importBaseActions } from "../../store/reducers/base";
import { useCallback } from "react";
import rals from "../../v2/assets/rals.json";

const ItemColor = ({
  data,
  //setIdColor1,
  //setIdColor2,
  //setNameColor,
  //setNameColor2,
  checkColor1,
  checkColor2,
  modelloCheckModal,
  finituraCheckModal,
  getAspetto,
  setFinituraEsterna,
  setFinituraInterna,
  setRalNonStandard,
  hideCustomRalFinituraEsterna
}) => {  
  const [selected1, setSelected1] = useState();
  const [selected2, setSelected2] = useState();
  const [colorCode1, setColorCode1] = useState({
    searchCode: "",
    color: null,
    status: "search_idle"
  });
  const [colorCode2, setColorCode2] = useState({
    searchCode: "",
    color: null,
    status: "search_idle"
  });
  const state = useSelector(state => state.base);
  const dispatch = useDispatch();
  const baseActions = importBaseActions();

  const location = useLocation();

  const searchColorCode2 = async () => {
    if (!colorCode2.searchCode || colorCode2.searchCode.length < 2) return;

    setColorCode2({...colorCode2, status: "search_in_progress"});
    try {
      const ral = rals.find(r => r.RAL == colorCode2.searchCode);
      
      if (ral) {
        setColorCode2({
          ...colorCode2,
          color: ral,
          status: "search_idle"
        });
        setSelected2(ral.RAL);
        setRalNonStandard({id: null, code: ral.RAL, name: ral.Italian});
        setFinituraEsterna(null);
      } else {
        setColorCode2({
          ...colorCode2,
          status: "search_error"
        });
      }
    } catch (e) {

    }
  }

  const searchColorCode1 = async () => {
    if (!colorCode1.searchCode || colorCode1.searchCode.length < 2) return;
    
    setColorCode1({...colorCode1, status: "search_in_progress"});
    try {
      const ral = rals.find(r => r.RAL == colorCode1.searchCode);
          
      if (ral) {
        setColorCode1({
          ...colorCode1,
          color: ral,
          status: "search_idle"
        });
        setSelected1(ral.RAL);
        setRalNonStandard({id: null, code: ral.RAL, name: ral.Italian});
        setFinituraInterna(null);
      } else {
        setColorCode1({
          ...colorCode1,
          status: "search_error"
        });
      }
    } catch (e) {

    }
  }

  if (!data) return <Loading />;

  return (
    <>
      {checkColor1 ? (
        <>
          {location.pathname !== "/maniglierie" && (
            <Typography sx={{ marginBottom: 4, marginTop: 4 }} variant={"h4"}>
              Interna
            </Typography>
          )}
          <div className={"grid grid-cols-2 justify-items-start w-full"}>
            {data.map((res, index) => (
              <div
                onClick={() => {
                  dispatch(baseActions.setCampioneInterno(false));
                  //setNameColor(res.name);
                  //setIdColor1(res.id);
                  setFinituraInterna(res);
                  setSelected1(index);
                }}
                key={index}
                className={
                  "grid grid-cols-3 gap-4 content-center mb-4 group cursor-pointer"
                }>
                <Box
                  className={
                    "group-hover:border-2 group-hover:border-[#dc3d22]"
                  }
                  sx={
                    selected1 !== index
                      ? {
                          display: "flex",
                          justifyContent: "center",
                          height: 80,
                          width: 80,
                        }
                      : {
                          display: "flex",
                          justifyContent: "center",
                          height: 80,
                          width: 80,
                          border: "3px solid #dc3d22",
                        }
                  }>
                  <img src={res.img} alt={"img"} />
                </Box>
                <Typography
                  className={"group-hover:text-[#dc3d22]"}
                  sx={selected1 === index ? { color: "#dc3d22" } : {}}
                  variant={"h7"}>
                  {res.name}
                </Typography>
                <div>
                  <Radio checked={selected1 === index} />
                </div>
              </div>))}
            {colorCode1.color && (
              <div
                onClick={() => {
                  dispatch(baseActions.setCampioneInterno(false));
                  //setNameColor(colorCode1.name);
                  //setIdColor1(colorCode1.id);
                  setFinituraInterna(colorCode1.color);
                  setSelected1(colorCode1.color.RAL);
                }}
                className={"grid grid-cols-1 justify-items-start w-full group"}>
                <div
                  key={colorCode1.color.id}
                  className={"grid grid-cols-3 gap-4 content-center mb-4"}>
                  <Box
                    className={
                      "group-hover:border-2 group-hover:border-[#dc3d22]"
                    }
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      height: 80,
                      width: 80,
                      border: selected1 == colorCode1.color.RAL ? "2px solid #dc3d22" : "none",
                      backgroundColor: colorCode1.color.HEX
                    }}>                    
                  </Box>
                  <Typography
                    className={"group-hover:text-[#dc3d22]"}
                    sx={selected1 === colorCode1.color.id ? { color: "#dc3d22" } : {}}
                    variant={"h7"}>
                    {colorCode1.color.RAL}-{colorCode1.color.Italian}
                  </Typography>
                  <Radio checked={selected1 === colorCode1.color.RAL} />
                </div>
              </div>
            )}

            <div className={" mb-4 flex flex-col w-full"}>
              <div>
                <Typography sx={{ color: "#dc3d22" }} variant={"h7"}>
                  RAL non standard
                </Typography>                
                <TextField
                  onChange={(e) => setColorCode1({...colorCode1, searchCode: e.target.value})}
                  fullWidth
                  placeholder={"8016"}
                  margin="dense"
                  id="code1"
                  name={"code1"}
                  type="text"
                  variant="outlined"
                  value={colorCode1.searchCode}
                  error={"search_error" == colorCode1.status}
                  helperText={"search_error" == colorCode1.status && "Colore non trovato"}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={() => searchColorCode1()} sx={{ marginX: 2 }}>
                        <SearchIcon style={{ color: "black" }} />
                      </IconButton>
                    ),
                  }}
                />
              </div>
            </div>
          </div>
          {location.pathname !== "/maniglierie" && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.campioneInterno}
                  onChange={() => {
                    setSelected1(null);
                    dispatch(baseActions.setCampioneInterno(!state.campioneInterno));
                    if (state.campioneInterno === false) {
                      //setNameColor("Colore a campione");
                      // setIdColor1(null);
                      setFinituraInterna({id: null, name: "Colore a campione", img: null});
                    }
                  }}
                />
              }
              label={"Colore a campione"}
            />
          )}
        </>
      ) : (
        <>
          {location.pathname !== "/accessori" && (
            <>
              <Typography 
                sx={{ marginBottom: 4 }} 
                variant="h4">
                Esterna
              </Typography>
              <div className={"grid grid-cols-2 justify-items-start w-full"}>
                {data.map((res, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        dispatch(baseActions.setCampioneEsterno(false));
                        //dispatch(baseActions.setIdEsterni(res.id));
                        //setNameColor2(res.name);
                        //setIdColor2(res.id);
                        setFinituraEsterna(res);
                        setColorCode2({...colorCode2, searchCode: "", color: null})
                        setSelected2(index);
                      }}
                      className={"flex flex-col mb-4 cursor-pointer group"}>
                      <div
                        className={"grid grid-cols-3 gap-4 content-center "}>
                        <Box
                          className={
                            "group-hover:border-2 group-hover:border-[#dc3d22]"
                          }
                          sx={
                            selected2 !== index
                              ? {
                                  display: "flex",
                                  justifyContent: "center",
                                  height: 80,
                                  width: 80,
                                }
                              : {
                                  display: "flex",
                                  justifyContent: "center",
                                  height: 80,
                                  width: 80,
                                  border: "2px solid #dc3d22",
                                }
                          }>
                          <img src={res.img} alt={"img"} />
                        </Box>
                        <Typography
                          className={"group-hover:text-[#dc3d22]"}
                          sx={selected2 === index ? { color: "#dc3d22" } : {}}
                          variant={"h7"}>
                          {res.name}
                        </Typography>
                        <div>
                          <Radio
                            disabled={
                              (res.id === 922 &&
                                modelloCheckModal &&
                                finituraCheckModal) ||
                              (res.id === 922 &&
                                getAspetto.finish_id === 15 &&
                                (getAspetto.model_id === 9 ||
                                  getAspetto.finish_id === 10))
                            }
                            checked={selected2 === index}
                          />
                        </div>
                      </div>
                      {(res.id === 922 &&
                        modelloCheckModal &&
                        finituraCheckModal) ||
                        (res.id === 922 &&
                          getAspetto.finish_id === 15 &&
                          (getAspetto.model_id === 9 ||
                            getAspetto.finish_id === 10) && (
                            <Typography variant={"caption"}>
                              Disponibile nella finitura Smooth
                            </Typography>
                          ))}
                    </div>
                  );
                })}

                {colorCode2.color && (
                  <div
                    className={
                      "grid grid-cols-1 justify-items-start w-full group"
                    }>
                    <div
                      className={"grid grid-cols-3 gap-4 content-center mb-4"}
                      key={colorCode2.color.id}>
                      <Box
                        className={
                          "group-hover:border-2 group-hover:border-[#dc3d22]"
                        }
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          height: 80,
                          width: 80,
                          border: selected2 == colorCode2.color.RAL ? "2px solid #dc3d22" : "",
                          backgroundColor: colorCode2.color.HEX
                        }}>                        
                      </Box>
                      <Typography
                        className={"group-hover:text-[#dc3d22]"}
                        sx={selected2 === colorCode2.color.id ? { color: "#dc3d22" } : {}}
                        variant={"h7"}>
                        {colorCode2.color.RAL}-{colorCode2.color.Italian}
                      </Typography>
                      <div>
                        <Radio
                          disabled={
                            (colorCode2.color.id === 922 &&
                              modelloCheckModal &&
                              finituraCheckModal) ||
                            (colorCode2.color.id === 922 &&
                              getAspetto.finish_id === 15 &&
                              (getAspetto.model_id === 9 ||
                                getAspetto.finish_id === 10))
                          }
                          checked={selected2 === colorCode2.color.RAL}
                          onChange={() => {
                            dispatch(baseActions.setCampioneEsterno(false));
                            //dispatch(baseActions.setIdEsterni(colorCode2.id));
                            //setNameColor2(colorCode2.name);
                            //setIdColor2(colorCode2.id);
                            setFinituraEsterna(colorCode2.color);
                            setSelected2(colorCode2.color.RAL);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
                
                {!hideCustomRalFinituraEsterna && (<>
                  <div className={"flex items-center w-full"}>
                    <div>
                      <Typography sx={{ color: "#dc3d22" }} variant={"h7"}>
                        RAL non standard
                      </Typography>
                      <TextField
                        onChange={(e) => setColorCode2({...colorCode2, searchCode: e.target.value})}
                        fullWidth
                        placeholder={"8016"}
                        margin="dense"
                        id="code2"
                        name={"code2"}
                        type="text"
                        variant="outlined"
                        value={colorCode2.searchCode}
                        error={"search_error" == colorCode2.status}
                        helperText={"search_error" == colorCode2.status && "Colore non trovato"}
                        InputProps={{
                          endAdornment: (
                            <IconButton onClick={() => searchColorCode2()} sx={{ marginX: 2 }}>
                              <SearchIcon style={{ color: "black" }} />
                            </IconButton>
                          ),
                        }}
                      />
                    </div>
                  </div>

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.campioneEsterno}
                        onChange={() => {
                          setSelected2(null);
                          dispatch(baseActions.setCampioneEsterno(!state.campioneEsterno));
                          if (state.campioneEsterno === false) {
                            //setNameColor2("Colore a campione");
                            setFinituraEsterna({id: null, name: "Colore a campione", img: null});
                          }
                        }}
                      />
                    }
                    label={"Colore a campione"}
                  />
                </>)}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ItemColor;
