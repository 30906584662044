import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ColorCode, DoorType, FinishType, InstallationType, ModelType, NullableNumber, NullableString, SpringType, Step, StepSlugsEnum } from "../../../v2/core/common_types";
import { Color, ColorType, DoorConfiguration, FrameInternalColorType, Option, AiringConfiguration, WindowingConfiguration, PanelConfiguration, WindowSize, GlassType, IntegratedDoorConfiguration, GridSize, ExternalDoorConfiguration } from "../../../v2/models/door_configuration";
import { buildBoundingBoxPortina } from "../../../v2/routes/configurations/door/builder/components/object-draw/draw_utils";

export type DoorConfigurationBuilderState = {
    configuration: DoorConfiguration | null,
    steps: Step[],
    options: Option[], 
    colors: Color[],
    getExternalColorCode: () => NullableString,
    getInternalColorCode: () => NullableString,
    getWindowFrameColorCode: () => NullableString,
    getGridColorCode: () => NullableString,
    isOltreLuce: () => boolean,
    isMontanteSxInLuce: () => boolean,
    isMontanteDxInLuce: () => boolean,
    isTraversaInLuce: () => boolean,
    isLaminato: () => boolean,
    isPietravera: () => boolean,
    isWoodstyle: () => boolean,
    isColoreCampione: () => boolean,
    isSuperficieAreata: () => boolean,
    isMolleTorsione: () => boolean,
    getExternalColorType: () => ColorType,
    getInternalColorType: () => ColorType,
    isPlana: () => boolean,
    isGlasspanel: () => boolean,
    isFinituraGlasspanel: () => boolean,
    isGammaFullVision: () => boolean
}

const initialState: DoorConfigurationBuilderState = {
    configuration: null,
    steps: [],
    options: [], 
    colors: [],
    getExternalColorCode(): NullableString {
        if (!this.configuration) return undefined;

        if (this.configuration.tipoColoreEsterno == "ral_standard") {
            let color = this.colors.find(c => c?.id == this.configuration?.coloreEsterno);

            if (!color || !color.code) return undefined;

            return color.code;
        } else {
            return this.configuration.coloreEsternoNsRal;
        }
    },
    getInternalColorCode(): NullableString {
        if (!this.configuration) return undefined;

        if (this.configuration.tipoColoreInterno == "ral_standard") {
            let color = this.colors.find(c => c?.id == this.configuration?.coloreInterno);

            if (!color || !color.code) return undefined;

            return color.code;
        } else {
            return this.configuration.coloreInternoNsRal;
        }
    },
    getWindowFrameColorCode(): NullableString {
        if (!this.configuration) return undefined;
        if (!this.configuration.finestratura) return undefined;

        return this.configuration?.finestratura?.externalFrameColorCode;
    },
    getGridColorCode(): NullableString {
        if (!this.configuration) return undefined;
        if (!this.configuration.ventilazione) return undefined;

        return this.configuration.ventilazione.externalColorCode;
    },
    isOltreLuce(): boolean {
        if (!this.configuration) return false;
        
        return this.configuration.tipoInstallazioneMontanteDx == InstallationType.oltre_luce 
        || this.configuration.tipoInstallazioneMontanteSx == InstallationType.oltre_luce
        || this.configuration.tipoInstallazioneTraversa == InstallationType.oltre_luce;
    },
    isMontanteSxInLuce(): boolean {
        if (!this.configuration) return false;

        return this.configuration.tipoInstallazioneMontanteSx == InstallationType.in_luce;
    },
    isMontanteDxInLuce(): boolean {
        if (!this.configuration) return false;

        return this.configuration.tipoInstallazioneMontanteDx == InstallationType.in_luce;
    },
    isTraversaInLuce(): boolean {
        if (!this.configuration) return false;

        return this.configuration.tipoInstallazioneTraversa == InstallationType.in_luce;
    },
    isLaminato(): boolean {
        if (!this.configuration) return false;

        let finitura = this.options.find(p => p?.optionId == this.configuration?.finitura);
        return finitura?.name == FinishType.material_hpl 
        || finitura?.name == FinishType.wood_hpl;
    },
    isPietravera(): boolean {
        if (!this.configuration) return false;

        let finitura = this.options.find(p => p?.optionId == this.configuration?.finitura);
        return finitura?.name == FinishType.pietravera;
    },
    isWoodstyle(): boolean {
        if (!this.configuration) return false;

        let finitura = this.options.find(p => p?.optionId == this.configuration?.finitura);
        return finitura?.name == FinishType.woodstyle;
    },
    isColoreCampione(): boolean {
        if (!this.configuration) return false;

        return this.configuration.coloreEsternoACampione;
    },
    isSuperficieAreata(): boolean {
        if (!this.configuration) return false;
        if (!this.configuration.ventilazione) return false;

        return this.configuration.ventilazione.gridSize == GridSize.superificie_fq ||
        this.configuration.ventilazione.gridSize == GridSize.superificie_ft;
    },
    isMolleTorsione(): boolean {
        if (!this.configuration) return false;

        return this.configuration.tipoMolle == SpringType.torsione;
    },
    getExternalColorType(): ColorType {
        if (!this.configuration) return "ral_standard";

        return this.configuration.tipoColoreEsterno;
    },
    getInternalColorType(): ColorType {
        if (!this.configuration) return "ral_standard";

        return this.configuration.tipoColoreInterno;
    },
    isPlana(): boolean {
        if (!this.configuration) return false;

        let gamma = this.options.find(o => o?.optionId == this.configuration?.porta);

        return gamma ? gamma.name == DoorType.plana : false;
    },
    isGlasspanel(): boolean {
        if (!this.configuration) return false;
        if (!this.configuration.finestratura) return false;

        return this.configuration.finestratura.windowSize == WindowSize.glasspanel_vetro_4_12_4
        || this.configuration.finestratura.windowSize == WindowSize.glasspanel_vetro_5
        || this.configuration.finestratura.windowSize == WindowSize.glasspanel_vetro_6_7;
    },
    isGammaFullVision(): boolean {
        if (!this.configuration) return false;

        let gamma = this.options.find(o => o?.optionId == this.configuration?.porta);

        return gamma ? gamma.name == DoorType.full_vision : false;
    },
    isFinituraGlasspanel(): boolean {
        if (!this.configuration) return false;

        let gamma = this.options.find(o => o?.optionId == this.configuration?.finitura);

        return gamma ? gamma.name == FinishType.glasspanel : false;
    }
}

const reducers = {
    clearStorage(state: DoorConfigurationBuilderState) {
        state.configuration = initialState.configuration;
        state.steps = initialState.steps;
    },
    setConfiguration(state: DoorConfigurationBuilderState, action: PayloadAction<{configuration: DoorConfiguration, options: Option[], colors: Color[]}>) {
        state.configuration = action.payload.configuration;
        state.options = action.payload.options;
        state.colors = action.payload.colors;
    },
    setQuantita(state: DoorConfigurationBuilderState, action: PayloadAction<number>) {
        if (!state.configuration) return;
        state.configuration.quantita = action.payload;
    },
    setVanoConAltroAccesso(state: DoorConfigurationBuilderState, action: PayloadAction<boolean>) {
        if (!state.configuration) return;
        state.configuration.vanoConAltroAccesso = action.payload;
    },
    // setPortinaPedonaleAffiancata(state: DoorConfigurationBuilderState, action: PayloadAction<boolean>) {
    //     if (!state.configuration) return;
    //     state.configuration.portinaPedonaleAffiancata = action.payload;
    // },
    setPortinaPedonaleIntegrata(state: DoorConfigurationBuilderState, action: PayloadAction<boolean>) {
        if (!state.configuration) return;
        state.configuration.portinaPedonaleIntegrata = action.payload;
    },
    setProfonditaGarage(state: DoorConfigurationBuilderState, action: PayloadAction<number>) {
        if (!state.configuration) return;
        state.configuration.profonditaGarage = action.payload;
    },
    setAltezzaArchitrave(state: DoorConfigurationBuilderState, action: PayloadAction<number>) {
        if (!state.configuration) return;
        state.configuration.altezzaArchitrave = Math.max(0, action.payload);
    },
    setSpessoreArchitrave(state: DoorConfigurationBuilderState, action: PayloadAction<number>) {
        if (!state.configuration) return;
        state.configuration.spessoreArchitrave = Math.max(0, action.payload);
    },
    setLarghezzaSpallettaSx(state: DoorConfigurationBuilderState, action: PayloadAction<number>) {
        if (!state.configuration) return;
        state.configuration.larghezzaSpallettaSx = action.payload;
    },
    setSpessoreSpallettaSx(state: DoorConfigurationBuilderState, action: PayloadAction<number>) {
        if (!state.configuration) return;
        state.configuration.spessoreSpallettaSx = action.payload;
    },
    setLarghezzaSpallettaDx(state: DoorConfigurationBuilderState, action: PayloadAction<number>) {
        if (!state.configuration) return;
        state.configuration.larghezzaSpallettaDx = action.payload;
    },
    setSpessoreSpallettaDx(state: DoorConfigurationBuilderState, action: PayloadAction<number>) {
        if (!state.configuration) return;
        state.configuration.spessoreSpallettaDx = action.payload;
    },
    setLarghezzaForo(state: DoorConfigurationBuilderState, action: PayloadAction<number>) {
        if (!state.configuration) return;
        state.configuration.larghezzaForo = action.payload;
    },
    setAltezzaForo(state: DoorConfigurationBuilderState, action: PayloadAction<number>) {
        if (!state.configuration) return;
        state.configuration.altezzaForo = action.payload;
    },
    setLarghezzaPorta(state: DoorConfigurationBuilderState, action: PayloadAction<number>) {
        if (!state.configuration) return;
        state.configuration.larghezzaPorta = action.payload;
        state.configuration.portinaIntegrata = null;
        state.configuration.finestratura = null;
        state.configuration.ventilazione = null;
    },
    setAltezzaPorta(state: DoorConfigurationBuilderState, action: PayloadAction<number>) {
        if (!state.configuration) return;
        state.configuration.altezzaPorta = action.payload;
        state.configuration.portinaIntegrata = null;
        state.configuration.finestratura = null;
        state.configuration.ventilazione = null;
    },
    setTipoMolle(state: DoorConfigurationBuilderState, action: PayloadAction<SpringType>) {
        if (!state.configuration) return;
        state.configuration.tipoMolle = action.payload;
    },
    setIsMotorizzata(state: DoorConfigurationBuilderState, action: PayloadAction<boolean>) {
        if (!state.configuration) return;
        state.configuration.isMotorizzata = action.payload;
    },
    setTipoInstallazioneTraversa(state: DoorConfigurationBuilderState, action: PayloadAction<InstallationType>) {
        if (!state.configuration) return;
        state.configuration.tipoInstallazioneTraversa = action.payload;
    },
    setTipoInstallazioneMontanteDx(state: DoorConfigurationBuilderState, action: PayloadAction<InstallationType>) {
        if (!state.configuration) return;
        state.configuration.tipoInstallazioneMontanteDx = action.payload;
    },
    setTipoInstallazioneMontanteSx(state: DoorConfigurationBuilderState, action: PayloadAction<InstallationType>) {
        if (!state.configuration) return;
        state.configuration.tipoInstallazioneMontanteSx = action.payload;
    },
    setPorta(state: DoorConfigurationBuilderState, action: PayloadAction<NullableNumber>) {
        if (!state.configuration) return;
        state.configuration.porta = action.payload;
        state.configuration.modello = null;
        state.configuration.finitura = null;
        state.configuration.coloreEsterno = null;
        state.configuration.coloreEsternoNsRal = null;
        state.configuration.coloreInterno = null;
        state.configuration.coloreInternoNsRal = null;
        state.configuration.finestratura = null;
        state.configuration.ventilazione = null;
        state.configuration.portinaIntegrata = null;
        state.configuration.portinaAffiancata = null;
    },
    setModello(state: DoorConfigurationBuilderState, action: PayloadAction<NullableNumber>) {
        if (!state.configuration) return;
        state.configuration.modello = action.payload;
        state.configuration.finitura = null;
        state.configuration.coloreEsterno = null;
        state.configuration.coloreEsternoNsRal = null;
        state.configuration.coloreInterno = null;
        state.configuration.coloreInternoNsRal = null;
        state.configuration.finestratura = null;
        state.configuration.ventilazione = null;
        state.configuration.portinaIntegrata = null;
        state.configuration.portinaAffiancata = null;
    },
    setFinitura(state: DoorConfigurationBuilderState, action: PayloadAction<NullableNumber>) {
        if (!state.configuration) return;
        state.configuration.finitura = action.payload;
        state.configuration.tipoColoreEsterno = "ral_standard";
        state.configuration.coloreEsterno = null;
        state.configuration.coloreEsternoNsRal = null;
        state.configuration.tipoColoreInterno = "ral_standard";
        state.configuration.coloreInterno = null;
        state.configuration.coloreInternoNsRal = null;
        state.configuration.finituraVetro = null;
        state.configuration.finestratura = null;
        state.configuration.ventilazione = null;
        state.configuration.portinaIntegrata = null;
        state.configuration.portinaAffiancata = null;
    },
    setFinituraVetro(state: DoorConfigurationBuilderState, action: PayloadAction<NullableNumber>) {
        if (!state.configuration) return;
        state.configuration.finituraVetro = action.payload;
    },
    setTipoColoreEsterno(state: DoorConfigurationBuilderState, action: PayloadAction<ColorType>) {
        if (!state.configuration) return;
        state.configuration.tipoColoreEsterno = action.payload;
        state.configuration.coloreEsterno = null;
        state.configuration.coloreEsternoNsRal = null;
        state.configuration.coloreEsternoACampione = action.payload == "a_campione";
    },
    setColoreEsterno(state: DoorConfigurationBuilderState, action: PayloadAction<NullableNumber>) {
        if (!state.configuration) return;
        state.configuration.coloreEsterno = action.payload;
        if (action.payload) {
            state.configuration.coloreEsternoNsRal = null;
        }
    },
    setColoreEsternoNsRal(state: DoorConfigurationBuilderState, action: PayloadAction<NullableString>) {
        if (!state.configuration) return;
        state.configuration.coloreEsternoNsRal = action.payload;
        if (action.payload) {
            state.configuration.coloreEsterno = null;
        }
    },
    setTipoColoreInterno(state: DoorConfigurationBuilderState, action: PayloadAction<ColorType>) {
        if (!state.configuration) return;
        state.configuration.tipoColoreInterno = action.payload;
        state.configuration.coloreInterno = null;
        state.configuration.coloreInternoNsRal = null;
        state.configuration.coloreInternoACampione = action.payload == "a_campione";
    },
    setColoreInterno(state: DoorConfigurationBuilderState, action: PayloadAction<NullableNumber>) {
        if (!state.configuration) return;
        state.configuration.coloreInterno = action.payload;
        if (action.payload) {
            state.configuration.coloreInternoNsRal = null;
        }
    },
    setColoreInternoNsRal(state: DoorConfigurationBuilderState, action: PayloadAction<NullableString>) {
        if (!state.configuration) return;
        state.configuration.coloreInternoNsRal = action.payload;
        state.configuration.coloreInterno = null;
        state.configuration.coloreInternoACampione = false;
    },
    setColoreTelaioInternoDiverso(state: DoorConfigurationBuilderState, action: PayloadAction<boolean>) {
        if (!state.configuration) return;
        state.configuration.coloreTelaioInternoDiverso = action.payload;
    },

    /** Finestratura **/
    setFinestratura(state: DoorConfigurationBuilderState, action: PayloadAction<WindowingConfiguration>) {
        if (!state.configuration) return;
        
        state.configuration.finestratura = action.payload;
    },
    resetFinestratura(state: DoorConfigurationBuilderState) {
        if (!state.configuration) return;
        
        state.configuration.finestratura = null;
    },
    /** Finestratura **/

    /** Portina integrata **/
    setPortinaIntegrata(state: DoorConfigurationBuilderState, action: PayloadAction<IntegratedDoorConfiguration>) {
        if (!state.configuration) return;

        let bbox = buildBoundingBoxPortina(state.configuration.larghezzaForo, state.configuration.altezzaForo, state.isOltreLuce(), action.payload);
        
        state.configuration.portinaIntegrata = action.payload;
        state.configuration.portinaIntegrata.bbox = bbox;
        state.configuration.finestratura = null;
        state.configuration.ventilazione = null;
    },
    resetPortinaIntegrata(state: DoorConfigurationBuilderState) {
        if (!state.configuration) return;
        
        state.configuration.portinaIntegrata = null;
    },
    /** Portina integrata **/

    /** Portina affiancata **/
    setPortinaAffiancata(state: DoorConfigurationBuilderState, action: PayloadAction<ExternalDoorConfiguration>) {
        if (!state.configuration) return;
        
        state.configuration.portinaAffiancata = action.payload;
    },
    resetPortinaAffiancata(state: DoorConfigurationBuilderState) {
        if (!state.configuration) return;
        
        state.configuration.portinaAffiancata = null;
    },
    /** Portina affiancata **/

    setVentilazione(state: DoorConfigurationBuilderState, action: PayloadAction<AiringConfiguration>) {
        if (!state.configuration) return;
        state.configuration.ventilazione = action.payload;
    },  
    resetVentilazione(state: DoorConfigurationBuilderState) {
        if (!state.configuration) return;
        
        state.configuration.ventilazione = null;
    },
    setStepActive(state: DoorConfigurationBuilderState, action: PayloadAction<{stepSlug: StepSlugsEnum, isActive: boolean}>) {
        let steps = state.steps;
        
        steps.forEach(s => {
            if (s.slug == action.payload.stepSlug) {
                s.isActive = action.payload.isActive;
            } else {
                s.isActive = false;
            }
        });

        state.steps = steps;
    },
    setStepComplete(state: DoorConfigurationBuilderState, action: PayloadAction<{stepSlug: StepSlugsEnum, isComplete: boolean}>) {
        let steps = state.steps;
        
        let step = steps.find(s => s.slug == action.payload.stepSlug);
        if (step) {
            step.isComplete = action.payload.isComplete;
            state.steps = steps;
        }
    },
    setStepVisible(state: DoorConfigurationBuilderState, action: PayloadAction<{stepSlug: StepSlugsEnum, isVisible: boolean}>) {
        let steps = state.steps;
        
        let step = steps.find(s => s.slug == action.payload.stepSlug);
        if (step && step.isVisible != action.payload.isVisible) {
            step.isVisible = action.payload.isVisible;
            state.steps = steps;
        }
    },
    resetSteps(state: DoorConfigurationBuilderState) {
        let steps = state.steps;
        steps.forEach(s => s.isComplete = false);
        state.steps = steps;
    },
    setSteps(state: DoorConfigurationBuilderState, action: PayloadAction<Step[]>) {
        state.steps = action.payload;
    }
};

const slice = createSlice({
    name: "door_configuration_builder",
    initialState,
    reducers
});

const { actions, reducer } = slice;
export function useDoorConfigurationBuilderActions()
{
    return actions;
}
export default reducer;