import React, { FC, useEffect, useState } from "react";
import { 
    DEFAULT_LARGHEZZA_GUARNIZIONE,
    PROFONDITA_INVOLUCRO, 
    SPESSORE_STIPITE, 
    SPESSORE_TELAIO_GLASSPANEL
} from "../../../../../../../core/common_constants";
import { Group, Label, Rect, Tag, Text } from "react-konva";
import { degree, distance, toDrawUnit } from "../../../../../../../core/common_functions";
import { Vector } from "vecti";
import { useStoreSelector } from "../../../../../../../../hooks/StoreHooks";
import { InstallationType, SpringType } from "../../../../../../../core/common_types";
import { Tubolare } from "../accessories/tubolare";
import { Color, Option } from "../../../../../../../models/door_configuration";
import { Guarnizione } from "../accessories/guarnizione";
import { DatiPannello } from "../../../../../../../core/common_draw_types";
import useImage from 'use-image';
import { useFinishes } from "../../../../../../../providers/finish_provider";

export const PortaSezionaleFullVision: FC<{
    pf: Vector, 
    lfdraw: number, 
    hfdraw: number, 
    lpdraw: number, 
    hpdraw: number, 
    customizeAiring?: boolean, 
    customizeWindowing?: boolean,
    frameColor: HTMLImageElement|undefined}> = (props) => {   
    const {pf, lpdraw, hpdraw, lfdraw, hfdraw, customizeAiring, customizeWindowing, frameColor} = props;
    const larghezzaGuarnizione = toDrawUnit(DEFAULT_LARGHEZZA_GUARNIZIONE);
    const {doorConfigurationBuilder} = useStoreSelector(store => store);
    const finishesProvider = useFinishes();
        
    const buildPannelli = (): DatiPannello[] => {
        let hDisp: number = 0;
        let wDisp: number = 0;
        let ptBottomLeft = Vector.of([(lfdraw/2)-(lpdraw/2), hfdraw]);
        let ptTopLeft = Vector.of([(lfdraw/2)-(lpdraw/2), hfdraw-hpdraw]);
        let ptTopRight = Vector.of([(lfdraw/2)+(lpdraw/2), hfdraw-hpdraw]);
        let altezzaAssoluta = ptBottomLeft.y - ptTopLeft.y;
        let larghezzaAssoluta = ptTopRight.x - ptTopLeft.x;

        hDisp = altezzaAssoluta;
        wDisp = larghezzaAssoluta;

        if (doorConfigurationBuilder.isOltreLuce()) {
            let vecDirTopLeft = pf.subtract(Vector.of([0, 0])).normalize();
            let vecDirTopRight = pf.subtract(Vector.of([lfdraw, 0])).normalize();
            let vecDirBottomLeft = pf.subtract(Vector.of([0, hfdraw])).normalize();
            
            if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.in_luce) {                    
                ptTopLeft = Vector.of([lfdraw-lpdraw, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptBottomLeft = Vector.of([lfdraw-lpdraw, hfdraw]).add(vecDirBottomLeft.multiply(SPESSORE_STIPITE));
                ptTopRight = Vector.of([lfdraw-10, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.in_luce) {
                ptTopLeft = Vector.of([10, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptBottomLeft = Vector.of([10, hfdraw]).add(vecDirBottomLeft.multiply(SPESSORE_STIPITE));
                ptTopRight = Vector.of([lfdraw-(lfdraw-lpdraw), hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
            } else {
                ptTopLeft = Vector.of([(lfdraw/2)-(lpdraw/2), hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptBottomLeft = Vector.of([(lfdraw/2)-(lpdraw/2), hfdraw]).add(vecDirBottomLeft.multiply(SPESSORE_STIPITE));
                ptTopRight = Vector.of([(lfdraw/2)+(lpdraw/2), hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
            }
            
            hDisp = ptBottomLeft.y - ptTopLeft.y;
            wDisp = ptTopRight.x - ptTopLeft.x;
        }

        let hp: number = hDisp / 6;
        let y: number = ptBottomLeft.y;
        let panels: DatiPannello[] = [];
        let altezzaAssolutaPannello = altezzaAssoluta / 6;

        let hCur: number = 0;
        let panelNumber = 1;
        let panelCount: number = 0;
        while (hCur < hDisp) {
            let height = hp;
            let selectable: boolean = true;
            let isTagliato: boolean = false;

            if ((hCur + hp) > hDisp) {
                height = hDisp - hCur;
                selectable = false;
                isTagliato = true;
            }

            let p: DatiPannello = { 
                bbox: {x: ptTopLeft.x, y: y - height, width: wDisp, height},
                //yCenter: y - (hp / 2),
                panelNumber,
                panelCount,
                larghezzaAssoluta,
                altezzaAssoluta: altezzaAssolutaPannello,
                altezzaRelativa: altezzaAssolutaPannello,
                selectable
            };

            panels.push(p);

            hCur += height;
            y -= height;
            panelNumber++;

            if (panelNumber == 7) break;
        }

        panelCount = panels.length;
        panels.forEach(p => p.panelCount = panelCount);
        
        return panels;
    }

    const buildGuarnizioneSx = (): {x: number, y: number, length: number, rotation: number} => {
        if (!doorConfigurationBuilder.isOltreLuce()) {
            return {
                x: (lfdraw/2)-(lpdraw/2), 
                y: hfdraw - hpdraw, 
                length: hpdraw,
                rotation: 0
            };          
        } else {
            let vecDirTopLeft = pf.subtract(Vector.of([0, 0])).normalize();            
            let vecDirBottomLeft = pf.subtract(Vector.of([0, hfdraw])).normalize();
            
            let ptTopLeft: Vector;
            let ptBottomLeft: Vector;
            if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.in_luce) {
                ptTopLeft = Vector.of([0, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptBottomLeft = Vector.of([0, hfdraw]).add(vecDirBottomLeft.multiply(SPESSORE_STIPITE));
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.in_luce) {
                ptTopLeft = Vector.of([10, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptBottomLeft = Vector.of([10, hfdraw]).add(vecDirBottomLeft.multiply(SPESSORE_STIPITE));
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.in_luce) {
                ptTopLeft = Vector.of([0, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptBottomLeft = Vector.of([0, hfdraw]).add(vecDirBottomLeft.multiply(SPESSORE_STIPITE));
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.in_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.in_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.oltre_luce) {
                ptTopLeft = Vector.of([10, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptBottomLeft = Vector.of([10, hfdraw]).add(vecDirBottomLeft.multiply(SPESSORE_STIPITE));
            } else {
                ptTopLeft = Vector.of([0, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptBottomLeft = Vector.of([0, hfdraw]).add(vecDirBottomLeft.multiply(SPESSORE_STIPITE));
            }
            
            return {
                x: ptTopLeft.x, 
                y: ptTopLeft.y, 
                length: ptBottomLeft.y-ptTopLeft.y, 
                rotation: 0
            }; 
        }
    }

    const buildGuarnizioneDx = (): {x: number, y: number, length: number, rotation: number} => {
        if (!doorConfigurationBuilder.isOltreLuce()) {
            return {
                x: (lfdraw/2)+(lpdraw/2) - larghezzaGuarnizione,
                y: hfdraw - hpdraw,
                length: hpdraw,
                rotation: 0
            }
        } else {
            let vecDirTopRight = pf.subtract(Vector.of([lfdraw, 0])).normalize();
            let vecDirBottomRight = pf.subtract(Vector.of([lfdraw, hfdraw])).normalize();

            let ptTopRight: Vector;
            let ptBottomRight: Vector;
            if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.in_luce) {                    
                ptTopRight = Vector.of([lfdraw-larghezzaGuarnizione-10, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
                ptBottomRight = Vector.of([lfdraw-larghezzaGuarnizione-10, hfdraw]).add(vecDirBottomRight.multiply(SPESSORE_STIPITE));
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.in_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.oltre_luce) {                    
                ptTopRight = Vector.of([lfdraw-larghezzaGuarnizione, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
                ptBottomRight = Vector.of([lfdraw-larghezzaGuarnizione, hfdraw]).add(vecDirBottomRight.multiply(SPESSORE_STIPITE));
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.in_luce) {
                ptTopRight = Vector.of([lfdraw-larghezzaGuarnizione, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));   
                ptBottomRight = Vector.of([lfdraw-larghezzaGuarnizione, hfdraw]).add(vecDirBottomRight.multiply(SPESSORE_STIPITE));             
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.in_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.in_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.oltre_luce) {
                ptTopRight = Vector.of([lfdraw-10-larghezzaGuarnizione, 0]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));   
                ptBottomRight = Vector.of([lfdraw-10-larghezzaGuarnizione, hfdraw]).add(vecDirBottomRight.multiply(SPESSORE_STIPITE));
            } else {
                ptTopRight = Vector.of([lfdraw-larghezzaGuarnizione, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
                ptBottomRight = Vector.of([lfdraw-larghezzaGuarnizione, hfdraw]).add(vecDirBottomRight.multiply(SPESSORE_STIPITE));
            }

            return {
                x: ptTopRight.x, 
                y: ptTopRight.y, 
                length: ptBottomRight.y-ptTopRight.y, 
                rotation: 0
            }; 
        }
    }

    const buildGuarnizioneTraversa = (): {x: number, y: number, length: number, rotation: number} => {
        if (!doorConfigurationBuilder.isOltreLuce()) {
            return {
                x: (lfdraw/2)+(lpdraw/2),
                y: hfdraw-hpdraw,
                length: lpdraw,
                rotation: 90
            }
        } else {
            let vecDirTopLeft = pf.subtract(Vector.of([0, 0])).normalize();
            let vecDirTopRight = pf.subtract(Vector.of([lfdraw, 0])).normalize();

            let ptTopLeft: Vector;
            let ptTopRight: Vector;
            if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.in_luce) {                               
                if (doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.in_luce) {
                    ptTopLeft = Vector.of([0, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));         
                    ptTopRight = Vector.of([lfdraw - 10, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
                } else {
                    ptTopLeft = Vector.of([0, 0]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));         
                    ptTopRight = Vector.of([lfdraw - 10, 0]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
                }
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.in_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.oltre_luce) {            
                if (doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.in_luce) {
                    ptTopLeft = Vector.of([10, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                    ptTopRight = Vector.of([lfdraw, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));                
                } else {
                    ptTopLeft = Vector.of([10, 0]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                    ptTopRight = Vector.of([lfdraw, 0]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));                
                }                        
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.in_luce) {                    
                ptTopLeft = Vector.of([0, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptTopRight = Vector.of([lfdraw, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));                
            } else {
                if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.in_luce &&
                    doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.in_luce) {
                    ptTopLeft = Vector.of([10, 0]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                    ptTopRight = Vector.of([lfdraw - 10, 0]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
                } else {
                    ptTopLeft = Vector.of([0, 0]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                    ptTopRight = Vector.of([lfdraw, 0]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
                }
            }

            return {
                x: ptTopRight.x, 
                y: ptTopRight.y, 
                length: ptTopRight.x-ptTopLeft.x, 
                rotation: 90
            }; 
        }
    }

    const buildTubolareSx = (): {x: number, y: number, length: number, width: number, rotation: number} => {        
        if (!doorConfigurationBuilder.isOltreLuce()) {
            return {
                x: (lfdraw/2)-(lpdraw/2) - 10, 
                y: hfdraw - hpdraw, 
                length: hpdraw,
                width: 100,
                rotation: 0
            };
        } else {
            let vecDirTopLeft = pf.subtract(Vector.of([0, 0])).normalize();            
            let vecDirBottomLeft = pf.subtract(Vector.of([0, hfdraw])).normalize();

            let ptTopLeft: Vector;
            let ptBottomLeft: Vector;
            if (doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.in_luce) {
                ptTopLeft = Vector.of([0, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptBottomLeft = Vector.of([0, hfdraw]).add(vecDirBottomLeft.multiply(SPESSORE_STIPITE));
            } else {
                ptTopLeft = Vector.of([0, 0]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptBottomLeft = Vector.of([0, hfdraw]).add(vecDirBottomLeft.multiply(SPESSORE_STIPITE));
            }

            return {
                x: ptTopLeft.x,
                y: ptTopLeft.y,
                length: distance(ptTopLeft.x, ptTopLeft.y, ptBottomLeft.x, ptBottomLeft.y),
                width: 100,
                rotation: 0
            }; 
        }
    }

    const buildTubolareDx = (): {x: number, y: number, length: number, width: number, rotation: number} => {
        if (!doorConfigurationBuilder.isOltreLuce()) {
            return {
                x: (lfdraw/2)+(lpdraw/2),
                y: hfdraw - hpdraw,
                length: hpdraw,
                width: 100,
                rotation: 0
            }
        } else {
            let vecDirTopRight = pf.subtract(Vector.of([lfdraw, 0])).normalize();
            let vecDirBottomRight = pf.subtract(Vector.of([lfdraw, hfdraw])).normalize();

            let ptTopRight: Vector;
            let ptBottomRight: Vector
            if (doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.in_luce) {
                ptTopRight = Vector.of([lfdraw-10, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
                ptBottomRight = Vector.of([lfdraw-10, hfdraw]).add(vecDirBottomRight.multiply(SPESSORE_STIPITE));
            } else {
                ptTopRight = Vector.of([lfdraw-10, 0]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
                ptBottomRight = Vector.of([lfdraw-10, hfdraw]).add(vecDirBottomRight.multiply(SPESSORE_STIPITE));
            }

            return {
                x: ptTopRight.x,
                y: ptTopRight.y,
                length: distance(ptTopRight.x, ptTopRight.y, ptBottomRight.x, ptBottomRight.y),
                width: 100,
                rotation: 0
            }; 
        }
    }

    const buildTubolareTraversa = (sectionWidth:number, offset: number): {x: number, y: number, length: number, width: number, rotation: number} => {
        if (!doorConfigurationBuilder.isOltreLuce()) {
            return {
                x: lfdraw-lpdraw - (2 * 10),
                y: hfdraw-hpdraw - offset,
                length: lpdraw + (2 * 10),
                width: sectionWidth,
                rotation: 270
            }
        } else {
            let vecDirTopLeft = pf.subtract(Vector.of([0, 0])).normalize();
            let vecDirTopRight = pf.subtract(Vector.of([lfdraw, 0])).normalize();

            let ptTopLeft: Vector;
            let ptTopRight: Vector;
            if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.in_luce) {
                ptTopLeft = Vector.of([0, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptTopRight = Vector.of([lfdraw, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.in_luce) {
                ptTopLeft = Vector.of([0, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptTopRight = Vector.of([lfdraw, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.in_luce) {
                ptTopLeft = Vector.of([0, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptTopRight = Vector.of([lfdraw, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
            } else {
                ptTopLeft = Vector.of([(lfdraw/2)-(lpdraw/2), hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptTopRight = Vector.of([(lfdraw/2)+(lpdraw/2), hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
            }

            return {
                x: ptTopLeft.x,
                y: ptTopLeft.y - offset,
                length: distance(ptTopLeft.x, ptTopLeft.y, ptTopRight.x, ptTopRight.y),
                width: sectionWidth,
                rotation: 270
            }; 
        }
    }

    const renderTubolareVeletta = () => {
        if (!doorConfigurationBuilder.configuration) return null;
        
        if (doorConfigurationBuilder.configuration.tipoMolle == SpringType.torsione) {
            let sectionWidth: number = 100;
            let doppioTubolare: boolean = true;
            
            return (
                <>
                    <Tubolare color={frameColor} {...buildTubolareTraversa(sectionWidth, 0)} />
                    {doppioTubolare && <Tubolare color={frameColor} {...buildTubolareTraversa(sectionWidth, 10)} />}
                </>
            );
        } else if (doorConfigurationBuilder.configuration?.tipoMolle == SpringType.trazione) {
            let sectionWidth = 60;
            if (doorConfigurationBuilder.configuration?.isMotorizzata) {
                sectionWidth = 120;
            } 
            return <Tubolare color={frameColor} {...buildTubolareTraversa(sectionWidth, 0)} />
        }

        return null;
    }

    const renderPanels = () => {
        let pannelli = buildPannelli();
                
        return pannelli.map((p, idx, ar) => (
            <PannelloPortaFullVision
                key={`panel_${(idx+1)}`}
                panelData={p}
                frameColor={frameColor}
                customizeAiring={customizeAiring}
                customizeWindowing={customizeWindowing}
            />
        ));
    }

    const renderLabelCampione = () => {
        let pt1: Vector = Vector.of([0, hpdraw]);
        let pt2: Vector = Vector.of([lpdraw, 0]);
        let rads = degree(pt1.x, pt1.y, pt2.x, pt2.y);
        return (
            <Label x={(lfdraw/2)-75} y={(hfdraw/2)+40} rotation={rads} opacity={0.6}>
                <Tag fill="white" stroke="red"/>
                <Text text={"Colore da campione"} padding={5} fontSize={20} />
            </Label>
        );
    }

    if (!doorConfigurationBuilder.configuration) return null;
    if (!doorConfigurationBuilder.configuration.porta) return null;
    if (!doorConfigurationBuilder.configuration.modello) return null;

    return (
        <>
            {renderPanels()}

            {doorConfigurationBuilder.isMontanteDxInLuce() && <Tubolare color={frameColor} {...buildTubolareSx()} />}
            {doorConfigurationBuilder.isMontanteSxInLuce() && <Tubolare color={frameColor} {...buildTubolareDx()} />}
            {doorConfigurationBuilder.isTraversaInLuce() && renderTubolareVeletta()}
            
            <Guarnizione {...buildGuarnizioneSx()} />
            <Guarnizione {...buildGuarnizioneDx()} />
            <Guarnizione {...buildGuarnizioneTraversa()} />

            {doorConfigurationBuilder.configuration.tipoColoreEsterno == "a_campione" && renderLabelCampione()}
        </>
    );
}

const PannelloPortaFullVision: FC<{customizeAiring?: boolean, customizeWindowing?: boolean, frameColor: HTMLImageElement|undefined, panelData: DatiPannello}> = (props) => {

    const renderVetrata = () => {
        return (
            <Group x={props.panelData.bbox.x} y={props.panelData.bbox.y}>
                <Rect
                    x={0}
                    y={0} 
                    width={props.panelData.bbox.width} 
                    height={props.panelData.bbox.height} 
                    stroke={"#000"} 
                    strokeWidth={0.75}
                    fillPatternImage={props.frameColor}
                />
                <Rect
                    x={SPESSORE_TELAIO_GLASSPANEL}
                    y={SPESSORE_TELAIO_GLASSPANEL} 
                    width={props.panelData.bbox.width- (2*SPESSORE_TELAIO_GLASSPANEL)} 
                    height={props.panelData.bbox.height - (2*SPESSORE_TELAIO_GLASSPANEL)} 
                    stroke={"#000"} 
                    strokeWidth={0.75}
                    fill={"lightblue"}
                />
            </Group>
        );
    }

    return (
        <Group>
            <Rect            
                x={props.panelData.bbox.x}
                y={props.panelData.bbox.y} 
                width={props.panelData.bbox.width} 
                height={props.panelData.bbox.height} 
                stroke={"#000"} 
                strokeWidth={0.5}
                strokeEnabled
            />

            {renderVetrata()}
        </Group>
    );
}