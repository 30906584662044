import React, { FC, useState } from "react";
import { Rect } from "react-konva";
import { DEFAULT_STROKE_WIDTH } from "../../../../../../../core/common_constants";
import { toDrawUnit } from "../../../../../../../core/common_functions";

export const Tubolare: FC<{x: number, y: number, length: number, width: number, rotation: number, color: HTMLImageElement|undefined}> = ({x, y, length, width, rotation, color}) => {

    return (
        <Rect
            x={x} 
            y={y} 
            width={toDrawUnit(width)} 
            height={length} 
            stroke={"#000"} 
            strokeWidth={DEFAULT_STROKE_WIDTH}
            fillPatternImage={color}
            rotation={rotation}
        />
    );
}