import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { GarageInternal } from "./components/object-draw/environment/garage_internal";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { Scene, SceneViewLabel } from "./components/object-draw/scene";
import { useStoreDispatch, useStoreSelector } from "../../../../../hooks/StoreHooks";
import { FrmImpostazioniIniziali } from "./components/forms/frm_impostazioni_iniziali";
import { useNavigate, useParams } from "react-router-dom";
import { useDialogs } from "../../../../providers/dialogs_provider";
import { useDoorConfigurationBuilderActions } from "../../../../../store/reducers/door_configuration_builder";
import { useConfigurationsService } from "../../../../services/configurations_service";
import { getCurrentUser } from "../../../../core/common_functions";
import { DoorOption, FormDimensioniModel, Option } from "../../../../models/door_configuration";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { ALTEZZA_GARAGE, LARGHEZZA_GARAGE, PROFONDITA_INVOLUCRO, PROFONDITA_INVOLUCRO_INTERNO } from "../../../../core/common_constants";

export const DimensionsPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { base, doorConfigurationBuilder } = useStoreSelector(store => store);
  const dialogs = useDialogs();
  const dispatcher = useStoreDispatch();
  const { setStepActive, setStepComplete, setStepVisible, setLarghezzaPorta, setAltezzaPorta } = useDoorConfigurationBuilderActions();
  const configurationService = useConfigurationsService();
  const currentUser = getCurrentUser(base);
  const [isSubmit, setSubmit] = useState<boolean>(false);
  const [dialogOptions, setDialogOptions] = useState<DoorOption[] | null>(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const closeDialogOptions = () => {
    setDialogOptions(null);
  };

  const submit = async () => {
    try {
      if (!doorConfigurationBuilder.configuration) throw "invalid configuration";

      if (!doorConfigurationBuilder.configuration) throw { severity: "error", message: "Nessuna configurazione in corso" };
      if (doorConfigurationBuilder.configuration.larghezzaForo <= 0) throw { severity: "warning", message: "Larghezza non valida" };
      if (doorConfigurationBuilder.configuration.altezzaForo <= 0) throw { severity: "warning", message: "Altezza non valida" };
      if (doorConfigurationBuilder.configuration.profonditaGarage < doorConfigurationBuilder.configuration.altezzaForo) throw { severity: "warning", message: "Profondità garage non valida" };

      setSubmit(true);

      await configurationService.verificaVincoliDimensioni(doorConfigurationBuilder.configuration);

      let result = await configurationService.cercaOpzioniPorta(currentUser, doorConfigurationBuilder.configuration);

      let lunghezzaOptions = result.filter(o => o.tipo == "larghezza_standard");
      if (lunghezzaOptions.length == 1) lunghezzaOptions[0].isSelected = true;

      let altezzaOptions = result.filter(o => o.tipo == "altezza_standard");
      if (altezzaOptions.length == 1) altezzaOptions[0].isSelected = true;

      if (lunghezzaOptions[0].valore < 2000) throw { severity: "warning", message: "Larghezza non consentita. Consentita solo installazione Oltre Luce." };
      if (altezzaOptions[0].valore < 2000) throw { severity: "warning", message: "Altezza non consentita. Consentita solo installazione Oltre Luce." };

      setDialogOptions(result);

      setSubmit(false);      
    } catch (e: any) {
      setSubmit(false);

      if (typeof e == "string") {
        dialogs?.showError("Attenzione...", e);
      } else {
        if (e.severity == "error") {
          dialogs?.showError("Attenzione...", e.message);
        } else if (e.severity == "warning") {
          dialogs?.showWarning("Attenzione...", e.message);
        }
      }
    }
  }

  const selectOption = async () => {
    let larghezzaPorta: number = 0;
    let altezzaPorta: number = 0;

    let selectedOptions = dialogOptions?.filter(o => o.isSelected);
    if (selectedOptions && selectedOptions.length <= 0) {
      dialogs?.showWarning("Attenzione...", "Nessuna opzione selezionata");
      return;
    }
    selectedOptions?.forEach(o => {
      if (o.tipo == "larghezza_standard") {
        larghezzaPorta = o.valore;
        dispatcher(setLarghezzaPorta(o.valore));
      } else if (o.tipo == "altezza_standard") {
        altezzaPorta = o.valore;
        dispatcher(setAltezzaPorta(o.valore));
      }
    });

    closeDialogOptions();

    setSubmit(true);
    try {
      if (!doorConfigurationBuilder.configuration) throw "invalid configuration";

      let data: FormDimensioniModel = {
        quantita: doorConfigurationBuilder.configuration.quantita,
        vanoConAltroAccesso: doorConfigurationBuilder.configuration.vanoConAltroAccesso,
        larghezzaForo: doorConfigurationBuilder.configuration.larghezzaForo,
        altezzaForo: doorConfigurationBuilder.configuration.altezzaForo,
        larghezzaPorta: larghezzaPorta,
        altezzaPorta: altezzaPorta,
        profonditaGarage: doorConfigurationBuilder.configuration.profonditaGarage,
        altezzaArchitrave: doorConfigurationBuilder.configuration.altezzaArchitrave,
        spessoreArchitrave: doorConfigurationBuilder.configuration.spessoreArchitrave,
        larghezzaSpallettaSx: doorConfigurationBuilder.configuration.larghezzaSpallettaSx,
        spessoreSpallettaSx: doorConfigurationBuilder.configuration.spessoreSpallettaSx,
        larghezzaSpallettaDx: doorConfigurationBuilder.configuration.larghezzaSpallettaDx,
        spessoreSpallettaDx: doorConfigurationBuilder.configuration.spessoreSpallettaDx,
        isMotorizzata: doorConfigurationBuilder.configuration.isMotorizzata,
        tipoInstallazioneMontanteDx: doorConfigurationBuilder.configuration.tipoInstallazioneMontanteDx,
        tipoInstallazioneMontanteSx: doorConfigurationBuilder.configuration.tipoInstallazioneMontanteSx,
        tipoInstallazioneTraversa: doorConfigurationBuilder.configuration.tipoInstallazioneTraversa,
        tipoMolle: doorConfigurationBuilder.configuration.tipoMolle,
      };

      await configurationService.updateDimensions(currentUser, doorConfigurationBuilder.configuration.configurationId, data);
      
      dispatcher(setStepVisible({ stepSlug: "motorizzazione", isVisible: data.isMotorizzata }));
      dispatcher(setStepVisible({ stepSlug: "accessori-motorizzazione", isVisible: data.isMotorizzata }));

      dispatcher(setStepComplete({ stepSlug: "dimensioni", isComplete: true }));

      navigate(`/door-configurations/${id}/aspetto`);
    } catch (e: any) {
      setSubmit(false);

      if (typeof e == "string") {
        dialogs?.showError("Attenzione...", e);
      } else {
        if (e.severity == "error") {
          dialogs?.showError("Attenzione...", e.message);
        } else if (e.severity == "warning") {
          dialogs?.showWarning("Attenzione...", e.message);
        }
      }
    }
  }

  useEffect(() => {
    if (!id || isNaN(Number(id)) || !doorConfigurationBuilder.configuration) {
      navigate("/configurations");
    } else {
      dispatcher(setStepActive({ stepSlug: "dimensioni", isActive: true }));      
    }
  }, []);

  return (
    <>
      <div className="row m-0 h-full">
        <div className="col-xl-7 p-0" style={{ position: "relative", height: "100%" }}>
            <Scene 
              bbox={{
                x: -PROFONDITA_INVOLUCRO,
                y: -PROFONDITA_INVOLUCRO,
                width: LARGHEZZA_GARAGE+(2*PROFONDITA_INVOLUCRO),
                height: ALTEZZA_GARAGE+(2*PROFONDITA_INVOLUCRO)
              }}
              sidebarSx={(<SceneViewLabel text="VISTA INTERNA" rotation="-90deg" />)}
              panEnabled 
              zoomEnabled>
                <GarageInternal>
                  
                </GarageInternal>
            </Scene>
        </div>
        <div className="col-xl-5 p-0" style={{ overflowY: "auto", height: "100%", borderLeft: "solid 1px #ccc" }}>
          <div className="p-4">
            <h5 className="text-primary">Nuova configurazione</h5>
            <FrmImpostazioniIniziali />

            <div className="d-flex flex-row-reverse mt-3">
              <Button disabled={isSubmit} variant="contained" className="ps-3 pe-1" onClick={() => submit()}>
                <span>Avanti</span>
                <NavigateNextIcon />
              </Button>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        fullScreen={fullScreen}
        maxWidth={"lg"}
        open={dialogOptions != null}
        onClose={closeDialogOptions}>
        <DialogTitle textAlign={"center"}>Misure passaggio</DialogTitle>
        <DialogContent sx={{ backgroundColor: "#f1f1f1" }} className="pt-3 pb-1">
          <div>
            <h5 className="justify-content-between d-flex m-0">
              <span className=" me-5">Larghezza passaggio</span>
              {/* <strong>{doorConfigurationBuilder.configuration?.larghezzaForo} mm</strong> */}
              {dialogOptions?.filter(o => o.tipo == "larghezza_standard").map((o, idx) => (<strong key={"val_l_"+idx}>{o.valore} mm</strong>))}
            </h5>
            {/* <List>
              {dialogOptions?.filter(o => o.tipo == "larghezza_standard").map((o, idx) => (
                <ListItem key={`larghezza_standard_${(idx+1)}`} disablePadding>
                  <ListItemButton selected={o.isSelected}>
                    <ListItemText>
                      <div className="d-flex justify-content-between">
                        <span className="me-3">Larghezza porta:</span> <strong>{o.valore} mm</strong>
                      </div>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              ))}
            </List> */}
          </div>

          <div className="mt-3">
            <h5 className="text-primary d-flex justify-content-between">
              <span className="me-5">Altezza passaggio</span>
              {/* <strong>{doorConfigurationBuilder.configuration?.altezzaForo} mm</strong> */}
              {dialogOptions?.filter(o => o.tipo == "altezza_standard").map((o, idx) => (<strong key={"val_a_"+idx}>{o.valore} mm</strong>))}
            </h5>
            {/* <List>
              {dialogOptions?.filter(o => o.tipo == "altezza_standard").map((o, idx) => (
                <ListItem key={`altezza_standard_${(idx+1)}`} disablePadding>
                  <ListItemButton selected={o.isSelected}>
                    <ListItemText>
                      <div className="d-flex justify-content-between">
                        <span className="me-3">Altezza porta:</span> <strong>{o.valore} mm</strong>
                      </div>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              ))}
            </List> */}
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={closeDialogOptions}>
            Annulla
          </Button>
          <Button variant="contained" color="primary" onClick={selectOption} disabled={dialogOptions?.filter(o => o.isSelected).length != 2}>
            Continua
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}