import * as React from "react";
import Grid from "@mui/material/Grid";
import TopBar from "../../components/TopBar/TopBar";
import { LayoutPage } from "../../components/Layout/layoutPage";
import {
  Button,
  FormControl,
  Select,
  Alert,
  TextField,
  InputLabel,
  InputAdornment,
  AlertTitle,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import TabsCustom from "../../components/Tabs/Tabs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import GridPreventivi from "../../components/GrdiPreventivi/gridPreventivi";
import axios from "axios";
import Loading from "../../components/Loading/loading";
import MenuItem from "@mui/material/MenuItem";
import { importBaseActions } from "../../store/reducers/base";
import { Events, useEventBroadcast } from "../../core/EventBroadcast";
import { AccessoriesEnum } from './../../core/AccessoriesEnum';
import { getProductName } from "../../Utility";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

const platinbronze = "https://media.deltaoverlap.com/public/img/maniglie/Platinbronze.png";
const platinsilver = "https://media.deltaoverlap.com/public/img/maniglie/Platinsilver.png";

const Maniglierie = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.base);
  const baseActions = importBaseActions();
  //const history = useHistory();
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [checkColor1, setCheckColor1] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [sbloccoObb, setSbloccoObb] = useState(false);
  const [stepState, setStepState] = useState({
    state: "loading", 
    steps: [], 
    accessories: null,
    verniciatura: null,
    verniciaturaSku: null,
    customRal: null,
    colors: null,
    imgRendering: null,
    currentStep: null,
  });

  const eventBroadcast = useEventBroadcast();

  const handleChangeVerniciatura = async (idVer) => { 
    debugger;
    setErrorVincolo(null);
    try {
      let { id, sku } = stepState.accessories.find(a => a.id == idVer);

      dispatch(baseActions.setIdVerniciaturaManiglia(id));
      eventBroadcast.dispatch(Events.configurationChanged,{
        ...state,
        idVerniciaturaManiglia: id
      });

      setStepState({...stepState, verniciatura: id, verniciaturaSku: sku});
      //setVerniciatura(event.target.value);
    } catch (e) {
      setStepState({...stepState, state: "error", error: e});
    }
  };

  const handleChangeCheckbox = (event) => {
    setErrorVincolo(null);
    dispatch(baseActions.setRadioM(event.target.value));
    //setSelect(event.target.value);

    if (Number(event.target.value) == 1) {      
      dispatch(baseActions.setRadioM(1));
      let manigliaDef = setManigliaDefault(stepState.accessories);
      handleChangeVerniciatura(manigliaDef.id);
    } else {
      setStepState({...stepState, verniciatura: null, verniciaturaSku: null});

      dispatch(baseActions.setIdVerniciaturaManiglia(event.target.value));
      eventBroadcast.dispatch(Events.configurationChanged,{
        ...state,
        idVerniciaturaManiglia: null
      });

      setErrorVincolo(null);
    }

    
  };

  const saveStepState = async () => {
    if (!state.selectedD) {
      if (!state.radioM || state.radioM <= 0) {
        setErrorVincolo("Selezionare il tipo di maniglia");
        setStepState({...stepState, state: "idle"});
        return;
      }

      if (state.radioM == 1 && (!stepState.verniciatura || stepState.verniciatura <= 1)) {
        setErrorVincolo("Selezionare il tipo di verniciatura");
        setStepState({...stepState, state: "idle"});
        return;
      }

      if (stepState.verniciaturaSku == AccessoriesEnum.DoppiaManigliaVerniciataSceltaRal && !stepState.customRal) {
        setErrorVincolo("Fornire il colore di verniciatura della maniglia");
        setStepState({...stepState, state: "idle"});
        return;
      }
    }

    let sendData = { 
      qty: 1,
      id: state.radioM === 158 || state.radioM === 0 ? state.radioM : stepState.verniciatura,
      quote: state.quoteId,
      slug: location.pathname.substring(1),
      colorVerniciatura: state.radioM === 158 || state.radioM === 0 ? state.radioM : stepState.customRal,
    };

    setStepState({...stepState, state: "save"});
    try {
      let res = await axios.post(`${process.env.REACT_APP_API_URL}/save-accessories`, sendData, axiosConfig);      

      if (res.data.success == 1) {
        if (res.data.data.error) {
          setErrorVincolo(res.data.data.error);
          setStepState({...stepState, state: "idle"});
        } else if (state.radioM === 0 && state.selectedD === false) {
          setSbloccoObb(true);
          setStepState({...stepState, state: "idle"});
        } else {
          dispatch(baseActions.setManiglierie(true));
          stepState.steps.splice(5, 1);
          stepState.steps &&
          stepState.steps.map(
              (el, index) =>
                `/${el.slug}` === location.pathname &&
                navigate(
                  `/${stepState.steps[index + 1] && stepState.steps[index + 1].slug}`,
                  {
                    id: state.idPorta,
                  }
                )
            );
        }
      }
    } catch (e) {
      setStepState({...stepState, state: "idle"});
      console.log(e);
    }    
  }

  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + state.user.token,
    },
  };

  const load = async () => {
    let steps = [];
    let currentStep = null;
    try {
      let res = await axios.post(`${process.env.REACT_APP_API_URL}/categories-steps`, { id: state.idPorta, quote_id: state.quoteId}, axiosConfig);      
      if (res.data && res.data.data) {
        steps = res.data.data;
        currentStep = findCategoryStep(steps);
      }      
    } catch (e) {
      
    }

    try {
        let res = await axios.post(`${process.env.REACT_APP_API_URL}/accessories`, {
          id: state.idPorta,
          quote: state.quoteId,
          step: location.pathname.substring(1),
        }, axiosConfig);

        let accessories = res.data.data;
                
        let colors = [];
        // try {
        //   res = await axios.post(`${process.env.REACT_APP_API_URL}/color-ral`, { 
        //     category: state.idPorta,
        //     quote_id: state.quoteId,
        //   }, axiosConfig);

        //   colors = res.data.data;
        // } catch (e) {
        //   console.log(e);
        // }
        
        let idVerniciatura = null;
        let skuVerniciatura = null;
        let customRal = null;
        accessories.forEach((a) => {
          if (a.id === a.selected) {
            if (a.sku == AccessoriesEnum.ManigliaPlatinSilver || a.sku == AccessoriesEnum.ManigliaPlatinBronze || a.sku == AccessoriesEnum.DoppiaManigliaVerniciataSceltaRal) {
              dispatch(baseActions.setRadioM(1));
              idVerniciatura = a.id;  
              skuVerniciatura = a.sku; 
              customRal = a.custom_ral;
              return null;
            } else if (a.id === a.selected && a.selected === 158) {
              dispatch(baseActions.setRadioM(158));
              return null;
            }
          }
        });

        res = await axios.post(`${process.env.REACT_APP_API_URL}/get-img-accessories`, {
          id: state.radioM === 1 ? state.radioM : stepState.verniciatura,
        }, axiosConfig);

        let imgRendering = res.data.data;

        setStepState({
          state: "idle",
          steps: steps, 
          accessories: accessories,
          verniciatura: idVerniciatura,
          verniciaturaSku: skuVerniciatura,
          customRal: customRal,
          colors: colors,
          imgRendering: imgRendering,
          currentStep: currentStep,
        });
    } catch (e) {
      setStepState({state: "error", error: e});
    }
  }

  const findCategoryStep = (categoriesSteps) => {    
    return categoriesSteps.find(cs => `/${cs.slug}` === location.pathname);
  };

  const setCustomRal = (code) => {
    setStepState({...stepState, customRal: code});
  }

  const setManigliaDefault = (accessories) => {
    let manigliaDef = accessories.find((a) => a.sku == AccessoriesEnum.ManigliaPlatinBronze);
    return manigliaDef;
  }

  useEffect(() => {
    load();
  }, []);

  const [value, setValue] = useState();
  const [errorVincolo, setErrorVincolo] = useState(null);
  const handleChange = () => {
    // data &&
    //   data.map((el) =>
    //     `/${el.slug}` === location.pathname ? setValue(el.id) : null
    //   );
  };

  if ("loading" == stepState.state) return <Loading />;
  if ("error" == stepState.state) {
    console.log(stepState.error);
    return <div>failed to load: {stepState.error.message}</div>; 
  }

  return (
    <LayoutPage>
      <div style={{ flex: "0 0 auto" }}>
        <TopBar />
        
        <div className={"d-flex justify-content-between p-3"}>
          <div className="d-flex flex-column" style={{ lineHeight: "1" }}>
              <h2 className="h2 fw-bold">
                  Nuova configurazione {getProductName(state.idPorta)} (#{state.quoteId})
              </h2>
          </div>
          <div>
              {/* <Button variant={deleteClickCount == 0 ? "outlined" : "contained"} color="error" className="me-3 px-2">
                  <RemoveCircleOutlineOutlinedIcon />
                  <span className="ms-2">{deleteClickCount == 0 ? "ELIMINA" : "CONFERMA"}</span>
              </Button> */}

              <Button variant="contained" color="secondary" onClick={() => navigate("/configurations")}>
                  <CloseIcon />
                  <span className="ms-2">CHIUDI</span>
              </Button>
          </div>
        </div>

        <Grid
          className={classes.root}
          container
          sx={{ justifyContent: "center" }}>
          {stepState.currentStep && <TabsCustom
            value={stepState.currentStep.id}
            setValue={setValue}
            id={state.idPorta}
            handleChange={handleChange}
            axios_conf={axiosConfig}
            url={`${process.env.REACT_APP_API_URL}/categories-steps`}
          />}
        </Grid>
      </div>

      <div style={{ flex: "1 1 auto", minHeight: "0" }}>
        {stepState.accessories && <GridPreventivi
          dataSend={{
            quote_id: state.quoteId,
            category_id: state.idPorta,
            slug: location.pathname.substring(1),
          }}
          imgRendering={stepState.imgRendering}
          axiosConfig={axiosConfig}
          url={`${process.env.REACT_APP_API_URL}/photo-preventivi`}
          dipendenze={stepState.verniciatura + state.radioM}>
            <div>
              <h5 className="text-primary">Maniglieria</h5>

              <FormControl fullWidth className="mt-3">
                <InputLabel id="tipoManiglia">Tipo maniglia</InputLabel>
                <Select
                    labelId="tipoManiglia"
                    value={state.radioM}
                    label="Tipo maniglia"
                    onChange={handleChangeCheckbox}>
                    {state.selectedD === true && (
                      <MenuItem value={0}>Nessuna Maniglia</MenuItem>
                    )}
                    <MenuItem value={1}>Doppia maniglia</MenuItem>
                    <MenuItem value={158}>Sblocco manuale</MenuItem>
                </Select>
              </FormControl>

              {state.radioM === 1 && <FormControl fullWidth className="mt-3">
                <InputLabel id="verniciaturaManiglia">Verniciatura maniglia</InputLabel>
                <Select
                    labelId="verniciaturaManiglia"
                    value={stepState.verniciatura ?? ""}
                    label="Verniciatura maniglia"
                    startAdornment={(
                      <InputAdornment position="start">
                          {stepState.verniciatura == 29 && <img src={platinbronze} style={{width: "2rem", height: "1.75rem"}} />}
                          {(stepState.verniciatura === 31 || stepState.verniciatura === 30) && <img src={platinsilver} style={{width: "2rem", height: "1.75rem"}} />}
                      </InputAdornment>
                    )}
                    onChange={(e) => handleChangeVerniciatura(e.target.value)}>
                    {stepState.accessories.filter(res => res.type === "radio" && (res.sku === "ACCM31" || res.sku === "ACCM32" || res.sku === "ACCM01")).map((res) => <MenuItem key={res.id} value={res.id}>
                      {res.name}
                    </MenuItem>)}
                </Select>
              </FormControl>}
                
              {AccessoriesEnum.DoppiaManigliaVerniciataSceltaRal == stepState.verniciaturaSku && <TextField
                fullWidth
                className="mt-3"
                label="Colore verniciatura maniglia"
                placeholder={"es. 8016"}
                variant="outlined"
                name={"customRal"}
                value={stepState.customRal}
                onChange={(e) => setCustomRal(e.target.value)}
              />}
            </div>

            <div className="d-flex flex-row-reverse justify-content-between mt-3">
              <Button disabled={"save" == stepState.state} variant="contained" className="ps-3 pe-1" onClick={() => saveStepState()}>
                <span>Avanti</span>
                <NavigateNextIcon />
              </Button>

              <Button variant="contained" className="ps-1 pe-3" color="secondary" onClick={() => {
                  stepState.steps &&
                  stepState.steps.map((el, index) => {
                      `/${el.slug}` === location.pathname &&
                        navigate(
                          `/${stepState.steps[index - 1] && stepState.steps[index - 1].slug}`,
                          {
                            id: state.idPorta,
                          }
                        );
                    });
                }}>
                <NavigateBeforeIcon />
                <span>Indietro</span>
              </Button>
            </div>
          </GridPreventivi>}

          {(errorVincolo || sbloccoObb) && <div className="d-flex justify-content-center py-3" style={{position: "fixed", left: "0", bottom: "0", width: "100%"}}>
            <Alert variant="filled" severity="warning" sx={{zIndex: "2000"}} onClose={() => setErrorVincolo(null)}>
              <AlertTitle>Attenzione...</AlertTitle>
              {errorVincolo && errorVincolo}
              {sbloccoObb && <span>Inserire un opzione per continuare</span>}
            </Alert>
          </div>}
      </div>
    </LayoutPage>
  );
};

const useStyles = makeStyles({
  root: {
    "& MuiTabs-flexContainer css-heg063-MuiTabs-flexContainer": {
      justifyContent: "center",
    },
  },
});

export default Maniglierie;
