import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Grid from "@mui/material/Grid";
import React, { FC, useState } from "react";
import { Color } from "../../models/door_configuration";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { NullableNumber } from "../../core/common_types";
import finishes from "../../assets/finishes.json";
import rals from "../../assets/rals.json";

export type ColorsProps = {
    colors: Color[],
    selectedColor: NullableNumber,
    onConfirm: (value: NullableNumber) => void,
}

export const ColorsDialog: FC<{data: ColorsProps, onConfirm: (value: NullableNumber) => void, onCancel: () => void}> = (props) => {
    const [value, setValue] = useState<Color>(props.data.colors.find(c => c?.id == props.data.selectedColor));
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const onConfirm = () => {
        props.onConfirm(value?.id ?? null);
    };

    const renderFinishPreview = (colorId: NullableNumber) => {
        if (!colorId) return null;
        
        let color = props.data.colors.find(c => c?.id == colorId);
        if (!color) return null;

        let preview = finishes.find(f => f.code == color?.code)?.img;
        if (preview) return <img src={preview} style={{width: "4rem", height: "4rem"}} />;

        preview = rals.find(f => f.RAL == color?.code)?.HEX;
        if (preview) return <div style={{width: "4rem", height: "4rem", backgroundColor: preview}}></div>;

        return null;        
    }

    return (
        <Dialog fullWidth={false} fullScreen={fullScreen} maxWidth="sm" onClose={props.onCancel} open={true}>
            <DialogTitle>Colori disponibili</DialogTitle>
            <DialogContent className="py-2">
                <Grid container spacing={2} className="dialog-colors-grid my-0">
                    {props.data.colors.map(c => (
                        <Grid key={c?.id} item md={props.data.colors.length == 1 ? 12 : 6} className={"p-2 dialog-colors-grid-item d-flex align-items-center " + (value && value.id == c?.id ? "selected" : "")} onClick={() => setValue(c)}>
                            {renderFinishPreview(c?.id)}
                            <div className="p-2">
                                <h6 className="text-secondary">{c?.name}</h6>                                
                                <small className="badge bg-light text-dark">RAL {c?.code}</small>
                                {c?.additionalInfo && <small style={{fontSize: ".75rem"}}> - {c.additionalInfo}</small>}
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={props.onCancel}>Annulla</Button>
                <Button variant="contained" color="primary" onClick={onConfirm} autoFocus>OK</Button>
            </DialogActions>
        </Dialog>
    );
}