import React, { useState } from "react";
import { LayoutPage } from "../../components/Layout/layoutPage";
import TopBar from "../../components/TopBar/TopBar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { InputCustom } from "../../components/Input/InputCustom";
import { useDispatch, useSelector } from "react-redux";
import { useAuthApi } from "../../api/AuthApi";
import CircularProgress from "@mui/material/CircularProgress";
import { importBaseActions } from "../../store/reducers/base";
import { Button } from "@mui/material";

export const ModificaPassword = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const baseState = useSelector(state => state.base);
    const authApi = useAuthApi();
    const dispatch = useDispatch();
    const baseActions = importBaseActions();
    const [localState, setLocalState] = useState({
        current_password: "",
        new_password: "",
        confirm_new_password: "",
        errors: {},
        alert: "",
        isBusy: false
    });

    const submit = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        let errors = {
            current_password: !localState.current_password,
            new_password: !localState.new_password,
            confirm_new_password: !localState.confirm_new_password || localState.new_password != localState.confirm_new_password
        };

        if (errors.current_password || errors.new_password || errors.confirm_new_password) {
            setLocalState({...localState, errors});
            return;
        }

        setLocalState({...localState, isBusy: true});
        try {
            let res = await authApi.changePassword(baseState.user.token, baseState.user.id, baseState.user.userType, localState.current_password, localState.new_password);
            
            if (res.success != 1) throw res.message;

            dispatch(baseActions.setUserPasswordChanged());

            let destination = "";
            switch (baseState.user.userType) {
              case "SuperAdmin":
              case "Admin":
                  destination = "/area-managers";
                  break;
              case "AreaManager":
                  destination = "/agenti";
                  break;

              case "Agente":
                  destination = "/clienti";
                  break;

              case "Retail":
                  destination = "/configurations";
                  break;
            }
            navigate(destination);
        } catch (e) {
            setLocalState({...localState, isBusy: false, alert: e});
        }
    }

    return (
        <LayoutPage>
            <div style={{ flex: "0 0 auto" }}>
                <TopBar />

                <div className={"d-flex justify-content-between p-3"}>
                    <div className="d-flex flex-column" style={{ lineHeight: "1" }}>
                        <h2 className="h2 fw-bold">
                            Modifica password
                        </h2>
                    </div>
                    <div>
                        {/* <Button variant={deleteClickCount == 0 ? "outlined" : "contained"} color="error" className="me-3 px-2">
                            <RemoveCircleOutlineOutlinedIcon />
                            <span className="ms-2">{deleteClickCount == 0 ? "ELIMINA" : "CONFERMA"}</span>
                        </Button> */}

                        {(!location.state || !location.state.disableBack) && <Button variant="contained" color="secondary" onClick={() => navigate("/area-managers")}>
                            <CloseIcon />
                            <span className="ms-2">CHIUDI</span>
                        </Button>}
                    </div>
                </div>
                <div style={{ flex: "1 1 auto", minHeight: "0" }}>
                    <div className="container py-3">
                        <div className="row justify-content-center">
                            <div className="col-xl-6">
                                <form onSubmit={submit}>
                                    <InputCustom
                                        name={"current_password"}
                                        label={"Password attuale*"}
                                        type={"password"}
                                        error={localState.errors.current_password}
                                        placeholder={"Inserisci la tua password attuale..."}
                                        onChange={(e) => setLocalState({...localState, current_password: e.target.value})}
                                    />
                                    <InputCustom
                                        sx={{mt: 3}}
                                        name={"new_password"}
                                        label={"Nuova password*"}
                                        type={"password"}
                                        error={localState.errors.new_password}
                                        placeholder={"Inserisci la tua nuova password..."}
                                        onChange={(e) => setLocalState({...localState, new_password: e.target.value})}
                                    />
                                    <InputCustom
                                        sx={{mt: 3}}
                                        name={"confirm_new_password"}
                                        label={"Conferma nuova password*"}
                                        type={"password"}
                                        error={localState.errors.confirm_new_password}
                                        placeholder={"Conferma la tua nuova password..."}
                                        onChange={(e) => setLocalState({...localState, confirm_new_password: e.target.value})}
                                    />

                                    {localState.alert && (
                                        <div className="alert alert-danger">
                                            <span>{localState.alert}</span>
                                        </div>
                                    )}

                                    <div className="text-end mt-3">
                                        {!localState.isBusy ? (
                                            <button className="btn btn-primary">
                                                <span>Conferma</span>
                                            </button>
                                        ) : (
                                            <div className="d-flex justify-content-center mt-3">
                                                <CircularProgress />
                                            </div>
                                        )}                                
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutPage>
    );
}