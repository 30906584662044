import React, { FC, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Scene, SceneViewLabel } from "./components/object-draw/scene";
import { Button, FormControl, InputLabel, Select } from "@mui/material";
import { GarageExternal } from "./components/object-draw/environment/garage_external";
import { MetricsSummary } from "./components/panels/pnl_metrics_summary";
import { FormModelloFinitura } from "./components/forms/frm_modello_finitura";
import { useStoreDispatch, useStoreSelector } from "../../../../../hooks/StoreHooks";
import { getCurrentUser, isStepVisible, toDrawUnit } from "../../../../core/common_functions";
import { useDoorConfigurationBuilderActions } from "../../../../../store/reducers/door_configuration_builder";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useDialogs } from "../../../../providers/dialogs_provider";
import { useOptionsService } from "../../../../services/options_service";
import { DoorType, FinishType, IntegratedDoorPosition, LoadingState, OpeningDirection, Optional } from "../../../../core/common_types";
import { useColorsService } from "../../../../services/colors_service";
import { useConfigurationsService } from "../../../../services/configurations_service";
import { IntegratedDoorOptions } from "../../../../models/door_configuration";
import { PROFONDITA_INVOLUCRO } from "../../../../core/common_constants";
import { FormPortinaIntegrata, FormPortinaIntegrataTypeRef } from "./components/forms/frm_portina_integrata";
import { useAccessoriesService } from "../../../../services/accessories_service";
import Loading from "../../../../../components/Loading/loading";
import { BBoxPainter } from "./components/object-draw/bbox_painter";
import { DoorDetails } from "./components/object-draw/environment/door_details";
import { Label, Tag, Text } from "react-konva";

export const IntegratedDoorPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { base, doorConfigurationBuilder } = useStoreSelector(store => store);
  const dispatcher = useStoreDispatch();
  const currentUser = getCurrentUser(base);
  const { setStepActive, setStepComplete, setStepVisible } = useDoorConfigurationBuilderActions();
  const dialogs = useDialogs();
  const [isSubmit, setSubmit] = useState<boolean>(false);
  const [options, setOptions] = useState<{items: IntegratedDoorOptions|null, isLoading: boolean}>({
    items: null,
    isLoading: true
  });
  const configurationService = useConfigurationsService();
  const sceneContainerRef = useRef<HTMLDivElement>(null);
  const formPortinaIntegrataRef = useRef<FormPortinaIntegrataTypeRef>(null);
  
  const submit = async () => {
    setSubmit(true);
    try {
      if (!doorConfigurationBuilder.configuration) throw "Invalid configuration";
      
      if (doorConfigurationBuilder.configuration.portinaIntegrata) {
        if (doorConfigurationBuilder.configuration.portinaIntegrata.externalFrameColorType == "ral_non_standard" &&
            !doorConfigurationBuilder.configuration.portinaIntegrata.externalFrameColorCode) throw {severity: "warning", message: "Attenzione, scegliere il colore RAL del telaio"};
        if (doorConfigurationBuilder.configuration.portinaIntegrata.internalFrameColorType == "ral_non_standard" &&
          !doorConfigurationBuilder.configuration.portinaIntegrata.internalFrameColorCode) throw {severity: "warning", message: "Attenzione, scegliere il colore RAL del telaio"};            
        if (doorConfigurationBuilder.configuration.portinaIntegrata.externalHandleColorType == "ral_non_standard" &&
          !doorConfigurationBuilder.configuration.portinaIntegrata.externalHandleColorCode) throw {severity: "warning", message: "Attenzione, scegliere il colore RAL della maniglia esterna"};
        if (doorConfigurationBuilder.configuration.portinaIntegrata.internalHandleColorType == "ral_non_standard" &&
          !doorConfigurationBuilder.configuration.portinaIntegrata.internalHandleColorCode) throw {severity: "warning", message: "Attenzione, scegliere il colore RAL della maniglia interna"};

        await configurationService.setPortinaIntegrata(currentUser, doorConfigurationBuilder.configuration.configurationId, doorConfigurationBuilder.configuration.portinaIntegrata);
      } else {
        await configurationService.removePortinaIntegrata(currentUser, doorConfigurationBuilder.configuration.configurationId);
      }

      setSubmit(false);

      dispatcher(setStepComplete({ stepSlug: "portina-pedonale", isComplete: true }));

      if (isStepVisible("finestratura", doorConfigurationBuilder.steps)) {
        navigate(`/door-configurations/${id}/finestratura`);
      } else if (isStepVisible("portina-pedonale", doorConfigurationBuilder.steps)) {
        navigate(`/door-configurations/${id}/portina-pedonale-affiancata`);
      } else if (doorConfigurationBuilder.configuration.isMotorizzata) {
        navigate(`/door-configurations/${id}/motorizzazione`);
      } else {
        navigate(`/door-configurations/${id}/accessori-vari`);
      }
    } catch (e: any) {
      setSubmit(false);

      if (typeof e == "string") {
        dialogs?.showError("Attenzione...", e);
      } else {
        if (e.severity == "error") {
          dialogs?.showError("Attenzione...", e.message);
        } else if (e.severity == "warning") {
          dialogs?.showWarning("Attenzione...", e.message);
        }
      }
    }
  }

  const loadPortinaIntegrata = async () => {
    try {
      if (!doorConfigurationBuilder.configuration) throw "Configuration error";

      let items = await configurationService.findCompatibleIntegratedDoorAccessories(currentUser, doorConfigurationBuilder.configuration.configurationId);

      setOptions({...options, items, isLoading: false});
    } catch (e: any) {
      setOptions({...options, isLoading: false});

      if (typeof e == "string") {
        dialogs?.showError("Attenzione...", e);
      } else {
        if (e.severity == "error") {
          dialogs?.showError("Attenzione...", e.message);
        } else if (e.severity == "warning") {
          dialogs?.showWarning("Attenzione...", e.message);
        }
      }
    }
  }

  useEffect(() => {
    if (!id || isNaN(Number(id)) || !doorConfigurationBuilder.configuration) {
      navigate("/configurations");
    } else {
      loadPortinaIntegrata();

      dispatcher(setStepActive({ stepSlug: "portina-pedonale", isActive: true }));
    }
  }, []);

  return (
    <div className="row h-full m-0">
      <div ref={sceneContainerRef} className="col-xl-7 p-0" style={{ position: "relative", height: "100%" }}>
        {doorConfigurationBuilder.configuration && (
          <Scene
            bbox={{
              width: toDrawUnit(doorConfigurationBuilder.configuration.larghezzaForo),
              height: toDrawUnit(doorConfigurationBuilder.configuration.altezzaForo),
              x: 0,
              y: 0
            }}
            sidebarSx={(<SceneViewLabel text="VISTA ESTERNA" />)}
            bottombar={(<MetricsSummary />)}
            panEnabled
            zoomEnabled>
            <DoorDetails
                options={doorConfigurationBuilder.options}>
              <Label>
                  <Tag fill="white" stroke="black" strokeWidth={0.5} />
                  <Text text="VISTA ESTERNA" padding={4} fontSize={18} />
              </Label>
            </DoorDetails>
          </Scene>
        )}
      </div>
      <div className="col-xl-5 p-0" style={{ overflowY: "auto", height: "100%", borderLeft: "solid 1px #ccc" }}>
        <div className="p-4">
          <h5 className="text-primary">Portina integrata a un punto di chiusura</h5>

          {options.isLoading && <Loading />}

          {!options.isLoading && options.items && <FormPortinaIntegrata ref={formPortinaIntegrataRef} options={options.items} />}

          <div className="d-flex flex-row-reverse justify-content-between mt-3">
            <Button disabled={isSubmit} variant="contained" className="ps-3 pe-1" onClick={() => submit()}>
              <span>Avanti</span>
              <NavigateNextIcon />
            </Button>

            <Button variant="contained" className="ps-1 pe-3" color="secondary" onClick={() => {
              navigate(`/door-configurations/${doorConfigurationBuilder.configuration?.configurationId}/aspetto`);
            }}>
              <NavigateBeforeIcon />
              <span>Indietro</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}