import { IRect, Vector2d } from "konva/lib/types";
import { ALTEZZA_FINESTRA, ALTEZZA_FINESTRA_ACCIAIO, ALTEZZA_GRIGLIA, ALTEZZA_GRIGLIA_ALLUMINIO, DISTANZA_MINIMA_FINESTRA_GRIGLIA, LARGHEZZA_FINESTRA, LARGHEZZA_FINESTRA_ACCIAIO, LARGHEZZA_GRIGLIA, LARGHEZZA_GRIGLIA_ALLUMINIO, LARGHEZZA_PORTINA_INT_1200, LARGHEZZA_PORTINA_INT_900, SPESSORE_STIPITE } from "../../../../../../core/common_constants";
import { NullableString } from "../../../../../../core/common_types";
import { GridSize, IntegratedDoorConfiguration, WindowSize } from "../../../../../../models/door_configuration";
import { DatiPannello } from "../../../../../../core/common_draw_types";
import { distance, toDrawUnit } from "../../../../../../core/common_functions";
import { Vector } from "vecti";

export type PlaceholderRect = IRect & { position: number };

export function calcolaNumeroFinestreDaLarghezzaPorta(larghezzaPorta: number): number {
    let res = 2;
    if (larghezzaPorta > 2410 && larghezzaPorta <= 3150) {
        res = 3;
    } else if (larghezzaPorta > 3150 && larghezzaPorta <= 3890) {
        res = 4;
    } else if (larghezzaPorta > 3890 && larghezzaPorta <= 4630) {
        res = 5;
    } else if (larghezzaPorta > 4630 && larghezzaPorta <= 5370) {
        res = 6;
    } else if (larghezzaPorta > 5370 && larghezzaPorta <= 6000) {
        res = 7;
    }
    return res;
}

export function calcolaNumeroGriglieDaLarghezzaPorta(larghezzaPorta: number): number {
    let res = 3;
    if (larghezzaPorta > 2590 && larghezzaPorta <= 3190) {
        res = 4;
    } else if (larghezzaPorta > 3190 && larghezzaPorta <= 3790) {
        res = 5;
    } else if (larghezzaPorta > 3790 && larghezzaPorta <= 4390) {
        res = 6;
    } else if (larghezzaPorta > 4390 && larghezzaPorta <= 4990) {
        res = 7;
    } else if (larghezzaPorta > 4990 && larghezzaPorta <= 5590) {
        res = 8;
    } else if (larghezzaPorta > 5590 && larghezzaPorta <= 6000) {
        res = 9;
    }
    return res;
}

export function calcolaFinestreDaNumeroElementi(numeroElementi: number, panelData: DatiPannello, windowSize: WindowSize): PlaceholderRect[] {
    let res: PlaceholderRect[] = [];
    
    let larghezzaFinestra = windowSize == WindowSize._230x230 ? LARGHEZZA_FINESTRA_ACCIAIO : LARGHEZZA_FINESTRA;
    let altezzaFinestra = windowSize == WindowSize._230x230 ? ALTEZZA_FINESTRA_ACCIAIO : ALTEZZA_FINESTRA;

    const ar = altezzaFinestra/larghezzaFinestra;
    altezzaFinestra = altezzaFinestra * panelData.bbox.height / panelData.altezzaAssoluta;
    larghezzaFinestra = altezzaFinestra / ar;

    let lunghezzaMax = panelData.bbox.width - (2 * DISTANZA_MINIMA_FINESTRA_GRIGLIA) - larghezzaFinestra;
    let interasse = lunghezzaMax / (numeroElementi - 1);

    let currentX: number = DISTANZA_MINIMA_FINESTRA_GRIGLIA;
    for (let i = 0; i < numeroElementi; i++) {
        res.push({
            x: currentX,
            y: 0,
            width: larghezzaFinestra,
            height: altezzaFinestra,
            position: i
        }); 

        currentX = currentX + interasse;
    }

    return res;
}

export function calcolaGriglieDaNumeroElementi(numeroElementi: number, panelData: DatiPannello, gridSize: GridSize): PlaceholderRect[] {
    let res: PlaceholderRect[] = [];

    let larghezzaGriglia = gridSize == GridSize._400x200 ? LARGHEZZA_GRIGLIA_ALLUMINIO : LARGHEZZA_GRIGLIA;
    let altezzaGriglie = gridSize == GridSize._400x200 ? ALTEZZA_GRIGLIA_ALLUMINIO : ALTEZZA_GRIGLIA;

    const ar = altezzaGriglie/larghezzaGriglia;
    altezzaGriglie = altezzaGriglie * panelData.bbox.height / panelData.altezzaAssoluta;
    larghezzaGriglia = altezzaGriglie / ar;

    let lunghezzaMax = panelData.bbox.width - (2 * DISTANZA_MINIMA_FINESTRA_GRIGLIA) - larghezzaGriglia;
    let interasse = lunghezzaMax / (numeroElementi - 1);

    let currentX: number = DISTANZA_MINIMA_FINESTRA_GRIGLIA;
    for (let i = 0; i < numeroElementi; i++) {
        res.push({
            x: currentX,
            y: 0,
            width: larghezzaGriglia,
            height: altezzaGriglie,
            position: i
        }); 

        currentX = currentX + interasse;
    }

    return res;
}

export function buildBoundingBoxPortina(larghezzaForo: number, altezzaForo: number, isOltreLuce: boolean, datiPortina: IntegratedDoorConfiguration): IRect {
    const lf = toDrawUnit(larghezzaForo);
    const hf = toDrawUnit(altezzaForo);
    const pf = Vector.of([lf/2, hf/2]);

    let larghezzaDefault: number = 0;        
    if (datiPortina.fuoriMisura == "900") {
        larghezzaDefault = LARGHEZZA_PORTINA_INT_900;
    } else if (datiPortina.fuoriMisura == "1200") {
        larghezzaDefault = LARGHEZZA_PORTINA_INT_1200;
    } else {
        larghezzaDefault = toDrawUnit(datiPortina.larghezza?.esternoTelaio ?? 0);
    }

    let org = Vector.of([(lf / 2), hf]);
    
    if (datiPortina.posizione == "sx") {
        org = Vector.of([(larghezzaDefault/2) + 50, hf]);
    } else if (datiPortina.posizione == "dx") {
        org = Vector.of([lf-(larghezzaDefault/2) - 50, hf]);
    }

    let altezzaPortina = toDrawUnit(datiPortina.altezza?.esternoTelaio ?? 0);
    let larghezzaPortina = larghezzaDefault;
    
    if (isOltreLuce) {            
        let dir = pf.subtract(org).normalize();
        let newOrg = org.add(dir.multiply(SPESSORE_STIPITE));

        let psx = Vector.of([0, hf]);
        dir = pf.subtract(psx).normalize();
        psx = psx.add(dir.multiply(SPESSORE_STIPITE));

        org = Vector.of([newOrg.x, psx.y]);    
            
        let psxT = Vector.of([0, hf-altezzaPortina]);
        dir = pf.subtract(psxT).normalize();
        psxT = psxT.add(dir.multiply(SPESSORE_STIPITE));

        let psxB = Vector.of([0, hf]);
        dir = pf.subtract(psxB).normalize();
        psxB = psxB.add(dir.multiply(SPESSORE_STIPITE));

        altezzaPortina = distance(psxT.x, psxT.y, psxB.x, psxB.y);
        
        psx = Vector.of([0, hf]);
        dir = pf.subtract(psx).normalize();
        psx = psx.add(dir.multiply(SPESSORE_STIPITE));

        let pdx = Vector.of([larghezzaPortina, hf]);
        dir = pf.subtract(pdx).normalize();
        pdx = pdx.add(dir.multiply(SPESSORE_STIPITE));

        larghezzaPortina = distance(psx.x, psx.y, pdx.x, pdx.y);
    }
    
    return {
        x: org.x - (larghezzaPortina/2),
        y: org.y - altezzaPortina,
        width: larghezzaPortina,
        height: altezzaPortina
    };
}