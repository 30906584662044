import { Button, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate, useParams } from "react-router-dom";
import { useStoreDispatch, useStoreSelector } from "../../../../../hooks/StoreHooks";
import { getCurrentUser, toDrawUnit } from "../../../../core/common_functions";
import { useDoorConfigurationBuilderActions } from "../../../../../store/reducers/door_configuration_builder";
import { useDialogs } from "../../../../providers/dialogs_provider";
import { useConfigurationsService } from "../../../../services/configurations_service";
import { Scene, SceneViewLabel } from "./components/object-draw/scene";
import { MetricsSummary } from "./components/panels/pnl_metrics_summary";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { AiringConfiguration, GridSize, Color, ColorType, ColorSide } from "../../../../models/door_configuration";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { DatiPannello } from "../../../../core/common_draw_types";
import { ColorCode, DoorType, NullableNumber, NullableString } from "../../../../core/common_types";
import { useFinishes } from "../../../../providers/finish_provider";
import { useFormik } from "formik";
import finishes from "../../../../assets/finishes.json";
import rals from "../../../../assets/rals.json";
import { calcolaNumeroGriglieDaLarghezzaPorta, PlaceholderRect } from "./components/object-draw/draw_utils";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Label, Tag, Text } from "react-konva";
import { DoorDetails } from "./components/object-draw/environment/door_details";

export const AiringPage = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { base, doorConfigurationBuilder } = useStoreSelector(store => store);
    const dispatcher = useStoreDispatch();
    const currentUser = getCurrentUser(base);
    const { setStepActive, setStepComplete, setVentilazione, resetVentilazione } = useDoorConfigurationBuilderActions();
    const dialogs = useDialogs();
    const [isSubmit, setSubmit] = useState<boolean>(false);
    const configurationService = useConfigurationsService();
    const sceneContainerRef = useRef<HTMLDivElement>(null);
    const finishesProvider = useFinishes();

    const formik = useFormik<AiringConfiguration>({
        initialValues: {
            positioning: doorConfigurationBuilder.configuration?.ventilazione?.positioning ?? [],
            gridSize: doorConfigurationBuilder.configuration?.ventilazione?.gridSize ?? GridSize.undefined,
            quantity: doorConfigurationBuilder.configuration?.ventilazione?.quantity ?? undefined,
            externalColorType: doorConfigurationBuilder.configuration?.ventilazione?.externalColorType ?? "ral_standard",
            externalColorCode: doorConfigurationBuilder.configuration?.ventilazione?.externalColorCode ?? undefined,
            internalColorType: doorConfigurationBuilder.configuration?.ventilazione?.internalColorType ?? "ral_standard",
            internalColorCode: doorConfigurationBuilder.configuration?.ventilazione?.internalColorCode ?? undefined
        },
        onSubmit: (data) => {

        }
    });

    const submit = async () => {
        setSubmit(true);
        try {
            if (!doorConfigurationBuilder.configuration) throw "invalid configuration";
            if (doorConfigurationBuilder.configuration.ventilazione) {
                if ((doorConfigurationBuilder.configuration.ventilazione.quantity ?? 0) <= 0) throw { severity: "warning", message: "Attenzione, selezionare la quantità" };
                if (!doorConfigurationBuilder.configuration.ventilazione.externalColorCode) throw { severity: "warning", message: "Attenzione, seleziona il colore RAL da applicare" };
                if (!doorConfigurationBuilder.configuration.ventilazione.positioning || 
                    doorConfigurationBuilder.configuration.ventilazione.positioning.length <= 0) throw { severity: "warning", message: "Attenzione, posiziona le griglie prima di procedere" };
            }

            if (doorConfigurationBuilder.configuration.ventilazione) {
                await configurationService.setVentilazione(currentUser, doorConfigurationBuilder.configuration?.configurationId, doorConfigurationBuilder.configuration.ventilazione);
            } else {
                await configurationService.removeVentilazione(currentUser, doorConfigurationBuilder.configuration?.configurationId);
            }

            setSubmit(false);

            dispatcher(setStepComplete({ stepSlug: "ventilazione", isComplete: true }));

            navigate(`/door-configurations/${id}/portina-pedonale-affiancata`);
            
            // if (doorConfigurationBuilder.configuration.portinaPedonaleAffiancata) {
            //     navigate(`/door-configurations/${id}/portina-pedonale-affiancata`);
            // } else if (doorConfigurationBuilder.configuration.isMotorizzata) {
            //     navigate(`/door-configurations/${id}/motorizzazione`);
            // } else {
            //     navigate(`/door-configurations/${id}/accessori-vari`);
            // }
        } catch (e: any) {
            setSubmit(false);

            if (typeof e == "string") {
                dialogs?.showError("Attenzione...", e);
            } else {
                if (e.severity == "error") {
                    dialogs?.showError("Attenzione...", e.message);
                } else if (e.severity == "warning") {
                    dialogs?.showWarning("Attenzione...", e.message);
                }
            }
        }
    }

    const getColors = (colorSide: ColorSide) => {
        let colors: Color[] = [];

        let _9016 = doorConfigurationBuilder.colors.find(c => c?.code == ColorCode.bianco_traffico);
        if (_9016) colors.push(_9016);

        if (colorSide == "external" && formik.values.gridSize != GridSize._400x200 && !doorConfigurationBuilder.isLaminato() && !doorConfigurationBuilder.isPietravera() && !doorConfigurationBuilder.isWoodstyle()) {
            let externalRal = doorConfigurationBuilder.colors.find(c => c?.code == doorConfigurationBuilder.getExternalColorCode());
            if (externalRal) colors.push(externalRal);
        } 

        return colors;
    }

    const findColorIdByCode = (colorCode: NullableString): NullableNumber => {
        if (!colorCode) return undefined;

        let color = doorConfigurationBuilder.colors.find(c => c?.code == colorCode);
        return color ? color.id : undefined;
    }

    const findColorCodeById = (colorId: NullableNumber): NullableString => {
        if (!colorId) return undefined;

        let color = doorConfigurationBuilder.colors.find(c => c?.id == colorId);
        return color ? color.code : undefined;
    }

    const getRalName = (colorCode: NullableString) => {
        if (!colorCode) return "";

        let color = doorConfigurationBuilder.colors.find(c => c?.code == colorCode);        
        return color ? `${color.name} (${color.code})` : finishesProvider?.getRalName(colorCode);
    }

    const renderFinishPreview = (colorCode: NullableString) => {
        if (!colorCode) return null;
        
        let preview = finishes.find(f => f.code == colorCode)?.img;
        if (preview) return <img src={preview} style={{width: "2rem", height: "1.75rem"}} />;

        preview = rals.find(f => f.RAL == colorCode)?.HEX;
        if (preview) return <div style={{width: "1.75rem", height: "1.75rem", backgroundColor: preview}}></div>;

        return null;        
    }

    const onPanelClicked = (panelData: DatiPannello, placeholderData: PlaceholderRect) => {
        if (!doorConfigurationBuilder.configuration) return;
        if (!doorConfigurationBuilder.configuration.ventilazione) return;
        if (!doorConfigurationBuilder.configuration.ventilazione.positioning) return;
        if (!doorConfigurationBuilder.configuration.ventilazione.gridSize) return;
    
        let positioning = formik.values.positioning.map(p => ({...p}));

        let item = positioning.find(i => i.panelNumber == panelData.panelNumber && i.position == placeholderData.position);
        if (item) {
            positioning = positioning.filter(i => i != item);
        } else {  
            if (doorConfigurationBuilder.configuration.finestratura) {
                // let personalizzazione = doorConfigurationBuilder.configuration.finestratura.positioning.find(f => f.panelNumber == panelData.panelNumber && f.position == placeholderData.position);
                // if (personalizzazione) {
                //     dialogs?.showWarning("Ventilazione", "Attenzione, su questo pannello hai già inserito le finestre");
                //     return;
                // }
            }
    
            if (doorConfigurationBuilder.configuration.portinaIntegrata && panelData.panelNumber <= 4 && (formik.values.gridSize == GridSize.superificie_fq || formik.values.gridSize == GridSize.superificie_ft)) {
                dialogs?.showWarning("Ventilazione", "Attenzione, su questo pannello non può essere inserita la superficie areata in quanto si interseca con la portina");
                return;
            }      
                    
            positioning.push({
                elementsNumber: 1,
                panelNumber: panelData.panelNumber,
                position: placeholderData.position,
                bbox: placeholderData
            });
        }

        let values = {...formik.values, positioning};
        formik.setValues(values);
        dispatcher(setVentilazione(values));     
    }

    const setModelloGriglia = (value: string) => {
        let gridSize: GridSize = value as GridSize;

        if (gridSize == GridSize.undefined) {
            formik.setValues({
                externalColorCode: undefined,
                externalColorType: "ral_standard",
                internalColorCode: undefined,
                internalColorType: "ral_standard",
                positioning: [],
                quantity: undefined,
                gridSize
            });
            dispatcher(resetVentilazione());
            return;
        }

        let quantity: number;
        let externalColorType: ColorType = "ral_standard";
        let externalColorCode: NullableString = undefined;
        let internalColorType: ColorType = "ral_standard";
        let internalColorCode: NullableString = undefined;
        if (doorConfigurationBuilder.isLaminato() || doorConfigurationBuilder.isPietravera() || doorConfigurationBuilder.isWoodstyle() || doorConfigurationBuilder.isColoreCampione()) {
            if (gridSize == GridSize.superificie_fq || gridSize == GridSize.superificie_ft) {
                quantity = 1;
                externalColorType = "ral_non_standard";
                internalColorType = "ral_non_standard";
                externalColorCode = undefined;
                internalColorCode = undefined;
            } else {
                quantity = calcolaNumeroGriglieDaLarghezzaPorta(doorConfigurationBuilder.configuration?.larghezzaPorta ?? 0);
                if (gridSize == GridSize._344x138) {
                    externalColorType = "ral_standard";
                    externalColorCode = ColorCode.bianco_traffico;                    
                    internalColorType = "ral_standard";
                    internalColorCode = ColorCode.bianco_traffico;
                } else if (gridSize == GridSize._400x200) {
                    externalColorType = "ral_standard";
                    externalColorCode = ColorCode.alluminio_brillante;
                    internalColorType = "ral_standard";
                    internalColorCode = ColorCode.alluminio_brillante;
                }
            }
        } else {
            if (gridSize == GridSize.superificie_fq || gridSize == GridSize.superificie_ft) {
                quantity = 1;
                externalColorType = "ral_non_standard";
                internalColorType = "ral_non_standard";
                externalColorCode = doorConfigurationBuilder.getExternalColorCode();
                internalColorCode = doorConfigurationBuilder.getExternalColorCode();
            } else {
                quantity = calcolaNumeroGriglieDaLarghezzaPorta(doorConfigurationBuilder.configuration?.larghezzaPorta ?? 0);
                if (gridSize == GridSize._344x138) {
                    externalColorType = "ral_non_standard";
                    externalColorCode = doorConfigurationBuilder.getExternalColorCode();
                    internalColorType = "ral_standard";
                    internalColorCode = ColorCode.bianco_traffico;
                } else if (gridSize == GridSize._400x200) {
                    externalColorType = "ral_standard";
                    externalColorCode = ColorCode.alluminio_brillante;
                    internalColorType = "ral_standard";
                    internalColorCode = ColorCode.alluminio_brillante;
                }
            }
        }

        let values: AiringConfiguration = {
            ...formik.values, 
            gridSize,
            quantity,
            positioning: [],
            externalColorType,
            externalColorCode,
            internalColorType,
            internalColorCode
        };
        formik.setValues(values);
        dispatcher(setVentilazione(values));
    }

    const renderDimensioneGriglie = () => {
        let porta = doorConfigurationBuilder.options.find(p => p?.optionId == doorConfigurationBuilder.configuration?.porta);
        let isPortaClassic = porta?.name == DoorType.classic;

        return (
            <FormControl fullWidth className="mt-2">
                <InputLabel id="modello_griglia">Dimensione griglia</InputLabel>
                <Select
                    labelId="modello_griglia"
                    value={formik.values.gridSize}
                    label="Dimensione griglia"
                    onChange={(e) => setModelloGriglia(e.target.value)}>
                    <MenuItem value={GridSize.undefined}>Nessuna griglia</MenuItem>
                    <MenuItem value={GridSize._344x138}>Griglia rettangolare di ventilazione in plastica simil RAL 9016, chiudibile - dimensione L 344mm x H 138 mm</MenuItem>
                    <MenuItem value={GridSize._400x200}>Griglia rettangolare di ventilazione in alluminio – dimensione L 400mm x H 200 mm</MenuItem>
                    {isPortaClassic && <MenuItem value={GridSize.superificie_ft}>Superficie areata con telaio in alluminio e lamiera con fori tondi di diametro 10mm</MenuItem>}
                    {isPortaClassic && <MenuItem value={GridSize.superificie_fq}>Superficie areata con telaio in alluminio e lamiera con fori quadrati 40x40mm</MenuItem>}
                </Select>
            </FormControl>
        );
    }

    // const renderSelectQuantita = () => {
    //     if (!doorConfigurationBuilder.configuration) return null;

    //     let startFrom: number = 1;
    //     let itemsNumber: number = 1;
    //     if (formik.values.gridSize != GridSize.superificie_fq && formik.values.gridSize != GridSize.superificie_ft) {
    //         itemsNumber = calcolaNumeroGriglieDaLarghezzaPorta(doorConfigurationBuilder.configuration.larghezzaPorta);
    //         startFrom = 2;
    //     }

    //     let quantities = [];
    //     for (let q = startFrom; q <= itemsNumber; q++) {
    //         quantities.push(q);
    //     }

    //     if (quantities.length < 2) return null;

    //     return (
    //         <FormControl fullWidth className="mt-3">
    //             <InputLabel id="numero_griglie">Numero griglie per pannello</InputLabel>
    //             <Select
    //                 labelId="numero_griglie"
    //                 value={formik.values.quantity ?? startFrom}
    //                 label="Numero griglie per pannello"
    //                 onChange={(e) => {
    //                     let values: AiringConfiguration = {
    //                         ...formik.values,
    //                         quantity: e.target.value as number,
    //                         positioning: []
    //                     };
    //                     formik.setValues(values);
    //                     dispatcher(setVentilazione(values));
    //                 }}>
    //                 {quantities.map(q => <MenuItem value={q}>{q}</MenuItem>)}
    //             </Select>
    //         </FormControl>
    //     );
    // }

    const renderSelectExternalColorType = () => {
        if ((doorConfigurationBuilder.isLaminato() || 
             doorConfigurationBuilder.isPietravera() || 
             doorConfigurationBuilder.isWoodstyle() || 
             doorConfigurationBuilder.isColoreCampione()) && 
            (formik.values.gridSize == GridSize.superificie_fq || 
             formik.values.gridSize == GridSize.superificie_ft)) return null;

        const lbl = formik.values.gridSize != GridSize._400x200 ? "Colore esterno" : "Colore esterno ed interno";

        return (
            <FormControl fullWidth className="mt-3">
                <InputLabel id="colore_esterno">{lbl}</InputLabel>
                <Select
                    labelId="colore_esterno"
                    value={getColorTypeValue("external")}
                    label={lbl}
                    onChange={(e) => {
                        let value = e.target.value;
                        let externalColorType: ColorType = "ral_standard";
                        let externalColorCode: NullableString = undefined;
                        if (isStandardRal(value ?? "")) {
                            externalColorType = "ral_standard";
                            externalColorCode = value;
                        } else {
                            externalColorType = "ral_non_standard";
                            if (value != "ral_non_standard") {
                                externalColorCode = value;
                            }
                        }

                        let values: AiringConfiguration = {
                            ...formik.values,
                            externalColorType,
                            externalColorCode
                        };

                        if (values.gridSize == GridSize._400x200) {
                            values.internalColorType = externalColorType;
                            values.internalColorCode = externalColorCode;
                        }

                        dispatcher(setVentilazione(values));
                        formik.setValues(values);
                    }}>
                    {renderStandardColorTypesMenuItems("external")}                    
                    <MenuItem value={"ral_non_standard"}>Colore personalizzato</MenuItem>
                </Select>
            </FormControl>
        );
    }

    const renderInputExternalNsRal = () => {
        if (formik.values.externalColorType != "ral_non_standard") return null;

        const lbl = formik.values.gridSize != GridSize._400x200 ? "RAL Colore esterno" : "RAL colore esterno ed interno";

        return (
            <TextField
                fullWidth 
                focused={false}
                className="mt-3"
                label={lbl}
                name={"coloreEsternoNsRal"}
                value={finishesProvider?.getRalName(formik.values.externalColorCode)}
                InputProps={{
                    readOnly: true, 
                    startAdornment: formik.values.externalColorCode && (
                        <InputAdornment position="start">
                            {finishesProvider?.getRalPreview(formik.values.externalColorCode)}
                        </InputAdornment>
                    ),

                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => dialogs?.selectRalColor({onConfirm: selectedColor => {                                
                                let values: AiringConfiguration = {
                                    ...formik.values,
                                    externalColorCode: selectedColor
                                };

                                if (values.gridSize == GridSize._400x200 || values.gridSize == GridSize.superificie_fq || values.gridSize == GridSize.superificie_ft) {
                                    values.internalColorCode = selectedColor;
                                }

                                dispatcher(setVentilazione(values));
                                formik.setValues(values);
                            }})}>
                                <OpenInNewIcon style={{ color: "black" }} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        );
    }

    const renderSelectInternalColorType = () => {
        if (formik.values.gridSize == GridSize._400x200 || (
            (doorConfigurationBuilder.isLaminato() || 
             doorConfigurationBuilder.isPietravera() || 
             doorConfigurationBuilder.isWoodstyle() || 
             doorConfigurationBuilder.isColoreCampione()) && 
            (formik.values.gridSize == GridSize.superificie_fq || 
             formik.values.gridSize == GridSize.superificie_ft))) return null;

        return (
            <FormControl fullWidth className="mt-3">
                <InputLabel id="colore_interno">Colore interno</InputLabel>
                <Select
                    labelId="colore_interno"
                    value={getColorTypeValue("internal")}
                    label={"Colore interno"}
                    onChange={(e) => {
                        let value = e.target.value;
                        let internalColorType: ColorType = "ral_standard";
                        let internalColorCode: NullableString = undefined;
                        if (isStandardRal(value ?? "")) {
                            internalColorType = "ral_standard";
                            internalColorCode = value;
                        } else {
                            internalColorType = "ral_non_standard";
                            if (value != "ral_non_standard") {
                                internalColorCode = value;
                            }
                        }

                        let values: AiringConfiguration = {
                            ...formik.values,
                            internalColorType,
                            internalColorCode
                        };
                        dispatcher(setVentilazione(values));
                        formik.setValues(values);
                    }}>
                    {renderStandardColorTypesMenuItems("internal")}                    
                    <MenuItem value={"ral_non_standard"}>Colore personalizzato</MenuItem>
                </Select>
            </FormControl>
        );
    }

    const renderInputInternalNsRal = () => {
        if (formik.values.internalColorType != "ral_non_standard") return null;
        if (formik.values.gridSize == GridSize._400x200) return null;

        return (
            <TextField
                fullWidth 
                focused={false}
                className="mt-3"
                label={"RAL colore interno"}
                name={"coloreInternoNsRal"}
                value={finishesProvider?.getRalName(formik.values.internalColorCode)}
                InputProps={{
                    readOnly: true, 
                    startAdornment: formik.values.internalColorCode && (
                        <InputAdornment position="start">
                            {finishesProvider?.getRalPreview(formik.values.internalColorCode)}
                        </InputAdornment>
                    ),

                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => dialogs?.selectRalColor({onConfirm: selectedColor => {                                
                                let values: AiringConfiguration = {
                                    ...formik.values,
                                    internalColorCode: selectedColor
                                };
                                dispatcher(setVentilazione(values));
                                formik.setValues(values);
                            }})}>
                                <OpenInNewIcon style={{ color: "black" }} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        );
    }

    const getColorTypeValue = (colorSide: ColorSide): string => {
        if (colorSide == "external") {
            if (formik.values.externalColorType == "ral_standard") {
                return formik.values.externalColorCode ?? "";
            }
            return formik.values.externalColorType;
        } else {
            if (formik.values.internalColorType == "ral_standard") {
                return formik.values.internalColorCode ?? "";
            }
            return formik.values.internalColorType;
        }
    }

    const isStandardRal = (value: string): boolean => {
        return value == ColorCode.bianco_traffico || 
        value == ColorCode.alluminio_brillante;
    }

    const renderStandardColorTypesMenuItems = (colorSide: ColorSide) => {
        let colors: string[] = [];

        if (doorConfigurationBuilder.isLaminato() || doorConfigurationBuilder.isPietravera() || doorConfigurationBuilder.isWoodstyle() || doorConfigurationBuilder.isColoreCampione()) {
            if (formik.values.gridSize == GridSize._400x200) {
                colors.push(ColorCode.alluminio_brillante);
            } else {
                if (formik.values.gridSize != GridSize.superificie_fq && formik.values.gridSize != GridSize.superificie_ft) {                            
                    colors.push(ColorCode.bianco_traffico);
                }            
            }
        } else {
            if (formik.values.gridSize == GridSize._400x200) {
                colors.push(ColorCode.alluminio_brillante);
            } else {
                if (formik.values.gridSize == GridSize.superificie_fq || formik.values.gridSize == GridSize.superificie_ft) {
                    if (formik.values.externalColorCode && !colors.includes(formik.values.externalColorCode)) colors.push(formik.values.externalColorCode);
                } else {
                    if (colorSide == "internal") {
                        let internalColorCode = doorConfigurationBuilder.getInternalColorCode();
                        if (internalColorCode && !colors.includes(internalColorCode) && internalColorCode != ColorCode.bianco_traffico) {
                            colors.push(internalColorCode);
                        }
                    }

                    let externalColorCode = doorConfigurationBuilder.getExternalColorCode();
                    if (externalColorCode && !colors.includes(externalColorCode) && externalColorCode != ColorCode.bianco_traffico) {
                        colors.push(externalColorCode);
                    }
                    
                    colors.push(ColorCode.bianco_traffico);
                }            
            }
        }

        return colors.map(c => (
            <MenuItem value={c}>
                <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
                    {formik.values.gridSize != GridSize._400x200 ? getRalName(c) : "Alluminio"}
                    {renderFinishPreview(c)}
                </div>
            </MenuItem>
        ));
    }

    const init = () => {
                
    }

    useEffect(() => {
        if (!id || isNaN(Number(id)) || !doorConfigurationBuilder.configuration) {
            navigate("/configurations");
        } else {
            dispatcher(setStepActive({ stepSlug: "ventilazione", isActive: true }));

            init();
        }
    }, []);

    return (
        <div className="row h-full m-0">
            <div ref={sceneContainerRef} className="col-xl-7 p-0" style={{ position: "relative", height: "100%" }}>
                {doorConfigurationBuilder.configuration && (
                    <Scene
                        bbox={{
                            width: toDrawUnit(doorConfigurationBuilder.configuration.larghezzaForo),
                            height: toDrawUnit(doorConfigurationBuilder.configuration.altezzaForo),
                            x: 0,
                            y: 0
                        }}
                        sidebarSx={(<SceneViewLabel text="VISTA ESTERNA" />)}
                        bottombar={(<MetricsSummary />)}
                        panEnabled
                        zoomEnabled>
                        <DoorDetails
                            options={doorConfigurationBuilder.options} 
                            onPanelClicked={onPanelClicked}>
                            <Label>
                                <Tag fill="white" stroke="black" strokeWidth={0.5} />
                                <Text text="VISTA ESTERNA" padding={4} fontSize={18} />
                            </Label>
                        </DoorDetails>
                    </Scene>
                )}
            </div>
            <div className="col-xl-5 p-0" style={{ overflowY: "auto", height: "100%", borderLeft: "solid 1px #ccc" }}>
                <div className="p-4">
                    <h5 className="text-primary">Ventilazione</h5>

                    {renderDimensioneGriglie()}

                    {formik.values.gridSize != GridSize.undefined && (
                        <>                    
                            {/*renderSelectQuantita()*/}
                            {renderSelectExternalColorType()}
                            {renderInputExternalNsRal()}
                            {renderSelectInternalColorType()}
                            {renderInputInternalNsRal()}
        
                            <p className="py-3 text-center"><ArrowBackOutlinedIcon color={"primary"} /> <strong>Clicca sul pannello per posizionare le griglie</strong></p>
                        </>
                    )}

                    <div className="d-flex flex-row-reverse justify-content-between mt-3">
                        <Button disabled={isSubmit} variant="contained" className="ps-3 pe-1" onClick={() => submit()}>
                            <span>Avanti</span>
                            <NavigateNextIcon />
                        </Button>

                        <Button variant="contained" className="ps-1 pe-3" color="secondary" onClick={() => {
                            navigate(`/door-configurations/${doorConfigurationBuilder.configuration?.configurationId}/finestratura`);
                        }}>
                            <NavigateBeforeIcon />
                            <span>Indietro</span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}