import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Scene, SceneViewLabel } from "./components/object-draw/scene";
import { Button } from "@mui/material";
import { MetricsSummary } from "./components/panels/pnl_metrics_summary";
import { FormModelloFinitura } from "./components/forms/frm_modello_finitura";
import { useStoreDispatch, useStoreSelector } from "../../../../../hooks/StoreHooks";
import { getCurrentUser, toDrawUnit } from "../../../../core/common_functions";
import { useDoorConfigurationBuilderActions } from "../../../../../store/reducers/door_configuration_builder";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useDialogs } from "../../../../providers/dialogs_provider";
import { DoorType, FinishType } from "../../../../core/common_types";
import { useConfigurationsService } from "../../../../services/configurations_service";
import { FormAspettoModel, Option } from "../../../../models/door_configuration";
import { PROFONDITA_INVOLUCRO } from "../../../../core/common_constants";
import { GarageExternal } from "./components/object-draw/environment/garage_external";

export const AspectPage = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { base, doorConfigurationBuilder } = useStoreSelector(store => store);
    const dispatcher = useStoreDispatch();
    const currentUser = getCurrentUser(base);
    const { setStepActive, setStepComplete, setStepVisible } = useDoorConfigurationBuilderActions();
    const dialogs = useDialogs();
    const [isSubmit, setSubmit] = useState<boolean>(false);
    const configurationService = useConfigurationsService();
    const sceneContainerRef = useRef<HTMLDivElement>(null);

    const findFinitura = (): Option|null|undefined => {
      if (!doorConfigurationBuilder.configuration) return null;

      if (!doorConfigurationBuilder.configuration.finitura) return null;
      return doorConfigurationBuilder.options.find(o => o?.optionId == doorConfigurationBuilder.configuration?.finitura);
    }

    const isFullglass = (finitura: Option|null|undefined): boolean => {
      if (!finitura) return false;
      return finitura.name == FinishType.glasspanel;
    }

    const isFullVision = (finitura: Option|null|undefined): boolean => {
      if (!finitura) return false;
      return finitura.name == FinishType.full_vision;
    }

    const isSpecial = (finitura: Option|null|undefined): boolean => {
      if (!finitura) return false;
      return finitura.name == FinishType.special;
    }

    const submit = async () => {  
      setSubmit(true);
      try {
        let finitura = findFinitura();

        if (!doorConfigurationBuilder.configuration) throw "invalid configuration";
        if (!doorConfigurationBuilder.configuration.porta) throw {severity: "warning", message: "Scegli il tipo di porta"};
        if (!doorConfigurationBuilder.configuration.modello) throw {severity: "warning", message: "Scegli il modello"};
        if (!doorConfigurationBuilder.configuration.finitura) throw {severity: "warning", message: "Scegli la finitura"};
        if (doorConfigurationBuilder.configuration.tipoColoreEsterno == "ral_standard" && !doorConfigurationBuilder.configuration.coloreEsterno) throw {severity: "warning", message: "Seleziona il colore esterno"};
        if (doorConfigurationBuilder.configuration.tipoColoreEsterno == "ral_non_standard" && !doorConfigurationBuilder.configuration.coloreEsternoNsRal) throw {severity: "warning", message: "Inserisci il RAL da usare come colore esterno"};
        
        if (doorConfigurationBuilder.configuration.tipoColoreInterno == "ral_standard" && !doorConfigurationBuilder.configuration.coloreInterno && (doorConfigurationBuilder.isGlasspanel() && doorConfigurationBuilder.configuration.coloreTelaioInternoDiverso)) throw {severity: "warning", message: "Seleziona il colore interno"};
        if (doorConfigurationBuilder.configuration.tipoColoreInterno == "ral_non_standard" && !doorConfigurationBuilder.configuration.coloreInternoNsRal && (doorConfigurationBuilder.isGlasspanel() && doorConfigurationBuilder.configuration.coloreTelaioInternoDiverso)) throw {severity: "warning", message: "Inserisci il RAL da usare come colore interno"};
        
        if (isFullglass(finitura) || isFullVision(finitura)) {
          if (doorConfigurationBuilder.configuration.coloreTelaioInternoDiverso) {
            if (doorConfigurationBuilder.configuration.tipoColoreInterno == "ral_standard" && !doorConfigurationBuilder.configuration.coloreInterno) throw {severity: "warning", message: "Seleziona il colore del telaio interno"};
            if (doorConfigurationBuilder.configuration.tipoColoreInterno == "ral_non_standard" && !doorConfigurationBuilder.configuration.coloreInternoNsRal) throw {severity: "warning", message: "Inserisci il RAL da usare per il colore del telaio interno"};
          }

          if (!doorConfigurationBuilder.configuration.finituraVetro) throw {severity: "warning", message: "Scegli la finitura del vetro"};
        }
        
        let data: FormAspettoModel = {
          porta: doorConfigurationBuilder.configuration.porta,
          modello: doorConfigurationBuilder.configuration.modello,
          finitura: doorConfigurationBuilder.configuration.finitura,
          tipoColoreEsterno: doorConfigurationBuilder.configuration.tipoColoreEsterno,
          coloreEsterno: doorConfigurationBuilder.configuration.coloreEsterno,
          coloreEsternoNsRal: doorConfigurationBuilder.configuration.coloreEsternoNsRal,
          coloreEsternoACampione: doorConfigurationBuilder.configuration.coloreEsternoACampione,
          tipoColoreInterno: doorConfigurationBuilder.configuration.tipoColoreInterno,
          coloreInterno: doorConfigurationBuilder.configuration.coloreInterno,
          coloreInternoNsRal: doorConfigurationBuilder.configuration.coloreInternoNsRal,
          coloreInternoACampione: doorConfigurationBuilder.configuration.coloreInternoACampione,
          finituraVetro: doorConfigurationBuilder.configuration.finituraVetro,
          coloreTelaioInternoDiverso: doorConfigurationBuilder.configuration.coloreTelaioInternoDiverso,
          finestratura: doorConfigurationBuilder.configuration.finestratura,
          ventilazione: doorConfigurationBuilder.configuration.ventilazione,
          portinaIntegrata: doorConfigurationBuilder.configuration.portinaIntegrata,
          portinaAffiancata: doorConfigurationBuilder.configuration.portinaAffiancata,
        };

        if ((isFullglass(finitura) || isFullVision(finitura)) && !data.coloreTelaioInternoDiverso) {
          data.coloreInterno = data.coloreEsterno;
          data.coloreInternoNsRal = data.coloreEsternoNsRal;
          data.coloreInternoACampione = data.coloreEsternoACampione;
        }
  
        let res = await configurationService.updateAspect(currentUser, doorConfigurationBuilder.configuration.configurationId, data);

        setSubmit(false);
          
        let porta = doorConfigurationBuilder.options.find(o => o?.optionId == doorConfigurationBuilder.configuration?.porta);
        let activateFinestratura = (porta && finitura && porta.name != DoorType.full_vision && finitura.name != FinishType.glasspanel) as boolean;
        dispatcher(setStepComplete({ stepSlug: "aspetto", isComplete: true }));
        dispatcher(setStepVisible({ stepSlug: "finestratura", isVisible: activateFinestratura }));
        dispatcher(setStepVisible({ stepSlug: "ventilazione", isVisible: activateFinestratura }));
        dispatcher(setStepVisible({ stepSlug: "portina-pedonale", isVisible: res?.integratedDoorSupport ?? false }));
        
        if (res?.integratedDoorSupport) {
          navigate(`/door-configurations/${id}/portina-pedonale`);
        } else if (activateFinestratura) {
          navigate(`/door-configurations/${id}/finestratura`);
        } else {
          navigate(`/door-configurations/${id}/portina-pedonale-affiancata`);
        }
        // } else if (doorConfigurationBuilder.configuration.portinaPedonaleAffiancata) {
        //   navigate(`/door-configurations/${id}/portina-pedonale-affiancata`);
        // } else if (doorConfigurationBuilder.configuration.isMotorizzata) {
        //   navigate(`/door-configurations/${id}/motorizzazione`);
        // } else  {
        //   navigate(`/door-configurations/${id}/accessori-vari`);
        // }
      } catch (e: any) {
        setSubmit(false);

        if (typeof e == "string") {
          dialogs?.showError("Attenzione...", e);
        } else {
          if (e.severity == "error") {
            if (e.message == "UNAVAILABLE") {
              let msg = "Siamo spiacenti, le dimensioni inserite non rientrano nei limiti di questa gamma/finitura scelti.";
              dialogs?.showError("Attenzione...", msg);
            } else {
              dialogs?.showError("Attenzione...", e.message);
            }
          } else if (e.severity == "warning") {
            dialogs?.showWarning("Attenzione...", e.message);
          }
        }
      }
    }

    useEffect(() => {
        if (!id || isNaN(Number(id)) || !doorConfigurationBuilder.configuration) {
            navigate("/configurations");
        } else {
            dispatcher(setStepActive({ stepSlug: "aspetto", isActive: true }));
        }
    }, []);

    return (
        <div className="row h-full m-0">
            <div ref={sceneContainerRef} className="col-xl-7 p-0" style={{ position: "relative", height: "100%" }}>
                {doorConfigurationBuilder.configuration && (
                  <Scene 
                    bbox={{
                      x: -PROFONDITA_INVOLUCRO*2,
                      y: -PROFONDITA_INVOLUCRO/2,
                      width: toDrawUnit(doorConfigurationBuilder.configuration.larghezzaForo)+(PROFONDITA_INVOLUCRO*2) + (PROFONDITA_INVOLUCRO*2),
                      height: toDrawUnit(doorConfigurationBuilder.configuration.altezzaForo)+ PROFONDITA_INVOLUCRO
                    }}
                    sidebarSx={(<SceneViewLabel text="VISTA ESTERNA" />)}
                    bottombar={(<MetricsSummary />)}
                    panEnabled 
                    zoomEnabled>
                    <GarageExternal options={doorConfigurationBuilder.options}>                      
                      {/* <BBoxPainter
                        x={-PROFONDITA_INVOLUCRO*2} 
                        y={-PROFONDITA_INVOLUCRO/2}
                        width={toDrawUnit(doorConfigurationBuilder.configuration.larghezzaForo)+(PROFONDITA_INVOLUCRO*2) + (PROFONDITA_INVOLUCRO*2)}
                        height={toDrawUnit(doorConfigurationBuilder.configuration.altezzaForo)+ PROFONDITA_INVOLUCRO}
                      /> */}
                    </GarageExternal>
                  </Scene>
                )}
            </div>
            <div className="col-xl-5 p-0" style={{ overflowY: "auto", height: "100%", borderLeft: "solid 1px #ccc" }}>                
                <div className="p-4">                        
                    <FormModelloFinitura options={doorConfigurationBuilder.options} colors={doorConfigurationBuilder.colors} />

                    <div className="d-flex flex-row-reverse justify-content-between mt-3">
                        <Button disabled={isSubmit} variant="contained" className="ps-3 pe-1" onClick={() => submit()}>
                            <span>Avanti</span>
                            <NavigateNextIcon />
                        </Button>

                        <Button variant="contained" className="ps-1 pe-3" color="secondary" onClick={() => {
                            navigate(`/door-configurations/${doorConfigurationBuilder.configuration?.configurationId}/dimensioni`);
                        }}>
                            <NavigateBeforeIcon />
                            <span>Indietro</span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}