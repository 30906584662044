import * as React from "react";
import Grid from "@mui/material/Grid";
import TopBar from "../../components/TopBar/TopBar";
import { LayoutPage } from "../../components/Layout/layoutPage";
import {
  Alert,
  AlertTitle,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import TabsCustom from "../../components/Tabs/Tabs";
import { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import GridPreventivi from "../../components/GrdiPreventivi/gridPreventivi";
import axios from "axios";
import useSWR from "swr";
import Loading from "../../components/Loading/loading";
import { InputCustom } from "../../components/Input/InputCustom";
import * as yup from "yup";
import CheckboxCustom from "../../components/Checkbox/CheckboxCustom";
import box from "../../assets/img/box.PNG";
import Box from "@mui/material/Box";
import { importBaseActions } from "../../store/reducers/base";
import { getProductName } from "../../Utility";
import posizione_sinistra from "../../assets/img/portinaIntegrata/sinistra.png";
import posizione_destra from "../../assets/img/portinaIntegrata/destra.png";
import posizione_centrata from "../../assets/img/portinaIntegrata/centrata.png";
import apertura_destra from "../../assets/img/portinaIntegrata/apartura-destra.png";
import apertura_sinistra from "../../assets/img/portinaIntegrata/apertura-sinistra.png";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

const validationSchema = yup.object({
  altezza: yup.string().required("Altezza obbligatoria"),
  larghezza: yup.string().required("Larghezza obbligatoria"),
  installazione: yup.string().required("Installazione obbligatoria"),
  apertura: yup.string().required("Apertura obbligatoria"),
  verso: yup.string().required("Verso obbligatorio"),
});

const PortinaPedonaleIntegrata = () => {
  const count = useSelector(state => state.base);
  const baseActions = importBaseActions();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    status: "loading",
    steps: [],
    currentStep: null,
    posizione: null,
    apertura: null,
    coloreTelaioInterno: "",
    coloreManigliaEsterna: "",
    coloreManigliaInterna: "",
    error: null
  });

  //const history = useHistory();
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  const [errorVincolo, setErrorVincolo] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const [open, setOpen] = useState(false);

  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + count.user.token,
    },
  };

  const [value, setValue] = useState();
  const handleChange = () => {
    // data &&
    //   data.map((el) =>
    //     `/${el.slug}` === location.pathname ? setValue(el.id) : null
    //   );
  };

  const load = async () => {
    try {
      let res = await axios.post(`${process.env.REACT_APP_API_URL}/categories-steps`, { id: count.idPorta, quote_id: count.quoteId }, axiosConfig);
      if (res.data && res.data.data) {
        let steps = res.data.data;
        let currentStep = findCategoryStep(steps);
        setState({
          ...state,
          steps,
          currentStep,
          status: "idle"
        });
      }
    } catch (e) {

    }

    try {
      let res = axios.post(`${process.env.REACT_APP_API_URL}/get-id-accessories`, {
        quote: count.quoteId,
        slug: location.pathname.substring(1),
        type: "radio",
      }, axiosConfig);

      //res.data.data.accessories_id

      res = axios.post(`${process.env.REACT_APP_API_URL}/get-id-accessories`, {
        quote: count.quoteId,
        slug: location.pathname.substring(1),
        type: "checkbox",
      }, axiosConfig);
    } catch (e) {

    }
  }

  useEffect(() => {
    load();
  }, []);

  const fetcher2 = (url) =>
    axios
      .post(
        url,
        {
          id: count.idPorta,
          quote: count.quoteId,
          step: location.pathname.substring(1),
        },
        axiosConfig
      )
      .then((res) => res.data.data);
  const { data: accessories, error: errorAccessories } = useSWR(
    [`${process.env.REACT_APP_API_URL}/accessories`, location.pathname],
    fetcher2
  );

  const navigateToPreviuosStep = () => {
    let previousStep = null;
    let stepsIds = state.steps.map(s => s.position);
    let minStepId = Math.min(...stepsIds);

    for (let stepId = state.currentStep.position - 1; stepId >= minStepId; stepId--) {
      let step = state.steps.find(s => s.position == stepId);
      if (!step) continue;
      if (count.fullglass && (step.position == 6 || step.position == 7 || step.position == 8)) {
        continue;
      }
      previousStep = step;
      if (previousStep) break;
    }
    if (!previousStep) return;

    navigate(`/${previousStep.slug}`, {
      id: state.idPorta,
    });
  }

  const findCategoryStep = (categoriesSteps) => {
    return categoriesSteps.find(cs => `/${cs.slug}` === location.pathname);
  };

  const submit = async () => {
    if (!state.posizione) {
      setErrorVincolo("Selezionare la posizione della porticina");
      return;
    }

    if (!state.apertura) {
      setErrorVincolo("Selezionare l'apertura della porticina");
      return;
    }

    let data = {
      quote_id: count.quoteId,
      posizione: state.posizione,
      apertura: state.apertura,
      ralCustomACCP60: state.coloreManigliaEsterna,
      ralCustomACCP61: state.coloreManigliaInterna,
      ralCustomACCP51: state.coloreTelaioInterno
    }

    setSubmitting(true);

    let res = await axios.post(`${process.env.REACT_APP_API_URL}/save-porticina-integrata`, data, axiosConfig);

    if (res.data.error === 1) {
      setSubmitting(false);
      setErrorVincolo(res.data.message);
    } else if (res.data.success === 1) {
      dispatch(baseActions.setRadioI(true));
      dispatch(baseActions.setPortinaPedonale(true));
      navigateToNextStep();
    }
  }

  const navigateToNextStep = () => {
    let nextStep = null;
    let stepsIds = state.steps.map(s => s.position);
    let maxStepId = Math.max(...stepsIds);

    for (let stepId = state.currentStep.position + 1; stepId <= maxStepId; stepId++) {
      let step = state.steps.find(s => s.position == stepId);
      if (!step) continue;
      if (count.fullglass && (step.position == 6 || step.position == 7 || step.position == 8)) {
        continue;
      }
      nextStep = step;
      if (nextStep) break;
    }
    if (!nextStep) return;

    navigate(`/${nextStep.slug}`, {
      id: count.idPorta,
    });
  }

  // if (error || errorAccessories) {
  //   console.log("AT - portinaPedonaleIntegrata");
  //   return <div>failed to load</div>;
  // }
  // if (!data || !accessories) return <Loading />;

  return (
    <LayoutPage>
      <div style={{ flex: "0 0 auto" }}>
        <TopBar />

        <div className={"d-flex justify-content-between p-3"}>
            <div className="d-flex flex-column" style={{ lineHeight: "1" }}>
                <h2 className="h2 fw-bold">
                    Nuova configurazione {getProductName(count.idPorta)} (#{count.quoteId})
                </h2>
            </div>
            <div>
                {/* <Button variant={deleteClickCount == 0 ? "outlined" : "contained"} color="error" className="me-3 px-2">
                    <RemoveCircleOutlineOutlinedIcon />
                    <span className="ms-2">{deleteClickCount == 0 ? "ELIMINA" : "CONFERMA"}</span>
                </Button> */}

                <Button variant="contained" color="secondary" onClick={() => navigate("/configurations")}>
                    <CloseIcon />
                    <span className="ms-2">CHIUDI</span>
                </Button>
            </div>
        </div>

        <Grid
          className={classes.root}
          container
          sx={{ justifyContent: "center" }}>
          {state.currentStep && <TabsCustom
            value={state.currentStep.id}
            setValue={setValue}
            id={count.idPorta}
            count={count}
            handleChange={handleChange}
            axios_conf={axiosConfig}
            url={`${process.env.REACT_APP_API_URL}/categories-steps`}
          />}
        </Grid>
      </div>

      <div style={{ flex: "1 1 auto", minHeight: "0" }}>
        <GridPreventivi
          dataSend={{
            quote_id: count.quoteId,
            category_id: count.idPorta,
          }}
          axiosConfig={axiosConfig}
          url={`${process.env.REACT_APP_API_URL}/photo-preventivi`}
          dipendenze={open}>
          <div>
            <h5 className="text-primary">Portina pedonale integrata</h5>
            {count.isPorticina && (
              <>
                  <h6 className="h6 text-secondary fw-bold mt-3">Posizione</h6>
                  <div className="row">
                    <div className="col-4">
                      <div className={"card card-posizione p-1 " + (state.posizione == "left" ? "active" : "")} onClick={() => setState({ ...state, posizione: "left" })}>
                        <img src={posizione_sinistra} />
                        <span className="d-block text-center">A sinistra</span>
                      </div>
                    </div>

                    <div className="col-4">
                      <div className={"card card-posizione p-1 " + (state.posizione == "center" ? "active" : "")} onClick={() => setState({ ...state, posizione: "center" })}>
                        <img src={posizione_centrata} />
                        <span className="d-block text-center">Al centro</span>
                      </div>
                    </div>

                    <div className="col-4">
                      <div className={"card card-posizione p-1 " + (state.posizione == "right" ? "active" : "")} onClick={() => setState({ ...state, posizione: "right" })}>
                        <img src={posizione_destra} />
                        <span className="d-block text-center">A destra</span>
                      </div>
                    </div>
                  </div>

                  <h6 className="h6 text-secondary fw-bold mt-3">Apertura</h6>
                  <div className="row">
                    <div className="col-4">
                      <div className={"card card-apertura p-1 " + (state.apertura == "left" ? "active" : "")} onClick={() => setState({ ...state, apertura: "left" })}>
                        <img src={apertura_sinistra} />
                        <span className="d-block text-center">A sinistra</span>
                      </div>
                    </div>

                    <div className="col-4">
                      <div className={"card card-apertura p-1 " + (state.apertura == "right" ? "active" : "")} onClick={() => setState({ ...state, apertura: "right" })}>
                        <img src={apertura_destra} />
                        <span className="d-block text-center">A destra</span>
                      </div>
                    </div>
                  </div>

                  {accessories && <FormGroup className="mt-4">
                    {accessories.map((res) => {
                      if (res.sku == "ACCP51") {
                        return (
                          <div key={res.id} className="row mb-3">
                            <div className="col-6">
                              <span className="text-primary">Verniciatura telaio lato interno:</span><br />
                              <strong>NELLA STESSA FINITURA DEL PANNELLO</strong>
                            </div>
                            <div className="col-2 text-center">
                              <strong>oppure</strong>
                            </div>
                            <div className="col-4">
                              <span className="text-primary">RAL custom</span><br />
                              <input
                                type="text"
                                className="form-control"
                                name="ral_custom_ACCP51"
                                //value={formik.values.ral_custom_ACCP60}
                                onChange={(e) => {
                                  //dispatch(baseActions.setAltezzaPortina(e.target.value));
                                  //formik.setFieldValue("ral_custom_ACCP60", e.target.value);
                                  setState({ ...state, coloreTelaioInterno: e.target.value });
                                }}
                              />
                            </div>
                          </div>
                        );
                      }

                      if (res.sku == "ACCP60") {
                        return (
                          <div key={res.id} className="row mb-3">
                            <div className="col-6">
                              <span className="text-primary">Verniciatura maniglia lato esterno:</span><br />
                              <strong>NERO</strong>
                            </div>
                            <div className="col-2 text-center">
                              <strong>oppure</strong>
                            </div>
                            <div className="col-4">
                              <span className="text-primary">RAL custom</span><br />
                              <input
                                type="text"
                                className="form-control"
                                name="ral_custom_ACCP60"
                                //value={formik.values.ral_custom_ACCP60}
                                onChange={(e) => {
                                  //dispatch(baseActions.setAltezzaPortina(e.target.value));
                                  //formik.setFieldValue("ral_custom_ACCP60", e.target.value);
                                  setState({ ...state, coloreManigliaInterna: e.target.value });
                                }}
                              />
                            </div>
                          </div>
                        );
                      }

                      if (res.sku == "ACCP61") {
                        return (
                          <div key={res.id} className="row mb-3">
                            <div className="col-6">
                              <span className="text-primary">Verniciatura maniglia lato interno:</span><br />
                              <strong>NERO</strong>
                            </div>
                            <div className="col-2 text-center">
                              <strong>oppure</strong>
                            </div>
                            <div className="col-4">
                              <span className="text-primary">RAL custom</span><br />
                              <input
                                type="text"
                                className="form-control"
                                name="ral_custom_ACCP61"
                                //value={formik.values.ral_custom_ACCP61}
                                onChange={(e) => {
                                  //dispatch(baseActions.setAltezzaPortina(e.target.value));
                                  //formik.setFieldValue("ral_custom_ACCP61", e.target.value);
                                  setState({ ...state, coloreManigliaEsterna: e.target.value });
                                }}
                              />
                            </div>
                          </div>
                        );
                      }
                      return (
                        <div key={res.id}>
                          {res.type === "checkbox" && (
                            <CheckboxCustom
                              selected={res.selected}
                              value={res.sku}
                              name={res.sku}
                              label={res.name}
                              axiosConfig={axiosConfig}
                              idAcc={res.id}
                              quote={count.quoteId}
                            />
                          )}
                        </div>
                      );
                    })}
                  </FormGroup>}

                  <div className="d-flex flex-row-reverse justify-content-between mt-3">
                    <Button disabled={submitting} variant="contained" className="ps-3 pe-1" onClick={() => submit()}>
                      <span>Avanti</span>
                      <NavigateNextIcon />
                    </Button>

                    <Button variant="contained" className="ps-1 pe-3" color="secondary" onClick={() => navigateToPreviuosStep()}>
                      <NavigateBeforeIcon />
                      <span>Indietro</span>
                    </Button>
                  </div>
              </>
            )}
          </div>
        </GridPreventivi>
      </div>

      {errorVincolo && <div className="d-flex justify-content-center py-3" style={{position: "fixed", left: "0", bottom: "0", width: "100%"}}>
        <Alert variant="filled" severity="warning" sx={{zIndex: "2000"}} onClose={() => setErrorVincolo(null)}>
          <AlertTitle>Attenzione...</AlertTitle>
            {errorVincolo}
        </Alert>
      </div>}
    </LayoutPage>
  );
};

const useStyles = makeStyles({
  root: {
    "& MuiTabs-flexContainer css-heg063-MuiTabs-flexContainer": {
      justifyContent: "center",
    },
  },
});

export default PortinaPedonaleIntegrata;
