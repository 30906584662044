import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { FC, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export type WindowingProps = {
    itemsNumber: number,
    startFrom: number,
    onConfirm: (elementsNumber: number) => void,
}

export const WindowingDialog: FC<{data: WindowingProps, onConfirm: (elementsNumber: number) => void, onCancel: () => void}> = (props) => {
    const [value, setValue] = useState<number>(0);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const onConfirm = () => {
        props.onConfirm(value);
    };

    const getArElements = () => {
        let res: number[] = [];
        for (let n = props.data.startFrom; n <= props.data.itemsNumber; n++) {
            res.push(n);
        }
        return res;
    }

    return (
        <Dialog fullWidth={false} fullScreen={fullScreen} maxWidth="sm" onClose={props.onCancel} open={true}>
            <DialogTitle>Quante finestre vuoi inserire?</DialogTitle>
            <DialogContent className="py-2">
                <div className="d-flex justify-content-between my-0 dialog-colors-grid">
                    {getArElements().map(n => (
                        <div 
                            key={n} 
                            className={"px-3 py-2 dialog-colors-grid-item " + (value == n ? "selected" : "")}
                            style={{ borderBottom: "solid 1px #f1f1f1" }} 
                            onClick={() => setValue(n)}>
                            {n}
                        </div>
                    ))}
                </div>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={props.onCancel}>Annulla</Button>
                <Button variant="contained" color="primary" onClick={onConfirm} autoFocus>OK</Button>
            </DialogActions>
        </Dialog>
    );
}