import React, { FC } from "react";
import { Group, Rect } from "react-konva";
import { SPESSORE_TELAIO_FINESTRA } from "../../../../../../../core/common_constants";
import { DatiPannello } from "../../../../../../../core/common_draw_types";
import { PlaceholderRect } from "../draw_utils";
import { IRect } from "konva/lib/types";

export const Finestra: FC<{ panelData: DatiPannello, bbox: PlaceholderRect, spessoreTelaio: number, color: HTMLImageElement|undefined, onClick?: (placeholderData: PlaceholderRect) => void }> = (props) => {
    const {panelData, bbox, color, spessoreTelaio, onClick} = props;

    let rectFrameExt: IRect = {
        x: bbox.x,
        y: (panelData.bbox.height / 2) - (bbox.height / 2),
        width: bbox.width,
        height: bbox.height
    };

    let rectFrameInt: IRect = {
        x: bbox.x + spessoreTelaio,
        y: (panelData.bbox.height / 2) - (bbox.height / 2) + spessoreTelaio,
        width: bbox.width - (2*spessoreTelaio),
        height: bbox.height - (2*spessoreTelaio)
    };

    return (
        <Group onClick={() => onClick?.(bbox)} onTap={() => onClick?.(bbox)}>
            <Rect
                {...rectFrameExt}
                stroke={"#000"} 
                strokeWidth={0.75}
                fillPatternImage={color}
            />
            <Rect
                {...rectFrameInt}
                stroke={"#000"} 
                strokeWidth={0.75}
                fill={"lightblue"}
            />
        </Group>
    );
}