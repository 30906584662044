import React, { FC, useEffect, useState } from "react";
import { IRect, Vector2d } from "konva/lib/types";
import { 
    DEFAULT_LARGHEZZA_GUARNIZIONE,
    PROFONDITA_INVOLUCRO, 
    SPESSORE_STIPITE, 
    SPESSORE_TELAIO_FINESTRA, 
    ALTEZZA_PANNELLO_PORTA_CLASSIC, 
    SPESSORE_TELAIO_GLASSPANEL,
    DEBUG
} from "../../../../../../../core/common_constants";
import { Circle, Group, Label, Line, Rect, Tag, Text } from "react-konva";
import { degree, distance, toDrawUnit } from "../../../../../../../core/common_functions";
import { Vector } from "vecti";
import { useStoreSelector } from "../../../../../../../../hooks/StoreHooks";
import { ColorCode, DoorType, InstallationType, ModelType, SpringType } from "../../../../../../../core/common_types";
import { Tubolare } from "../accessories/tubolare";
import { Color, GridSize, Option, WindowSize } from "../../../../../../../models/door_configuration";
import { Guarnizione } from "../accessories/guarnizione";
import { DatiPannello } from "../../../../../../../core/common_draw_types";
import useImage from 'use-image';
import { calcolaFinestreDaNumeroElementi, calcolaGriglieDaNumeroElementi, PlaceholderRect } from "../draw_utils";
import { Finestra } from "../accessories/finestra";
import { Griglia } from "../accessories/griglia";
import { SuperficieAreata } from "../accessories/superficie_areata";
import { Placeholder } from "../accessories/Placeholder";

export const PortaSezionaleClassic: FC<{
    pf: Vector, 
    lfdraw: number, 
    hfdraw: number, 
    lpdraw: number, 
    hpdraw: number, 
    modello: Option, 
    doorColor: HTMLImageElement|undefined, 
    windowsColor: HTMLImageElement|undefined, 
    gridsColor: HTMLImageElement|undefined
    onPanelClicked?: (pnaelData: DatiPannello, placeholderData: PlaceholderRect) => void}> = (props) => {
    const {pf, lpdraw, hpdraw, lfdraw, hfdraw, modello, doorColor, windowsColor, gridsColor, onPanelClicked} = props;    
    const larghezzaGuarnizione = toDrawUnit(DEFAULT_LARGHEZZA_GUARNIZIONE);
    const {doorConfigurationBuilder} = useStoreSelector(store => store);
    
    const buildPannelli = (): DatiPannello[] => {
        let altezzaDisegno: number = 0;
        let altezzaAssoluta = hpdraw;
        let larghezzaDisegno: number = 0;
        let altezzaPannelloDisegno: number = 0;
        let ptBottomLeft = Vector.of([(lfdraw/2)-(lpdraw/2), hfdraw]);
        let ptTopLeft = Vector.of([(lfdraw/2)-(lpdraw/2), altezzaAssoluta]);
        let ptTopRight = Vector.of([(lfdraw/2)+(lpdraw/2), altezzaAssoluta]);
        let larghezzaAssoluta = ptTopRight.x - ptTopLeft.x;

        altezzaDisegno = altezzaAssoluta;
        larghezzaDisegno = larghezzaAssoluta;
        altezzaPannelloDisegno = ALTEZZA_PANNELLO_PORTA_CLASSIC;

        if (doorConfigurationBuilder.isOltreLuce()) {            
            let vecDirTopLeft = pf.subtract(Vector.of([0, 0])).normalize();
            let vecDirTopRight = pf.subtract(Vector.of([lfdraw, 0])).normalize();
            let vecDirBottomLeft = pf.subtract(Vector.of([0, hfdraw])).normalize();
            
            if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.in_luce) {                    
                ptTopLeft = Vector.of([lfdraw-lpdraw, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptBottomLeft = Vector.of([lfdraw-lpdraw, hfdraw]).add(vecDirBottomLeft.multiply(SPESSORE_STIPITE));
                ptTopRight = Vector.of([lfdraw-10, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
                larghezzaAssoluta -= 10;
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.in_luce) {
                ptTopLeft = Vector.of([10, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptBottomLeft = Vector.of([10, hfdraw]).add(vecDirBottomLeft.multiply(SPESSORE_STIPITE));
                ptTopRight = Vector.of([lfdraw-(lfdraw-lpdraw), hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
                larghezzaAssoluta -= 10;
            } else {
                ptTopLeft = Vector.of([(lfdraw/2)-(lpdraw/2), hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptBottomLeft = Vector.of([(lfdraw/2)-(lpdraw/2), hfdraw]).add(vecDirBottomLeft.multiply(SPESSORE_STIPITE));
                ptTopRight = Vector.of([(lfdraw/2)+(lpdraw/2), hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
                larghezzaAssoluta -= 20;
            }
            
            altezzaDisegno = ptBottomLeft.y - ptTopLeft.y;
            larghezzaDisegno = ptTopRight.x - ptTopLeft.x;
            altezzaPannelloDisegno = ALTEZZA_PANNELLO_PORTA_CLASSIC * altezzaDisegno / hpdraw;
        }

        let y: number = ptBottomLeft.y;
        let altezzaCorrenteDisegno: number = 0;
        let panelNumber = 1;
        let panelCount: number = 0;

        let panels: DatiPannello[] = [];
        while (altezzaCorrenteDisegno < altezzaDisegno) {
            let height = altezzaPannelloDisegno;
            let altezzaAssolutaPannello = ALTEZZA_PANNELLO_PORTA_CLASSIC;

            if ((altezzaCorrenteDisegno + altezzaPannelloDisegno) > altezzaDisegno) {
                height = altezzaDisegno - altezzaCorrenteDisegno;
                altezzaAssolutaPannello = altezzaAssoluta - ((panelNumber-1) * ALTEZZA_PANNELLO_PORTA_CLASSIC);
            }

            let p: DatiPannello = { 
                bbox: {x: ptTopLeft.x, y: y - height, width: larghezzaDisegno, height},
                panelNumber,
                panelCount,
                larghezzaAssoluta,
                altezzaAssoluta: altezzaAssolutaPannello,
                altezzaRelativa: altezzaPannelloDisegno,
                selectable: true,
            };

            panels.push(p);

            altezzaCorrenteDisegno += height;
            y -= height;
            panelNumber++;
        }

        panelCount = panels.length;
        panels.forEach(p => p.panelCount = panelCount);
        
        return panels;
    }

    const buildGuarnizioneSx = (): {x: number, y: number, length: number, rotation: number} => {
        if (!doorConfigurationBuilder.isOltreLuce()) {
            return {
                x: (lfdraw/2)-(lpdraw/2), 
                y: hfdraw - hpdraw, 
                length: hpdraw,
                rotation: 0
            };          
        } else {
            let vecDirTopLeft = pf.subtract(Vector.of([0, 0])).normalize();            
            let vecDirBottomLeft = pf.subtract(Vector.of([0, hfdraw])).normalize();
            
            let ptTopLeft: Vector;
            let ptBottomLeft: Vector;
            if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.in_luce) {
                ptTopLeft = Vector.of([0, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptBottomLeft = Vector.of([0, hfdraw]).add(vecDirBottomLeft.multiply(SPESSORE_STIPITE));
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.in_luce) {
                ptTopLeft = Vector.of([10, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptBottomLeft = Vector.of([10, hfdraw]).add(vecDirBottomLeft.multiply(SPESSORE_STIPITE));
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.in_luce) {
                ptTopLeft = Vector.of([0, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptBottomLeft = Vector.of([0, hfdraw]).add(vecDirBottomLeft.multiply(SPESSORE_STIPITE));
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.in_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.in_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.oltre_luce) {
                ptTopLeft = Vector.of([10, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptBottomLeft = Vector.of([10, hfdraw]).add(vecDirBottomLeft.multiply(SPESSORE_STIPITE));
            } else {
                ptTopLeft = Vector.of([0, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptBottomLeft = Vector.of([0, hfdraw]).add(vecDirBottomLeft.multiply(SPESSORE_STIPITE));
            }
            
            return {
                x: ptTopLeft.x, 
                y: ptTopLeft.y, 
                length: ptBottomLeft.y-ptTopLeft.y, 
                rotation: 0
            }; 
        }
    }

    const buildGuarnizioneDx = (): {x: number, y: number, length: number, rotation: number} => {
        if (!doorConfigurationBuilder.isOltreLuce()) {
            return {
                x: (lfdraw/2)+(lpdraw/2) - larghezzaGuarnizione,
                y: hfdraw - hpdraw,
                length: hpdraw,
                rotation: 0
            }
        } else {
            let vecDirTopRight = pf.subtract(Vector.of([lfdraw, 0])).normalize();
            let vecDirBottomRight = pf.subtract(Vector.of([lfdraw, hfdraw])).normalize();

            let ptTopRight: Vector;
            let ptBottomRight: Vector;
            if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.in_luce) {                    
                ptTopRight = Vector.of([lfdraw-larghezzaGuarnizione-10, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
                ptBottomRight = Vector.of([lfdraw-larghezzaGuarnizione-10, hfdraw]).add(vecDirBottomRight.multiply(SPESSORE_STIPITE));
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.in_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.oltre_luce) {                    
                ptTopRight = Vector.of([lfdraw-larghezzaGuarnizione, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
                ptBottomRight = Vector.of([lfdraw-larghezzaGuarnizione, hfdraw]).add(vecDirBottomRight.multiply(SPESSORE_STIPITE));
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.in_luce) {
                ptTopRight = Vector.of([lfdraw-larghezzaGuarnizione, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));   
                ptBottomRight = Vector.of([lfdraw-larghezzaGuarnizione, hfdraw]).add(vecDirBottomRight.multiply(SPESSORE_STIPITE));             
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.in_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.in_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.oltre_luce) {
                ptTopRight = Vector.of([lfdraw-10-larghezzaGuarnizione, 0]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));   
                ptBottomRight = Vector.of([lfdraw-10-larghezzaGuarnizione, hfdraw]).add(vecDirBottomRight.multiply(SPESSORE_STIPITE));
            } else {
                ptTopRight = Vector.of([lfdraw-larghezzaGuarnizione, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
                ptBottomRight = Vector.of([lfdraw-larghezzaGuarnizione, hfdraw]).add(vecDirBottomRight.multiply(SPESSORE_STIPITE));
            }

            return {
                x: ptTopRight.x, 
                y: ptTopRight.y, 
                length: ptBottomRight.y-ptTopRight.y, 
                rotation: 0
            }; 
        }
    }

    const buildGuarnizioneTraversa = (): {x: number, y: number, length: number, rotation: number} => {
        if (!doorConfigurationBuilder.isOltreLuce()) {
            return {
                x: (lfdraw/2)+(lpdraw/2),
                y: hfdraw-hpdraw,
                length: lpdraw,
                rotation: 90
            }
        } else {
            let vecDirTopLeft = pf.subtract(Vector.of([0, 0])).normalize();
            let vecDirTopRight = pf.subtract(Vector.of([lfdraw, 0])).normalize();

            let ptTopLeft: Vector;
            let ptTopRight: Vector;
            if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.in_luce) {                               
                if (doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.in_luce) {
                    ptTopLeft = Vector.of([0, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));         
                    ptTopRight = Vector.of([lfdraw - 10, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
                } else {
                    ptTopLeft = Vector.of([0, 0]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));         
                    ptTopRight = Vector.of([lfdraw - 10, 0]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
                }
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.in_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.oltre_luce) {            
                if (doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.in_luce) {
                    ptTopLeft = Vector.of([10, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                    ptTopRight = Vector.of([lfdraw, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));                
                } else {
                    ptTopLeft = Vector.of([10, 0]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                    ptTopRight = Vector.of([lfdraw, 0]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));                
                }                        
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.in_luce) {                    
                ptTopLeft = Vector.of([0, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptTopRight = Vector.of([lfdraw, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));                
            } else {
                if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.in_luce &&
                    doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.in_luce) {
                    ptTopLeft = Vector.of([10, 0]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                    ptTopRight = Vector.of([lfdraw - 10, 0]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
                } else {
                    ptTopLeft = Vector.of([0, 0]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                    ptTopRight = Vector.of([lfdraw, 0]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
                }
            }

            return {
                x: ptTopRight.x, 
                y: ptTopRight.y, 
                length: ptTopRight.x-ptTopLeft.x, 
                rotation: 90
            }; 
        }
    }

    const buildTubolareSx = (): {x: number, y: number, length: number, width: number, rotation: number} => {        
        if (!doorConfigurationBuilder.isOltreLuce()) {
            return {
                x: (lfdraw/2)-(lpdraw/2) - 10, 
                y: hfdraw - hpdraw, 
                length: hpdraw,
                width: 100,
                rotation: 0
            };
        } else {
            let vecDirTopLeft = pf.subtract(Vector.of([0, 0])).normalize();            
            let vecDirBottomLeft = pf.subtract(Vector.of([0, hfdraw])).normalize();

            let ptTopLeft: Vector;
            let ptBottomLeft: Vector;
            if (doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.in_luce) {
                ptTopLeft = Vector.of([0, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptBottomLeft = Vector.of([0, hfdraw]).add(vecDirBottomLeft.multiply(SPESSORE_STIPITE));
            } else {
                ptTopLeft = Vector.of([0, 0]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptBottomLeft = Vector.of([0, hfdraw]).add(vecDirBottomLeft.multiply(SPESSORE_STIPITE));
            }

            return {
                x: ptTopLeft.x,
                y: ptTopLeft.y,
                length: distance(ptTopLeft.x, ptTopLeft.y, ptBottomLeft.x, ptBottomLeft.y),
                width: 100,
                rotation: 0
            }; 
        }
    }

    const buildTubolareDx = (): {x: number, y: number, length: number, width: number, rotation: number} => {
        if (!doorConfigurationBuilder.isOltreLuce()) {
            return {
                x: (lfdraw/2)+(lpdraw/2),
                y: hfdraw - hpdraw,
                length: hpdraw,
                width: 100,
                rotation: 0
            }
        } else {
            let vecDirTopRight = pf.subtract(Vector.of([lfdraw, 0])).normalize();
            let vecDirBottomRight = pf.subtract(Vector.of([lfdraw, hfdraw])).normalize();

            let ptTopRight: Vector;
            let ptBottomRight: Vector
            if (doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.in_luce) {
                ptTopRight = Vector.of([lfdraw-10, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
                ptBottomRight = Vector.of([lfdraw-10, hfdraw]).add(vecDirBottomRight.multiply(SPESSORE_STIPITE));
            } else {
                ptTopRight = Vector.of([lfdraw-10, 0]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
                ptBottomRight = Vector.of([lfdraw-10, hfdraw]).add(vecDirBottomRight.multiply(SPESSORE_STIPITE));
            }

            return {
                x: ptTopRight.x,
                y: ptTopRight.y,
                length: distance(ptTopRight.x, ptTopRight.y, ptBottomRight.x, ptBottomRight.y),
                width: 100,
                rotation: 0
            }; 
        }
    }

    const buildTubolareTraversa = (sectionWidth:number, offset: number): {x: number, y: number, length: number, width: number, rotation: number} => {        
        if (!doorConfigurationBuilder.isOltreLuce()) {
            return {
                x: lfdraw-lpdraw - (2 * 10),
                y: hfdraw-hpdraw - offset,
                length: lpdraw + (2 * 10),
                width: sectionWidth,
                rotation: 270
            }
        } else {
            let vecDirTopLeft = pf.subtract(Vector.of([0, 0])).normalize();
            let vecDirTopRight = pf.subtract(Vector.of([lfdraw, 0])).normalize();

            let ptTopLeft: Vector;
            let ptTopRight: Vector;
            if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.in_luce) {
                ptTopLeft = Vector.of([0, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptTopRight = Vector.of([lfdraw, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.in_luce) {
                ptTopLeft = Vector.of([0, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptTopRight = Vector.of([lfdraw, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
            } else if (doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteSx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneMontanteDx == InstallationType.oltre_luce &&
                doorConfigurationBuilder.configuration?.tipoInstallazioneTraversa == InstallationType.in_luce) {
                ptTopLeft = Vector.of([0, hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptTopRight = Vector.of([lfdraw, hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
            } else {
                ptTopLeft = Vector.of([(lfdraw/2)-(lpdraw/2), hfdraw-hpdraw]).add(vecDirTopLeft.multiply(SPESSORE_STIPITE));
                ptTopRight = Vector.of([(lfdraw/2)+(lpdraw/2), hfdraw-hpdraw]).add(vecDirTopRight.multiply(SPESSORE_STIPITE));
            }

            return {
                x: ptTopLeft.x,
                y: ptTopLeft.y - offset,
                length: distance(ptTopLeft.x, ptTopLeft.y, ptTopRight.x, ptTopRight.y),
                width: sectionWidth,
                rotation: 270
            }; 
        }
    }

    const renderTubolareVeletta = () => {
        if (!doorConfigurationBuilder.configuration) return null;
        
        if (doorConfigurationBuilder.configuration.tipoMolle == SpringType.torsione) {
            let sectionWidth: number = 100;
            let doppioTubolare: boolean = true;
            
            return (
                <>
                    <Tubolare color={doorColor} {...buildTubolareTraversa(sectionWidth, 0)} />
                    {doppioTubolare && <Tubolare color={doorColor} {...buildTubolareTraversa(sectionWidth, 10)} />}
                </>
            );
        } else if (doorConfigurationBuilder.configuration?.tipoMolle == SpringType.trazione) {
            let sectionWidth = 60;
            if (doorConfigurationBuilder.configuration?.isMotorizzata) {
                sectionWidth = 120;
            } 
            return <Tubolare color={doorColor} {...buildTubolareTraversa(sectionWidth, 0)} />
        }

        return null;
    }

    const renderPanels = () => {
        let pannelli = buildPannelli();
        
        return pannelli.map((p, idx, ar) => (
            <PannelloPortaClassic
                key={`panel_${(idx+1)}`}
                pf={pf}
                lf={lfdraw}
                hf={hfdraw}
                panelData={p}
                doorColor={doorColor}
                windowColor={windowsColor}
                gridColor={gridsColor}
                modello={modello}
                onClick={onPanelClicked}
            />
        ));
    }

    const renderLabelCampione = () => {
        let pt1: Vector = Vector.of([0, hpdraw]);
        let pt2: Vector = Vector.of([lpdraw, 0]);
        let rads = degree(pt1.x, pt1.y, pt2.x, pt2.y);
        return (
            <Label x={(lfdraw/2)-75} y={(hfdraw/2)+40} rotation={rads} opacity={0.6}>
                <Tag fill="white" stroke="red"/>
                <Text text={"Colore da campione"} padding={5} fontSize={20} />
            </Label>
        );
    }

    if (!doorConfigurationBuilder.configuration) return null;
    if (!doorConfigurationBuilder.configuration.porta) return null;
    if (!doorConfigurationBuilder.configuration.modello) return null;

    return (
        <>
            {renderPanels()}
            
            {doorConfigurationBuilder.isMontanteDxInLuce() && <Tubolare color={doorColor} {...buildTubolareSx()} />}
            {doorConfigurationBuilder.isMontanteSxInLuce() && <Tubolare color={doorColor} {...buildTubolareDx()} />}
            {doorConfigurationBuilder.isTraversaInLuce() && renderTubolareVeletta()}
            
            <Guarnizione {...buildGuarnizioneSx()} />
            <Guarnizione {...buildGuarnizioneDx()} />
            <Guarnizione {...buildGuarnizioneTraversa()} />

            {doorConfigurationBuilder.configuration.tipoColoreEsterno == "a_campione" && renderLabelCampione()}
        </>
    );
}

const PannelloPortaClassic: FC<{pf: Vector, lf: number, hf: number, modello: Option, doorColor: HTMLImageElement|undefined, windowColor: HTMLImageElement|undefined, gridColor: HTMLImageElement|undefined, panelData: DatiPannello, onClick?: (panelData: DatiPannello, placeholderData: PlaceholderRect) => void}> = (props) => {
    const {doorConfigurationBuilder} = useStoreSelector(store => store);
    const delta = toDrawUnit(30/2);

    const addFinituraWide = () => {
        let ptTopLeft = Vector.of([props.panelData.bbox.x, props.panelData.bbox.y + props.panelData.bbox.height + delta]);
        let ptTopRight = Vector.of([props.panelData.bbox.x + props.panelData.bbox.width, props.panelData.bbox.y + props.panelData.bbox.height + delta]);
        let ptBottomLeft = Vector.of([props.panelData.bbox.x, props.panelData.bbox.y + props.panelData.bbox.height - delta]);
        let ptBottomRight = Vector.of([props.panelData.bbox.x + props.panelData.bbox.width, props.panelData.bbox.y + props.panelData.bbox.height - delta]);
        let ptCenterLeft = Vector.of([props.panelData.bbox.x, props.panelData.bbox.y + props.panelData.bbox.height - (props.panelData.altezzaRelativa/2)]);
        let ptCenterRight = Vector.of([props.panelData.bbox.x + props.panelData.bbox.width, props.panelData.bbox.y + props.panelData.bbox.height - (props.panelData.altezzaRelativa/2)]);

        return (
            <Group>
                {props.panelData.panelNumber == 1 && <Line 
                    points={[ptTopLeft.x, props.panelData.bbox.y + props.panelData.bbox.height, ptTopRight.x, props.panelData.bbox.y + props.panelData.bbox.height]}
                    stroke={"#000"}
                    strokeWidth={0.5}
                />}
                {props.panelData.panelNumber > 1 && <Line 
                    points={[ptTopLeft.x, ptTopLeft.y, ptTopRight.x, ptTopRight.y]}
                    stroke={"#000"}
                    strokeWidth={0.5}
                />}
                {/*props.panelData.panelNumber != props.panelData.panelCount &&*/ <Line 
                    points={[ptCenterLeft.x, ptCenterLeft.y - delta, ptCenterRight.x, ptCenterRight.y - delta]}
                    stroke={"#000"}
                    strokeWidth={0.5}                    
                />}
                {/*props.panelData.panelNumber != props.panelData.panelCount &&*/ <Line 
                    points={[ptCenterLeft.x, ptCenterLeft.y + delta, ptCenterRight.x, ptCenterRight.y + delta]}
                    stroke={"#000"}
                    strokeWidth={0.5}
                />}
                <Line 
                    points={[ptBottomLeft.x, ptBottomLeft.y, ptBottomRight.x, ptBottomRight.y]}
                    stroke={"#000"}
                    strokeWidth={0.5}
                />
            </Group>
        );
    }

    const renderFinestratura = () => {
        if (!doorConfigurationBuilder.configuration) return null;
        if (!doorConfigurationBuilder.configuration.finestratura) return null;
        if (doorConfigurationBuilder.configuration.finestratura.windowSize == WindowSize.undefined) return null;
        if (!doorConfigurationBuilder.configuration.finestratura.quantity || doorConfigurationBuilder.configuration.finestratura.quantity <= 0) return null;
        
        if (doorConfigurationBuilder.configuration.finestratura.windowSize == WindowSize._540x360 && props.panelData.altezzaAssoluta < 45) return null;
        if (doorConfigurationBuilder.configuration.finestratura.windowSize == WindowSize._230x230 && props.panelData.altezzaAssoluta < 40) return null;

        const isSectionFinestratura = (doorConfigurationBuilder.steps.find(s => s.isActive && s.slug == "finestratura"));

        let fRender: PlaceholderRect[] = [];

        let glasspanel = doorConfigurationBuilder.isGlasspanel();
        let spessoreTelaio = SPESSORE_TELAIO_FINESTRA;
        if (!glasspanel) {
            fRender = calcolaFinestreDaNumeroElementi(doorConfigurationBuilder.configuration.finestratura.quantity ?? 1, props.panelData, doorConfigurationBuilder.configuration.finestratura.windowSize);
        } else {
            spessoreTelaio = SPESSORE_TELAIO_GLASSPANEL;
            fRender.push({
                x: 0,
                y: 0,
                width: props.panelData.bbox.width,
                height: props.panelData.bbox.height,
                position: 0
            });
        }

        const isGridCollision = (panelData: DatiPannello, placeholderData: PlaceholderRect) => {
            if (!doorConfigurationBuilder.configuration) return false;

            const {ventilazione} = doorConfigurationBuilder.configuration;
            if (ventilazione) {
                let griglie = ventilazione.positioning.filter(v => v.panelNumber == panelData.panelNumber);
                if (griglie.length <= 0) return false;

                let collisions = griglie.filter(griglia => (
                    placeholderData.x < griglia.bbox.x + griglia.bbox.width &&
                    placeholderData.x + placeholderData.width > griglia.bbox.x &&
                    placeholderData.y < griglia.bbox.y + griglia.bbox.height &&
                    placeholderData.y + placeholderData.height > griglia.bbox.y
                ));
                return collisions.length > 0;
            }

            return false;
        }

        const isIntegratedDoorCollision = (panelData: DatiPannello, placeholderData: PlaceholderRect) => {
            if (!doorConfigurationBuilder.configuration) return false;
            if (!doorConfigurationBuilder.configuration.portinaIntegrata) return false;
            if (!doorConfigurationBuilder.configuration.portinaIntegrata.portina) return false;

            const {portinaIntegrata} = doorConfigurationBuilder.configuration;
            if (portinaIntegrata && portinaIntegrata.bbox) {
                const phXAbs = placeholderData.x + (panelData.org?.x ?? 0);
                const phXRx = phXAbs + placeholderData.width;
                const phYAbs = (panelData.org?.y ?? 0) + (props.panelData.bbox.height/2)-(placeholderData.height/2);
                const phYBm = phYAbs + placeholderData.height;

                const piXRx = portinaIntegrata.bbox.x + portinaIntegrata.bbox.width;
                const piYBm = portinaIntegrata.bbox.y;
                const isCollision = (
                    (phXAbs >= portinaIntegrata.bbox.x && phXAbs <= piXRx && (phYAbs >= piYBm || phYBm >= piYBm)) ||
                    (phXAbs < portinaIntegrata.bbox.x && phXRx >= portinaIntegrata.bbox.x && (phYAbs >= piYBm || phYBm >= piYBm))
                );
                return isCollision;
            }

            return false;
        }

        const render = (panelData: DatiPannello, placeholderData: PlaceholderRect) => {
            if (!doorConfigurationBuilder.configuration) return null;
            if (!doorConfigurationBuilder.configuration.finestratura) return null;

            const {finestratura} = doorConfigurationBuilder.configuration;
            
            let finestra = finestratura.positioning.find(f => f.panelNumber == panelData.panelNumber && f.position == placeholderData.position);
            if (finestra) return <Finestra
                key={"window_" + (placeholderData.position+1)}
                color={props.windowColor}
                bbox={placeholderData} 
                panelData={panelData}
                spessoreTelaio={spessoreTelaio}
                onClick={(args) => isSectionFinestratura && props.onClick?.(panelData, args)} 
            />;

            if (isSectionFinestratura && !isGridCollision(panelData, placeholderData) && !isIntegratedDoorCollision(panelData, placeholderData)) return <Placeholder
                bbox={placeholderData} 
                panelData={panelData}
                onClick={(args) => props.onClick?.(panelData, args)} 
            />;

            return null;
        }

        return (
            <Group x={props.panelData.org?.x} y={props.panelData.org?.y}>
                {fRender.map((f, idx) => (
                    <Group>
                        {render(props.panelData, f)}
                        {DEBUG && <Circle x={f.x} y={(props.panelData.bbox.height/2)-(f.height/2)} radius={3} fill={"red"} />}
                    </Group>
                ))}
            </Group>
        );
    }

    const renderVentilazione = () => {
        if (!doorConfigurationBuilder.configuration) return null;
        if (!doorConfigurationBuilder.configuration.ventilazione) return null;

        if (doorConfigurationBuilder.configuration.ventilazione.gridSize == GridSize._344x138 && props.panelData.altezzaAssoluta < 23) return null;
        if (doorConfigurationBuilder.configuration.ventilazione.gridSize == GridSize._400x200 && props.panelData.altezzaAssoluta < 30) return null;

        const isSectionVentilazione = (doorConfigurationBuilder.steps.find(s => s.isActive && s.slug == "ventilazione"));
        
        let fRender: PlaceholderRect[] = [];

        let superficieAreata = doorConfigurationBuilder.isSuperficieAreata();
        if (!superficieAreata) {
            fRender = calcolaGriglieDaNumeroElementi(doorConfigurationBuilder.configuration.ventilazione.quantity ?? 1, props.panelData, doorConfigurationBuilder.configuration.ventilazione.gridSize);
        } else {
            fRender.push({
                x: 0,
                y: 0,
                width: props.panelData.bbox.width,
                height: props.panelData.bbox.height,
                position: 0
            });
        }

        const isWindowCollision = (panelData: DatiPannello, placeholderData: PlaceholderRect) => {
            if (!doorConfigurationBuilder.configuration) return false;

            const {finestratura} = doorConfigurationBuilder.configuration;
            if (finestratura) {
                let finestre = finestratura.positioning.filter(f => f.panelNumber == panelData.panelNumber);
                if (finestre.length <= 0) return false;

                let collisions = finestre.filter(finestra => (
                    placeholderData.x < finestra.bbox.x + finestra.bbox.width &&
                    placeholderData.x + placeholderData.width > finestra.bbox.x &&
                    placeholderData.y < finestra.bbox.y + finestra.bbox.height &&
                    placeholderData.y + placeholderData.height > finestra.bbox.y
                ));
                return collisions.length > 0;
            }

            return false;
        }

        const isIntegratedDoorCollision = (panelData: DatiPannello, placeholderData: PlaceholderRect) => {
            if (!doorConfigurationBuilder.configuration) return false;
            if (!doorConfigurationBuilder.configuration.portinaIntegrata) return false;
            if (!doorConfigurationBuilder.configuration.portinaIntegrata.portina) return false;

            const {portinaIntegrata} = doorConfigurationBuilder.configuration;
            if (portinaIntegrata && portinaIntegrata.bbox) {
                const phXAbs = placeholderData.x + (panelData.org?.x ?? 0);
                const phXRx = phXAbs + placeholderData.width;
                const phYAbs = (panelData.org?.y ?? 0) + (props.panelData.bbox.height/2)-(placeholderData.height/2);
                const phYBm = phYAbs + placeholderData.height;

                const piXRx = portinaIntegrata.bbox.x + portinaIntegrata.bbox.width;
                const piYBm = portinaIntegrata.bbox.y;
                const isCollision = (
                    (phXAbs >= portinaIntegrata.bbox.x && phXAbs <= piXRx && (phYAbs >= piYBm || phYBm >= piYBm)) ||
                    (phXAbs < portinaIntegrata.bbox.x && phXRx >= portinaIntegrata.bbox.x && (phYAbs >= piYBm || phYBm >= piYBm))
                );
                return isCollision;
            }

            return false;
        }

        const render = (panelData: DatiPannello, placeholderData: PlaceholderRect) => {
            if (!doorConfigurationBuilder.configuration) return null;
            if (!doorConfigurationBuilder.configuration.ventilazione) return null;

            const {ventilazione} = doorConfigurationBuilder.configuration;
            
            let griglia = ventilazione.positioning.find(f => f.panelNumber == panelData.panelNumber && f.position == placeholderData.position);
            if (griglia && !superficieAreata) return <Griglia
                key={"grid_" + (placeholderData.position+1)}
                bbox={placeholderData} 
                panelData={panelData}
                color={props.gridColor}
                onClick={(args) => isSectionVentilazione && props.onClick?.(panelData, args)} 
            />;
            if (griglia && superficieAreata) return <SuperficieAreata
                key={"grid_" + (placeholderData.position+1)}
                bbox={placeholderData} 
                panelData={panelData}
                gridSize={ventilazione.gridSize}
                color={props.gridColor}
                onClick={(args) => isSectionVentilazione && props.onClick?.(panelData, args)} 
            />;

            if (isSectionVentilazione && !isWindowCollision(panelData, placeholderData) && !isIntegratedDoorCollision(panelData, placeholderData)) return <Placeholder
                bbox={placeholderData} 
                panelData={panelData}
                onClick={(args) => props.onClick?.(panelData, args)} 
            />;

            return null;
        }

        return (
            <Group x={props.panelData.org?.x} y={props.panelData.org?.y}>
                {fRender.map((f, idx) => (
                    <Group>
                        {render(props.panelData, f)}
                    </Group>
                ))}
            </Group>
        );
    }

    const getBboxDisegno = (panelData: DatiPannello): IRect => {       
        let org = Vector.of([panelData.bbox.x, panelData.bbox.y]);
        let larghezza = panelData.bbox.width;
        let altezza = panelData.bbox.height;

        if (doorConfigurationBuilder.isOltreLuce()) {  
            panelData.debugPt = [];
        }

        let rect: IRect =  {
            ...org,
            width: larghezza,
            height: altezza
        };

        panelData.org = {x: org.x, y: org.y};
        
        return rect;
    }

    return (
        <Group>
            <Rect 
                {...getBboxDisegno(props.panelData)}
                stroke={"#000"} 
                strokeWidth={0.5}
                strokeEnabled={props.modello?.name != ModelType.wide}
                fillPatternImage={props.doorColor ? props.doorColor : undefined}
            />

            {props.modello?.name == ModelType.wide && addFinituraWide()}

            {renderFinestratura()}
            {renderVentilazione()}

            {/* <Circle
                x={props.panelData.org?.x}
                y={props.panelData.org?.y}
                radius={4}
                fill={"green"}
            /> */}

            {/*props.panelData.debugPt?.map(p => (
                <Circle
                    x={p.x}
                    y={p.y}
                    radius={4}
                    fill={"blue"}
                />  
            ))*/}
        </Group>
    );
}