import { Alert, AlertTitle, Button, Dialog, DialogActions, DialogTitle, TextField } from "@mui/material";
import React, { createContext, FC, PropsWithChildren, ReactNode, useContext, useState } from "react";
import { EditableMeasureProps } from "../routes/configurations/door/builder/components/object-draw/editable_measure";
import { MeasureEditorDialog } from "../ui/dialogs/measure_editor_dialog";
import { ColorsDialog, ColorsProps } from "../ui/dialogs/colors_dialog";
import { WindowingDialog, WindowingProps } from "../ui/dialogs/windowing_dialog";
import { TextInputDialog, TextInputProps } from "../ui/dialogs/text_input_dialog";
import { RalColorsDialog, RalColorsProps } from "../ui/dialogs/ral_colors_dialog";

type DialogsContextData = {
    textInput: (textInputDialog: TextInputProps) => void,
    customizeWindowing: (windowingData: WindowingProps) => void,
    editMeasure: (measureEditorDialog: EditableMeasureProps | null) => void,
    selectColor: (colorsData: ColorsProps | null) => void,
    selectRalColor: (ralColorsData: RalColorsProps | null) => void,
    showError: (title: string, message: string, actions?: ReactNode) => void,
    showWarning: (title: string, message: string) => void,
    showConfirm: (title: string, message: string) => void
}

const DialogsContext = createContext<DialogsContextData | null>(null);

const DialogsProvider: FC<{children?: ReactNode}> = ({ children }) => {
    const [measureEditorDialog, setMeasureEditorDialog] = useState<EditableMeasureProps | null>(null);
    const [colorsDialog, setColorsDialog] = useState<ColorsProps | null>(null);
    const [ralColorsDialog, setRalColorsDialog] = useState<RalColorsProps | null>(null);
    const [errorDialog, setErrorDialog] = useState<{title: string, message: string, actions?: ReactNode} | null>(null);
    const [warningDialog, setWarningDialog] = useState<{title: string, message: string} | null>(null);
    const [confirmDialog, setConfirmDialog] = useState<{title: string, message: string} | null>(null);
    const [windowingDialog, setWindowingDialog] = useState<WindowingProps|null>(null);
    const [textInputDialog, setTextInputDialog] = useState<TextInputProps|null>(null);

    const editMeasure = (measureEditorDialog: EditableMeasureProps | null) => {
        setMeasureEditorDialog(measureEditorDialog);
    }

    const selectColor = (colorsData: ColorsProps | null) => {
        setColorsDialog(colorsData);
    }

    const selectRalColor = (ralColorsData: RalColorsProps | null) => {
        setRalColorsDialog(ralColorsData);
    }

    const showError = (title: string, message: string, actions?: ReactNode) => {
        setErrorDialog({title, message, actions});
    }

    const showWarning = (title: string, message: string) => {
        setWarningDialog({title, message});
    }

    const showConfirm = (title: string, message: string) => {
        setConfirmDialog({title, message});
    }

    const customizeWindowing = (windowingData: WindowingProps) => {
        setWindowingDialog(windowingData);
    }

    const textInput = (textInputDialog: TextInputProps) => {
        setTextInputDialog(textInputDialog);
    }

    return (
        <DialogsContext.Provider value={{editMeasure, selectColor, selectRalColor, showError, showWarning, showConfirm, customizeWindowing, textInput}}>
            {children}

            {measureEditorDialog && <MeasureEditorDialog data={measureEditorDialog} onConfirm={(value) => {
                let res = measureEditorDialog.onConfirm(value);
                if (!res.isValid) return res;

                setMeasureEditorDialog(null);

                return res;
            }} onCancel={() => setMeasureEditorDialog(null)} />}

            {colorsDialog && <ColorsDialog data={colorsDialog} onConfirm={(value) => {
                colorsDialog.onConfirm(value);
                
                setColorsDialog(null);
            }} onCancel={() => setColorsDialog(null)} />}

            {ralColorsDialog && <RalColorsDialog data={ralColorsDialog} onConfirm={(value) => {
                ralColorsDialog.onConfirm(value);
                
                setRalColorsDialog(null);
            }} onCancel={() => setRalColorsDialog(null)} />}

            {windowingDialog && <WindowingDialog data={windowingDialog} onConfirm={(elementsNumber) => {
                windowingDialog.onConfirm(elementsNumber);
                
                setWindowingDialog(null);
            }} onCancel={() => setWindowingDialog(null)} />}

            {textInputDialog && <TextInputDialog data={textInputDialog} onConfirm={(text) => {
                textInputDialog.onConfirm(text);
                
                setTextInputDialog(null);
            }} onCancel={() => setTextInputDialog(null)} />}

            {errorDialog && (
                <div className="d-flex justify-content-center py-3" style={{position: "fixed", left: "0", bottom: "0", width: "100%"}}>
                    <Alert variant="filled" severity="error" sx={{zIndex: "1500"}} onClose={() => setErrorDialog(null)}>
                        <AlertTitle>{errorDialog.title}</AlertTitle>
                        {errorDialog.message}
                        {errorDialog.actions && <div className="d-flex justify-content-center mt-3" style={{gap: ".5rem"}}>
                            {errorDialog.actions}
                        </div>}
                    </Alert>
                </div>
            )}

            {warningDialog && (
                <div className="d-flex justify-content-center py-3" style={{position: "fixed", left: "0", bottom: "0", width: "100%"}}>
                    <Alert variant="filled" severity="warning" sx={{zIndex: "1500"}} onClose={() => setWarningDialog(null)}>
                        <AlertTitle>{warningDialog.title}</AlertTitle>
                        {warningDialog.message}
                    </Alert>
                </div>
            )}

            {confirmDialog && (
                <div className="d-flex justify-content-center py-3" style={{position: "fixed", left: "0", bottom: "0", width: "100%"}}>
                    <Alert variant="filled" severity="success" sx={{zIndex: "1500"}} onClose={() => setConfirmDialog(null)}>
                        <AlertTitle>{confirmDialog.title}</AlertTitle>
                        {confirmDialog.message}
                    </Alert>
                </div>
            )}
        </DialogsContext.Provider>
    );
}

export default DialogsProvider;

export const useDialogs = () => {
    return useContext(DialogsContext);
}