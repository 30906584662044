import React, { FC, useEffect, useState } from "react";
import { IRect } from "konva/lib/types";
import { Circle, Group, Label, Line, Rect, Tag, Text } from "react-konva";
import { degree, toDrawUnit, VectorToPointsArray } from "../../../../../../../core/common_functions";
import { Vector } from "vecti";
import { useStoreSelector } from "../../../../../../../../hooks/StoreHooks";
import { InstallationType, OpeningDirection } from "../../../../../../../core/common_types";
import { DatiPannello } from "../../../../../../../core/common_draw_types";
import { useFinishes } from "../../../../../../../providers/finish_provider";
import { PlaceholderRect } from "../draw_utils";

const SPESSORE_TELAIO_EXT_PORTINA_LAT: number = 5;
const SPESSORE_TELAIO_INT_PORTINA_LAT: number = 2.5;

export const PortinaLateralePlana: FC<{
    lpdraw: number, 
    hpdraw: number, 
    doorColor: HTMLImageElement|undefined,
    frameColor: HTMLImageElement|undefined,
    handleColor: HTMLImageElement|undefined}> = (props) => {
    const {lpdraw, hpdraw, doorColor, frameColor, handleColor} = props;
    const {doorConfigurationBuilder} = useStoreSelector(store => store);
    
    const buildPannelli = (): DatiPannello[] => {
        let spessoreTelaio = doorConfigurationBuilder.configuration?.portinaAffiancata?.tipoInstallazione == InstallationType.in_luce ? SPESSORE_TELAIO_EXT_PORTINA_LAT : 0;
        let altezzaAssoluta = hpdraw - spessoreTelaio;
        let ptTopLeft = Vector.of([0, altezzaAssoluta]);
        let ptTopRight = Vector.of([lpdraw - (2 * spessoreTelaio), altezzaAssoluta]);
        let larghezzaAssoluta = ptTopRight.x - ptTopLeft.x;
        
        let panels: DatiPannello[] = [];

        let p: DatiPannello = { 
            bbox: {x: ptTopLeft.x + spessoreTelaio, y: spessoreTelaio, width: larghezzaAssoluta, height: altezzaAssoluta},
            panelNumber: 1,
            panelCount: 1,
            larghezzaAssoluta,
            altezzaAssoluta: altezzaAssoluta,
            altezzaRelativa: larghezzaAssoluta,
            selectable: true,
        };

        panels.push(p);

        return panels;
    }
    
    const renderPorta = () => {
        if (doorConfigurationBuilder.configuration?.portinaAffiancata?.tipoInstallazione == InstallationType.in_luce) {
            return (
                <Group>
                    <Rect
                        x={SPESSORE_TELAIO_EXT_PORTINA_LAT}
                        y={SPESSORE_TELAIO_EXT_PORTINA_LAT}
                        width={lpdraw - (2 * SPESSORE_TELAIO_EXT_PORTINA_LAT)}
                        height={hpdraw - (SPESSORE_TELAIO_EXT_PORTINA_LAT)}
                        stroke={"#000"} 
                        strokeWidth={1}
                        fillPatternImage={doorColor}
                    />
                    <Line
                        points={[
                            SPESSORE_TELAIO_EXT_PORTINA_LAT+SPESSORE_TELAIO_INT_PORTINA_LAT,
                            SPESSORE_TELAIO_EXT_PORTINA_LAT+SPESSORE_TELAIO_INT_PORTINA_LAT,
                            lpdraw-SPESSORE_TELAIO_EXT_PORTINA_LAT-SPESSORE_TELAIO_INT_PORTINA_LAT,
                            SPESSORE_TELAIO_EXT_PORTINA_LAT+SPESSORE_TELAIO_INT_PORTINA_LAT,
                            lpdraw-SPESSORE_TELAIO_EXT_PORTINA_LAT-SPESSORE_TELAIO_INT_PORTINA_LAT,
                            hpdraw-SPESSORE_TELAIO_INT_PORTINA_LAT,
                            SPESSORE_TELAIO_EXT_PORTINA_LAT+SPESSORE_TELAIO_INT_PORTINA_LAT,
                            hpdraw-SPESSORE_TELAIO_INT_PORTINA_LAT,
                        ]}
                        stroke={"#000"} 
                        strokeWidth={1}
                        closed
                    />
                </Group>
            );
        } else {
            return (
                <Group>
                    <Rect
                        x={0}
                        y={0}
                        width={lpdraw}
                        height={hpdraw}
                        stroke={"#000"} 
                        strokeWidth={1}
                        fillPatternImage={doorColor}
                    />
                    <Line
                        points={[
                            SPESSORE_TELAIO_INT_PORTINA_LAT,
                            SPESSORE_TELAIO_INT_PORTINA_LAT,
                            lpdraw-SPESSORE_TELAIO_INT_PORTINA_LAT,
                            SPESSORE_TELAIO_INT_PORTINA_LAT,
                            lpdraw-SPESSORE_TELAIO_INT_PORTINA_LAT,
                            hpdraw-SPESSORE_TELAIO_INT_PORTINA_LAT,
                            SPESSORE_TELAIO_INT_PORTINA_LAT,
                            hpdraw-SPESSORE_TELAIO_INT_PORTINA_LAT,
                        ]}
                        stroke={"#000"} 
                        strokeWidth={1}
                        closed
                    />
                </Group>
            );
        }
    }

    const renderPanels = () => {
        let pannelli = buildPannelli();
        
        return pannelli.map((p, idx, ar) => (
            <PannelloPortaPlana
                key={`panel_${(idx+1)}`}                
                panelData={p}
                doorColor={doorColor}
            />
        ));
    }

    const renderTelaio = () => {
        if (!(doorConfigurationBuilder.configuration?.portinaAffiancata?.portina || false)) return null;

        const altezzaPortina = toDrawUnit(doorConfigurationBuilder.configuration.portinaAffiancata.altezza);
        const larghezzaPortina = toDrawUnit(doorConfigurationBuilder.configuration.portinaAffiancata.larghezza);

        return <TelaioPortinaLaterale 
            org={Vector.of([larghezzaPortina/2, altezzaPortina])} 
            altezzaPortina={altezzaPortina} 
            larghezzaPortina={larghezzaPortina} 
            spessoreTelaio={SPESSORE_TELAIO_EXT_PORTINA_LAT} 
            frameColor={frameColor} 
        />
    }

    const renderLabelCampione = () => {
        let pt1: Vector = Vector.of([0, hpdraw]);
        let pt2: Vector = Vector.of([lpdraw, 0]);
        let rads = degree(pt1.x, pt1.y, pt2.x, pt2.y);
        return (
            <Label x={(lpdraw/2)-75} y={(hpdraw/2)+40} rotation={rads} opacity={0.6}>
                <Tag fill="white" stroke="red"/>
                <Text text={"Colore da campione"} padding={5} fontSize={20} />
            </Label>
        );
    }

    const renderManiglia = () => {
        if (!(doorConfigurationBuilder.configuration?.portinaAffiancata?.portina || false)) return null;

        const altezzaPortina = toDrawUnit(doorConfigurationBuilder.configuration.portinaAffiancata.altezza);
        let larghezzaPortina = toDrawUnit(doorConfigurationBuilder.configuration.portinaAffiancata.larghezza);
        let sp = 0;
        
        if (doorConfigurationBuilder.configuration?.portinaAffiancata?.tipoInstallazione == InstallationType.in_luce) {
            larghezzaPortina -= 2 * SPESSORE_TELAIO_EXT_PORTINA_LAT;
            sp = SPESSORE_TELAIO_EXT_PORTINA_LAT;
        }
        
        return <Maniglia 
            org={Vector.of([(larghezzaPortina/2)+sp, altezzaPortina])} 
            altezzaPortina={altezzaPortina} 
            larghezzaPortina={larghezzaPortina}
            pos={doorConfigurationBuilder.configuration?.portinaAffiancata?.versoApertura ?? "sx"} 
            externalHandleColor={handleColor} 
        />
    }

    if (!doorConfigurationBuilder.configuration) return null;
    if (!doorConfigurationBuilder.configuration.porta) return null;
    if (!doorConfigurationBuilder.configuration.modello) return null;

    return (
        <Group>
            {doorConfigurationBuilder.configuration?.portinaAffiancata?.tipoInstallazione == InstallationType.in_luce && renderTelaio()}
            {renderPorta()}
            {renderPanels()}
            {renderManiglia()}

            {doorConfigurationBuilder.configuration.tipoColoreEsterno == "a_campione" && renderLabelCampione()}
        </Group>
    );
}

const PannelloPortaPlana: FC<{doorColor: HTMLImageElement|undefined, panelData: DatiPannello, onClick?: (panelData: DatiPannello, placeholderData: PlaceholderRect) => void}> = (props) => {
    const {doorConfigurationBuilder} = useStoreSelector(store => store);
           
    const getBboxDisegno = (panelData: DatiPannello): IRect => {       
        let org = Vector.of([panelData.bbox.x, panelData.bbox.y]);
        let larghezza = panelData.bbox.width;
        let altezza = panelData.bbox.height;

        if (doorConfigurationBuilder.isOltreLuce()) {  
            panelData.debugPt = [];
        }

        let rect: IRect =  {
            ...org,
            width: larghezza,
            height: altezza
        };

        panelData.org = {x: org.x, y: org.y};
        
        return rect;
    }

    return (
        <Group>
            <Rect            
                {...getBboxDisegno(props.panelData)}
                stroke={"#000"} 
                strokeWidth={0.5}                
                fillPatternImage={props.doorColor}
            />
        </Group>
    );
}

const TelaioPortinaLaterale: FC<{ org: Vector, altezzaPortina: number, larghezzaPortina: number, spessoreTelaio: number, frameColor: HTMLImageElement|undefined }> = (props) => {
    const { org, altezzaPortina, larghezzaPortina, spessoreTelaio, frameColor } = props;

    const getPointsTelaioSx = (): Vector[] => {
        let ptBottomLeft = Vector.of([org.x - (larghezzaPortina / 2), org.y]);
        let ptTopLeft = Vector.of([ptBottomLeft.x, org.y - altezzaPortina]);
        let ptTopRight = Vector.of([ptBottomLeft.x + spessoreTelaio, ptTopLeft.y + SPESSORE_TELAIO_EXT_PORTINA_LAT]);
        let ptBottomRight = Vector.of([ptTopRight.x, org.y]);

        return [
            ptBottomLeft,
            ptTopLeft,
            ptTopRight,
            ptBottomRight
        ];
    }

    const getPointsTelaioDx = (): Vector[] => {
        let ptTopRight = Vector.of([org.x + (larghezzaPortina / 2), org.y - altezzaPortina]);
        let ptBottomRight = Vector.of([ptTopRight.x, org.y]);
        let ptBottomLeft = Vector.of([ptBottomRight.x - spessoreTelaio, org.y]);
        let ptTopLeft = Vector.of([ptBottomLeft.x, ptTopRight.y + SPESSORE_TELAIO_EXT_PORTINA_LAT]);

        return [
            ptBottomLeft,
            ptTopLeft,
            ptTopRight,
            ptBottomRight
        ];
    }

    const getPointsTelaioSup = (): Vector[] => {
        let ptTopLeft = Vector.of([org.x - (larghezzaPortina / 2), org.y-altezzaPortina]);
        let ptTopRight = Vector.of([ptTopLeft.x + larghezzaPortina, ptTopLeft.y]);
        let ptBottomRight = Vector.of([ptTopRight.x - spessoreTelaio, ptTopLeft.y + spessoreTelaio]);
        let ptBottomLeft = Vector.of([ptTopLeft.x + spessoreTelaio, ptBottomRight.y]);

        return [
            ptBottomLeft,
            ptTopLeft,
            ptTopRight,
            ptBottomRight
        ];
    }

    return (
        <Group>
            <Line
                points={VectorToPointsArray(getPointsTelaioSx())}
                stroke={"#000"}
                strokeWidth={0.5}
                fillPatternImage={frameColor}
                closed
            />
            <Line
                points={VectorToPointsArray(getPointsTelaioDx())}
                stroke={"#000"}
                strokeWidth={0.5}
                fillPatternImage={frameColor}
                closed
            />
            <Line
                points={VectorToPointsArray(getPointsTelaioSup())}
                stroke={"#000"}
                strokeWidth={0.5}
                fillPatternImage={frameColor}
                closed
            />
        </Group>
    );
}

const Maniglia: FC<{ org: Vector, larghezzaPortina: number, altezzaPortina: number, pos: OpeningDirection, externalHandleColor: HTMLImageElement|undefined }> = (props) => {
    const { org, larghezzaPortina, altezzaPortina, pos, externalHandleColor } = props;

    const getPosManiglia = (): Vector => {
        if (pos == "dx") return Vector.of([
            org.x - (larghezzaPortina/2) + (SPESSORE_TELAIO_EXT_PORTINA_LAT + SPESSORE_TELAIO_INT_PORTINA_LAT + 5),
            org.y - (altezzaPortina/2)
        ])

        return Vector.of([
            org.x + (larghezzaPortina/2) - SPESSORE_TELAIO_INT_PORTINA_LAT - 12 - 10,
            org.y - (altezzaPortina/2)
        ]);
    }

    const getPosCopriManiglia = (): Vector => {
        if (pos == "dx") return Vector.of([
            org.x - (larghezzaPortina/2) + (SPESSORE_TELAIO_EXT_PORTINA_LAT + SPESSORE_TELAIO_INT_PORTINA_LAT + 5) - 1,
            org.y - (altezzaPortina/2) - 1.7
        ]);

        return Vector.of([
            org.x + (larghezzaPortina/2) - SPESSORE_TELAIO_INT_PORTINA_LAT - 10 - 1.25,
            org.y - (altezzaPortina/2) - 1.5
        ]);
    }

    const getPosCilindro = (): Vector => {
        if (pos == "dx") return Vector.of([
            org.x - (larghezzaPortina/2) + (SPESSORE_TELAIO_EXT_PORTINA_LAT + SPESSORE_TELAIO_INT_PORTINA_LAT + 5),
            org.y - (altezzaPortina/2) + 8
        ]);

        return Vector.of([
            org.x + (larghezzaPortina/2) - SPESSORE_TELAIO_INT_PORTINA_LAT - 10,
            org.y - (altezzaPortina/2) + 8
        ]);
    }

    return (
        <Group>
            <Rect
                {...getPosCopriManiglia()}
                width={2.5}
                height={5}
                fill={externalHandleColor == undefined ? "#000" : undefined}
                fillPatternImage={externalHandleColor != undefined ? externalHandleColor : undefined }
                cornerRadius={1}
                stroke={"#000"}
                strokeWidth={0.25}
            />
            <Rect
                {...getPosManiglia()}
                width={12}
                height={2}
                fill={externalHandleColor == undefined ? "#000" : undefined}
                fillPatternImage={externalHandleColor != undefined ? externalHandleColor : undefined }
                cornerRadius={0.5}
                stroke={"#000"}
                strokeWidth={0.25}
            />
            <Circle
                {...getPosCilindro()}
                radius={1.5}
                fill={externalHandleColor == undefined ? "#000" : undefined}
                fillPatternImage={externalHandleColor != undefined ? externalHandleColor : undefined }
                stroke={"#000"}
                strokeWidth={0.25}
            />
            <Circle
                {...getPosCilindro()}
                radius={0.75}
                fill={externalHandleColor == undefined ? "#000" : undefined}
                fillPatternImage={externalHandleColor != undefined ? externalHandleColor : undefined }
                stroke={"#000"}
                strokeWidth={0.25}
            />
        </Group>
    );
}