import React, { FC } from "react";
import { Group, Rect } from "react-konva";
import { DatiPannello } from "../../../../../../../core/common_draw_types";
import { IRect } from "konva/lib/types";
import { PlaceholderRect } from "../draw_utils";

export const Placeholder: FC<{ panelData: DatiPannello, bbox: PlaceholderRect, onClick?: (placeholderData: PlaceholderRect) => void }> = (props) => {
    const { panelData, bbox, onClick } = props;

    let rect: IRect = {
        x: bbox.x,
        y: (panelData.bbox.height / 2) - (bbox.height / 2),
        width: bbox.width,
        height: bbox.height
    };

    return (
        <Group onClick={() => onClick?.(bbox)} onTap={() => onClick?.(bbox)}>
            <Rect
                key={"window_bg_" + (bbox.position + 1)}
                fill={"white"}
                opacity={0.3}
                {...rect}
            />
            <Rect
                key={"window_st_" + (bbox.position + 1)}
                stroke={"red"}
                strokeWidth={1}
                dash={[5, 2]}
                {...rect}
            />
        </Group>
    );
}