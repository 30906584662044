import { IRect } from "konva/lib/types";
import { InstallationType, IntegratedDoorPosition, NullableNumber, NullableString, OpeningDirection, OpeningMode, Optional, SpringType, StepSlugsEnum } from "../core/common_types";
import { CategoryCode } from "./category";

export type DoorOptionType = "larghezza_standard" | "larghezza_standard_minore" | "larghezza_standard_maggiore" | "altezza_standard" | "altezza_standard_minore" | "altezza_standard_maggiore";

export type ColorSide = "internal" | "external";
export type ColorType = "ral_standard" | "ral_non_standard" | "a_campione";
export type IntegratedDoorMeasureType = "undefined" | "standard" | "900" | "1200";
export type FrameInternalColorType = "same_as_external" | "different_from_external";
export type DownPaymentType = "percentage" | "amount";
export type InputType = "ral" | "number";
export enum WindowSize {
    undefined = "undefined",
    _540x360 = "540x360",
    _230x230 = "230x230",
    glasspanel = "glasspanel",
    glasspanel_vetro_6_7 = "glasspanel_vetro_6_7",
    glasspanel_vetro_5 = "glasspanel_vetro_5",
    glasspanel_vetro_4_12_4 = "glasspanel_vetro_4_12_4",
};
export enum GridSize {
    undefined = "undefined",
    _344x138 = "344x138",
    _400x200 = "400x200",
    superificie_ft = "superificie_ft",
    superificie_fq = "superificie_fq"
}

export enum GlassType {
    vetro_camera_4_12_4_trasparente = "vetro_camera_4_12_4_trasparente",
    vetro_camera_4_12_4_satinato = "vetro_camera_4_12_4_satinato",
    vetro_6_7_sicurezza_trasparente = "vetro_6_7_sicurezza_trasparente", 
    vetro_6_7_sicurezza_satinato_bianco = "vetro_6_7_sicurezza_satinato_bianco",
    vetro_6_7_sicurezza_grigio_fume_trasparente = "vetro_6_7_sicurezza_grigio_fume_trasparente", 
    vetro_6_7_sicurezza_bronzo_trasparente = "vetro_6_7_sicurezza_bronzo_trasparente",
    vetro_camera_temperato_4_12_4_trasparente = "vetro_camera_temperato_4_12_4_trasparente",
    vetro_camera_temperato_4_12_4_satinato_bianco = "vetro_camera_temperato_4_12_4_satinato_bianco"
};

export type FormDimensioniModel = {
    quantita: number,
    vanoConAltroAccesso: boolean,
    larghezzaForo: number,
    altezzaForo: number,
    larghezzaPorta: number,
    altezzaPorta: number,
    profonditaGarage: number,
    altezzaArchitrave: number,
    spessoreArchitrave: number,
    larghezzaSpallettaSx: number,
    spessoreSpallettaSx: number,
    larghezzaSpallettaDx: number,
    spessoreSpallettaDx: number,
    tipoInstallazioneMontanteDx: InstallationType,
    tipoInstallazioneMontanteSx: InstallationType,
    tipoInstallazioneTraversa: InstallationType,
    tipoMolle: SpringType,
    isMotorizzata: boolean,
}

export type FormAspettoModel = {
    porta: NullableNumber,
    modello: NullableNumber,
    finitura: NullableNumber,
    tipoColoreEsterno: ColorType,
    coloreEsterno: NullableNumber,
    coloreEsternoNsRal: NullableString,
    coloreEsternoACampione: boolean,
    tipoColoreInterno: ColorType,
    coloreInterno: NullableNumber,
    coloreInternoNsRal: NullableString,
    coloreInternoACampione: boolean,
    finituraVetro: NullableNumber,
    coloreTelaioInternoDiverso: boolean,
    finestratura: WindowingConfiguration|null,
    ventilazione: AiringConfiguration|null,
    portinaIntegrata: IntegratedDoorConfiguration|null,
    portinaAffiancata: ExternalDoorConfiguration|null
}

export type PanelConfiguration = {panelNumber: number, elementsNumber: number, position: number, bbox: IRect};

export type AiringConfiguration = {
    gridSize: GridSize,
    positioning: PanelConfiguration[],
    quantity: NullableNumber,
    externalColorType: ColorType,
    externalColorCode: NullableString,
    internalColorType: ColorType,
    internalColorCode: NullableString,
}

export type WindowingConfiguration = {
    windowSize: WindowSize,
    positioning: PanelConfiguration[],
    quantity: NullableNumber,
    externalFrameColorType: ColorType,
    externalFrameColorCode: NullableString,
    internalFrameColorType: ColorType,
    internalFrameColorCode: NullableString,
    glassFinish: GlassType|undefined
}

export type IntegratedDoorConfiguration = {
    portina: boolean,
    bbox: IRect|null,
    larghezza: {esternoTelaio: number, internoTelaio: number}|null,
    altezza: {esternoTelaio: number, internoTelaio: number}|null,
    externalFrameColorType: ColorType,
    externalFrameColorCode: NullableString,
    internalFrameColorType: ColorType,
    internalFrameColorCode: NullableString,
    externalHandleColorType: ColorType,
    externalHandleColorCode: NullableString,
    internalHandleColorType: ColorType,
    internalHandleColorCode: NullableString,
    posizione: IntegratedDoorPosition,
    apertura: OpeningDirection,
    fuoriMisura: IntegratedDoorMeasureType,
    accessori: Optional[]
}

export type ExternalDoorConfiguration = {
    portina: boolean,
    larghezza: number,
    altezza: number,
    usaStessaFinitura: boolean,
    porta: Option,
    modello: Option,
    finitura: Option,
    tipoColoreEsterno: ColorType,
    coloreEsterno: NullableString,
    tipoColoreInterno: ColorType,
    coloreInterno: NullableString,
    externalFrameColorType: ColorType,
    externalFrameColorCode: NullableString,
    internalFrameColorType: ColorType,
    internalFrameColorCode: NullableString,
    externalHandleColorType: ColorType,
    externalHandleColorCode: NullableString,
    internalHandleColorType: ColorType,
    internalHandleColorCode: NullableString,
    finituraVetro: Option,
    finituraLamiera: Option,
    usaColoreTelaioInternoComeEsterno: boolean,
    tipoInstallazione: InstallationType,
    tipoApertura: OpeningMode,
    versoApertura: OpeningDirection,
    accessori: Optional[]
}

export interface DoorConfiguration {
    categoryCode: CategoryCode,
    categoryId: number,
    configurationId: number,
    quantita: number,
    larghezzaForo: number,
    altezzaForo: number,
    larghezzaPorta: number,
    altezzaPorta: number,
    vanoConAltroAccesso: boolean,
    portinaPedonaleIntegrata: boolean,
    profonditaGarage: number,
    altezzaArchitrave: number,
    spessoreArchitrave: number,
    larghezzaSpallettaSx: number,
    spessoreSpallettaSx: number,
    larghezzaSpallettaDx: number,
    spessoreSpallettaDx: number,
    tipoMolle: SpringType,
    tipoInstallazioneMontanteDx: InstallationType,
    tipoInstallazioneMontanteSx: InstallationType,
    tipoInstallazioneTraversa: InstallationType,
    porta: NullableNumber,
    modello: NullableNumber,
    finitura: NullableNumber,
    finituraVetro: NullableNumber,
    tipoColoreEsterno: ColorType,
    coloreEsterno: NullableNumber,
    coloreEsternoNsRal: NullableString,
    coloreEsternoACampione: boolean,
    tipoColoreInterno: ColorType,
    coloreInterno: NullableNumber,
    coloreInternoNsRal: NullableString,
    coloreInternoACampione: boolean,
    coloreTelaioInternoDiverso: boolean,
    isMotorizzata: boolean,
    finestratura: WindowingConfiguration|null,
    windowFrameColorId: NullableNumber,
    windowFrameColorNsRal: NullableString,
    ventilazione: AiringConfiguration|null,
    portinaIntegrata: IntegratedDoorConfiguration|null,
    portinaAffiancata: ExternalDoorConfiguration|null,
    isFinito: boolean,
    stepAttuale: StepSlugsEnum
}

export type DoorOption = {
    proprieta: string,
    valore: number,
    tipo: DoorOptionType,
    valorePartenza: number,
    tubolare: Tubular | null,
    isSelected: boolean
}

export type DoorConfig = {
    integratedDoorSupport: boolean
} | null;

export type Tubular = {
    width: number,
    height: number
}

export type Option = {
    id: number,
    optionId: number,
    parentId: NullableNumber,
    value: NullableString,    
    name: string,
    type: string
} | null | undefined;

export type Color = {
    id: number,
    code: string,
    name: string,
    img: NullableString,
    sortOrder: number,
    additionalInfo: NullableString,
    parentOptionId: number,
    side: ColorSide
} | null | undefined;

export type Accessorio = {
    id: number,
    sku: string,
    name: NullableString,
    price: number,
    um: NullableString,
    img: NullableString,
    qtyMax: number,
    inputType: InputType
} | null | undefined;

export type AccessorioInConfigurazione = {
    accessoryId: number,
    qty: number,
    value: string|null|undefined
}

export type IntegratedDoorOptions = {
    descrizione: string,
    fuoriMisura: Accessorio[],
    accessoriVari: Accessorio[],
}

export type ExternalDoorOptions = {
    accessoriVari: Accessorio[],
    verniciaturaNonStandard: Accessorio[],
}

export type WindowingOptions = {
    windows: Accessorio[],
    verniciaturaNonStandard: Accessorio[],
}

export type AiringOptions = {
    grids: Accessorio[],
    verniciaturaNonStandard: Accessorio[],
}

export type DoorConfigurationConditions = {
    customerId: NullableString,
    customClientName: NullableString,
    customClientAddress: NullableString,
    customClientZipCode: NullableString,
    customClientCity: NullableString,
    endClientDownPaymentType: DownPaymentType,
    endClientDownPaymentValue: NullableNumber,
    endClientDiscount1: NullableNumber,
    endClientDiscount2: NullableNumber,
    endClientNet: NullableNumber,
    endClientTaxRate: NullableNumber,
    endClientTaxableAmount: NullableNumber,
    endClientTotalTaxes: NullableNumber,
    endClientTotalDiscount: NullableNumber,
    endClientTotal: NullableNumber,
    retailTaxRate: NullableNumber,
    retailDownPaymentType: DownPaymentType,
    retailDownPaymentValue: NullableNumber,
    retailDiscount1: NullableNumber,
    retailDiscount2: NullableNumber,
    retailNet: NullableNumber,
    retailTaxableAmount: NullableNumber,
    retailTotalTaxes: NullableNumber,
    retailTotalDiscount: NullableNumber,
    retailTotal: NullableNumber,
    baseNetPrice: NullableNumber,
    totalServices: NullableNumber,
    quantity: NullableNumber,
    notes: NullableString
};

export type QuoteData = {
    id: number,
    creationDate: NullableString,
    agentName: NullableString,
    agentSurname: NullableString,
    clientName: NullableString,
    clientSurname: NullableString,
    clientAddress: NullableString,
    clientZipCode: NullableString,
    clientCity: NullableString,
    clientPhone: NullableString,
    clientCode: NullableString,
    clientVatNumber: NullableString,
    clientEmail: NullableString,
    architrave: NullableNumber,
    height: NullableNumber,
    width: NullableNumber,
    externalFrameHeight: NullableNumber,
    externalFrameWidth: NullableNumber,
    sxStileWidth: NullableNumber,
    dxStileWidth: NullableNumber,
    sku: NullableString,
    description: NullableString,
    qty: NullableNumber,
    uom: NullableString,
    baseNetPrice: NullableNumber,
    productPrice: NullableNumber,
    totalServices: NullableNumber,
    notes: NullableString,
    crossbeamInstallationType: NullableNumber,
    dxPillarInstallationType: NullableNumber,
    sxPillarInstallationType: NullableNumber,
    externalColorType: NullableString,
    externalColorNsRal: NullableString,
    externalColorRal: NullableString,
    externalColorName: NullableString,
    internalColorRal: NullableString,
    internalColorName: NullableString,
    withIntegratedDoor: boolean,
    widthSideDoor: boolean,
    hasOverflow: boolean,
    imageRendering: NullableString,
    isServiceCustomer: boolean,
    endClientDownPaymentType: NullableString,
    endClientDownPaymentValue: NullableNumber,
    endClientDiscount1: NullableNumber,
    endClientDiscount2: NullableNumber,
    endClientNet: NullableNumber,
    endClientTaxRate: NullableNumber,
    endClientTaxableAmount: NullableNumber,
    endClientTotalTaxes: NullableNumber,
    endClientTotalDiscount: NullableNumber,
    endClientTotal: NullableNumber,
    retailTaxRate: NullableNumber,
    retailDownPaymentType: NullableNumber,
    retailDownPaymentValue: NullableNumber,
    retailDiscount1: NullableNumber,
    retailDiscount2: NullableNumber,
    retailNet: NullableNumber,
    retailTaxableAmount: NullableNumber,
    retailTotalTaxes: NullableNumber,
    retailTotalDiscount: NullableNumber,
    retailTotal: NullableNumber,
    transportAmount: NullableNumber,
    packagingAmount: NullableNumber,
}

export type QuoteAccessory = {
    id: number,
    qty: number
    sku: NullableString,
    name: NullableString,
    price: NullableNumber,
    um: NullableString,
    net: NullableNumber,
    additionalData: NullableString
}

export type QuoteSummary = {
    data: QuoteData,
    accessories: QuoteAccessory[],    
} | null;