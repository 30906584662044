import Konva from "konva";
import React, { FC, forwardRef, ReactNode, Ref, useImperativeHandle, useLayoutEffect, useRef, useState } from "react";
import { Circle, Group, Label, Layer, Rect, Stage, Tag, Text } from "react-konva";
import { useStoreSelector } from "../../../../../../../hooks/StoreHooks";
import { NullableString } from "../../../../../../core/common_types";
import { IRect } from "konva/lib/types";
import { Button, ButtonGroup } from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

export type SceneArgs = {    
    getPreview(): string,
    getPreviewAsBlob(): Promise<Blob|null>,
    getImage(): Promise<unknown>,
    downloadPreview(): void
}

export type SceneProps = {
    bbox: IRect,    
    panEnabled?: boolean, 
    zoomEnabled?: boolean, 
    centered?: boolean,
    children?: ReactNode,
    topbar?: ReactNode,
    bottombar?: ReactNode,
    sidebarSx?: ReactNode
}

export type SceneTypeRef = React.ElementRef<typeof Scene>;

export const Scene = forwardRef((props: SceneProps, ref: Ref<SceneArgs>) => {
    const {bbox,children,panEnabled,zoomEnabled} = props;
    const {doorConfigurationBuilder} = useStoreSelector(store => store);

    const stageRef = useRef<Konva.Stage>(null);
    const stageContainerRef = useRef<HTMLDivElement>(null);

    const [scene, setScene] = useState<{
        scaleX: number,
        scaleY: number,
        posX: number,
        posY: number,
        larghezza: number,
        altezza: number
    }>({
        scaleX: 1,
        scaleY: 1,
        posX: 0,
        posY: 0,
        larghezza: 0,
        altezza: 0
    });

    const zoomIn = () => {
        applyZoom(1);
    }

    const zoomOut = () => {
        applyZoom(-1);
    }

    const onWheel = (evt: Konva.KonvaEventObject<WheelEvent>) => {
        if (!zoomEnabled) return;

        if (evt.evt.deltaY < 0) {
            zoomIn();
        } else {
            zoomOut();
        }
    }

    const applyZoom = (direction: number) => {
        if (!stageRef.current) return;

        var oldScale = stageRef.current.scaleX();

        let ptCenterX: number = stageRef.current.width() / 2;
        let ptCenterY: number = stageRef.current.height() / 2;

        var scaleBy = 1.05;

        var mousePointTo = {
            x: (ptCenterX - stageRef.current.x()) / oldScale,
            y: (ptCenterY - stageRef.current.y()) / oldScale,
        };

        var newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;

        var newPos = {
            x: ptCenterX - mousePointTo.x * newScale,
            y: ptCenterY - mousePointTo.y * newScale,
        };

        setScene({ ...scene, scaleX: newScale, scaleY: newScale, posX: newPos.x, posY: newPos.y });
    }

    const resizeStageContainer = () => {
        if (!stageContainerRef.current || !stageRef.current || !doorConfigurationBuilder.configuration) return;

        // Ottieni le dimensioni del canvas
        let canvasWidth = stageContainerRef.current.clientWidth;
        let canvasHeight = stageContainerRef.current.clientHeight;
        
        // Ottieni le dimensioni del bounding box dell'oggetto
        const oggettoWidth = bbox.width;
        const oggettoHeight = bbox.height;

        // Calcola la scala massima possibile per far entrare l'oggetto nel canvas
        const scalaX = canvasWidth / oggettoWidth;
        const scalaY = canvasHeight / oggettoHeight;
        const scala = Math.min(scalaX, scalaY);

        // Calcola la nuova larghezza e altezza dell'oggetto scalato
        const nuovaLarghezza = oggettoWidth * scala;
        const nuovaAltezza = oggettoHeight * scala;

        const offsetX = Math.abs(bbox.x) * scala;
        const offsetY = Math.abs(bbox.y) * scala;

        // Calcola la posizione x e y per centrare l'oggetto nel canvas
        const posizioneX = (canvasWidth - nuovaLarghezza) / 2 + offsetX;
        const posizioneY = (canvasHeight - nuovaAltezza) / 2 + offsetY;
        
        setScene({ ...scene, larghezza: canvasWidth, altezza: canvasHeight, scaleX: scala, scaleY: scala, posX: posizioneX, posY: posizioneY});

        window.addEventListener("resize", resizeStageContainer);
    }

    useLayoutEffect(() => {
        resizeStageContainer();
    }, []);

    useImperativeHandle(ref, () => ({
        getPreview() {
            if (!stageRef.current) return "";
            let res = stageRef.current.toDataURL({mimeType: "image/png"});
            return res;
        },

        getPreviewAsBlob(): Promise<Blob|null> {
            return new Promise((resolve) => {
                if (!stageRef.current) return;

                stageRef.current.toBlob({
                    mimeType: "image/png",
                    callback(blob) {
                        resolve(blob);
                    }
                });
            });            
        },

        async getImage() {
            if (!stageRef.current) return "";
            let res = await stageRef.current.toImage({
                callback(img) {
                    console.log(img);
                },
            });
            return res;
        },

        async downloadPreview() {
            let preview = await this.getPreview();
            if (preview) {
                let link = document.createElement("a");
                link.download = "preview.png";
                link.href = preview;
                link.click();
                document.removeChild(link);
            }
        }
    }));

    return (
        <div ref={stageContainerRef} className="h-full" style={{backgroundImage: "url(/img/bg-draw.png)"}}>
            <Stage
                ref={stageRef}
                draggable={panEnabled}              
                width={scene.larghezza}
                height={scene.altezza}
                scale={{ x: scene.scaleX, y: scene.scaleY }}
                x={scene.posX}
                y={scene.posY}                                
                onWheel={(e) => onWheel(e)}>                
                {children}
            </Stage>

            {props.topbar && <SceneTopbar>
                {props.topbar}
            </SceneTopbar>}

            {props.sidebarSx && <SceneSidebarSx>
                {props.sidebarSx}
            </SceneSidebarSx>}

            {(props.bottombar || zoomEnabled) && <SceneBottombar>
                {zoomEnabled && (
                    <ButtonGroup size="small">
                        <Button key="zoom-in" color="secondary" onClick={() => zoomIn()}>
                            <ZoomInIcon />
                        </Button>
                        <Button key="zoom-out" color="secondary" onClick={() => zoomOut()}>
                            <ZoomOutIcon />
                        </Button>
                    </ButtonGroup>
                )}

                {props.bottombar}
            </SceneBottombar>}
        </div>
    );
});

const SceneTopbar: FC<{children: ReactNode}> = ({children}) => {
    return (
        <div style={{
            position: "absolute", 
            top: "0", 
            left:"0", 
            width: "100%", 
            background: "rgba(255,255,255,0)", 
            padding: ".25rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"}}>
            {children}
        </div>
    );
}

const SceneSidebarSx: FC<{children: ReactNode}> = ({children}) => {
    return (
        <div style={{
            position: "absolute", 
            top: "0", 
            left:"0", 
            width: "auto",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent:"center",
            background: "rgba(255,255,255,0)"}}>
            {children}
        </div>
    );
}

const SceneBottombar: FC<{children: ReactNode}> = ({children}) => {
    return (
        <div style={{
            position: "absolute", 
            bottom: "0", 
            left:"0", 
            width: "100%", 
            background: "rgba(255,255,255,0)", 
            display: "flex",
            flexDirection: "row-reverse",
            justifyContent: "space-between",
            padding: ".5rem",
            alignItems: "center"}}>
            {children}
        </div>
    );
}

export const SceneViewLabel: React.FC<{text: string, rotation?: string}> = (props) => {
  const {text} = props;

  const getStyle = (): React.CSSProperties => {
    let style: React.CSSProperties = {
        fontSize: ".8rem", 
        fontWeight: "700",
        transform: "rotate(-90deg) translateY(-50px)"
    };

    return style;
  }

  return (
    <span className="text-secondary" style={getStyle()}>
      <VisibilityOutlinedIcon />
      <span className="ms-2">{text}</span>
    </span>
  );
}