import React, { FC, ReactNode, useEffect, useState } from "react";
import { Circle, Group, Layer, Line, Rect, Text } from "react-konva";
import { toDrawUnit, Vector2dToPointsArray } from "../../../../../../../core/common_functions";
import { DEFAULT_COLORE_STIPITE, PROFONDITA_INVOLUCRO, DEFAULT_STROKE_WIDTH, SPESSORE_ARCHITRAVE, SPESSORE_STIPITE } from "../../../../../../../core/common_constants";
import { useStoreSelector } from "../../../../../../../../hooks/StoreHooks";
import { IRect, Vector2d } from "konva/lib/types";
import { Vector } from "vecti";
import imagePavimento from "../../../../../../../assets/images/pavimento.jpg";
import { Color, Option } from "../../../../../../../models/door_configuration";
import { PortaSezionaleClassic } from "../door-models/porta_sezionale_classic";
import { ColorCode, DoorType } from "../../../../../../../core/common_types";
import { PortaSezionalePlana } from "../door-models/porta_sezionale_plana";
import { PortaSezionaleFullVision } from "../door-models/porta_sezionale_full_vision";
import { useFinishes } from "../../../../../../../providers/finish_provider";
import { PortinaIntegrata } from "../accessories/portina_integrata";

export const GarageExternal: FC<{options: Option[], children?: ReactNode}> = ({options, children}) => {
    const finishesProvider = useFinishes();
    const [colors, setColors] = useState<{door: HTMLImageElement|undefined, windows: HTMLImageElement|undefined, grids: HTMLImageElement|undefined}|undefined>();

    const {doorConfigurationBuilder} = useStoreSelector(store => store);

    const hf = toDrawUnit(doorConfigurationBuilder.configuration?.altezzaForo ?? 0);
    const lf = toDrawUnit(doorConfigurationBuilder.configuration?.larghezzaForo ?? 0);
    const hp = toDrawUnit(doorConfigurationBuilder.configuration?.altezzaPorta ?? 0);
    const lp = toDrawUnit(doorConfigurationBuilder.configuration?.larghezzaPorta ?? 0);
    const pf = Vector.of([lf/2, hf/2]);

    const findPorta = () => {
        let option = options.find(o => o?.optionId == doorConfigurationBuilder.configuration?.porta);
        return option;
    }

    const findModello = () => {
        let option = options.find(o => o?.optionId == doorConfigurationBuilder.configuration?.modello);
        return option;
    }

    const findFinitura = () => {
        let option = options.find(o => o?.optionId == doorConfigurationBuilder.configuration?.finitura);
        return option;
    }

    const renderPorta = () => {
        if (hp <= 0) return null;
        if (lp <= 0) return null;

        let porta = findPorta();
        let modello = findModello();
        let finitura = findFinitura();

        if (porta?.name == DoorType.classic) return (
            <PortaSezionaleClassic
                pf={pf}
                hfdraw={hf} 
                lfdraw={lf} 
                hpdraw={hp} 
                lpdraw={lp} 
                modello={modello}
                doorColor={colors?.door}
                windowsColor={colors?.windows}
                gridsColor={colors?.grids}
            />
        );

        if (porta?.name == DoorType.plana) return (
            <PortaSezionalePlana
                pf={pf}
                hfdraw={hf} 
                lfdraw={lf} 
                hpdraw={hp} 
                lpdraw={lp} 
                finish={finitura}
                doorColor={colors?.door}
                windowsColor={colors?.windows}
                gridsColor={colors?.grids}
            />
        );

        if (porta?.name == DoorType.full_vision) return (
            <PortaSezionaleFullVision
                pf={pf}
                hfdraw={hf} 
                lfdraw={lf} 
                hpdraw={hp} 
                lpdraw={lp} 
                frameColor={colors?.door}
            />
        );
    }

    const renderPortina = () => {
        return (
            <PortinaIntegrata
                pf={pf}
                hf={hf} 
                lf={lf} 
                hp={hp} 
                lp={lp}
                doorColor={colors?.door}
            />
        );
    }

    const loadColors = async () => {  
        let door = await finishesProvider?.getImgRal(doorConfigurationBuilder.getExternalColorCode());
        let grids = await finishesProvider?.getImgRal(doorConfigurationBuilder.getGridColorCode());
        let windows = await finishesProvider?.getImgRal(doorConfigurationBuilder.getWindowFrameColorCode()); 
        
        setColors({door, windows, grids});
    }

    useEffect(() => {
        loadColors();
    }, [
        doorConfigurationBuilder.configuration?.coloreEsterno,
        doorConfigurationBuilder.configuration?.coloreEsternoNsRal, 
        doorConfigurationBuilder.configuration?.finestratura,
        doorConfigurationBuilder.configuration?.ventilazione
    ]);

    if (!colors) return null;

    return (
        <Layer>
            <Group x={0} y={0}>
                {/* <Soffitto hg={configurationBuilder.altezzaGarage} lg={configurationBuilder.larghezzaGarage} /> */}
                <Parete hgDraw={hf} lgDraw={lf} />
                <Foro pf={pf} h={hf} l={lf} />
                <Pavimento pf={pf} h={hf} l={lf} />
                {renderPorta()}
                {doorConfigurationBuilder.configuration?.portinaIntegrata && renderPortina()}
                            
                {children}
            </Group>            
        </Layer>
    );
}

const Pavimento: FC<{pf: Vector, l: number, h: number}> = ({l, h, pf}) => {
    const [pavimento, setPavimento] = useState<{ image: HTMLImageElement, width: number, height: number } | null>(null);

    // const loadBackgroundPavimento = () => {
    //     let img = new window.Image();
    //     img.onload = () => {
    //       setPavimento({ image: img, width: img.naturalWidth, height: img.naturalHeight });
    //     }        
    //     img.src = imagePavimento;
    //   }
    
    const buildPolyPavimento = (): Vector2d[] => {
        let vecDir = pf.subtract(Vector.of([0, h])).normalize();
        let vecPt2 = Vector.of([0, h]).add(vecDir.multiply(SPESSORE_STIPITE));

        vecDir = pf.subtract(Vector.of([l, h])).normalize();
        let vecPt3 = Vector.of([l, h]).add(vecDir.multiply(SPESSORE_STIPITE));

        let pt1: Vector2d = {x: 0, y: h};
        let pt2: Vector2d = {x: vecPt2.x, y: vecPt2.y};
        let pt3: Vector2d = {x: vecPt3.x, y: vecPt3.y};
        let pt4: Vector2d = {x: l, y: h};
        let pt5: Vector2d = {x: l + PROFONDITA_INVOLUCRO, y: h};
        let pt6: Vector2d = {x: l + (PROFONDITA_INVOLUCRO*2), y: h + PROFONDITA_INVOLUCRO - (PROFONDITA_INVOLUCRO/2)};
        let pt7: Vector2d = {x: -PROFONDITA_INVOLUCRO*2, y: h + PROFONDITA_INVOLUCRO - (PROFONDITA_INVOLUCRO/2)};
        let pt8: Vector2d = {x: -PROFONDITA_INVOLUCRO, y: h};

        return [pt1, pt2, pt3, pt4, pt5, pt6, pt7, pt8];
    }

    // useEffect(() => {
    //     loadBackgroundPavimento();
    //   }, []);

    return (
        <>
            {/*pavimento && */<Line 
                points={Vector2dToPointsArray(buildPolyPavimento())} 
                stroke={"000"}
                strokeWidth={1}
                fill={"#aaa"}
                closed
                // onClick={() => alert("Hai cliccato il pavimento")}
            />}
        </>
    );
}

const Foro: FC<{pf: Vector, l: number, h: number}> = ({l, h, pf}) => {

    const buildPolyStipiteSx = (): Vector2d[] => {
        let vecDir = pf.subtract(Vector.of([0, 0])).normalize();
        let vecPt2 = Vector.of([0, 0]).add(vecDir.multiply(SPESSORE_STIPITE));

        vecDir = pf.subtract(Vector.of([0, h])).normalize();
        let vecPt3 = Vector.of([0, h]).add(vecDir.multiply(SPESSORE_STIPITE));
        //vecPt3 = Vector.of([vecPt2.x, vecPt3.y]);

        let pt1: Vector2d = {x: 0, y: 0};
        let pt2: Vector2d = {x: vecPt2.x, y: vecPt2.y};
        let pt3: Vector2d = {x: vecPt3.x, y: vecPt3.y}
        let pt4: Vector2d = {x: 0, y: h};

        return [pt1, pt2, pt3, pt4];
    }
        
    const buildPolyStipiteDx = (): Vector2d[] => {
        let vecDir = pf.subtract(Vector.of([l, h])).normalize();
        let vecPt3 = Vector.of([l, h]).add(vecDir.multiply(SPESSORE_STIPITE));

        vecDir = pf.subtract(Vector.of([l, 0])).normalize();
        let vecPt4 = Vector.of([l, 0]).add(vecDir.multiply(SPESSORE_STIPITE));

        //vecPt3 = Vector.of([vecPt4.x, vecPt3.y]);

        let pt1: Vector2d = {x: l, y: 0};
        let pt2: Vector2d = {x: l, y: h};
        let pt3: Vector2d = {x: vecPt3.x, y: vecPt3.y};
        let pt4: Vector2d = {x: vecPt4.x, y: vecPt4.y}

        return [pt1, pt2, pt3, pt4];
    }
    
    const buildPolyArchitrave = (): Vector2d[] => {
        let vecDir = pf.subtract(Vector.of([l, 0])).normalize();
        let vecPt3 = Vector.of([l, 0]).add(vecDir.multiply(SPESSORE_STIPITE));

        vecDir = pf.subtract(Vector.of([0, 0])).normalize();
        let vecPt4 = Vector.of([0, 0]).add(vecDir.multiply(SPESSORE_STIPITE));
        
        let pt1: Vector2d = {x: 0, y: 0};
        let pt2: Vector2d = {x: l, y: 0};
        let pt3: Vector2d = {x: vecPt3.x, y: vecPt3.y};
        let pt4: Vector2d = {x: vecPt4.x, y: vecPt4.y};

        return [pt1, pt2, pt3, pt4];
    }

    return (
        <>
            {/* <Rect
                x={0} 
                y={0} 
                width={l} 
                height={h} 
                stroke={"#000"} 
                strokeWidth={DEFAULT_STROKE_WIDTH}
            /> */}

            <Line points={Vector2dToPointsArray(buildPolyStipiteSx())} 
                strokeEnabled
                stroke={"000"}
                strokeWidth={1}
                fillEnabled
                fill={"#aaa"}
                closed
            />

            <Line points={Vector2dToPointsArray(buildPolyStipiteDx())} 
                strokeEnabled
                stroke={"000"}
                strokeWidth={1}
                fillEnabled
                fill={"#aaa"}
                closed
            />

            <Line points={Vector2dToPointsArray(buildPolyArchitrave())} 
                strokeEnabled
                stroke={"000"}
                strokeWidth={1}
                fillEnabled
                fill={"#aaa"}
                closed
            />
        </>
    );
}

const Parete: FC<{lgDraw: number, hgDraw: number}> = ({lgDraw, hgDraw}) => {
    const buildPolyParete = (): Vector2d[] => {
        let pt1: Vector2d = {x: 0, y: 0};
        let pt2: Vector2d = {x: 0, y: hgDraw};
        let pt3: Vector2d = {x: -PROFONDITA_INVOLUCRO, y: hgDraw};        
        let pt4: Vector2d = {x: -PROFONDITA_INVOLUCRO, y: -PROFONDITA_INVOLUCRO/2};
        let pt5: Vector2d = {x: lgDraw + (PROFONDITA_INVOLUCRO), y: -PROFONDITA_INVOLUCRO/2};
        let pt6: Vector2d = {x: lgDraw + (PROFONDITA_INVOLUCRO), y: hgDraw};
        let pt7: Vector2d = {x: lgDraw, y: hgDraw};
        let pt8: Vector2d = {x: lgDraw, y: 0};

        // let pt2: Vector2d = {x: -PROFONDITA_INVOLUCRO*2, y: hgDraw + PROFONDITA_INVOLUCRO};
        // let pt3: Vector2d = {x: -PROFONDITA_INVOLUCRO*2, y: -PROFONDITA_INVOLUCRO};
        // let pt5: Vector2d = {x: lgDraw + (PROFONDITA_INVOLUCRO*2), y: hgDraw + PROFONDITA_INVOLUCRO};
        // let pt6: Vector2d = {x: lgDraw, y: hgDraw};

        return [pt1, pt2, pt3, pt4, pt5, pt6, pt7, pt8];
    }

    return (
        <>
            <Line points={Vector2dToPointsArray(buildPolyParete())} 
                strokeEnabled
                stroke={"000"}
                strokeWidth={1}
                fillEnabled
                fill={"#ddd"}
                closed
                // onClick={() => alert("Hai cliccato la parete esterna")}
            />

            {/*buildPolyParete().map((p, index) => (
                <>
                    <Circle
                        x={p.x}
                        y={p.y}
                        radius={4}
                        fill={"#f00"}
                    />
                    <Text
                        x={p.x}
                        y={p.y}
                        text={"P"+(index+1)}
                    />
                </>
            ))*/}
        </>
    );
}