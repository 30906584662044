import React, { useEffect, useState } from "react";
import { useStoreDispatch, useStoreSelector } from "../../../../../hooks/StoreHooks";
import { getCurrentUser, numberCompare } from "../../../../core/common_functions";
import { useAccessoriesService } from "../../../../services/accessories_service";
import { useDialogs } from "../../../../providers/dialogs_provider";
import { useDoorConfigurationBuilderActions } from "../../../../../store/reducers/door_configuration_builder";
import { Accessorio } from "../../../../models/door_configuration";
import { Alert, Button, Grid, IconButton } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import Loading from "../../../../../components/Loading/loading";
import { useNavigate, useParams } from "react-router-dom";
import { useConfigurationsService } from "../../../../services/configurations_service";

export const MotorizationPage = () => {
    const { base, doorConfigurationBuilder } = useStoreSelector(store => store);
    const navigate = useNavigate();
    const { id } = useParams();
    const dialogs = useDialogs();
    const dispatcher = useStoreDispatch();
    const currentUser = getCurrentUser(base);
    const { setStepActive, setStepComplete } = useDoorConfigurationBuilderActions();
    const [accessories, setAccessories] = useState<{ isLoading: boolean, items: Accessorio[] }>({ isLoading: true, items: [] });
    const [isSubmit, setSubmit] = useState<boolean>(false);
    const accessoriesService = useAccessoriesService();
    const configurationService = useConfigurationsService();
    const [motorizzazione, setMotorizzazione] = useState<Accessorio>(null);

    const loadAccessories = async () => {
        try {
            if (!doorConfigurationBuilder.configuration) throw "invalid_configuration";

            let result = await configurationService.findCompatibleMotorizations(currentUser, doorConfigurationBuilder.configuration.configurationId);

            setAccessories({ isLoading: false, items: result });

            let motorizationId = await configurationService.getMotorizzazione(currentUser, doorConfigurationBuilder.configuration.configurationId);
            if (motorizationId) {
                let selectedMot = result.find(m => m?.id == motorizationId);
                setMotorizzazione(selectedMot);
            }
        } catch (e: any) {
            setAccessories({ ...accessories, isLoading: false });

            if (typeof e == "string") {
                dialogs?.showError("Attenzione...", e);
            } else {
                if (e.severity == "error") {
                    dialogs?.showError("Attenzione...", e.message);
                } else if (e.severity == "warning") {
                    dialogs?.showWarning("Attenzione...", e.message);
                }
            }
        }
    }

    const submit = async () => {
        setSubmit(true);
        try {
            if (!doorConfigurationBuilder.configuration) throw "invalid configuration";
            if (!motorizzazione) throw { severity: "warning", message: "Motorizzazione non selezionata" };

            await configurationService.setMotorizzazione(currentUser, doorConfigurationBuilder.configuration?.configurationId, motorizzazione.id);

            setSubmit(false);

            dispatcher(setStepComplete({ stepSlug: "motorizzazione", isComplete: true }));

            navigate(`/door-configurations/${id}/accessori-motorizzazione`);
        } catch (e: any) {
            setSubmit(false);

            if (typeof e == "string") {
                dialogs?.showError("Attenzione...", e);
            } else {
                if (e.severity == "error") {
                    dialogs?.showError("Attenzione...", e.message);
                } else if (e.severity == "warning") {
                    dialogs?.showWarning("Attenzione...", e.message);
                }
            }
        }
    }

    useEffect(() => {
        if (!id || isNaN(Number(id)) || !doorConfigurationBuilder.configuration) {
            navigate("/configurations");
        } else {
            dispatcher(setStepActive({ stepSlug: "motorizzazione", isActive: true }));

            loadAccessories();
        }
    }, []);

    return (
        <div className="container h-full">
            <div className="row m-0 h-full">
                <div className="col-xl-7 p-0" style={{ overflowY: "auto", height: "100%" }}>
                    <div className="p-4">
                        <h5 className="text-primary">Motorizzazioni disponibili</h5>

                        {accessories.isLoading && <Loading />}

                        <Grid container spacing={2} className="accessories-grid my-0">
                            {!accessories.isLoading && accessories.items.sort((a,b) => numberCompare(Number(b?.price) ?? 0, Number(a?.price) ?? 0)).map((a,idx) => (
                                <Grid key={a?.id} item xl={12} className={"p-2 accessories-grid-item"} style={{ borderBottom: "solid 1px #f1f1f1" }} onClick={() => setMotorizzazione(a)}>
                                    {a?.img && <img src={a?.img ?? ""} style={{ width: "4rem", height: "4rem" }} />}
                                    <div className="p-2">
                                        <h6 className="text-secondary">
                                            {a?.name}
                                        </h6>
                                        <div className="d-flex flex-row-reverse justify-content-between">
                                            <span className="badge bg-light text-dark">&euro; {a?.price}</span>
                                            {idx == 0 && <span className="badge rounded-pill bg-primary" style={{textTransform: "uppercase", fontWeight: "normal", fontSize: ".7rem"}}><CheckIcon sx={{fontSize: ".9rem"}} /> Scelta consigliata</span>}
                                        </div>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>

                        {!accessories.isLoading && accessories.items.length <= 0 && (
                            <Alert severity="warning">Nessuna motorizzazione disponibile</Alert>
                        )}
                    </div>
                </div>

                <div className="col-xl-5 p-0" style={{ overflowY: "auto", height: "100%", borderLeft: "solid 1px #ccc" }}>
                    <div className="p-4">
                        <h5 className="text-primary">Motorizzazione selezionata</h5>

                        {motorizzazione && (
                            <div className="card bg-light">
                                <div className="car-body p-2">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="p-2">
                                            <h6 className="text-secondary m-0" style={{ fontSize: ".85rem" }}>
                                                {motorizzazione.name}
                                            </h6>
                                            <div>
                                                <span className="badge bg-light text-dark">&euro; {motorizzazione.price}</span> <span className="badge bg-light text-dark">SKU {motorizzazione.sku}</span>
                                            </div>
                                        </div>

                                        <IconButton onClick={() => setMotorizzazione(null)}>
                                            <CancelIcon color={"primary"} />
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        )}

                        {!accessories.isLoading && <div className="d-flex flex-row-reverse justify-content-between mt-3">
                            <Button disabled={isSubmit || !motorizzazione} variant="contained" className="ps-3 pe-1" onClick={() => submit()}>
                                <span>Avanti</span>
                                <NavigateNextIcon />
                            </Button>

                            <Button variant="contained" className="ps-1 pe-3" color="secondary" onClick={() => {
                                if (doorConfigurationBuilder.steps.find(s => s.slug == "portina-pedonale-affiancata" && s.isVisible)) {
                                    navigate(`/door-configurations/${doorConfigurationBuilder.configuration?.configurationId}/portina-pedonale-affiancata`);
                                } else if (doorConfigurationBuilder.steps.find(s => s.slug == "ventilazione" && s.isVisible)) {
                                    navigate(`/door-configurations/${doorConfigurationBuilder.configuration?.configurationId}/ventilazione`);
                                } else if (doorConfigurationBuilder.steps.find(s => s.slug == "finestratura" && s.isVisible)) {
                                    navigate(`/door-configurations/${doorConfigurationBuilder.configuration?.configurationId}/finestratura`);
                                } else if (doorConfigurationBuilder.steps.find(s => s.slug == "portina-pedonale" && s.isVisible)) {
                                    navigate(`/door-configurations/${doorConfigurationBuilder.configuration?.configurationId}/portina-pedonale`);
                                } else {
                                    navigate(`/door-configurations/${doorConfigurationBuilder.configuration?.configurationId}/aspetto`);
                                }
                            }}>
                                <NavigateBeforeIcon />
                                <span>Indietro</span>
                            </Button>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    );
}