import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import * as React from "react";

const Loading = () => {
  return (
    <div style={{ 
      display: "flex", 
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      position: "fixed",
      zIndex: 50,
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(255,255,255,0.6)"
    }}>
      <Box>
        <img style={{ marginBottom: 5, width: 400 }} src={"/img/LogoOverlap.png"} alt={"img"} />
        <div className={"flex justify-center"}>
          <CircularProgress />
        </div>
      </Box>
    </div>
  );
};

export default Loading;
