import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import React, { FC, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useFormik } from "formik";

export type TextInputProps = {
    title: string,
    defaultText: string,
    required: boolean,
    onConfirm: (text: string) => void,
}

export const TextInputDialog: FC<{data: TextInputProps, onConfirm: (text: string) => void, onCancel: () => void}> = (props) => {    
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [erroreText, setErroreText] = useState<string | null>(null);
    const formik = useFormik<{text: string}>({
        initialValues: {
            text: props.data.defaultText
        },
        onSubmit: (model) => {

        }
    });

    const onConfirm = () => {
        if (!formik.values.text && props.data.required) {
            setErroreText("Campo obbligatorio");
            return;
        }
        props.onConfirm(formik.values.text);
    };

    return (
        <Dialog fullWidth={false} fullScreen={fullScreen} maxWidth="sm" onClose={props.onCancel} open={true}>
            <DialogTitle>{props.data.title}</DialogTitle>
            <DialogContent className="py-2">
                <TextField
                    label=""
                    variant="outlined"
                    className="w-full"
                    type="text"
                    size="small"
                    multiline
                    value={formik.values.text}
                    onChange={(e) => formik.setFieldValue("text", e.target.value)}
                    error={erroreText != null}
                    helperText={erroreText}
                />
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={props.onCancel}>Annulla</Button>
                <Button variant="contained" color="primary" onClick={onConfirm} autoFocus>OK</Button>
            </DialogActions>
        </Dialog>
    );
}