import * as React from "react";
import Grid from "@mui/material/Grid";
import TopBar from "../../components/TopBar/TopBar";
import { LayoutPage } from "../../components/Layout/layoutPage";
import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import TabsCustom from "../../components/Tabs/Tabs";
import { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import "redux";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import * as yup from "yup";
import { InputCustom } from "../../components/Input/InputCustom";
import axios from "axios";
import Loading from "../../components/Loading/loading";
import GridPreventivi from "../../components/GrdiPreventivi/gridPreventivi";
import FormControlLabel from "@mui/material/FormControlLabel";
import { importBaseActions } from "../../store/reducers/base";
import { getProductName } from "../../Utility";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const validationSchema = yup.object({
  qty: yup
    .number()
    .typeError("Inserire un numero intero")
    .required("Quantità richiesta obbligatoria"),
  altezza: yup
    .number()
    .typeError("Inserire un numero valido")
    .required("Altezza obbligatoria"),
  larghezza: yup
    .number()
    .typeError("Inserire un numero valido")
    .required("Larghezza obbligatoria"),
  architrave: yup
    .number()
    .typeError("Inserire un numero valido")
    .required("Architrave obbligatoria"),
  spallettaSX: yup
    .number()
    .typeError("Inserire un numero valido")
    .required("Spalletta Sinistra obbligatoria"),
  spallettaDX: yup
    .number()
    .typeError("Inserire un numero valido")
    .required("Spalletta Destra obbligatoria"),
  // SMuroSuperiore: yup
  //   .number()
  //   .typeError("Inserire un numero valido"),
  // SMuroSinistro: yup
  //   .number()
  //   .typeError("Inserire un numero valido"),
  // SMuroDestro: yup
  //   .number()
  //   .typeError("Inserire un numero valido"),
  PGarage: yup
    .number()
    .typeError("Inserire un numero valido")
    .required("Profondità Garage obbligatoria"),
});

const Dimensioni = () => {
  const state = useSelector(state => state.base);
  const dispatch = useDispatch();
  const baseActions = importBaseActions();
  const [data, setData] = useState({
    state: "loading", 
    steps: [],
    currentStep: null,
    dimensioni: null
  });

  //const history = useHistory();
  const navigate = useNavigate();
  const classes = useStyles();
  const [errorVincolo, setErrorVincolo] = useState(null);

  const location = useLocation();
  const [value, setValue] = useState(0);
  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + state.user.token,
    },
  };

  const [checked, setChecked] = useState(state.selectedD);
  const [submit, setSubmit] = useState(false);
  const [switchPorticinaAffiancata, setSwitchPorticinaAffiancata] = useState(state.isPorticinaAffiancata);

  const handleChange = () => {
    // data &&
    //   data.map((el) =>
    //     `/${el.slug}` === location.pathname ? setValue(el.id) : null
    //   );
  };

  const getData = () => {
    formik.setFieldValue("altezza", state.altezza);
    formik.setFieldValue("larghezza", state.larghezza);
    formik.setFieldValue("architrave", state.architrave);
    formik.setFieldValue("spallettaSX", state.spallettaSinistra);
    formik.setFieldValue("spallettaDX", state.spallettaDestra);
    formik.setFieldValue("SMuroSuperiore", state.SMuroSuperiore);
    formik.setFieldValue("SMuroSinistro", state.SMuroSinistro);
    formik.setFieldValue("SMuroDestro", state.SMuroDestro);
    formik.setFieldValue("PGarage", state.PGarage);
  };

  useEffect(() => {
    //return getData();
    load();
  }, []);

  const load = async () => {     
    let steps = [];
    let currentStep = null;
    try {
      let res = await axios.post(`${process.env.REACT_APP_API_URL}/categories-steps`, { id: state.idPorta, quote_id: state.quoteId }, axiosConfig);      
      console.log(res);
      if (res.data && res.data.data) {
        steps = res.data.data;
        currentStep = findCategoryStep(steps);
        console.log(currentStep);
      }      
    } catch (e) {
      
    }

    try {
      let res = await axios.post(`${process.env.REACT_APP_API_URL}/get-dimensioni`, { id: state.customer ? state.customer.id : state.user.id, quote: state.quoteId }, axiosConfig);

      let dimensioni = res.data.data;
      if (dimensioni) {        
        formik.setFieldValue("qty", dimensioni.qty);
        formik.setFieldValue("altezza", dimensioni.height);
        formik.setFieldValue("larghezza", dimensioni.width);
        formik.setFieldValue("architrave", dimensioni.architrave);
        formik.setFieldValue("spallettaSX", dimensioni.distance_ss);
        formik.setFieldValue("spallettaDX", dimensioni.distance_ds);
        formik.setFieldValue("SMuroSuperiore", dimensioni.upper_wall_thickness);
        formik.setFieldValue("SMuroSinistro", dimensioni.left_wall_thickness);
        formik.setFieldValue("SMuroDestro", dimensioni.right_wall_thickness);
        formik.setFieldValue("PGarage", dimensioni.garage_depth);
        formik.setFieldValue("locale_con_accesso", dimensioni.locale_con_accesso == 1);
        //formik.setFieldValue("with_door", dimensioni.with_door == 1);
        formik.setFieldValue("with_side_door", dimensioni.with_side_door == 1);
        dispatch(baseActions.setSelectedD(dimensioni.locale_con_accesso == 1));
        //dispatch(baseActions.setIsPorticina(dimensioni.with_door == 1));
        dispatch(baseActions.setIsPorticinaAffiancata(dimensioni.with_side_door == 1));
      }
        
      setData({
        state: "idle",
        steps: steps, 
        currentStep: currentStep,
        dimensioni
      });
    } catch (e) {
      console.error(e);
      setData({state: "error", error: e});
    }
  }

  const findCategoryStep = (categoriesSteps) => {    
    return categoriesSteps.find(cs => `/${cs.slug}` === location.pathname);
  };

  const navigateToNextStep = () => {
    let nextStep = null;
    let stepsIds = data.steps.map(s => s.position);
    let maxStepId = Math.max(...stepsIds);

    for (let stepId = data.currentStep.position + 1; stepId <= maxStepId; stepId++) {
      let step = data.steps.find(s => s.position == stepId);
      if (!step) continue;
      if (state.fullglass && (step.position == 6 || step.position == 7 || step.position == 8)) {
        continue;
      }
      nextStep = step;
      if (nextStep) break;
    }    
    if (!nextStep) return;

    navigate(`/${nextStep.slug}`, { 
      id: state.idPorta,
    });
  }

  const setQty = (value) => {
    formik.setFieldValue("qty", value);
  }

  const isValidQta = (value) => !isNaN(value) && value > 0;
  const [erroreQta, setErroreQta] = useState(null);

  // useEffect(() => {
  //   cache.clear();
  //   return handleChange();
  // }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      qty: "0",
      altezza: "0",
      larghezza: "0",
      architrave: "0",
      spallettaSX: "0",
      spallettaDX: "0",
      SMuroSuperiore: "0",
      SMuroSinistro: "0",
      SMuroDestro: "0",
      PGarage: "0",
      locale_con_accesso: false,
      is_debordante: false,
      //with_door: false,
      with_side_door: false
    },
    //validationSchema: validationSchema,
    onSubmit: (values) => {
      values.architrave = state.architrave;
      values.SMuroSuperiore = state.SMuroSuperiore;
      values.PGarage = state.PGarage;
      values.SMuroSinistro = state.SMuroSinistro;
      values.spallettaSX = state.spallettaSinistra;
      values.spallettaDX = state.spallettaDestra;
      values.SMuroDestro = state.SMuroDestro;
      values.larghezza = state.larghezza;
      values.altezza = state.altezza;
      const valid = validationSchema.isValidSync(values);
      if (!valid) {
        setErrorVincolo("Attenzione, assicurati di aver inserito tutte le misure necessarie.");
        return;
      }

      setSubmit(true);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/dimensioni`,
          {
            id: state.customer ? state.customer.id : state.user.id,
            qty: values.qty,
            category_id: state.idPorta,
            quote: state.quoteId,
            altezza: state.altezza,
            larghezza: state.larghezza,
            architrave: state.architrave,
            spallettaSX: state.spallettaSinistra,
            spallettaDX: state.spallettaDestra,
            SMuroSuperiore: state.SMuroSuperiore,
            SMuroSinistro: state.SMuroSinistro,
            SMuroDestro: state.SMuroDestro,
            PGarage: state.PGarage,
            locale_con_accesso: state.selectedD,
            is_debordante: state.isDebordante,
            //with_door: state.isPorticina,
            with_side_door: state.isPorticinaAffiancata,
          },
          axiosConfig
        )
        .then((res) => {          
          setSubmit(false);
          if (res.data.error === 1) {
            setErrorVincolo(res.data.message);
          } else {            
            dispatch(baseActions.setDimensione(true));
            navigateToNextStep();
          }
        })
        .catch(function (error) {
          setSubmit(false);
          console.log(error);
        });
    },
  });

  if ("error" == data.state) return <div>failed to load</div>;
  if ("loading" == data.state) return <Loading />;

  return (
    <LayoutPage>
      <div style={{ flex: "0 0 auto" }}>
        <TopBar />
        
        <div className={"d-flex justify-content-between p-3"}>
            <div className="d-flex flex-column" style={{ lineHeight: "1" }}>
                <h2 className="h2 fw-bold">
                    Nuova configurazione {getProductName(state.idPorta)} (#{state.quoteId})
                </h2>
            </div>
            <div>
                {/* <Button variant={deleteClickCount == 0 ? "outlined" : "contained"} color="error" className="me-3 px-2">
                    <RemoveCircleOutlineOutlinedIcon />
                    <span className="ms-2">{deleteClickCount == 0 ? "ELIMINA" : "CONFERMA"}</span>
                </Button> */}

                <Button variant="contained" color="secondary" onClick={() => navigate("/configurations")}>
                    <CloseIcon />
                    <span className="ms-2">CHIUDI</span>
                </Button>
            </div>
        </div>

        <Grid
          className={classes.root}
          container
          sx={{ justifyContent: "center" }}>
          <TabsCustom
            value={value}
            setValue={setValue}
            count={state}
            id={state.idPorta}
            handleChange={handleChange}
            axios_conf={axiosConfig}
            url={`${process.env.REACT_APP_API_URL}/categories-steps`}
          />
        </Grid>
      </div>
      <div style={{ flex: "1 1 auto", minHeight: "0" }}>
        {data.dimensioni && <GridPreventivi
          dataSend={{
            quote_id: state.quoteId,
            category_id: state.idPorta,
            slug: location.pathname.substring(1),
          }}
          axiosConfig={axiosConfig}
          url={`${process.env.REACT_APP_API_URL}/photo-preventivi`}
          dimensioni={data.dimensioni && data.dimensioni}>
          <h5 className="text-primary">Nuova configurazione</h5>

          <form onSubmit={formik.handleSubmit}>
            <div>
              <TextField
                  variant="outlined"
                  label="Quantità"
                  type="number"
                  className="mt-3 w-full"
                  value={formik.values.qty}
                  onChange={(e) => formik.values.qty}
                  onBlur={(e) => {
                      if (!isValidQta(Number(e.target.value))) {
                          setErroreQta("Valore non valido");
                          return;
                      }                            
                      setErroreQta(null);
                      setQty(e.target.value)
                  }}
                  error={erroreQta != null}
                  helperText={erroreQta}
              />

              <FormControlLabel 
                  className="w-full mt-4" 
                  control={
                      <Checkbox
                          checked={formik.values.locale_con_accesso}
                          onChange={(e) => {
                            setChecked(e.target.checked);
                            dispatch(baseActions.setSelectedD(e.target.checked));
                            formik.setFieldValue("locale_con_accesso", e.target.checked);
                          }}
                          sx={{marginTop: "0", marginBottom: "0"}}
                      />
                  } 
                  label="Locale garage con altro accesso" 
                  sx={{marginTop: "0", marginBottom: "0", textTransform: "uppercase"}}
              />

              <FormControlLabel 
                  className="w-full" 
                  control={
                      <Checkbox
                          checked={formik.values.with_side_door}
                          onChange={(e) => {
                            setSwitchPorticinaAffiancata(e.target.checked);
                            dispatch(baseActions.setIsPorticinaAffiancata(e.target.checked));
                            formik.setFieldValue("with_side_door", e.target.checked);
                          }}
                          sx={{marginTop: "0", marginBottom: "0"}}
                      />
                  } 
                  label="Aggiungi portina pedonale affiancata" 
                  sx={{marginTop: "0", marginBottom: "0", textTransform: "uppercase"}}
              />
            </div>

            <div className="d-flex flex-row-reverse mt-3">
              <Button disabled={submit} variant="contained" className="ps-3 pe-1" type={"submit"}>
                <span>Avanti</span>
                <NavigateNextIcon />
              </Button>
            </div>
          </form>
        </GridPreventivi>}

        {errorVincolo && <div className="d-flex justify-content-center py-3" style={{position: "fixed", left: "0", bottom: "0", width: "100%"}}>
          <Alert variant="filled" severity="warning" sx={{zIndex: "1500"}} onClose={() => setErrorVincolo(null)}>
              <AlertTitle>Attenzione...</AlertTitle>
              {errorVincolo}
          </Alert>
        </div>}
      </div>
    </LayoutPage>
  );
};
const useStyles = makeStyles({
  root: {
    "& MuiTabs-flexContainer css-heg063-MuiTabs-flexContainer": {
      justifyContent: "center",
    },
  },
});

export default Dimensioni;
