import * as React from "react";
import Grid from "@mui/material/Grid";
import TopBar from "../../components/TopBar/TopBar";
import { LayoutPage } from "../../components/Layout/layoutPage";
import { Button, CircularProgress, Typography } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import HomeIcon from "@mui/icons-material/Home";
import SendIcon from "@mui/icons-material/Send";
import TabsCustom from "../../components/Tabs/Tabs";
import { useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import iconPDF from "../../assets/img/iconPDF.png";
import { makeStyles } from "@mui/styles";
import Loading from "../../components/Loading/loading";
import axios from "axios";
import useSWR from "swr";
import ErrorCustom from "../../components/Error/Error";
import { GridPDF } from "../../components/GrdiPreventivi/GridPDF";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { importBaseActions } from "../../store/reducers/base";
import { current } from "@reduxjs/toolkit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DownloadIcon from "@mui/icons-material/Download";
import translate from "../../i18n/messages/translate";
import { getProductName } from "../../Utility";
import { AmountItem, NumberItem } from "./richiesta";

const GeneraPDF = () => {
  const count = useSelector(state => state.base);
  const baseActions = importBaseActions();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    status: "loading",
    pdfData: null,
    error: null
  });

  //const history = useHistory();
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();

  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + count.user.token,
    },
  };
  // const fetcher = (url) =>
  //   axios.post(url, { id: count.idPorta }, axiosConfig).then((res) => res.data.data);
  // const { data, error } = useSWR([`${process.env.REACT_APP_API_URL}/categories-steps`], fetcher);

  // const fetcher1 = (url) =>
  //   axios
  //     .post(url, { id: count.quoteId, user: count.user.id }, axiosConfig)
  //     .then((res) => res.data);
  // const { data: pdf, error: errorPdf } = useSWR([`${process.env.REACT_APP_API_URL}/pdf`], fetcher1);

  const [value, setValue] = useState();
  const [isBusy, setIsBusy] = useState(false);
  const [isShowMenuExtra, setIsShowMenuExtra] = useState(false);
  const handleChange = () => {
    state.steps &&
    state.steps.map((el) =>
        `/${el.slug}` === location.pathname ? setValue(el.id) : null
      );
  };

  const load = async () => {
    try {
      let res = await axios.get(`${process.env.REACT_APP_API_URL}/pdf?quoteId=${count.quoteId}`, axiosConfig);

      if (res.data.data.quote.end_client_summary) {
        try {
          res.data.data.quote.end_client_summary = JSON.parse(res.data.data.quote.end_client_summary);
        } catch (e) {

        }
      }

      setState({
        ...state,        
        pdfData: res.data.data,
        status: "idle"
      });
    } catch (e) { 
      setState({
        ...state,
        status: "error",
        error: e
      });
    }
  }

  const inviaOrdine = async () => {
    setIsBusy(true);
    try {
      let res = await axios.get(`${process.env.REACT_APP_API_URL}/send-order?quoteId=${state.pdfData.quote.id}`, axiosConfig);

      if (res.data.success == 0) throw res.data.message;

      alert("L'ordine è stato inviato con successo");

      setIsBusy(false);
    } catch (e) {
      alert("Si è verificato un errore durante l'invio dell'ordine. Ti preghiamo di riprovare.");

      setIsBusy(false);
    }
  }

  useEffect(() => {
    load();
    //return handleChange();
  }, []);

  if ("error" == state.status) return <ErrorCustom route={"/configurations"} />;
  if ("loading" == state.status) return <Loading />;

  return (
    <LayoutPage>
      <div style={{ flex: "0 0 auto" }}>
        <TopBar />

        <div className={"d-flex justify-content-between p-3"}>
            <div className="d-flex flex-column" style={{ lineHeight: "1" }}>
              <h4 className="h4 fw-bold">
                Il tuo preventivo è pronto!<br />
                <small style={{fontWeight: "normal", fontSize: "1rem"}}>Puoi scaricare e stampare una copia del preventivo appena generato</small>
              </h4>
            </div>
            <div className="d-flex align-items-start" style={{gap: ".5rem"}}>
              <Button variant="contained" color="secondary" onClick={() => navigate("/configurations")}>
                  <CloseIcon />
                  <span className="ms-2">CHIUDI</span>
              </Button>

              <Button variant="contained" color="primary" onClick={(e) => {
                setIsShowMenuExtra(false);
                window.open(`${process.env.REACT_APP_WEB_URL}/pdf/retail?quoteId=${state.pdfData.quote.id}`);
              }}>
                  <DownloadIcon />
                  <span className="ms-2">SCARICA PREVENTVO</span>
              </Button>

              {count.user.userType == "Retail" && (
                <button type="button" className="btn btn-primary" style={{cursor: "pointer"}} onClick={(e) => {
                  setIsShowMenuExtra(false);
                  window.open(`${process.env.REACT_APP_WEB_URL}/pdf/customer?quoteId=${state.pdfData.quote.id}`);
                }}>
                  <DownloadIcon className="me-2" style={{ fontSize: "1.25rem" }} />
                  SCARICA COPIA CLIENTE
                </button>
              )}
              <button type="button" className="btn btn-primary" onClick={() => inviaOrdine()}>
                <SendIcon className="me-2" style={{ fontSize: "1.25rem" }} />
                INVIA ORDINE
              </button>
              <div className={"dropdown " + (isShowMenuExtra ? "show" : "")}>
                <button type="button" className="btn btn-light" onClick={(evt) => setIsShowMenuExtra(!isShowMenuExtra)}>
                  <MoreVertIcon />
                </button>

                <ul className={"dropdown-menu mt-2 " + (isShowMenuExtra ? "show" : "")} style={{ position: "absolute", right: "0" }}>
                  <li>
                    <Link to="/dimensioni" className="dropdown-item">
                      <EditIcon className="me-2" style={{ fontSize: "1.25rem" }} />
                      MODIFICA
                    </Link>
                  </li>
                  {/* <li>
                    <a className="dropdown-item" style={{cursor: "pointer"}} onClick={(e) => {
                      setIsShowMenuExtra(false);
                      window.open(`${process.env.REACT_APP_WEB_URL}/pdf/retail?quoteId=${state.pdfData.quote.id}`);
                    }}>
                      <DownloadIcon className="me-2" style={{ fontSize: "1.25rem" }} />
                      SCARICA PREVENTVO
                    </a>
                  </li>
                  {count.user.userType == "Retail" && <li>
                    <a className="dropdown-item" style={{cursor: "pointer"}} onClick={(e) => {
                      setIsShowMenuExtra(false);
                      window.open(`${process.env.REACT_APP_WEB_URL}/pdf/customer?quoteId=${state.pdfData.quote.id}`);
                    }}>
                      <DownloadIcon className="me-2" style={{ fontSize: "1.25rem" }} />
                      SCARICA COPIA CLIENTE
                    </a>
                  </li>}*/}
                </ul>
              </div>
            </div>
        </div>
      </div>

      <div style={{ flex: "1 1 auto", minHeight: "0", overflowY: "auto" }}>
        <div className="row m-0 h-full">
          <div className="col-xl-6 p-0">
            <div className="p-4">
              <img src={`${process.env.REACT_APP_WEB_URL}/pdf/imgPreview?quoteId=${state.pdfData.quote.id}`} className="w-100" />
              
              <div className="mt-3">
                <h6 className="h6 fw-bold" style={{borderBottom: "solid 1px var(--bs-primary)"}}>DIMENSIONI DEL VANO</h6>
                <table className="w-100">
                  <tbody>
                    <tr>
                      <td className="border-bottom">Architrave:</td>
                      <th className="text-end border-bottom">{state.pdfData.quote.architrave} mm</th>                    
                    </tr>
                    <tr>
                      <td className="border-bottom">Altezza:</td>
                      <th className="text-end border-bottom">{state.pdfData.quote.height} mm</th>                    
                    </tr>
                    <tr>
                      <td className="border-bottom">Larghezza:</td>
                      <th className="text-end border-bottom">{state.pdfData.quote.width} mm</th>
                    </tr>
                    <tr>
                      <td className="border-bottom">Spalletta sinistra:</td>
                      <th className="text-end border-bottom">{state.pdfData.quote.distance_ss} mm</th>
                    </tr>
                    <tr>
                      <td>Spalletta destra:</td>
                      <th className="text-end">{state.pdfData.quote.distance_ds} mm</th>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="mt-3">
                <h6 className="h6 fw-bold" style={{borderBottom: "solid 1px var(--bs-primary)"}}>MISURE PORTA</h6>
                <table className="w-100">
                  <tbody>
                    <tr>
                      <td className="border-bottom">Quantità:</td>
                      <th className="text-end border-bottom">{state.pdfData.quote.qty ? state.pdfData.quote.qty.toFixed(2) : 0}</th>                    
                    </tr>
                    <tr>
                      <td className="border-bottom">Altezza:</td>
                      <th className="text-end border-bottom">{state.pdfData.quote.height_calculate} mm</th>                    
                    </tr>
                    <tr>
                      <td className="">Larghezza:</td>
                      <th className="text-end">{state.pdfData.quote.width_calculate} mm</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="col-xl-6 p-0">
            <div className="p-4">
              <h6 className="h6 fw-bold" style={{borderBottom: "solid 1px var(--bs-primary)"}}>PRODOTTO</h6>
              <table>
                <thead>
                  <tr>
                    <th className="border-bottom" style={{width: "70%"}}>DESCRIZIONE</th>
                    <th className="border-bottom text-end" style={{width: "20%"}}>Q.TÀ</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border-bottom">{state.pdfData.quote.description}</td>
                    <td className="border-bottom text-end">{state.pdfData.quote.qty}</td>
                  </tr>
                </tbody>
              </table>
              
              <h6 className="h6 fw-bold mt-3" style={{borderBottom: "solid 1px var(--bs-primary)"}}>ACCESSORI</h6>
              <table>
                <thead>
                  <tr>
                    <th className="border-bottom" style={{width: "10%"}}>CODICE</th>
                    <th className="border-bottom" style={{width: "70%"}}>DESCRIZIONE</th>
                    <th className="border-bottom text-end" style={{width: "20%"}}>Q.TÀ</th>
                  </tr>
                </thead>
                <tbody>
                  {state.pdfData.accessories.map(a => (
                    <tr key={a.id}>
                      <td className="border-bottom align-top">{a.codice}</td>
                      <td className="border-bottom">{a.descrizione}</td>
                      <td className="border-bottom text-end">{a.qty ? a.qty.toFixed(2) : 0} {a.um}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {state.pdfData.quote && (
                <div className="mt-1 rounded-3 p-3 mt-3 bg-light " style={{ position: "relative" }}>
                  <h4 className="h4 fw-bold">Totale:</h4>
                  <div className="d-flex justify-content-between">
                    <NumberItem label="Quantità:" value={state.pdfData.quote.qty} />
                  </div>

                  <div className="d-flex justify-content-between">
                    <AmountItem label="Totale merce:" value={state.pdfData.quote.base_net_price} />
                  </div>

                  <div className="d-flex justify-content-between">
                    <AmountItem label="Totale servizi:" value={state.pdfData.quote.total_services} />
                  </div>

                  {count.user.userType != "Retail" && (
                    <>
                      <div className="my-1 text-center" style={{lineHeight: "1"}}>
                        <hr />
                        {process.env.NODE_ENV != "production" && <small>Rivendita</small>}
                      </div>

                      <div className="d-flex justify-content-between">
                        <AmountItem label="Sconto:" value={state.pdfData.quote.total_discount} />
                      </div>

                      <div className="d-flex justify-content-between">
                        <AmountItem label="Totale merce netto:" value={state.pdfData.quote.net} />

                      </div>

                      <div className="d-flex justify-content-between">
                        <AmountItem label="Totale imponibile:" value={state.pdfData.quote.taxable_amount} />
                      </div>

                      <div className="d-flex justify-content-between">
                        <AmountItem label="Totale iva:" value={state.pdfData.quote.total_taxes} />
                      </div>

                      <div className="d-flex justify-content-between">
                        <AmountItem label="Totale ordine:" value={state.pdfData.quote.total} />
                      </div>
                    </>
                  )}

                  {count.user.userType == "Retail" && state.pdfData.quote.end_client_summary && (
                    <>                      
                      <div className="my-1 text-center" style={{lineHeight: "1"}}>
                        <hr />
                        {process.env.NODE_ENV != "production" && <small>Cliente finale</small>}
                      </div>

                      <div className="d-flex justify-content-between">
                        <AmountItem label="Sconto:" value={state.pdfData.quote.end_client_summary.totalDiscount} />
                      </div>

                      <div className="d-flex justify-content-between">
                        <AmountItem label="Totale merce netto:" value={state.pdfData.quote.end_client_summary.net} />
                      </div>

                      <div className="d-flex justify-content-between">
                        <AmountItem label="Totale imponibile:" value={state.pdfData.quote.end_client_summary.taxableAmount} />
                      </div>

                      <div className="d-flex justify-content-between">
                        <AmountItem label="Totale iva:" value={state.pdfData.quote.end_client_summary.totalTaxes} />
                      </div>

                      <div className="d-flex justify-content-between">
                        <AmountItem label="Totale ordine:" value={state.pdfData.quote.end_client_summary.total} />
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </LayoutPage>
  );
};

const useStyles = makeStyles({
  root: {
    "& MuiTabs-flexContainer css-heg063-MuiTabs-flexContainer": {
      justifyContent: "center",
    },
  },
});

export default GeneraPDF;
