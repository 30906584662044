import * as React from "react";
import Grid from "@mui/material/Grid";
import TopBar from "../../components/TopBar/TopBar";
import { LayoutPage } from "../../components/Layout/layoutPage";
import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import TabsCustom from "../../components/Tabs/Tabs";
import { connect, useDispatch, useSelector } from "react-redux";
import "redux";
import { makeStyles } from "@mui/styles";
import GridPreventivi from "../../components/GrdiPreventivi/gridPreventivi";
import { useFormik } from "formik";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import DialogScroll from "../../components/Dialog/DialogScroll";
import axios from "axios";
import useSWR from "swr";
import Loading from "../../components/Loading/loading";
import box from "../../assets/img/box.PNG";
import * as yup from "yup";
import { DialogCustom } from "../../components/Dialog/dialog";
import RadioItemDimensioni from "../../components/Dialog/radioItemDimensioni";
import CircularProgress from "@mui/material/CircularProgress";
import { importBaseActions } from "../../store/reducers/base";
import { useCallback } from "react";
import { Events, useEventBroadcast } from "../../core/EventBroadcast";
import { useAspettoService } from "../../services/AspettoService";
import { OptionsTypeEnum } from "../../core/OptionsTypeEnum";
import { getProductName } from "../../Utility";
import { InstallationType } from "../../v2/core/common_types";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

// const validationSchema = yup.object({
//   installazione: yup.number().required("Tipo di installazione obbligatoria"),
//   porta: yup.number().required("Tipo di porta obbligatoria"),
//   modello: yup.number().required("Modello obbligatorio"),
//   finitura: yup.number().required("Finitura obbligatoria"),
//   colorName: yup.string().required("Finitura interna obbligatoria"),
//   colorName2: yup.string().required("Finitura esterna obbligatoria"),
// });

const Aspetto = () => {
  //const history = useHistory();
  const navigate = useNavigate();
  const classes = useStyles();
  const [openEsterni, setOpenEsterni] = useState(false);
  const [openInterni, setOpenInterni] = useState(false);
  const [checkColor1, setCheckColor1] = useState(false);
  const [checkColor2, setCheckColor2] = useState(false);
  const [scroll, setScroll] = useState("paper");
  //const [value, setValue] = useState();
  const [partNumber, setPartNumber] = useState();
  const [modal, setModal] = useState(false);
  const [modalValueLarghezza, setModalValueLarghezza] = useState();
  const [modalValueAltezza, setModalValueAltezza] = useState();
  const [modalOptions, setModalOptions] = useState({
    show: false,
    options: null,
    selectedCrossbeam: null,
    selectedPillars: null,
    state: "idle",
    error: null
  });
  const [dataModal, setDataModal] = useState();
  const [errorVincolo, setErrorVincolo] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [colorEsterno, setColorEsterno] = useState(false);
  const [modelloCheckModal, setModelloCheckModal] = useState(false);
  const [finituraCheckModal, setFinituraCheckModal] = useState(false);
  const [errorModal, setErrorModal] = useState("");
  //const [getAspetto, setGetAspetto] = useState();
  const [warning, setWarning] = useState("");
  const dispatch = useDispatch();
  const state = useSelector(state => state.base);
  const baseActions = importBaseActions();
  const eventBroadcast = useEventBroadcast();
  const aspettoService = useAspettoService();
  //const [switchDebordante, setSwitchDebordante] = useState(state.isDebordante);
  //const [switchPorticina, setSwitchPorticina] = useState(state.isPorticina);

  //new
  const [stepState, setStepState] = useState({
    state: "loading",
    steps: [],
    aspetto: null,
    installazioni: null,    
    porte: null,
    modelli: null,
    tipiFinitura: null,
    finitureEsterne: null,
    finitureInterne: null,
    currentStep: null,
    finituraEsterna: null,
    finituraInterna: null,
    customRal: "",
    error: null
  });

  const location = useLocation();

  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + state.user.token,
    },
  };

  const load = async () => {
    let steps = [];
    let currentStep = null;
    try {
      let res = await axios.post(`${process.env.REACT_APP_API_URL}/categories-steps`, { id: state.idPorta, quote_id: state.quoteId }, axiosConfig);
      console.log(res);
      if (res.data && res.data.data) {
        steps = res.data.data;
        currentStep = findCategoryStep(steps);
        console.log(currentStep);
      }
    } catch (e) {

    }

    try {
      let aspetto = null;
      let installazioni = [];
      let porte = [];

      let res = await axios.post(`${process.env.REACT_APP_API_URL}/get-aspetto`, { id: state.quoteId }, axiosConfig);
      if (res.data && res.data.data) {
        aspetto = res.data.data;

        formik.setFieldValue("installazioneTraversa", aspetto.crossbeam_installation_id ?? "");
        formik.setFieldValue("installazioneMontanteSx", aspetto.sx_pillar_installation_id ?? "");
        formik.setFieldValue("installazioneMontanteDx", aspetto.dx_pillar_installation_id ?? "");
        formik.setFieldValue("porta", aspetto.door_id ?? "");
        formik.setFieldValue("modello", aspetto.model_id ?? "");
        formik.setFieldValue("finitura", aspetto.finish_id ?? "");
        formik.setFieldValue("color", aspetto.color_id ?? "");
        formik.setFieldValue("color2", aspetto.color_id_esterni ?? "");
        formik.setFieldValue("colorName", aspetto.name1 ?? "");
        formik.setFieldValue("colorName2", aspetto.name2 ?? "");
        formik.setFieldValue("colorImg", aspetto.img1 ?? "");
        formik.setFieldValue("colorImg2", aspetto.img2 ?? "");
        formik.setFieldValue("elementiCheckbox", aspetto.elements ?? "");
        formik.setFieldValue("customRal", aspetto.custom_ral_fullglass ?? "");

        dispatch(baseActions.init({
          idInstallazioneTraversa: aspetto.crossbeam_installation_id ?? "",
          idInstallazioneMontanteSx: aspetto.sx_pillar_installation_id ?? "",
          idInstallazioneMontanteDx: aspetto.dx_pillar_installation_id ?? "",
          idTipoPorta: aspetto.door_id ?? "",
          idModel: aspetto.model_id ?? "",
          idTipoFinitura: aspetto.finish_id ?? "",
          idEsterni: aspetto.color_id_esterni ?? ""
        }));
      }

      res = await axios.post(`${process.env.REACT_APP_API_URL}/options`, { id: state.idPorta, porta: null }, axiosConfig);
      if (res.data && res.data.data) {
        installazioni = res.data.data;
      }

      res = await axios.post(`${process.env.REACT_APP_API_URL}/options`, { id: state.idPorta, porta: 8 }, axiosConfig);
      if (res.data && res.data.data) {
        porte = res.data.data;
      }

      let modelli = null;
      if (aspetto && aspetto.door_id) {
        try {
          let res = await axios.post(`${process.env.REACT_APP_API_URL}/options`, { id: state.idPorta, porta: aspetto.door_id }, axiosConfig);
          if (res.data && res.data.data) {
            modelli = res.data.data;
          }
        } catch (e) {

        }
      }

      let tipiFinitura = null;
      if (aspetto && aspetto.model_id) {
        try {
          let res = await axios.post(`${process.env.REACT_APP_API_URL}/options`, { id: state.idPorta, porta: aspetto.model_id }, axiosConfig);
          if (res.data && res.data.data) {
            tipiFinitura = res.data.data;
          }
        } catch (e) {

        }
      }

      let finitureEsterne = null;
      let finitureInterne = null;
      if (aspetto && aspetto.finish_id) {
        try {
          let res = await axios.post(`${process.env.REACT_APP_API_URL}/colors`, { category: state.idPorta, option_id: aspetto.finish_id, parent_id: aspetto.model_id, color: 0, }, axiosConfig);
          if (res.data && res.data.data) {
            finitureEsterne = res.data.data;
          }
        } catch (e) {

        }

        try {
          let res = await axios.post(`${process.env.REACT_APP_API_URL}/colors`, { category: state.idPorta, option_id: aspetto.finish_id, parent_id: aspetto.model_id, color: 1, }, axiosConfig);
          if (res.data && res.data.data) {
            finitureInterne = res.data.data;
          }
        } catch (e) {

        }
      }

      let finituraEsterna = null;
      if (aspetto && aspetto.colorName2) {
        finituraEsterna = {
          color2: aspetto.color_id_esterni ?? "",
          colorName2: aspetto.name2 ?? "",
          colorImg2: aspetto.img2 ?? ""
        }
      }

      let finituraInterna = null;
      if (aspetto && aspetto.colorName) {
        finituraInterna = {
          color: aspetto.color_id ?? "",
          colorName: aspetto.name ?? "",
          colorImg: aspetto.img ?? ""
        }
      }

      if (aspetto.crossbeam_accessory_id || aspetto.pillars_accessory_id) {
        setModalOptions({...modalOptions, selectedCrossbeam: aspetto.crossbeam_accessory_id, selectedPillars: aspetto.pillars_accessory_id});
      }

      setStepState({
        state: "idle",
        steps: steps,
        aspetto: aspetto,
        installazioni: installazioni,
        porte: porte,
        modelli: modelli,
        tipiFinitura: tipiFinitura,
        finitureEsterne: finitureEsterne,
        finitureInterne: finitureInterne,
        currentStep: currentStep,
        finituraInterna,
        finituraEsterna
      });
    } catch (e) {
      setStepState({ state: "error", error: e });
    }
  }

  useEffect(() => {
    load();
  }, []);

  const setInstallazioneTraversa = (e) => {
    setImgLeft(e.target.value);
    formik.setFieldValue("installazioneTraversa", e.target && e.target.value);
    dispatch(baseActions.setIdInstallazioneTraversa(e.target && e.target.value));

    eventBroadcast.dispatch(Events.configurationChanged, { ...state, idInstallazioneTraversa: e.target && e.target.value });
  }

  const setInstallazioneMontanteDx = (e) => {
    setImgLeft(e.target.value);
    formik.setFieldValue("installazioneMontanteDx", e.target && e.target.value);
    dispatch(baseActions.setIdInstallazioneMontanteDx(e.target && e.target.value));

    eventBroadcast.dispatch(Events.configurationChanged, { ...state, idInstallazioneMontanteDx: e.target && e.target.value });
  }

  const setInstallazioneMontanteSx = (e) => {
    setImgLeft(e.target.value);
    formik.setFieldValue("installazioneMontanteSx", e.target && e.target.value);
    dispatch(baseActions.setIdInstallazioneMontanteSx(e.target && e.target.value));

    eventBroadcast.dispatch(Events.configurationChanged, { ...state, idInstallazioneMontanteSx: e.target && e.target.value });
  }

  const setPorta = async (e) => {
    if (!e.target) return;

    formik.setFieldValue("porta", e.target.value);
    dispatch(baseActions.setIdTipoPorta(e.target.value));
    eventBroadcast.dispatch(Events.configurationChanged, {
      ...state,
      idTipoPorta: e.target.value,
      idModel: "",
      idTipoFinitura: "",
      idEsterni: ""
    });

    try {
      let res = await axios.post(`${process.env.REACT_APP_API_URL}/options`, { id: state.idPorta, porta: e.target.value }, axiosConfig);
      if (res.data) {
        formik.setFieldValue("modello", "");
        formik.setFieldValue("finitura", "");
        formik.setFieldValue("color", "");
        formik.setFieldValue("color2", "");
        formik.setFieldValue("colorName", "");
        formik.setFieldValue("colorName2", "");
        formik.setFieldValue("colorImg", "");
        formik.setFieldValue("colorImg2", "");
        setStepState({ ...stepState, modelli: res.data.data, finitureEsterne: null, finitureInterne: null, tipiFinitura: null });
      }
    } catch (e) {
      alert("Error");
    }
  }

  const setModello = async (idModello) => {
    formik.setFieldValue("modello", idModello);
    dispatch(baseActions.setIdModel(idModello));
    eventBroadcast.dispatch(Events.configurationChanged, {
      ...state,
      idModel: idModello,
      idTipoFinitura: "",
      idEsterni: ""
    });

    if (idModello === 9 || idModello === 10) {
      setModelloCheckModal(true);
    } else {
      setModelloCheckModal(false);
    }

    try {
      let res = await axios.post(`${process.env.REACT_APP_API_URL}/options`, { id: state.idPorta, porta: idModello }, axiosConfig);
      if (res.data) {
        if (res.data.data && res.data.data.length == 1) {
          setTipoFinitura(res.data.data[0].id, idModello, res.data.data);
        } else {
          formik.setFieldValue("finitura", "");
          formik.setFieldValue("color", "");
          formik.setFieldValue("color2", "");
          formik.setFieldValue("colorName", "");
          formik.setFieldValue("colorName2", "");
          formik.setFieldValue("colorImg", "");
          formik.setFieldValue("colorImg2", "");
          setStepState({ ...stepState, tipiFinitura: res.data.data, finitureEsterne: null, finitureInterne: null });
        }
      }
    } catch (e) {
      alert("Error");
    }
  }

  const setTipoFinitura = async (idTipoFinitura, idModello, tipiFinitura) => {
    formik.setFieldValue("finitura", idTipoFinitura);
    dispatch(baseActions.setIdTipoFinitura(idTipoFinitura));
    eventBroadcast.dispatch(Events.configurationChanged, {
      ...state,
      idTipoFinitura: idTipoFinitura,
      idEsterni: ""
    });

    if (idTipoFinitura === 15) {
      setFinituraCheckModal(true);
    } else {
      setFinituraCheckModal(false);
    }

    let finitureEsterne = [];
    let finitureInterne = [];
    try {
      let res = await axios.post(`${process.env.REACT_APP_API_URL}/colors`, { category: state.idPorta, option_id: idTipoFinitura, parent_id: idModello ?? formik.values.modello, color: 0, }, axiosConfig);
      if (res.data && res.data.data) {
        finitureEsterne = res.data.data;
      }

      res = await axios.post(`${process.env.REACT_APP_API_URL}/colors`, { category: state.idPorta, option_id: idTipoFinitura, parent_id: idModello ?? formik.values.modello, color: 1, }, axiosConfig);
      if (res.data && res.data.data) {
        finitureInterne = res.data.data;
      }
    } catch (e) {

    }

    formik.setFieldValue("color", "");
    formik.setFieldValue("color2", "");
    formik.setFieldValue("colorName", "");
    formik.setFieldValue("colorName2", "");
    formik.setFieldValue("colorImg", "");
    formik.setFieldValue("colorImg2", "");

    let currentStepState = {
      ...stepState,
      finitureEsterne,
      finitureInterne
    };

    if (tipiFinitura) currentStepState.tipiFinitura = tipiFinitura;

    setStepState(currentStepState);
  }

  const setFinituraEsterna = ({ id, name, img }) => {
    debugger
    formik.setFieldValue("color2", id ?? "");
    formik.setFieldValue("colorName2", name ?? "");
    formik.setFieldValue("colorImg2", img ?? "");

    dispatch(baseActions.setIdEsterni(id));
    eventBroadcast.dispatch(Events.configurationChanged, { ...state, idEsterni: id, campioneEsterno: !id, externalNsRal: "" });

    setStepState({
      ...stepState, finituraEsterna: {
        color2: id ?? "",
        colorName2: name ?? "",
        colorImg2: img ?? ""
      }
    });
  }

  const setExternalColorNsRal = (color) => {
    formik.setFieldValue("color2", "");
    formik.setFieldValue("colorName2", color ? `${color.code} - ${color.name}` : "");
    formik.setFieldValue("colorImg2", "");

    dispatch(baseActions.setExternalNsRal(color?.code));
    eventBroadcast.dispatch(Events.configurationChanged, { ...state, idEsterni: null, campioneEsterno: false, externalNsRal: color?.code });

    setStepState({
      ...stepState, finituraEsterna: {
        color2: "",
        colorName2: color ? `${color.code} - ${color.name}` : "",
        colorImg2: ""
      }
    });
  }

  const setInternalColorNsRal = (color) => {
    formik.setFieldValue("color", "");
    formik.setFieldValue("colorName", color ? `${color.code} - ${color.name}` : "");
    formik.setFieldValue("colorImg", "");

    dispatch(baseActions.setInternalNsRal(color?.code));

    setStepState({
      ...stepState, finituraInterna: {
        color1: "",
        colorName1: color ? `${color.code} - ${color.name}` : "",
        colorImg1: ""
      }
    });
  }

  const setFinituraInterna = ({ id, name, img }) => {
    formik.setFieldValue("color", id ?? "");
    formik.setFieldValue("colorName", name ?? "");
    formik.setFieldValue("colorImg", img ?? "");

    setStepState({
      ...stepState, finituraInterna: {
        color: id ?? "",
        colorName: name ?? "",
        colorImg: img ?? ""
      }
    });
  }

  const selectFinituraEsterna = () => {
    setColorEsterno(true);
    setOpenEsterni(true);
    setCheckColor1(false);
    setCheckColor2(true);
  }

  const findCategoryStep = (categoriesSteps) => {
    return categoriesSteps.find(cs => `/${cs.slug}` === location.pathname);
  };

  const getColorImg2 = () => {
    return formik.values.colorImg2 ? formik.values.colorImg2 : box;

    // if (!stepState.finitureEsterne) return;

    // let col = stepState.finitureEsterne.find(f => f.id == color2);
    // if (col) {
    //   return col.img;
    // }
    // return box;
  }

  const setCustomRal = value => {
    formik.setFieldValue("customRal", value ?? "");
    setStepState({...stepState, customRal: value});
  }

  const getColorImg = () => {
    return formik.values.colorImg ? formik.values.colorImg : box;

    // if (!stepState.finitureInterne) return;

    // let col = stepState.finitureInterne.find(f => f.id == color1);
    // if (col) {
    //   return col.img;
    // }
    // return box;
  }

  const hideCustomRalFinituraEsterna = () => {
    if (!state.idModel || state.idModel <= 0) return true;
    if (!state.idTipoFinitura || state.idTipoFinitura <= 0) return true;
    if (!stepState.modelli) return;
    if (!stepState.tipiFinitura) return;

    let model = stepState.modelli.find(m => m.id == state.idModel);
    if (!model) return true;

    let finitura = stepState.tipiFinitura.find(tf => tf.id == state.idTipoFinitura);
    if (!finitura) return true;

    if (OptionsTypeEnum.PLANA_FG_CODE == model.value) return true;
    if ((OptionsTypeEnum.PLANA40_CODE == model.value || OptionsTypeEnum.PLANA75_CODE == model.value) && OptionsTypeEnum.LAMIERA810_CODE != finitura.value) return true;
    if ((OptionsTypeEnum.AIR_CODE == model.value || OptionsTypeEnum.WIDE_CODE == model.value) && OptionsTypeEnum.WOODSTYLE_CODE == finitura.value) return true;
    if ((OptionsTypeEnum.AIR_OVERBAS_CODE == model.value || OptionsTypeEnum.WIDE_OVERBAS_CODE == model.value) && OptionsTypeEnum.WOODSTYLE_CODE == finitura.value) return true;

    return false;
  }

  const handleChange = () => {
    //data && data.categoriesSteps.map((el) => `/${el.slug}` === location.pathname ? setValue(el.id) : null);
  };

  const navigateToNextStep = () => {
    let nextStep = null;
    let stepsIds = stepState.steps.map(s => s.position);
    let maxStepId = Math.max(...stepsIds);

    for (let stepId = stepState.currentStep.position + 1; stepId <= maxStepId; stepId++) {
      let step = stepState.steps.find(s => s.position == stepId);
      if (!step) continue;
      if (state.fullglass && (step.slug == "richiesta" || step.slug == "finestrature" || step.position == "ventilazione")) continue;
      if (state.idPorta == 3) {
        if (state.isPorticina && step.slug == "maniglierie") continue;
        if (!state.isPorticina && step.slug == "finestrature") {
          navigate(`/maniglierie`, {
            id: state.idPorta,
          });
          return;
        }
      }

      nextStep = step;
      if (nextStep) break;
    }
    if (!nextStep) return;

    navigate(`/${nextStep.slug}`, {
      id: state.idPorta,
    });
  }

  const saveSector = () => {
    if (dataModal.altezza.length > 1 && !modalValueAltezza) {
      setErrorModal("Per continuare è necessario selezionare un'altezza tra quelle proposte");
      return;
    }

    if (dataModal.lunghezza.length > 1 && !modalValueLarghezza) {
      setErrorModal("Per continuare è necessario selezionare una larghezza tra quelle proposte");
      return;
    }

    setLoadingModal(true);
    axios.post(`${process.env.REACT_APP_API_URL}/save-sector`, {
      nameAltezza:
        dataModal.altezza.length === 1
          ? dataModal.altezza[0].name
          : modalValueAltezza,
      nameLarghezza:
        dataModal.lunghezza.length === 1
          ? dataModal.lunghezza[0].name
          : modalValueLarghezza,
      category_id: state.idPorta,
      id: state.quoteId,
    }, axiosConfig).then((res) => {
      if (res.data.data === "Warning") {
        setWarning(res.data.message);
        setModal(false);
        setDataModal(null);
        setLoadingModal(false);
        //formik.setFieldValue("installazione", 7);
        setLoading(false);
        return;
      }
      if (res.data.success === 1) {
        setModal(false);
        setLoadingModal(false);
        dispatch(baseActions.setH(res.data.data.height_calculate));
        dispatch(baseActions.setL(res.data.data.width_calculate));
        dispatch(baseActions.setAspetto(true));
        if (res.data.message != "modal") {
          navigateToNextStep();
        } else {
          let crossbeam = res.data.data.crossbeam ? res.data.data.crossbeam.find(i => i.selected).item.id : null;
          let pillars = res.data.data.pillars ? res.data.data.pillars.find(i => i.selected).item.id : null;
          setModalOptions({
            show: true, 
            options: res.data.data, 
            selectedCrossbeam: crossbeam, 
            selectedPillars: pillars
          });
        }
      } else {
        //setModal(false);
        setLoadingModal(false);
        setErrorModal(res.data.message);
      }
    })
      .catch(function (e) {
        setLoadingModal(false);
        console.log(e);
      });
  }

  const setInstallationAccessories = async () => {
    if (!modalOptions.selectedCrossbeam && !modalOptions.selectedPillars) {
      setModalOptions({...modalOptions, state: "error", error: "Nessuna opzione selezionata"});
      return;
    }

    setModalOptions({...modalOptions, state: "save", error: null});
    let res = await aspettoService.saveInstallationAccessories(state.user.token, {
      quoteId: state.quoteId,
      accessorioTraversa: modalOptions.selectedCrossbeam, 
      accessorioMontanti: modalOptions.selectedPillars,
    });
    
    navigateToNextStep();
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      isDebordante: false,
      withDoor: false,
      installazioneTraversa: "",
      installazioneMontanteDx: "",
      installazioneMontanteSx: "",
      porta: "", //data && data.aspetto ? data.aspetto.door_id : "",
      modello: "", //data && data.aspetto ? data.aspetto.model_id : "",
      finitura: "", //data && data.aspetto ? data.aspetto.finish_id : "",
      color: "", //data && data.aspetto ? data.aspetto.color_id : "",
      color2: "", //data && data.aspetto ? data.aspetto.color_id_esterni : "",
      colorName: "", //data && data.aspetto ? data.aspetto.name1 : "",
      colorName2: "", //data && data.aspetto ? data.aspetto.name2 : "",
      colorImg: "", //data && data.aspetto ? data.aspetto.img1 : "",
      colorImg2: "", //data && data.aspetto ? data.aspetto.img2 : "",
      customRal: "",
      elementiCheckbox: 0,//data && data.aspetto ? data.aspetto.elements : 0,
    },
    // validationSchema: validationSchema,
    onSubmit: (values) => {
      if (state.idModel == OptionsTypeEnum.PLANAFULLGLASS && state.fullglass && !values.customRal && (
          state.idInstallazioneMontanteDx == OptionsTypeEnum.INLUCE ||
          state.idInstallazioneMontanteSx == OptionsTypeEnum.INLUCE ||
          state.idInstallazioneTraversa == OptionsTypeEnum.INLUCE)) {
        setWarning("Specificare un RAL a scelta");
        return;
      }

      if (!values.color2 && values.colorName2 != "Colore a campione" && !state.externalNsRal) {
        setWarning("Selezionare il colore esterno");
        return;
      }
      
      setLoading(true);

      if (state.fullglass) {
        dispatch(baseActions.setRadioF(null));
        dispatch(baseActions.setQtaF(0));
        dispatch(baseActions.setRadioV(null));
        dispatch(baseActions.setQtaV(0));
        dispatch(baseActions.setRadioM(null));
        dispatch(baseActions.setIdVerniciaturaManiglia(null));
      }

      let customRal = values.customRal;
      if (state.idInstallazioneMontanteDx != OptionsTypeEnum.INLUCE && 
          state.idInstallazioneMontanteSx != OptionsTypeEnum.INLUCE && 
          state.idInstallazioneTraversa != OptionsTypeEnum.INLUCE) {
          customRal = "";
      }

      debugger

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/aspetto`,
          {
            id: state.quoteId,
            category_id: state.idPorta,
            isDebordante: state.isDebordante,
            withDoor: state.isPorticina,
            installazioneTraversa: values.installazioneTraversa,
            installazioneMontanteDx: values.installazioneMontanteDx,
            installazioneMontanteSx: values.installazioneMontanteSx,
            porta: values.porta,
            modello: values.modello,
            finitura: values.finitura,
            color_id: values.color,
            color_id_esterni: values.color2,
            color: values.color,
            campioneEsterno: state.campioneEsterno,
            campioneInterno: state.campioneInterno,
            externalNsRal: state.externalNsRal,
            internalNsRal: state.internalNsRal,
            elementiCheckbox: state.elementiCheckbox,
            customRal: customRal
          },
          axiosConfig
        )
        .then((res) => {
          if (res.data.error === 1) {
            setErrorVincolo(res.data.message);
            setLoading(false);
          }
          if (res.data.message === "modal") {
            if (res.data.data.lunghezza && res.data.data.lunghezza.length > 0 && res.data.data.altezza && res.data.data.altezza.length > 0) {
              setModal(true);
              setDataModal(res.data.data);
              setLoading(false);
            } else {
              setErrorVincolo("Siamo spiacenti, non è possibile determinare le dimensioni della porta. Verificare le misure inserite.");
              setLoading(false);
            }
            return;
          }
          if (res.data.data === "Warning") {
            setWarning(res.data.message);
            //formik.setFieldValue("installazione", 7);
            setLoading(false);
            return;
          }
          if (res.data.success === 1 && res.data.message !== "Warning") {
            setLoading(false);
            dispatch(baseActions.setAspetto(true));
            dispatch(baseActions.setAltezza(res.data.data.height_calculate));
            dispatch(baseActions.setLarghezza(res.data.data.width_calculate));
            navigateToNextStep();
          }
        })
        .catch(function (error) {
          console.log(error);
          setLoading(false);
        });
    },
  });

  const [imgLeft, setImgLeft] = useState();

  if ("loading" == stepState.state) return <Loading />;

  if ("error" == stepState.state) {
    console.log("aspetto");
    return <div>failed to load</div>;
  }

  return (
    <LayoutPage>
      <div style={{ flex: "0 0 auto" }}>
        <TopBar />
        
        <div className={"d-flex justify-content-between p-3"}>
            <div className="d-flex flex-column" style={{ lineHeight: "1" }}>
                <h2 className="h2 fw-bold">
                    Nuova configurazione {getProductName(state.idPorta)} (#{state.quoteId})
                </h2>
            </div>
            <div>
                {/* <Button variant={deleteClickCount == 0 ? "outlined" : "contained"} color="error" className="me-3 px-2">
                    <RemoveCircleOutlineOutlinedIcon />
                    <span className="ms-2">{deleteClickCount == 0 ? "ELIMINA" : "CONFERMA"}</span>
                </Button> */}

                <Button variant="contained" color="secondary" onClick={() => navigate("/configurations")}>
                    <CloseIcon />
                    <span className="ms-2">CHIUDI</span>
                </Button>
            </div>
        </div>

        <Grid
          className={classes.root}
          container
          sx={{ justifyContent: "center" }}>
          <TabsCustom
            value={stepState.currentStep.id}
            setValue={val => setStepState({ ...stepState, currentStep: val })}
            id={state.idPorta}
            handleChange={handleChange}
            axios_conf={axiosConfig}
            url={`${process.env.REACT_APP_API_URL}/categories-steps`}
          />
        </Grid>
      </div>

      <div style={{ flex: "1 1 auto", minHeight: "0" }}>
        {stepState.aspetto && <GridPreventivi
            dataSend={{
              quote_id: state.quoteId,
              category_id: state.idPorta,
              crossbeam_installation_id: formik.values.installazioneTraversa,
              dx_pillar_installation_id: formik.values.installazioneMontanteDx,
              sx_pillar_installation_id: formik.values.installazioneMontanteSx,
              door_id: formik.values.porta,
              model_id: state.idModel !== 0 ? state.idModel : stepState.aspetto.model_id,
              finish_id: formik.values.finitura,
              color_id_esterni: state.idEsterni,
              slug: location.pathname.substring(1),
            }}
            dipendenze={stepState.finituraEsterna?.color2}
            axiosConfig={axiosConfig}
            url={`${process.env.REACT_APP_API_URL}/photo-preventivi`}>
            <form onSubmit={formik.handleSubmit}>
              <div>
                <h5 className="text-primary">Gamma e finitura</h5>

                {state.idPorta == 3 && <>
                  <FormControl fullWidth className="mt-3">
                    <InputLabel id="debordante">Porta debordante</InputLabel>
                    <Select
                      labelId="debordante"
                      value={formik.values.isDebordante ? "true" : "false"}
                      label="Porta debordante"
                      onChange={(e) => {                        
                          dispatch(baseActions.setIsDebordante(e.target.value == "true"));
                          formik.setFieldValue("isDebordante", e.target.value == "true");
                      }}>
                      <MenuItem value="true">SI</MenuItem>
                      <MenuItem value="false">NO</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl fullWidth className="mt-3">
                    <InputLabel id="portina_integrata">Portina integrata</InputLabel>
                    <Select
                      labelId="portina_integrata"
                      value={formik.values.withDoor ? "true" : "false"}
                      label="Portina integrata"
                      onChange={(e) => {
                        dispatch(baseActions.setIsPorticina(e.target.value == "true"));
                        formik.setFieldValue("withDoor", e.target.value == "true");
                      }}>
                      <MenuItem value="true">SI</MenuItem>
                      <MenuItem value="false">NO</MenuItem>
                    </Select>
                  </FormControl>
                </>}
                
                <FormControl fullWidth className="mt-3">
                  <InputLabel id="tipoInstallazioneTraversa">Installazione traversa</InputLabel>
                  <Select
                      labelId="tipoInstallazioneTraversa"
                      value={formik.values.installazioneTraversa}
                      label="Installazione traversa"
                      onChange={(e) => {
                        setInstallazioneTraversa(e)
                      }}>
                      <MenuItem value={InstallationType.in_luce}>In Luce</MenuItem>
                      <MenuItem value={InstallationType.oltre_luce}>Oltre Luce</MenuItem>
                  </Select>
                </FormControl>
                            
                <FormControl fullWidth className="mt-3">
                    <InputLabel id="tipoInstallazioneMontanteSx">Installazione montante SX</InputLabel>
                    <Select
                        labelId="tipoInstallazioneMontanteSx"
                        value={formik.values.installazioneMontanteSx}
                        label="Installazione montante SX"
                        onChange={(e) => {
                          setInstallazioneMontanteSx(e)
                        }}>
                        <MenuItem value={InstallationType.in_luce}>In Luce</MenuItem>
                        <MenuItem value={InstallationType.oltre_luce}>Oltre Luce</MenuItem>
                    </Select>
                </FormControl>
                
                <FormControl fullWidth className="mt-3">
                    <InputLabel id="tipoInstallazioneMontanteDx">Installazione montante DX</InputLabel>
                    <Select
                        labelId="tipoInstallazioneMontanteDx"
                        value={formik.values.installazioneMontanteDx}
                        label="Installazione montante DX"
                        onChange={(e) => {
                          setInstallazioneMontanteDx(e);
                        }}>
                        <MenuItem value={InstallationType.in_luce}>In Luce</MenuItem>
                        <MenuItem value={InstallationType.oltre_luce}>Oltre Luce</MenuItem>
                    </Select>
                </FormControl> 

                <FormControl fullWidth className="mt-3">
                    <InputLabel id="porta">Gamma</InputLabel>
                    <Select
                        labelId="porta"
                        value={formik.values.porta}
                        label="Gamma"
                        onChange={(e) => setPorta(e)}
                        error={formik.touched.porta && Boolean(formik.errors.porta)}
                        helperText={formik.touched.porta && formik.errors.porta}>
                        {stepState.porte && stepState.porte.filter(res => res.type == "PORTA").map((res, index) => {
                          return <MenuItem key={index} value={res.id}>{res.name}</MenuItem>;
                        })}
                    </Select>
                </FormControl>

                {stepState.modelli && <FormControl fullWidth className="mt-3">
                  <InputLabel id="modello">Modello</InputLabel>
                  <Select
                      labelId="modello"
                      value={formik.values.modello}
                      label="Modello"
                      onChange={(e) => setModello(Number(e.target.value))}
                      error={
                        formik.touched.modello && Boolean(formik.errors.modello)
                      }
                      helperText={formik.touched.modello && formik.errors.modello}>
                      {stepState.modelli.filter(res => res.type == "MODELLO").map((res, index) => <MenuItem
                        onClick={() => {
                          setPartNumber(res.value);
                        }}
                        key={index}
                        value={res.id}>
                          {res.name}
                        </MenuItem>
                      )};
                  </Select>
                </FormControl>}

                {stepState.tipiFinitura && stepState.tipiFinitura.length > 1 && <FormControl fullWidth className="mt-3">
                  <InputLabel id="finitura">Finitura</InputLabel>
                  <Select
                      labelId="finitura"
                      value={formik.values.finitura}
                      label="Finitura"
                      onChange={(e) => setTipoFinitura(Number(e.target.value))}
                      error={
                        formik.touched.finitura &&
                        Boolean(formik.errors.finitura)
                      }
                      helperText={
                        formik.touched.finitura && formik.errors.finitura
                      }>
                      {stepState.tipiFinitura.filter(res => res.type == "FINITURA").map((res, index) => <MenuItem
                          onClick={() => {
                            res.id === OptionsTypeEnum.FULLGLASS_VETRO_CAMERA || res.id === OptionsTypeEnum.FULLGLASS_VETRO_LAMINATO
                              ? dispatch(baseActions.setFullglass(true))
                              : dispatch(baseActions.setFullglass(false));
                            setPartNumber(res.value);
                          }}
                          key={index}
                          value={res.id}>
                          {res.name}
                        </MenuItem>
                      )}
                  </Select>
                </FormControl>}

                {stepState.finitureEsterne && <TextField
                  fullWidth 
                  focused={false}
                  className="mt-3"
                  label={"Colore esterno*"}
                  name="coloreEsterno"
                  value={formik.values.colorName2}
                  InputProps={{
                    readOnly: true, 
                    startAdornment: stepState.finituraEsterna && formik.values.color2 && (
                        <InputAdornment position="start">
                            <img src={getColorImg2()} style={{width: "2rem", height: "1.75rem"}} />
                        </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                          <IconButton onClick={() => selectFinituraEsterna()}>
                              <OpenInNewIcon style={{ color: "black" }} />
                          </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />}

                {stepState.finitureInterne && stepState.finitureInterne.length > 0 && <TextField
                  fullWidth 
                  focused={false}
                  className="mt-3"
                  label={"Colore interno"}
                  name={"colorName"}
                  value={formik.values.colorName}
                  InputProps={{
                    readOnly: true, 
                    startAdornment: stepState.finituraInterna && formik.values.color && (
                        <InputAdornment position="start">
                            <img src={getColorImg()} style={{width: "2rem", height: "1.75rem"}} />
                        </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                          <IconButton onClick={() => {
                            setColorEsterno(false);
                            setOpenInterni(true);
                            setCheckColor2(false);
                            setCheckColor1(true);
                          }}>
                              <OpenInNewIcon style={{ color: "black" }} />
                          </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />}
                
                {state.idModel == OptionsTypeEnum.PLANAFULLGLASS && state.fullglass && (
                state.idInstallazioneMontanteDx == OptionsTypeEnum.INLUCE ||
                state.idInstallazioneMontanteSx == OptionsTypeEnum.INLUCE ||
                 state.idInstallazioneTraversa == OptionsTypeEnum.INLUCE) && <TextField
                    fullWidth 
                    focused={false}
                    className="mt-3"
                    placeholder={"es. 8016"}
                    variant="outlined"
                    label={"Colore rivestimenti: specificare RAL*"}
                    name={"customRal"}
                    value={formik.values.customRal}
                    onChange={(e) => setCustomRal(e.target.value)}
                  />}
            </div>

            <div className="d-flex flex-row-reverse justify-content-between mt-3">
              <Button disabled={loading} variant="contained" className="ps-3 pe-1" type="submit">
                <span>Avanti</span>
                <NavigateNextIcon />
              </Button>

              <Button variant="contained" className="ps-1 pe-3" color="secondary" onClick={() => {
                  stepState.steps.map((el, index) => {
                    `/${el.slug}` === location.pathname &&
                      navigate(
                        `/${stepState.steps[index - 1] && stepState.steps[index - 1].slug}`,
                        {
                          id: state.idPorta,
                        }
                      );
                  });
                }}>
                <NavigateBeforeIcon />
                <span>Indietro</span>
              </Button>
            </div>
          </form>
        </GridPreventivi>}
      </div>

      <DialogCustom
        title={"Conferma misura"}
        open={modal}
        handleClose={() => saveSector()}
        handleCancel={() => setModal(false)}
        loadingModal={modalOptions.state == "save"}
        content={
          <>
            <RadioItemDimensioni
              modalValueLarghezza={modalValueLarghezza}
              setModalValueLarghezza={setModalValueLarghezza}
              modalValueAltezza={modalValueAltezza}
              setModalValueAltezza={setModalValueAltezza}
              data={dataModal}
              count={state}
            />

            {(errorModal !== "") && (
              <Alert severity={"error"}>{errorModal}</Alert>
            )}
          </>
        }
      />

      <DialogCustom
        title={"Seleziona un opzione"}
        open={modalOptions.show}
        handleClose={setInstallationAccessories}
        handleCancel={() => setModalOptions({...modalOptions, show: false})}
        //loadingModal={loadingModal}
        content={
          <>
            {modalOptions.options && (
              <DialogOptions 
                options={modalOptions.options} 
                selectedCrossbeam={modalOptions.selectedCrossbeam}
                selectedPillars={modalOptions.selectedPillars}
                onSetCrossbeam={value => setModalOptions({...modalOptions, selectedCrossbeam: value})}
                onSetPillars={value => setModalOptions({...modalOptions, selectedPillars: value})}
              />
            )}

            {"error" == modalOptions.state && (
              <Alert sx={{marginTop: "1rem"}} severity={"error"}>{modalOptions.error}</Alert>
            )}
          </>
        }
      />

      {(errorVincolo !== "") && <div className="d-flex justify-content-center py-3" style={{position: "fixed", left: "0", bottom: "0", width: "100%"}}>
        <Alert variant="filled" severity="warning" sx={{zIndex: "2000"}} onClose={() => setErrorVincolo("")}>
          <AlertTitle>Attenzione...</AlertTitle>
          {errorVincolo}
        </Alert>
      </div>}

      {warning !== "" && <div className="d-flex justify-content-center py-3" style={{position: "fixed", left: "0", bottom: "0", width: "100%"}}>
        <Alert variant="filled" severity="warning" sx={{zIndex: "2000"}} onClose={() => setWarning("")}>
          <AlertTitle>Attenzione...</AlertTitle>
            {warning}
        </Alert>
      </div>}

      {stepState.aspetto &&
        <DialogScroll
          getAspetto={stepState.aspetto}
          modelloCheckModal={modelloCheckModal}
          finituraCheckModal={finituraCheckModal}
          open={openEsterni}
          setOpen={setOpenEsterni}
          scroll={scroll}
          setScroll={setScroll}
          data={stepState.finitureEsterne}
          //setNameColor2={setNameColor2}
          color2={stepState.finituraEsterna?.color2}
          //setColor2={setColor2}
          setFinituraEsterna={setFinituraEsterna}
          setRalNonStandard={setExternalColorNsRal}
          checkColor2={checkColor2}
          //setNameColor={setNameColor}
          color1={stepState.finituraInterna?.color1}
          //setColor1={setColor1}
          setFinituraInterna={setFinituraInterna}
          hideCustomRalFinituraEsterna={hideCustomRalFinituraEsterna()}
          checkColor1={checkColor1}
          quote_id={state.quoteId}
          endpoint={"aspetto"}
          axios_config={axiosConfig}
        />}
      {stepState.finitureInterne &&
        <DialogScroll
          open={openInterni}
          setOpen={setOpenInterni}
          scroll={scroll}
          setScroll={setScroll}
          data={stepState.finitureInterne}
          //setNameColor={setNameColor}
          axios_config={axiosConfig}
          color1={stepState.finituraInterna?.color1}
          endpoint={"aspetto"}
          //setColor1={setColor1}
          setFinituraInterna={setFinituraInterna}
          setRalNonStandard={setInternalColorNsRal}
          quote_id={state.quoteId}
          checkColor1={checkColor1}
          checkColor2={checkColor2}
          //setColor2={setColor2}
          setFinituraEsterna={setFinituraEsterna}
          color2={stepState.finituraEsterna?.color2}
        //setNameColor2={setNameColor2}
        />}
    </LayoutPage>
  );
};

const DialogOptions = ({options, selectedCrossbeam, selectedPillars, onSetCrossbeam, onSetPillars}) => {
  return (
    <>
      {/* TRAVERSA */}
      {options.crossbeam && (
        <Typography
          color={"primary"}
          sx={{
            textAlign: "start",
            fontSize: 20,
          }}>
          <span>Traversa</span>
        </Typography>
      )} 
      {options.crossbeam && (
        <>
          {options.crossbeam.map(o =>              
            <div key={o.item.id} className={"flex cursor-pointer"} style={{marginTop: ".5rem", alignItems: "start"}}>            
              <Radio
                sx={{padding: ".125rem .25rem"}}
                checked={o.item.id == selectedCrossbeam}
                onChange={(evt) => onSetCrossbeam && onSetCrossbeam(evt.target.value)}
                value={o.item.id}
                name={o.item.name}
              />

              <Typography
                sx={{
                  fontSize: 16, 
                  textAlign: "left"
                }}>
                {o.item.name} {o.recommended && <span> - <strong>(Scelta consigliata)</strong></span>}
              </Typography>
            </div>
          )}

          <div key={-1} className={"flex cursor-pointer"} style={{marginTop: ".5rem", alignItems: "start"}}>            
            <Radio
              sx={{padding: ".125rem .25rem"}}
              checked={-1 == selectedCrossbeam}
              onChange={(evt) => onSetCrossbeam && onSetCrossbeam(evt.target.value)}
              value={-1}
              name="NO_CHOICE"
            />

            <Typography
              sx={{
                fontSize: 16, 
                textAlign: "left"
              }}>
              Nessuna delle precedenti opzioni
            </Typography>
          </div>
        </>        
      )}

      {/* MONTANTI */}
      {options.pillars && (
        <Typography
          color={"primary"}
          sx={{
            textAlign: "start",
            fontSize: 20,
            marginTop: ".5rem"
          }}>
          <span>Montanti</span>
        </Typography>
      )} 
      {options.pillars && (
        <>
          {options.pillars.map(o =>              
            <div key={o.item.id} className={"flex cursor-pointer"} style={{marginTop: ".5rem", alignItems: "start"}}>            
              <Radio
                sx={{padding: ".125rem .25rem"}}
                checked={o.item.id == selectedPillars}
                onChange={(evt) => onSetPillars && onSetPillars(evt.target.value)}
                value={o.item.id}
                name={o.item.name}
              />

              <Typography
                sx={{
                  fontSize: 16, 
                  textAlign: "left"
                }}>
                {o.item.name} {o.recommended && <span> - <strong>(Scelta consigliata)</strong></span>}
              </Typography>
            </div>
          )}          

          <div key={-2} className={"flex cursor-pointer"} style={{marginTop: ".5rem", alignItems: "start"}}>            
            <Radio
              sx={{padding: ".125rem .25rem"}}
              checked={-2 == selectedPillars}
              onChange={(evt) => onSetPillars && onSetPillars(evt.target.value)}
              value={-2}
              name={"NO_CHOICE"}
            />

            <Typography
              sx={{
                fontSize: 16, 
                textAlign: "left"
              }}>
              Nessuna delle precedenti opzioni
            </Typography>
          </div>
        </>
      )}
    </>
  );
}

const useStyles = makeStyles({
  root: {
    "& MuiTabs-flexContainer css-heg063-MuiTabs-flexContainer": {
      justifyContent: "center",
    },
  },
});

export default Aspetto;
