import React, { FC } from "react";
import { Group, Line, Rect } from "react-konva";
import { SPESSORE_TELAIO_GRIGLIA } from "../../../../../../../core/common_constants";
import { DatiPannello } from "../../../../../../../core/common_draw_types";
import { PlaceholderRect } from "../draw_utils";
import { IRect } from "konva/lib/types";

export const Griglia: FC<{panelData: DatiPannello, bbox: PlaceholderRect, color: HTMLImageElement|null|undefined, onClick?: (placeholderData: PlaceholderRect) => void}> = (props) => {
    const {panelData, bbox, onClick} = props;

    let rectFrameExt: IRect = {
        x: bbox.x,
        y: (panelData.bbox.height / 2) - (bbox.height / 2),
        width: bbox.width,
        height: bbox.height
    };
    
    let rectFrameInt: IRect = {
        x: bbox.x + SPESSORE_TELAIO_GRIGLIA,
        y: (panelData.bbox.height / 2) - (bbox.height / 2) + SPESSORE_TELAIO_GRIGLIA,
        width: bbox.width - (2*SPESSORE_TELAIO_GRIGLIA),
        height: bbox.height - (2*SPESSORE_TELAIO_GRIGLIA)
    };

    return (
        <Group onClick={() => onClick?.(bbox)} onTap={() => onClick?.(bbox)}>
            <Rect
                {...rectFrameExt}
                stroke={"#000"} 
                strokeWidth={0.5}
                fillPatternImage={props.color ? props.color : undefined}
            />
            <Rect
                {...rectFrameInt} 
                stroke={"#000"} 
                strokeWidth={0.5}
                fillPatternImage={props.color ? props.color : undefined}
            />
            <Group x={rectFrameExt.x + SPESSORE_TELAIO_GRIGLIA} y={(rectFrameExt.y + rectFrameExt.height/2)}>
                <Line
                    points={[0,0,bbox.width-(2*SPESSORE_TELAIO_GRIGLIA),0]}  
                    stroke={"#000"} 
                    strokeWidth={0.75}
                />
                <Line
                    points={[0,-(bbox.height/3)+SPESSORE_TELAIO_GRIGLIA,bbox.width-(2*SPESSORE_TELAIO_GRIGLIA),-(bbox.height/3)+SPESSORE_TELAIO_GRIGLIA]}
                    stroke={"#000"} 
                    strokeWidth={0.75}
                />                    
                <Line
                    points={[0,(bbox.height/3)-SPESSORE_TELAIO_GRIGLIA,bbox.width-(2*SPESSORE_TELAIO_GRIGLIA),(bbox.height/3)-SPESSORE_TELAIO_GRIGLIA]}
                    stroke={"#000"} 
                    strokeWidth={0.75}
                />
            </Group>
        </Group>
    );
}