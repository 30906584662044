import React, { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Arrow, Group, Image, Label, Line, Rect, Tag, Text } from "react-konva";
import { DEFAULT_METRIC_SIZE, PRIMARY_COLOR } from "../../../../../../core/common_constants";
import { Vector2d } from "konva/lib/types";
import { distance, midpoint } from "../../../../../../core/common_functions";
import { useDialogs } from "../../../../../../providers/dialogs_provider";
import { EditorDialogConfirmResult, LengthMeasurementUnits } from "../../../../../../core/common_types";
import useImage from "use-image";
import { LabelConfig } from "konva/lib/shapes/Label";
import { Stage } from "konva/lib/Stage";
import { KonvaImageTypeRef, KonvaLabelTypeRef } from "./draw_types";

export type EditableMeasureProps = {
    ptFrom: Vector2d,
    ptTo: Vector2d,
    prefix: string,
    label: string,
    labelPosition: Vector2d,
    value: number,
    um: LengthMeasurementUnits,
    editTitle: string,
    onConfirm: (value: number) => EditorDialogConfirmResult,
}

export const EditableMeasure: FC<EditableMeasureProps> = (props) => {
    const dialogs = useDialogs();
    const [editIcon] = useImage("/img/icon_edit.png", "anonymous", "origin");    
    const labelRef = useRef<KonvaLabelTypeRef>(null);
    const imageRef = useRef<KonvaImageTypeRef>(null);

    const onMeasureMouseEnter = (stage: Stage|null|undefined) => {
        if (!stage) return;

        stage.container().style.cursor = "pointer";
    }

    const onMeasureMouseLeave = (stage: Stage|null|undefined) => {
        if (!stage) return;

        stage.container().style.cursor = "default";
    }

    useLayoutEffect(() => {
        if (!labelRef.current) return;
        if (!imageRef.current) return;

        let labelWidth = labelRef.current.getWidth();
        let labelHeight = labelRef.current.getHeight();
        imageRef.current.width(labelHeight);
        imageRef.current.height(labelHeight);
        imageRef.current.setPosition({x: labelWidth, y: 0});
    }, []);

    return (
        <Group>
            <Arrow
                points={[props.ptFrom.x, props.ptFrom.y, props.ptTo.x, props.ptTo.y]}
                stroke={PRIMARY_COLOR}
                fill={PRIMARY_COLOR}
                pointerAtBeginning
                pointerWidth={4}
                pointerLength={4}
            />
            <Group x={props.labelPosition.x} y={props.labelPosition.y}>
                <Label 
                    ref={labelRef} 
                    rotation={0} 
                    onMouseEnter={() => onMeasureMouseEnter(labelRef.current?.getStage())}
                    onMouseLeave={() => onMeasureMouseLeave(labelRef.current?.getStage())}>
                    <Tag fill="white" stroke="black" strokeWidth={0.5} />
                    <Text text={props.label} padding={4} fontSize={18} onTap={(e => {
                        dialogs?.editMeasure(props);
                    })} onClick={(e) => {
                        dialogs?.editMeasure(props);
                    }} />
                </Label>
                <Image
                    ref={imageRef}
                    image={editIcon}
                    fill={"#df5b23"}
                    width={0}
                    height={0}
                    strokeEnabled
                    stroke={"#000"}
                    strokeWidth={0.5}
                    onMouseEnter={() => onMeasureMouseEnter(imageRef.current?.getStage())}
                    onMouseLeave={() => onMeasureMouseLeave(imageRef.current?.getStage())}
                    onTap={(e => {
                        dialogs?.editMeasure(props);
                    })} onClick={(e) => {
                        dialogs?.editMeasure(props);
                    }}
                />
            </Group>
        </Group>
    );
}