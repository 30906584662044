import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import ItemColor from "./itemColor";
import { useLocation } from "react-router-dom";
import Loading from "../Loading/loading";
import { useDispatch, useSelector } from "react-redux";
import { importBaseActions } from "../../store/reducers/base";

export default function DialogScroll({
  open,
  setOpen,
  scroll,
  optionId,
  data,
  axios_config,
  endpoint,
  color1,
  color2,
  //setColor1,
  //setColor2,
  quote_id,
  //setNameColor,
  //setNameColor2,
  checkColor1,
  checkColor2,
  modelloCheckModal,
  finituraCheckModal,
  getAspetto,
  setFinituraEsterna,
  setFinituraInterna,
  setRalNonStandard,
  hideCustomRalFinituraEsterna
}) {
  const dispatch = useDispatch();
  const state = useSelector(state => state.base);
  const baseActions = importBaseActions();
  const handleClose = () => setOpen(false);
  const location = useLocation();
  const [campioneInterno, setCampioneInterno] = useState(state.campioneInterno);
  const [campioneEsterno, setCampioneEsterno] = useState(state.campioneEsterno);
  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  if (!data) return <></>;

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description">
        <DialogTitle id="scroll-dialog-title" fontWeight={700}>
          {location.pathname === "/maniglierie" ? "Scegli verniciatura maniglia" : "Scegli lo stile"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          />
          <ItemColor
            getAspetto={getAspetto}
            modelloCheckModal={modelloCheckModal}
            finituraCheckModal={finituraCheckModal}
            //setIdColor1={setColor1}
            //setNameColor={setNameColor}
            //setNameColor2={setNameColor2}
            //setIdColor2={setColor2}
            setFinituraEsterna={setFinituraEsterna}
            setFinituraInterna={setFinituraInterna}
            setRalNonStandard={setRalNonStandard}
            hideCustomRalFinituraEsterna={hideCustomRalFinituraEsterna}            
            data={data}
            checkColor1={checkColor1}
            checkColor2={checkColor2}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            color={"secondary"}
            fullWidth
            variant={"contained"}
            sx={{ fontWeight: "bold", color: "white" }}
            onClick={handleClose}>
            ANNULLA
          </Button>
          <Button
            sx={{ fontWeight: "bold" }}
            fullWidth
            variant={"contained"}
            onClick={() => {
              // axios
              //   .post(
              //     `${process.env.REACT_APP_API_URL}/${endpoint}`,
              //     {
              //       quote: quote_id,
              //       id: optionId,
              //       slug: location.pathname.substring(1),
              //       color_id: color1,
              //       color_id_esterni: color2,
              //       color: color1,
              //       campioneEsterno: state.campioneEsterno,
              //       campioneInterno: state.campioneInterno,
              //     },
              //     axios_config
              //   )
              //   .then((res) => res.data.success === 1 && setOpen(false))
              //   .catch(function (error) {
              //     console.log(error);
              //   })                
              //   .then(() => dispatch(baseActions.setSuccess(!state.success)));
              handleClose();
            }}>
            SCEGLI
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
