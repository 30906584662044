import React, { useState } from "react";
import { Events, useEventBroadcast } from "../../core/EventBroadcast";
import { useEffect } from "react";
import { useOptionsApi } from "../../api/OptionsApi";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { useCallback } from "react";
import { useRef } from "react";
import store, { persistor } from "../../store";

//var onStoreChanged = null;

export const ImageRender = props => {
    const eventBroadcast = useEventBroadcast();
    const optionsApi = useOptionsApi();
    const appState = useSelector(state => state.base);
    const [internalState, setInternalState] = useState({
        status: "loading",
        images: [],
        error: null
    });
    const loadingOverlayRef = useRef();

    const subscribe = () => {
        // onStoreChanged = store.subscribe(() => {
        //     if (loadingOverlayRef.current) loadingOverlayRef.current.style.display = "flex";
        //     debugger
        //     invalidateRender(store.getState().base);
        // });

        eventBroadcast.subscribe("ImageRender", Events.configurationChanged, (args) => {
            if (loadingOverlayRef.current) loadingOverlayRef.current.style.display = "flex";
            invalidateRender(args);
        });
    }

    const unsubscribe = () => {
        eventBroadcast.unsubscribe("ImageRender", Events.configurationChanged);

        // if (onStoreChanged) {
        //     onStoreChanged();
        //     onStoreChanged = null;
        // }
    }

    const invalidateRender = useCallback(async (state) => {
        try {       
            let data = {
                altezza: state.altezza,
                larghezza: state.larghezza,
                quote_id: state.quoteId,
                category_id: state.idPorta,
                crossbeam_installation_id: state.idInstallazioneTraversa,
                dx_pillar_installation_id: state.idInstallazioneMontanteDx,
                sx_pillar_installation_id: state.idInstallazioneMontanteSx,
                door_id: state.idTipoPorta,
                model_id: state.idModel,
                finish_id: state.idTipoFinitura,
                color_id_esterni: state.idEsterni,
                campione_esterno: state.campioneEsterno,
                colore_esterno_ns_ral: state.externalNsRal,
                id_verniciatura_maniglia: state.idVerniciaturaManiglia,
                id_finestra: state.radioF,
                qty_finestre: state.qtaF,
                id_griglia: state.radioV,
                qty_griglie: state.qtaV
            };
            
            let images = await optionsApi.getPhotoPreview(state.user.token, data);

            if (loadingOverlayRef.current) loadingOverlayRef.current.style.display = "none";

            setInternalState({
                ...internalState,
                status: "idle",
                images: images
            });
        } catch (e) {
            if (loadingOverlayRef.current) loadingOverlayRef.current.style.display = "none";

            setInternalState({
                ...internalState,
                status: "error",
                error: e
            });
        }
    }, []);

    useEffect(() => {
        invalidateRender(appState);

        subscribe();

        return () => unsubscribe();
    }, []);

    if ("loading" == internalState.status || !internalState.images) return (
        <div className="p-6 flex justify-center">
            <CircularProgress />
        </div>
    );

    if ("error" == internalState.status) return (
        <p>Error: {internalState.error.message}</p>
    );

    return (
        <div style={{position: "relative"}}>
            {internalState.images && internalState.images.map((res, index) => {            
                let style = {zIndex: index, width: "100%"};

                if (index > 0) {
                    style.position = "absolute";
                    style.top = 0;
                    style.left = 0;
                    style.width = "100%";
                    style.height = "auto";
                }

                return res && (
                    <img
                        key={index}
                        style={style}
                        src={res}
                    />
                );
            })}            
            <div ref={loadingOverlayRef} style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(255,255,255,0.7)",
                display: "none",
                alignItems: "center",
                justifyContent: "center",
                zIndex: "1000"
            }}>
                <CircularProgress />
            </div>
        </div>
    );
}