import React, { createContext, FC, PropsWithChildren, ReactNode, useContext, useState } from "react";
import { useStoreSelector } from "../../hooks/StoreHooks";
import { NullableString } from "../core/common_types";
import rals from "../assets/rals.json";
import finishes from "../assets/finishes.json";

type FinishContextData = {
    getImgRal: (code: NullableString) => Promise<HTMLImageElement|undefined>
    getRalPreview: (ral: NullableString) => ReactNode
    getRalName: (ral: NullableString) => string
}

const FinishContext = createContext<FinishContextData | null>(null);

const FinishProvider: FC<{children?: ReactNode}> = ({ children }) => {
    const {doorConfigurationBuilder} = useStoreSelector(store => store);
    
    const getImgRal = (code: NullableString) => {
        return new Promise<HTMLImageElement|undefined>(resolve => { 
            if (!code) {
                resolve(undefined);
                return;
            }

            let imgSource: NullableString = null;

            let ral = rals.find(r => r.RAL == code);
            if (ral) {
                const canvas = document.createElement('canvas');
                canvas.width = 800;
                canvas.height = 800;

                const ctx = canvas.getContext('2d');
                if (ctx) {
                    ctx.fillStyle = ral.HEX;
                    ctx.fillRect(0, 0, canvas.width, canvas.height);
                    
                    imgSource = canvas.toDataURL('image/jpeg');                        
                }
            } else {
                imgSource = finishes.find(f => f.code == code)?.img;
            }
            
            if (imgSource) {
                const img = new Image(800, 800);
                img.onload = () => {
                    resolve(img);
                };
                img.src = imgSource;
                img.crossOrigin = "anonymous";
                img.referrerPolicy = "origin";
                return;
            }
            resolve(undefined);
        });
    }

    const getRalPreview = (ral: NullableString): ReactNode => {
        if (!ral) return null;

        let preview = rals.find(f => f.RAL == ral)?.HEX;
        if (preview) return <div style={{width: "1.75rem", height: "1.75rem", backgroundColor: preview}}></div>;

        return null;        
    }

    const getRalName = (ral: NullableString): string => {
        if (!ral) return "";

        let preview = rals.find(f => f.RAL == ral);
        if (preview) return preview.Italian + ` (${ral})`;

        return ral;
    }

    return (
        <FinishContext.Provider value={{getImgRal, getRalPreview, getRalName}}>
            {children}
        </FinishContext.Provider>
    );
}

export default FinishProvider;

export const useFinishes = () => {
    return useContext(FinishContext);
}