import { useAccessoriesApi } from "../api/accessories_api";
import { Accessorio } from "../models/door_configuration";
import { User } from "../models/user";

export const useAccessoriesService = () => {
    const accessoriesApi = useAccessoriesApi();

    return {
        async findMotorizationsByCategory(currentUser: User, categoryId: number): Promise<Accessorio[]> {
            let result = await accessoriesApi.findMotorizationsByCategory(currentUser, categoryId);
            
            if (!result.success) {
                throw result.message;
            }

            return result.data as Accessorio[];
        },

        async findMotorizationAccessoriesByCategory(currentUser: User, categoryId: number): Promise<Accessorio[]> {
            let result = await accessoriesApi.findMotorizationAccessoriesByCategory(currentUser, categoryId);
            
            if (!result.success) {
                throw result.message;
            }

            return result.data as Accessorio[];
        },

        async findGenericAccessoriesByCategory(currentUser: User, categoryId: number): Promise<Accessorio[]> {
            let result = await accessoriesApi.findGenericAccessoriesByCategory(currentUser, categoryId);
            
            if (!result.success) {
                throw result.message;
            }

            return result.data as Accessorio[];
        },
    }
}