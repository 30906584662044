import React, { useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import img1 from "../../assets/img/dimensioni1.png";
import img2 from "../../assets/img/aspetto.png";
import img3 from "../../assets/img/finestre.png";
import img4 from "../../assets/img/accessori.png";
import img5 from "../../assets/img/richieste.png";
import IMG1 from "../../assets/img/aspetto2.png";
import IMG2 from "../../assets/img/finestre2.png";
import IMG3 from "../../assets/img/accessori2.png";
import IMG4 from "../../assets/img/richieste2.png";
import { useLocation, useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { IconButton } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import axios from "axios";
import useSWR from "swr";
import TabCustom from "./TabCustom";
import { useState } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { importBaseActions } from "../../store/reducers/base";

const TabsCustom = ({
  value,
  handleChange,
  url,
  id,
  axios_conf
}) => {
  const state = useSelector(state => state.base);
  const baseActions = importBaseActions();
  const dispatch = useDispatch();
  const location = useLocation();
  //const history = useHistory();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  // const fetcher = (url) =>
  //   axios
  //     .post(url, { id: id, quote_id: state.quoteId }, axios_conf)
  //     .then((res) => res.data.data);
  // const { data, error } = useSWR([url, location.pathname], fetcher);

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    try {
      let res = await axios.post(url, { id: id, quote_id: state.quoteId }, axios_conf);
      if (res.data.success !== 1) throw res.data.message;

      dispatch(baseActions.resetSteps({steps: res.data.data, currentLocation: location.pathname.substring(1)}));

      setData(res.data.data);
    } catch (e) {
      setError(e);
    }
  }

  if (error) {
    console.log("AT - Tabs");
    return <div>failed to load</div>;
  }

  if (!data) return null;

  if (location.pathname === "/generaPDF") return null;

  return (
    <Box display={"flex"} justifyContent={"center"} alignItems={"start"} sx={{backgroundColor: "#f1f1f1", overflow: "auto", width: "100%"}}>
        {data && data.map((el, index) => {
          return (
            <TabCustom
              key={el.id}
              id={el.id}
              count={state}
              nome={el.slug}
              label={el.name}
              index={el.id}
              rottaPrec={data[index - 1] && data[index - 1].slug}
              rottaSucc={data[index + 1] && data[index + 1].slug}
              img={el.img}
              IdFinale={data.length - 1}
            />
          );
        })}
    </Box>
  );
};

/*
const useStyles = makeStyles({
  root: {
    "& .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer": {
      justifyContent: "center",
    },
  },
  contain: {
    flexGrow: 1,
    width: "100%",
    maxWidth: "300rem",
    boxShadow: "inset 0 0 5px rgba(0, 0, 0, 0.25);",
    justifyContent: "center",
  },
});*/

export default TabsCustom;
