import React, { FC, useEffect, useState } from "react";
import { Group, Image, Layer, Line } from "react-konva";
import { useStoreSelector } from "../../../../../../../../hooks/StoreHooks";
import { Vector2d } from "konva/lib/types";
import { Color, Option } from "../../../../../../../models/door_configuration";
import { PortaSezionaleClassic } from "../door-models/porta_sezionale_classic";
import { ColorCode, DoorType, FinishType } from "../../../../../../../core/common_types";
import { PortaSezionalePlana } from "../door-models/porta_sezionale_plana";
import { PortaSezionaleFullVision } from "../door-models/porta_sezionale_full_vision";
import { DatiPannello } from "../../../../../../../core/common_draw_types";
import { toDrawUnit, Vector2dToPointsArray } from "../../../../../../../core/common_functions";
import { Vector } from "vecti";
import { useFinishes } from "../../../../../../../providers/finish_provider";
import { PortinaAffiancata } from "../accessories/portina_affiancata";
import { PROFONDITA_INVOLUCRO_PORTINA_LAT, SPESSORE_STIPITE } from "../../../../../../../core/common_constants";
import { PortinaLateraleClassic } from "../door-models/portina_laterale_classic";
import { PortinaLateralePlana } from "../door-models/portina_laterale_plana";
import { PortinaLateraleVetrata } from "../door-models/portina_laterale_vetrata";

export const ExternalDoor: FC<{options: Option[]}> = (props) => {
    const {options} = props;
    const finishesProvider = useFinishes();
    const [colors, setColors] = useState<{door: HTMLImageElement|undefined, frame: HTMLImageElement|undefined, handle: HTMLImageElement|undefined}|undefined>();

    const {doorConfigurationBuilder} = useStoreSelector(store => store);

    const hp = toDrawUnit(doorConfigurationBuilder.configuration?.portinaAffiancata?.altezza ?? 0);
    const lp = toDrawUnit(doorConfigurationBuilder.configuration?.portinaAffiancata?.larghezza ?? 0);    
    
    const findPorta = () => {
        if (doorConfigurationBuilder.configuration?.portinaAffiancata?.usaStessaFinitura) {
            let option = options.find(o => o?.optionId == doorConfigurationBuilder.configuration?.porta);
            return option;
        } else {
            let option = options.find(o => o?.optionId == doorConfigurationBuilder.configuration?.portinaAffiancata?.porta?.optionId);
            return option;
        }
    }

    const findModello = () => {        
        if (doorConfigurationBuilder.configuration?.portinaAffiancata?.usaStessaFinitura) {
            let option = options.find(o => o?.optionId == doorConfigurationBuilder.configuration?.modello);
            return option;
        } else {
            let option = options.find(o => o?.optionId == doorConfigurationBuilder.configuration?.portinaAffiancata?.modello?.optionId);
            return option;
        }
    }

    const findFinitura = () => {
        if (doorConfigurationBuilder.configuration?.portinaAffiancata?.usaStessaFinitura) {
            let option = options.find(o => o?.optionId == doorConfigurationBuilder.configuration?.finitura);
            return option;
        } else {
            let option = options.find(o => o?.optionId == doorConfigurationBuilder.configuration?.portinaAffiancata?.finitura?.optionId);
            return option;
        }
    }

    const renderPorta = () => {
        let porta = findPorta();
        let modello = findModello();
        let finitura = findFinitura();

        if (porta?.name == DoorType.classic) return (
            <PortinaLateraleClassic
                hpdraw={hp} 
                lpdraw={lp} 
                modello={modello}
                doorColor={colors?.door}
                frameColor={colors?.frame}
                handleColor={colors?.handle}
            />
        );

        if (porta?.name == DoorType.plana && finitura?.name != FinishType.glasspanel) return (
            <PortinaLateralePlana
                hpdraw={hp} 
                lpdraw={lp} 
                doorColor={colors?.door}
                frameColor={colors?.frame}
                handleColor={colors?.handle}
            />
        );

        if (porta?.name == DoorType.full_vision || (porta?.name == DoorType.plana && finitura?.name == FinishType.glasspanel)) return (
            <PortinaLateraleVetrata
                hpdraw={hp} 
                lpdraw={lp} 
                doorColor={colors?.door}
                frameColor={colors?.frame}
                handleColor={colors?.handle}
            />
        );

        return null;
    }

    const loadColors = async () => {
        let door: HTMLImageElement|undefined;
        let frame: HTMLImageElement|undefined;
        let handle: HTMLImageElement|undefined;
        if (doorConfigurationBuilder.configuration?.portinaAffiancata?.usaStessaFinitura) {
            door = await finishesProvider?.getImgRal(doorConfigurationBuilder.getExternalColorCode());
        } else {
            door = await finishesProvider?.getImgRal(doorConfigurationBuilder.configuration?.portinaAffiancata?.coloreEsterno);
        }

        frame = await finishesProvider?.getImgRal(doorConfigurationBuilder.configuration?.portinaAffiancata?.externalFrameColorCode);
        handle = await finishesProvider?.getImgRal(doorConfigurationBuilder.configuration?.portinaAffiancata?.externalHandleColorCode);
        
        setColors({door, frame, handle});
    }

    useEffect(() => {
        loadColors();
    }, [doorConfigurationBuilder.configuration?.portinaAffiancata]);

    if (!colors) return null;

    return (
        <Layer>
            <Group x={0} y={0}>
                {/* <Soffitto hg={configurationBuilder.altezzaGarage} lg={configurationBuilder.larghezzaGarage} /> */}
                <Parete hgDraw={hp} lgDraw={lp} />
                {/* <Foro pf={pf} h={hf} l={lf} /> */}
                {renderPorta()}
            </Group>            
        </Layer>
    );
}

const Parete: FC<{lgDraw: number, hgDraw: number}> = ({lgDraw, hgDraw}) => {
    const buildPolyParete = (): Vector2d[] => {
        let pt1: Vector2d = {x: 0, y: 0};
        let pt2: Vector2d = {x: 0, y: hgDraw};
        let pt3: Vector2d = {x: -PROFONDITA_INVOLUCRO_PORTINA_LAT, y: hgDraw};        
        let pt4: Vector2d = {x: -PROFONDITA_INVOLUCRO_PORTINA_LAT, y: -PROFONDITA_INVOLUCRO_PORTINA_LAT};
        let pt5: Vector2d = {x: lgDraw + (PROFONDITA_INVOLUCRO_PORTINA_LAT), y: -PROFONDITA_INVOLUCRO_PORTINA_LAT};
        let pt6: Vector2d = {x: lgDraw + (PROFONDITA_INVOLUCRO_PORTINA_LAT), y: hgDraw};
        let pt7: Vector2d = {x: lgDraw, y: hgDraw};
        let pt8: Vector2d = {x: lgDraw, y: 0};

        // let pt2: Vector2d = {x: -PROFONDITA_INVOLUCRO*2, y: hgDraw + PROFONDITA_INVOLUCRO};
        // let pt3: Vector2d = {x: -PROFONDITA_INVOLUCRO*2, y: -PROFONDITA_INVOLUCRO};
        // let pt5: Vector2d = {x: lgDraw + (PROFONDITA_INVOLUCRO*2), y: hgDraw + PROFONDITA_INVOLUCRO};
        // let pt6: Vector2d = {x: lgDraw, y: hgDraw};

        return [pt1, pt2, pt3, pt4, pt5, pt6, pt7, pt8];
    }

    return (
        <>
            <Line points={Vector2dToPointsArray(buildPolyParete())} 
                strokeEnabled
                stroke={"000"}
                strokeWidth={1}
                fillEnabled
                fill={"#ddd"}
                closed
                // onClick={() => alert("Hai cliccato la parete esterna")}
            />

            {/*buildPolyParete().map((p, index) => (
                <>
                    <Circle
                        x={p.x}
                        y={p.y}
                        radius={4}
                        fill={"#f00"}
                    />
                    <Text
                        x={p.x}
                        y={p.y}
                        text={"P"+(index+1)}
                    />
                </>
            ))*/}
        </>
    );
}