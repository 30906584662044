import React, { forwardRef, Ref, useImperativeHandle, useState } from "react";
import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useStoreDispatch, useStoreSelector } from "../../../../../../../hooks/StoreHooks";
import { useDoorConfigurationBuilderActions } from "../../../../../../../store/reducers/door_configuration_builder";
import { InstallationType, SpringType } from "../../../../../../core/common_types";

export type FrmImpostazioniInizialiData = {
    quantita: number,
    vanoConAltroAccesso: boolean,
    tipoInstallazioneTraversa: InstallationType,
    tipoInstallazioneMontanteSx: InstallationType,
    tipoInstallazioneMontanteDx: InstallationType,
    tipoMolle: SpringType,
    isMotorizzata: boolean
}

export type FrmImpostazioniInizialiArgs = {
    
}

export type FrmImpostazioniInizialiTypeRef = React.ElementRef<typeof FrmImpostazioniIniziali>;

export const FrmImpostazioniIniziali = forwardRef((props: any, ref: Ref<FrmImpostazioniInizialiArgs>) => {
    const { doorConfigurationBuilder } = useStoreSelector(store => store);    
    const [erroreQta, setErroreQta] = useState<string | null>(null);
    
    const dispatcher = useStoreDispatch();
    const {
        setQuantita,
        setTipoInstallazioneTraversa,
        setTipoInstallazioneMontanteSx,
        setTipoInstallazioneMontanteDx,
        setTipoMolle,
        setVanoConAltroAccesso,
        setIsMotorizzata
    } = useDoorConfigurationBuilderActions();
    
    const formik = useFormik<FrmImpostazioniInizialiData>({
        initialValues: {
            vanoConAltroAccesso: doorConfigurationBuilder.configuration ? doorConfigurationBuilder.configuration.vanoConAltroAccesso : false,
            quantita: doorConfigurationBuilder.configuration ? doorConfigurationBuilder.configuration.quantita : 0,
            isMotorizzata: doorConfigurationBuilder.configuration ? doorConfigurationBuilder.configuration.isMotorizzata : false,
            tipoInstallazioneTraversa: doorConfigurationBuilder.configuration ? doorConfigurationBuilder.configuration.tipoInstallazioneTraversa : InstallationType.oltre_luce,
            tipoInstallazioneMontanteSx: doorConfigurationBuilder.configuration ? doorConfigurationBuilder.configuration.tipoInstallazioneMontanteSx : InstallationType.oltre_luce,
            tipoInstallazioneMontanteDx: doorConfigurationBuilder.configuration ? doorConfigurationBuilder.configuration.tipoInstallazioneMontanteDx : InstallationType.oltre_luce,
            tipoMolle: doorConfigurationBuilder.configuration ? doorConfigurationBuilder.configuration.tipoMolle ?? SpringType.torsione : SpringType.torsione,
        },
        onSubmit: (model) => {
            
        }
    });

    const check = () => {
        if (!isValidQta(formik.values.quantita)) throw "Quantità non valida";
    }

    const isValidQta = (value: number): boolean => !isNaN(value) && value > 0;

    useImperativeHandle(ref, () => ({
        
    }));

    return (
        <div>
            <TextField
                variant="outlined"
                label="Quantità"
                type="number"
                className="mt-3 w-full"
                value={formik.values.quantita}
                onChange={(e) => formik.setFieldValue("quantita", Number(e.target.value))}
                onBlur={(e) => {
                    if (!isValidQta(Number(e.target.value))) {
                        setErroreQta("Valore non valido");
                        return;
                    }                            
                    setErroreQta(null);
                    dispatcher(setQuantita(Number(e.target.value)));
                }}
                error={erroreQta != null}
                helperText={erroreQta}
            />

            <FormControl fullWidth className="mt-3">
                <InputLabel id="tipoInstallazioneTraversa">Installazione superiore</InputLabel>
                <Select
                    labelId="tipoInstallazioneTraversa"
                    value={formik.values.tipoInstallazioneTraversa}
                    label="Installazione superiore"
                    onChange={(e) => {
                        formik.setFieldValue("tipoInstallazioneTraversa", e.target.value);
                        dispatcher(setTipoInstallazioneTraversa(e.target.value as InstallationType));
                    }}>
                    <MenuItem value={InstallationType.in_luce}>In Luce</MenuItem>
                    <MenuItem value={InstallationType.oltre_luce}>Oltre Luce</MenuItem>
                </Select>
            </FormControl>
            
            <FormControl fullWidth className="mt-3">
                <InputLabel id="tipoInstallazioneMontanteSx">Installazione laterale - Guida sinistra (vista interna)</InputLabel>
                <Select
                    labelId="tipoInstallazioneMontanteSx"
                    value={formik.values.tipoInstallazioneMontanteSx}
                    label="Installazione laterale - Guida sinistra (vista interna)"
                    onChange={(e) => {
                        formik.setFieldValue("tipoInstallazioneMontanteSx", e.target.value);
                        dispatcher(setTipoInstallazioneMontanteSx(e.target.value as InstallationType));
                    }}>
                    <MenuItem value={InstallationType.in_luce}>In Luce</MenuItem>
                    <MenuItem value={InstallationType.oltre_luce}>Oltre Luce</MenuItem>
                </Select>
            </FormControl>
            
            <FormControl fullWidth className="mt-3">
                <InputLabel id="tipoInstallazioneMontanteDx">Installazione laterale - Guida destra (vista interna)</InputLabel>
                <Select
                    labelId="tipoInstallazioneMontanteDx"
                    value={formik.values.tipoInstallazioneMontanteDx}
                    label="Installazione laterale - Guida destra (vista interna)"
                    onChange={(e) => {
                        formik.setFieldValue("tipoInstallazioneMontanteDx", e.target.value);
                        dispatcher(setTipoInstallazioneMontanteDx(e.target.value as InstallationType));
                    }}>
                    <MenuItem value={InstallationType.in_luce}>In Luce</MenuItem>
                    <MenuItem value={InstallationType.oltre_luce}>Oltre Luce</MenuItem>
                </Select>
            </FormControl> 
            
            <FormControl fullWidth className="mt-3">
                <InputLabel id="tipoMolle">Tipologia Molle</InputLabel>
                <Select
                    labelId="tipoMolle"
                    value={formik.values.tipoMolle}
                    label="Tipologia Molle"
                    onChange={(e) => {
                        formik.setFieldValue("tipoMolle", e.target.value);
                        dispatcher(setTipoMolle(e.target.value as SpringType));
                    }}>
                    <MenuItem value={SpringType.torsione}>Torsione</MenuItem>
                    <MenuItem value={SpringType.trazione}>Trazione</MenuItem>
                </Select>
            </FormControl>
            
            <FormControlLabel 
                className="w-full mt-4" 
                control={
                    <Checkbox                            
                        checked={formik.values.vanoConAltroAccesso}
                        onChange={(e) => {
                            formik.setFieldValue("vanoConAltroAccesso", e.target.checked);
                            dispatcher(setVanoConAltroAccesso(e.target.checked));
                        }}
                        sx={{marginTop: "0", marginBottom: "0"}}
                    />
                } 
                label="Locale garage con altro accesso" 
                sx={{marginTop: "0", marginBottom: "0", textTransform: "uppercase"}}
            />
            
            {/* <FormControlLabel 
                className="w-full"
                control={
                    <Checkbox                            
                        checked={formik.values.portinaPedonaleAffiancata}
                        onChange={(e) => {
                            formik.setFieldValue("portinaPedonaleAffiancata", e.target.checked);
                            dispatcher(setPortinaPedonaleAffiancata(e.target.checked));
                        }}
                        sx={{marginTop: "0", marginBottom: "0"}}
                    />
                } 
                label="Aggiungi portina pedonale affiancata"  
                sx={{marginTop: "0", marginBottom: "0", textTransform: "uppercase"}}
            /> */}

            <FormControlLabel
                className="w-full" 
                control={
                    <Checkbox                            
                        checked={formik.values.isMotorizzata}
                        onChange={(e) => {
                            formik.setFieldValue("isMotorizzata", e.target.checked);
                            dispatcher(setIsMotorizzata(e.target.checked));
                        }}
                        sx={{marginTop: "0", marginBottom: "0"}}
                    />
                } 
                label="Porta motorizzata" 
                sx={{marginTop: "0", marginBottom: "0", textTransform: "uppercase"}}
            />
        </div>
    );
});