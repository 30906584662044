import * as React from "react";
import Grid from "@mui/material/Grid";
import TopBar from "../../components/TopBar/TopBar";
import { LayoutPage } from "../../components/Layout/layoutPage";
import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  InputAdornment,
  MenuItem,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import TabsCustom from "../../components/Tabs/Tabs";
import { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import GridPreventivi from "../../components/GrdiPreventivi/gridPreventivi";
import { makeStyles } from "@mui/styles";
import * as yup from "yup";
import { useFormik } from "formik";
import { InputCustom } from "../../components/Input/InputCustom";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import axios from "axios";
import useSWR from "swr";
import Loading from "../../components/Loading/loading";
import ErrorCustom from "../../components/Error/Error";
import { importBaseActions } from "../../store/reducers/base";
import { getProductName } from "../../Utility";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

const Richiesta = () => {
  const count = useSelector(state => state.base);
  const baseActions = importBaseActions();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [checked, setChecked] = useState(true);
  const [boolScont, setBoolScont] = useState(false);
  const [messageError, setMessageError] = useState();
  const [deposit, setDeposit] = useState({open: false, type: "percentage"});
  const [endClientDeposit, setEndClientDeposit] = useState({oepn: false, type: "percentage"});

  const formRef = React.useRef(null);

  //const history = useHistory();
  const classes = useStyles();
  const handleChangeCheckbox = (event) => setChecked(event.target.checked);
  const location = useLocation();
  let axiosConfig = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + count.user.token,
    },
  };

  // const fetcher = (url) =>
  //   axios
  //     .post(url, { id: count.idPorta }, axiosConfig)
  //     .then((res) => res.data.data);
  // const { data, error } = useSWR([`${process.env.REACT_APP_API_URL}/categories-steps`], fetcher);

  const [data, setData] = useState({
    state: "loading",
    steps: [],
    currentStep: null,
    quote: null,
    discountError: null,
    depositType: "percentage",
    endClientDepositType: "percentage",
    errors: {}
  });
  const [value, setValue] = useState();
  const [discounts, setDiscounts] = useState({ discount1: true, discount2: false });
  const [endClientDiscounts, setEndClientDiscounts] = useState({ discount1: true, discount2: false });
  const handleChange = () => {
    // data &&
    //   data.map((el) =>
    //     `/${el.slug}` === location.pathname ? setValue(el.id) : null
    //   );
  };

  // const fetcher3 = (url) =>
  //   axios.post(url, { id: count.quoteId }, axiosConfig).then((res) => {
  //     console.log(res.data.data);
  //     return res.data.data;
  //   });
  // const { data: form, error: errorForm } = useSWR(
  //   [`${process.env.REACT_APP_API_URL}/richiesta-form`, value],
  //   fetcher3
  // );

  useEffect(() => {
    load();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    initialValues: {
      qty: 1,
      customClientName: "",
      endClientDepositType: "percentage",
      endClientDepositValue: 0,
      endClientTaxRate: 0,
      depositType: "percentage",
      depositValue: 0,
      taxRate: 0,
      notes: ""
    },
    onSubmit: async (values) => {
      let errors = checkBeforeSave();
      if (errors) {
        setData({...data, errors});
        return;
      }

      setData({ ...data, state: "save" });
      try {
        let res = await axios.post(`${process.env.REACT_APP_API_URL}/richiesta`, {
          quoteId: count.quoteId,
          customClientName: values.customClientName,
          endClientDepositType: values.endClientDepositType,
          endClientDepositValue: values.endClientDepositValue,
          endClientTaxRate: values.endClientTaxRate,
          depositType: values.depositType,
          depositValue: values.depositValue,
          discount1: values.discount1,
          discount2: values.discount2,
          taxRate: values.taxRate,
          endClientDiscount1: values.endClientDiscount1,
          endClientDiscount2: values.endClientDiscount2,
          notes: values.notes,
        }, axiosConfig);

        if (res.data.success == 1) {
          dispatch(baseActions.setRichiesta(true));

          navigate(`/generaPDF`);
        } else {
          setData({ ...data, discountError: res.data.message, state: "idle", errors: {} });
        }        
      } catch (e) {
        console.log(e);
        setData({ ...data, state: "idle" });
      }
    }
  });

  const checkBeforeSave = () => {
    let errors = {
      customClientName: (count.user.userType == "Retail" || count.customer.isServiceCustomer) && !formik.values.customClientName
    };
    
    let res = Object.entries(errors).filter(values => values[1]);
    return res.length > 0 ? Object.fromEntries(res) : null;
  }

  const load = async () => {
    let steps = [];
    let currentStep = null;
    try {
      let res = await axios.post(`${process.env.REACT_APP_API_URL}/categories-steps`, { id: count.idPorta, quote_id: count.quoteId }, axiosConfig);
      if (res.data && res.data.data) {
        steps = res.data.data;
        currentStep = findCategoryStep(steps);
      }
    } catch (e) {

    }

    try {
      let quote = null;
      let endClientSummary = null;

      let res = await axios.post(`${process.env.REACT_APP_API_URL}/richiesta-form`, { id: count.quoteId }, axiosConfig);
      if (res.data.success == 1) {
        quote = res.data.data;

        endClientSummary = quote ? JSON.parse(quote.end_client_summary) : null;
        
        formik.setFieldValue("customClientName", quote ? quote.custom_client_name : "");
        formik.setFieldValue("depositType", quote ? quote.down_payment_type : "percentage");
        formik.setFieldValue("depositValue", quote ? quote.down_payment : "0");
        formik.setFieldValue("discount1", quote ? quote.discount1 : "0");
        formik.setFieldValue("discount2", quote ? quote.discount2 : "0");
        formik.setFieldValue("discount3", /*quote ? quote.discount3 :*/ "0");
        formik.setFieldValue("taxRate", quote ? quote.tax_rate : "0");        
        formik.setFieldValue("endClientDepositType", endClientSummary ? endClientSummary.downPaymentType : "percentage");
        formik.setFieldValue("endClientDepositValue", endClientSummary ? endClientSummary.downPayment : "0");
        formik.setFieldValue("endClientTaxRate", endClientSummary ? endClientSummary.taxRate : "0");        
        formik.setFieldValue("endClientDiscount1", endClientSummary ? endClientSummary.discount1 : "0");
        formik.setFieldValue("endClientDiscount2", endClientSummary ? endClientSummary.discount2 : "0");
        formik.setFieldValue("endClientDiscount3", /*quote ? quote.discount3 :*/ "0");
        formik.setFieldValue("notes", quote ? quote.notes : "");

        setDeposit({open: false, type: quote ? quote.down_payment_type : "percentage"});
        setEndClientDeposit({open: false, type: endClientSummary ? endClientSummary.downPaymentType : "percentage"});

        setDiscounts({
          discount2: quote.discount1 && quote.discount1 > 0
        });

        setEndClientDiscounts({
          discount2: endClientSummary && endClientSummary.discount1 ? endClientSummary.discount1 : 0
        });
      }

      setData({
        state: "idle",
        steps: steps,
        currentStep: currentStep,
        quote: quote,
        endClientSummary: endClientSummary
      });
    } catch (e) {
      setData({
        state: "error",
        error: e
      });
    }
  }

  const findCategoryStep = (categoriesSteps) => {
    return categoriesSteps.find(cs => `/${cs.slug}` === location.pathname);
  };

  const applyDiscount = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    setData({ ...data, state: "refresh" });
    try {
      let res = await axios.post(`${process.env.REACT_APP_API_URL}/update-discount`, {
        quoteId: count.quoteId,
        discount1: formik.values.discount1,
        discount2: formik.values.discount2,
        discount3: 0,//formik.values.discount3,
        taxRate: formik.values.taxRate,
        endClientDiscount1: formik.values.endClientDiscount1,
        endClientDiscount2: formik.values.endClientDiscount2,
        endClientTaxRate: formik.values.endClientTaxRate,
      }, axiosConfig);

      if (res.data.success == 1) {
        let quote = res.data.data.configuration;
        let endClientSummary = quote ? JSON.parse(quote.end_client_summary) : null;
        setData({ ...data, quote, endClientSummary, discountError: null, state: "idle" });
      } else {
        setData({ ...data, discountError: res.data.message, state: "idle" });
      }
    } catch (e) {
      setData({ ...data, discountError: null, state: "idle" });
    }
  }

  if ("error" == data.state) return <ErrorCustom message={data.error} route={"dimensioni"} />;

  if ("loading" == data.state) return <Loading />;

  return (
    <LayoutPage>
      <div style={{ flex: "0 0 auto" }}>
        <TopBar />
        
        <div className={"d-flex justify-content-between p-3"}>
          <div className="d-flex flex-column" style={{ lineHeight: "1" }}>
              <h2 className="h2 fw-bold">
                  Nuova configurazione {getProductName(count.idPorta)} (#{count.quoteId})
              </h2>
          </div>
          <div>
              {/* <Button variant={deleteClickCount == 0 ? "outlined" : "contained"} color="error" className="me-3 px-2">
                  <RemoveCircleOutlineOutlinedIcon />
                  <span className="ms-2">{deleteClickCount == 0 ? "ELIMINA" : "CONFERMA"}</span>
              </Button> */}

              <Button variant="contained" color="secondary" onClick={() => navigate("/configurations")}>
                  <CloseIcon />
                  <span className="ms-2">CHIUDI</span>
              </Button>
          </div>
        </div>

        <Grid
          className={classes.root}
          container
          sx={{ justifyContent: "center" }}>
          {data.currentStep && <TabsCustom
            value={data.currentStep.id}
            setValue={setValue}
            id={count.idPorta}
            count={count}
            handleChange={handleChange}
            axios_conf={axiosConfig}
            url={`${process.env.REACT_APP_API_URL}/categories-steps`}
          />}
        </Grid>
      </div>

      <div style={{ flex: "1 1 auto", minHeight: "0" }}>
        <div className="container">
          {!data.quote && (
            <div className="row mt-3">
              <div className="col-12">
                <div className="alert alert-danger">
                  <strong>Attenzione!</strong> Configurazione non valida
                </div>
              </div>
            </div>
          )}

          {data.quote && (
            <form>
              <div className="row mt-3">
                <div className="col-xl-7">
                  <h5 className="text-primary mb-3">
                    Condizioni di vendita
                    {process.env.NODE_ENV !== "production" && count.user.userType == "Retail" && <label>- Cliente finale</label>}
                    {process.env.NODE_ENV !== "production" && count.user.userType != "Retail" && <label>- Rivendita</label>}
                  </h5>

                  {count.user.userType == "Retail" && (
                    <>
                      {process.env.NODE_ENV !== "production" && <label>Cliente finale</label>}

                      <div className="">
                        <label className="form-label text-primary mb-1">Denominazione cliente</label>
                        <textarea name="customClientName" className={"form-control " + (data && data.errors && data.errors.customClientName ? "is-invalid" : "")} onChange={(e) => formik.setFieldValue("customClientName", e.target.value)}>{formik.values.customClientName}</textarea>
                      </div>

                      <div className="row mt-2">
                        <div className="col-xl-4">
                          <label className="form-label text-primary mb-1">Aliquota IVA</label>
                          <select className="form-control custom-select" value={formik.values.endClientTaxRate} onChange={(e) => formik.setFieldValue("endClientTaxRate", e.target.value)}>
                            <option value={0}>0</option>
                            <option value={4}>4</option>
                            <option value={10}>10</option>
                            <option value={22}>22</option>
                          </select>
                        </div>
                        <div className="col-xl-4">
                          <label className="form-label text-primary mb-1">Acconto</label>
                          <div className="input-group">
                            <button className={"btn btn-secondary dropdown-toggle " + (endClientDeposit.open ? "show" : "")} type="button" onClick={() => setEndClientDeposit({...endClientDeposit, open: !endClientDeposit.open})}>{endClientDeposit.type == "percentage" ? "%" : "€"}</button>
                            {endClientDeposit.open && (
                              <ul className="dropdown-menu show" style={{ position: "absolute", transform: "translateY(40px)", left: "0" }}>
                                <li><button type="button" className="dropdown-item" onClick={() => {
                                  formik.setFieldValue("endClientDepositType", "percentage");
                                  formik.setFieldValue("endClientDepositValue", "0");
                                  setEndClientDeposit({...endClientDeposit, open: false, type: "percentage"});
                                }}>%</button></li>
                                <li><button type="button" className="dropdown-item" onClick={() => {
                                  formik.setFieldValue("endCutomerDepositType", "amount");
                                  formik.setFieldValue("endClientDepositValue", "0");
                                  setEndClientDeposit({...endClientDeposit, open: false, type: "amount"});
                                }}>&euro;</button></li>
                              </ul>
                            )}
                            <input type="number" className="form-control text-right" name="endClientDepositValue" value={formik.values.endClientDepositValue} onChange={(e) => formik.setFieldValue("endClientDepositValue", e.target.value)} />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xl-8">
                          <div className="bg-light rounded-3 mt-2 p-2">
                            <label className="text-primary">Sconto</label>
                            <div className="row">
                              <div className="col-4">                                        
                                <input
                                  type="number"
                                  name={"endClientDiscount1"}
                                  value={formik.values.endClientDiscount1}
                                  onChange={(e) => {
                                    formik.setFieldValue("endClientDiscount1", e.target.value);
                                  }}
                                  onBlur={(e) => setEndClientDiscounts({ ...endClientDiscounts, discount2: formik.values.endClientDiscount1 > 0 })}
                                  className="form-control text-center"
                                />
                              </div>

                              <div className="col-4">
                                <input
                                  type="number"
                                  name={"endClientDiscount2"}
                                  value={formik.values.endClientDiscount2}
                                  onChange={(e) => {
                                    formik.setFieldValue("endClientDiscount2", e.target.value);
                                  }}
                                  disabled={!endClientDiscounts.discount2}
                                  className="form-control text-center"
                                />
                              </div>

                              <div className="col-4">
                                <button type="button" className="btn btn-primary w-100" onClick={(e) => applyDiscount(e)}>
                                  AGGIORNA
                                </button>
                              </div>
                            </div>

                            {"END_CLIENT_DISCOUNT_ERROR" == data.discountError && (
                              <Alert className="mt-2" severity="error">Sconto non applicabile</Alert>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {count.user.userType != "Retail" && (
                    <>
                      {process.env.NODE_ENV !== "production" && <label>Rivendita</label>}

                      {count.customer.isServiceCustomer && <div className="">
                        <label className="form-label text-primary mb-1">Denominazione cliente</label>
                        <textarea name="customClientName" className={"form-control " + (data && data.errors && data.errors.customClientName ? "is-invalid" : "")} onChange={(e) => formik.setFieldValue("customClientName", e.target.value)}>{formik.values.customClientName}</textarea>
                      </div>}

                      <div className="row mt-2">
                        {count.customer.isServiceCustomer && <div className="col-xl-4">
                          <label className="form-label text-primary mb-1">Aliquota IVA</label>
                          <select className="form-control custom-select" value={formik.values.taxRate} onChange={(e) => formik.setFieldValue("taxRate", e.target.value)}>
                            <option value={0}>0</option>
                            <option value={4}>4</option>
                            <option value={10}>10</option>
                            <option value={22}>22</option>
                          </select>
                        </div>}
                        <div className="col-xl-4">
                          <label className="form-label text-primary mb-1">Acconto</label>
                          <div className="input-group">
                            <button disabled={!count.customer.isServiceCustomer} className={"btn btn-secondary dropdown-toggle " + (deposit.open ? "show" : "")} type="button" onClick={() => setDeposit({...deposit, open: !deposit.open})}>{deposit.type == "percentage" ? "%" : "€"}</button>
                            {deposit.open && (
                              <ul className="dropdown-menu show" style={{ position: "absolute", transform: "translateY(40px)", left: "0" }}>
                                <li><button type="button" className="dropdown-item" onClick={() => {
                                  formik.setFieldValue("depositType", "percentage");
                                  formik.setFieldValue("depositValue", "0");
                                  setDeposit({...deposit, open: false, type: "percentage"});
                                }}>%</button></li>
                                <li><button type="button" className="dropdown-item" onClick={() => {
                                  formik.setFieldValue("depositType", "amount");
                                  formik.setFieldValue("depositValue", "0");
                                  setDeposit({...deposit, open: false, type: "amount"});
                                }}>&euro;</button></li>
                              </ul>
                            )}
                            <input disabled={!count.customer.isServiceCustomer} type="number" className="form-control text-right" name="depositValue" value={formik.values.depositValue} onChange={(e) => formik.setFieldValue("depositValue", e.target.value)} />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xl-8">
                          <div className="bg-light rounded-3 mt-2 p-2">
                            <label className="text-primary">Sconto</label>
                            <div className="row">
                              <div className={count.customer.isServiceCustomer ? "col-4" : "col-6"}>
                                <input
                                  disabled={!count.customer.isServiceCustomer}
                                  type="number"
                                  name={"discount1"}
                                  value={formik.values.discount1}
                                  onChange={(e) => {
                                    formik.setFieldValue("discount1", e.target.value);
                                    if (e.target.value <= 0) {
                                      formik.setFieldValue("discount2", 0);
                                    }
                                  }}
                                  onBlur={(e) => setDiscounts({ ...discounts, discount2: formik.values.discount1 > 0 })}
                                  className="form-control text-center"
                                />
                              </div>

                              <div className={count.customer.isServiceCustomer ? "col-4" : "col-6"}>
                                <input
                                  type="number"
                                  name={"discount2"}
                                  value={formik.values.discount2}
                                  onChange={(e) => {
                                    formik.setFieldValue("discount2", e.target.value);
                                  }}
                                  disabled={!count.customer.isServiceCustomer || !discounts.discount2}
                                  className="form-control text-center"
                                />
                              </div>

                              {count.customer.isServiceCustomer && <div className="col-4">
                                <button type="button" className="btn btn-primary w-100" onClick={(e) => applyDiscount(e)}>
                                  AGGIORNA
                                </button>
                              </div>}
                            </div>

                            {"RETAIL_DISCOUNT_ERROR" == data.discountError && (
                              <Alert className="mt-2" severity="error">Sconto non applicabile</Alert>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  
                  <div className="mt-2">
                    <label className="form-label text-primary mb-1">Note</label>
                    <textarea className="form-control" name="notes" value={formik.values.notes} onChange={(e) => formik.setFieldValue("notes", e.target.value)}></textarea>
                  </div>
                </div>

                <div className="col-xl-5">
                  {data.quote && (
                    <div className="mt-1 rounded-3 p-3 bg-light " style={{ position: "relative" }}>
                      <h4 className="h4 fw-bold">Totale:</h4>
                      <div className="d-flex justify-content-between">
                        <NumberItem label="Quantità:" value={data.quote.qty} />
                      </div>

                      <div className="d-flex justify-content-between">
                        <AmountItem label="Totale merce:" value={data.quote.base_net_price} />
                      </div>

                      <div className="d-flex justify-content-between">
                        <AmountItem label="Totale servizi:" value={data.quote.total_services} />
                      </div>

                      {count.user.userType != "Retail" && (
                        <>
                          <div className="my-1 text-center" style={{lineHeight: "1"}}>
                            <hr />
                            {process.env.NODE_ENV != "production" && <small>Rivendita</small>}
                          </div>

                          <div className="d-flex justify-content-between">
                            <AmountItem label="Sconto:" value={-data.quote.total_discount} />
                          </div>

                          <div className="d-flex justify-content-between">
                            <AmountItem label="Totale merce netto:" value={data.quote.net} />
                          </div>

                          <div className="d-flex justify-content-between">
                            <AmountItem label="Totale imponibile:" value={data.quote.taxable_amount} />
                          </div>

                          <div className="d-flex justify-content-between">
                            <AmountItem label="Totale iva:" value={data.quote.total_taxes} />
                          </div>

                          <div className="d-flex justify-content-between">
                            <AmountItem label="Totale ordine:" value={data.quote.total} />
                          </div>
                        </>
                      )}

                      {count.user.userType == "Retail" && data.endClientSummary && (
                        <>                      
                          <div className="my-1 text-center" style={{lineHeight: "1"}}>
                            <hr />
                            {process.env.NODE_ENV != "production" && <small>Cliente finale</small>}
                          </div>

                          <div className="d-flex justify-content-between">
                            <AmountItem label="Sconto:" value={-data.endClientSummary.totalDiscount} />
                          </div>

                          <div className="d-flex justify-content-between">
                            <AmountItem label="Totale merce netto:" value={data.endClientSummary.net} />
                          </div>

                          <div className="d-flex justify-content-between">
                            <AmountItem label="Totale imponibile:" value={data.endClientSummary.taxableAmount} />
                          </div>

                          <div className="d-flex justify-content-between">
                            <AmountItem label="Totale iva:" value={data.endClientSummary.totalTaxes} />
                          </div>

                          <div className="d-flex justify-content-between">
                            <AmountItem label="Totale ordine:" value={data.endClientSummary.total} />
                          </div>
                        </>
                      )}

                      <div className="d-flex flex-row-reverse justify-content-between mt-3">
                        <Button disabled={"save" == data.state} variant="contained" className="ps-3 pe-1" onClick={(e) => formik.handleSubmit(e)}>
                          <span>Genera preventivo</span>
                          <NavigateNextIcon />
                        </Button>

                        <Button variant="contained" className="ps-1 pe-3" color="secondary" onClick={() => navigate("/accessori")}>
                          <NavigateBeforeIcon />
                          <span>Indietro</span>
                        </Button>
                      </div>

                      {"refresh" == data.state && (
                        <div className="d-flex justify-content-center align-items-center" style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%" }}>
                          <CircularProgress />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </LayoutPage>
  );
};

const useStyles = makeStyles({
  root: {
    "& MuiTabs-flexContainer css-heg063-MuiTabs-flexContainer": {
      justifyContent: "center",
    },
  },
});

const mapState = (state) => ({
  count: state.count,
});

export const AmountItem = (props) => {
  if (!props) return <span>ERRORE</span>;

  return (
    <>
      <h6 className="h6">{props.label}</h6>
      <h6 className="h6 text-primary">
        {Number(props.value)
          .toFixed(2)
          .replace(".", ",")
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")} &euro;
      </h6>
    </>
  );
}

export const NumberItem = (props) => {
  return (
    <>
      <h6 className="h6">{props.label}</h6>
      <h6 className="h6 text-primary">
        {Number(props.value).toFixed(props.decimal || 0)}
      </h6>
    </>
  );
}

export default connect(mapState)(Richiesta);
