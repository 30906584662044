import Konva from "konva";
import React, { FC, useEffect, useRef } from "react";
import { Group, Label, Rect, Tag, Text } from "react-konva";
import { PlaceholderRect } from "../draw_utils";
import { DatiPannello } from "../../../../../../../core/common_draw_types";
import { IRect } from "konva/lib/types";
import { GridSize } from "../../../../../../../models/door_configuration";

export const SuperficieAreata: FC<{panelData: DatiPannello, bbox: PlaceholderRect, gridSize: GridSize, color: HTMLImageElement|null|undefined, onClick?: (placeholderData: PlaceholderRect) => void}> = (props) => {
    const {panelData, bbox, gridSize, onClick} = props;
    const labelRef = useRef<Konva.Label>(null);

    let rectFrameExt: IRect = {
        x: bbox.x,
        y: (panelData.bbox.height / 2) - (bbox.height / 2),
        width: bbox.width,
        height: bbox.height
    };

    const getDes = () => {
        return "SUPERFICIE AREATA: " + (gridSize == GridSize.superificie_fq ? "FORI QUADRATI" : "FORI TONDI")+"\nIMMAGINE IN ALLESTIMENTO";
    }

    useEffect(() => {
        if (!labelRef.current) return;

        let lw = labelRef.current.getWidth();
        let lh = labelRef.current.getHeight();
        
        let y = (bbox.height/2)-(lh/2);
        let x = (bbox.width/2)-(lw/2);
        labelRef.current.setPosition({x, y});
    }, []);

    return (
        <Group onClick={() => onClick?.(bbox)} onTap={() => onClick?.(bbox)}>
            <Rect
                {...rectFrameExt}
                fill={"#f1f1f1"}
                opacity={0.3}
            />
            <Label ref={labelRef}>
                <Tag strokeWidth={0.75} />
                <Text padding={5} text={getDes()} align="center" lineHeight={1.5} fontSize={8} />
            </Label>
        </Group>
    );
}