import * as React from "react";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import * as yup from "yup";
import { InputCustom } from "../../components/Input/InputCustom";
import { Auth } from "../../components/Auth/auth";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { DialogCustom } from "../../components/Dialog/dialog";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { LOCALES } from "../../i18n";
import { importBaseActions } from "../../store/reducers/base";

const validationSchema = yup.object({
  code: yup
    .string()
    .required("campo obbligatorio"),
  password: yup
    .string()
    .min(5, "La password deve contenere almeno almeno 8 caratteri")
    .required("Password obbligatoria"),
});

const Login = () => {
  const dispatch = useDispatch();
  const baseActions = importBaseActions();

  const [dialogError, setDialogError] = useState({open: false, title: "", error: ""});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    dispatch(baseActions.logoutUser());
  }, []);

  const formik = useFormik({
    initialValues: {
      code: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try 
      {
        let res = await axios.post(`${process.env.REACT_APP_API_URL}/login`, {
            email: values.code,
            password: values.password,
        });

        setLoading(false);
        
        if (res.data.success === 1) {
          dispatch(baseActions.setUser(res.data.data));
          dispatch(baseActions.setLocale(LOCALES.ITALIAN));
         
          if (res.data.data.requireChangePassword) {
            navigate("/modifica-password", { state: { disableBack: true } });
          } else {
            let destination = "";
            switch (res.data.data.userType) {
              case "SuperAdmin":
              case "Admin":
                  destination = "/area-managers";
                  break;
              case "AreaManager":
                  destination = "/agenti";
                  break;

              case "Agente":
                  destination = "/clienti";
                  break;

              case "Retail":
                  destination = "/configurations";
                  break;
            }
            navigate(destination);
          }          
        } else {
          setDialogError({open: true, title: "Credenziali non valide", error: "Verificare che le credenziali siano esatte e riprovare"});
        }
      }
      catch(error) 
      {
        setLoading(false)

        if (error.response.status === 403) {
          if (error.response.data.message == "EXPIRED_ACCOUNT") {
            setDialogError({open: true, title: "Account scaduto", error: "Siamo spiacenti, non puoi accedere perchè il tuo account non è più attivo."});
          } else {
            setDialogError({open: true, title: "Credenziali non valide", error: "Verificare che le credenziali siano esatte e riprovare"});
          }
        } else {
          setDialogError({open: true, title: "Errore generico", error: "Siamo spiacenti, si è verificato un errore durante il login. Ti preghiamo di riprovare."});
        }
      }
    },
  });

  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
      formik.handleSubmit();
    }
  }

  
  return (
    <div style={{position: "relative", height: "100%", background: "url(img/login-background.jpg)", backgroundPosition: "center center", backgroundSize: "cover", display: "flex", flexDirection: "column", justifyContent: "center"}}>
      <div className="row m-0 align-items-center">
        <div className="col-12 col-xl-4 offset-xl-4">
          <div style={{backgroundColor: "rgba(255,255,255,0.9)", padding: "3rem", borderRadius: "1rem"}}>
            <form onSubmit={formik.handleSubmit} onKeyDown={onKeyDown}>
              <img src={"/img/LogoOverlap.png"} alt={"logo"} style={{margin: "0 auto"}} />
                
              <InputCustom
                sx={{marginTop: "2rem"}}
                name={"code"}
                label={"Codice Utente*"}
                placeholder={"Inserisci il tuo codice utente..."}
                formik={formik.values}
                error={formik.touched.code && Boolean(formik.errors.code)}
                value={formik.values.code}
                helperText={formik.touched.code && formik.errors.code}
                onChange={formik.handleChange}
              />
              <InputCustom
                name={"password"}
                label={"Password*"}
                type={"password"}
                placeholder={"Inserisci la tua password..."}
                error={formik.touched.password && Boolean(formik.errors.password)}
                value={formik.values.password}
                helperText={formik.touched.password && formik.errors.password}
                onChange={formik.handleChange}
              />
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}>
              </Grid>
              {!loading ? (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    py: 1.5,
                    fontWeight: "bold",
                    fontSize: 16,
                  }}          >
                  ACCEDI
                </Button>
              ) : (
                <Box sx={{ display: "flex", mt: 5, justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              )}
            </form>
          </div>
        </div>
      </div>
      
      <DialogCustom
        handleClose={() => setDialogError({open: false, error: "", title: ""})}
        open={dialogError.open}
        content={dialogError.error}
        title={dialogError.title}
      />
    </div>
  );
};

export default Login;
