import * as React from "react";
import Tab from "@mui/material/Tab";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Icon, IconButton } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { makeStyles } from "@mui/styles";

const TabCustom = ({
  count,
  index,
  nome,
  IMG,
  img,
  id,
  rottaSucc,
  rottaPrec,
  label,
  IdFinale,
}) => {
  const location = useLocation();
  //const history = useHistory();
  // const classes = useStyles();
  const navigate = useNavigate();

  const getAdditionalClassNames = () => {        
    let additionalClassNames = [];

    if (location.pathname !== `/${nome}` && count[nome]) {
      additionalClassNames.push("completed");
    } else if (location.pathname == `/${nome}`) {
      additionalClassNames.push("active");
    }

    // if (step.isActive) {
    //     additionalClassNames.push("active");
    // } else if (step.isComplete) {
    //     additionalClassNames.push("completed");
    // }

    return additionalClassNames.join(" ");
  }

  const changeStep = () => {
      if (!count[nome]) return;
      if (location.pathname == `/${nome}`) return;

      navigate(`/${nome}`);
  }

  return (
      <div className={"configuration-stepper m-3 d-flex flex-column justify-content-center align-items-center " + getAdditionalClassNames()} onClick={() => changeStep()}>
          <div className="configuration-stepper-icon">
              {img && <img src={img} />}
          </div>
          <div className="configuration-stepper-title-container">
              <span className="configuration-stepper-title">{label}</span>
          </div>
      </div>
  );
};

export default TabCustom;
