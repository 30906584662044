import React, { FC, ReactNode, useRef } from "react";
import { Group, Layer, Line, Rect } from "react-konva";
import { ALTEZZA_ARCHITRAVE, ALTEZZA_GARAGE, PROFONDITA_INVOLUCRO_INTERNO, DEFAULT_STROKE_WIDTH, LARGHEZZA_GARAGE, LARGHEZZA_SPALLETTA_DX, LARGHEZZA_SPALLETTA_SX, SPESSORE_ARCHITRAVE, SPESSORE_SPALLETTA_DX, SPESSORE_SPALLETTA_SX, PROFONDITA_INVOLUCRO } from "../../../../../../../core/common_constants";
import { Vector2dToPointsArray } from "../../../../../../../core/common_functions";
import { useStoreDispatch, useStoreSelector } from "../../../../../../../../hooks/StoreHooks";
import { Vector } from "vecti";
import { Vector2d } from "konva/lib/types";
import { useDoorConfigurationBuilderActions } from "../../../../../../../../store/reducers/door_configuration_builder";
import { Misure } from "../misure";

type GarageInternalProps = {
    chidlren?: ReactNode
}

type GarageLinkedObjects = {
    polySezioneArchitrave: Vector2d[],
    polySolettaArchitrave: Vector2d[]
}

export const GarageInternal: FC<GarageInternalProps> = ({children}) => {
    const {doorConfigurationBuilder} = useStoreSelector(store => store);
    const dispatcher = useStoreDispatch();
    const {setAltezzaArchitrave, setSpessoreArchitrave} = useDoorConfigurationBuilderActions();
    const garageLinkedObjectsRef = useRef<GarageLinkedObjects>({
        polySezioneArchitrave: [],
        polySolettaArchitrave: []
    });

    return (
        <Layer id="disegno">
            <Group x={0} y={0}>
                <Soffitto />
                <Pavimento />
                <Vuoto />

                <Architrave linkedObjects={garageLinkedObjectsRef.current} />
                <SpallettaSinistra linkedObjects={garageLinkedObjectsRef.current} />
                <SpallettaDestra linkedObjects={garageLinkedObjectsRef.current} />
                {children}
            </Group>
        
            <Group x={0} y={0}>
                <Misure />
            </Group>
        </Layer>
    );
}

const Soffitto = () => {
    const buildPolySoffitto = (): number[] => {
        return [
            -PROFONDITA_INVOLUCRO, -PROFONDITA_INVOLUCRO,
            LARGHEZZA_GARAGE + (PROFONDITA_INVOLUCRO), -PROFONDITA_INVOLUCRO,
            LARGHEZZA_GARAGE, 0,
            0, 0
        ];
    }

    return (
        <Line points={buildPolySoffitto()} 
            strokeEnabled
            stroke={"000"}
            strokeWidth={1}
            fillEnabled
            fill={"#ddd"}
            closed
        />
    );
}

const Pavimento = () => {
    const buildPolyPavimento = (): number[] => {        
        return [
            0, ALTEZZA_GARAGE,
            LARGHEZZA_GARAGE, ALTEZZA_GARAGE,
            LARGHEZZA_GARAGE + (PROFONDITA_INVOLUCRO_INTERNO), ALTEZZA_GARAGE + PROFONDITA_INVOLUCRO_INTERNO,
            -PROFONDITA_INVOLUCRO_INTERNO, ALTEZZA_GARAGE + PROFONDITA_INVOLUCRO_INTERNO
        ];
    }

    return (
        <Line points={buildPolyPavimento()} 
            strokeEnabled
            stroke={"000"}
            strokeWidth={1}
            fillEnabled
            fill={"#aaa"}
            closed
            // onClick={() => alert("Hai cliccato il pavimento")}
        />
    );
}

const Vuoto = () => {
    return (
        <Rect 
            x={0} 
            y={0} 
            width={LARGHEZZA_GARAGE} 
            height={ALTEZZA_GARAGE} 
            stroke={"#000"} 
            strokeWidth={DEFAULT_STROKE_WIDTH}
        />
    );
}

const Architrave: FC<{linkedObjects: GarageLinkedObjects}> = ({linkedObjects}) => {

    const buildPolySezioneArchitrave = (): Vector2d[] => {
        let dir = Vector.of([-PROFONDITA_INVOLUCRO_INTERNO, -PROFONDITA_INVOLUCRO_INTERNO]).subtract(Vector.of([0, 0])).normalize();

        let vStart = Vector.of([0, 0]);
        let vEnd = vStart.add(dir.multiply(SPESSORE_ARCHITRAVE));

        let p1: Vector2d = {x: vEnd.x, y: vEnd.y};

        vStart = Vector.of([0, ALTEZZA_ARCHITRAVE]);
        vEnd = vStart.add(dir.multiply(SPESSORE_ARCHITRAVE));
        let p4: Vector2d = {x: vEnd.x, y: vEnd.y};
        
        //secondo punto
        dir = Vector.of([LARGHEZZA_GARAGE + (PROFONDITA_INVOLUCRO_INTERNO), -PROFONDITA_INVOLUCRO_INTERNO]).subtract(Vector.of([LARGHEZZA_GARAGE, 0])).normalize();

        vStart = Vector.of([LARGHEZZA_GARAGE, 0]);
        vEnd = vStart.add(dir.multiply(SPESSORE_ARCHITRAVE));

        let p2: Vector2d = {x: vEnd.x, y: vEnd.y};

        vStart = Vector.of([LARGHEZZA_GARAGE, ALTEZZA_ARCHITRAVE]);
        vEnd = vStart.add(dir.multiply(SPESSORE_ARCHITRAVE));

        let p3: Vector2d = {x: vEnd.x, y: vEnd.y};

        linkedObjects.polySezioneArchitrave = [p1, p2, p3, p4];

        return linkedObjects.polySezioneArchitrave;
    }

    const buildPolySolettaArchitrave = (): Vector2d[] => {
        let v1 = Vector.of([LARGHEZZA_GARAGE + (PROFONDITA_INVOLUCRO_INTERNO), -PROFONDITA_INVOLUCRO_INTERNO]);
        let v2 = Vector.of([LARGHEZZA_GARAGE, 0]);
        let dir = v2.subtract(v1).normalize();
        let p3 = Vector.of([linkedObjects.polySezioneArchitrave[2].x, linkedObjects.polySezioneArchitrave[2].y]).add(dir.multiply(SPESSORE_ARCHITRAVE));
        
        v1 = Vector.of([-PROFONDITA_INVOLUCRO_INTERNO, -PROFONDITA_INVOLUCRO_INTERNO]);
        v2 = Vector.of([0, 0]);
        dir = v2.subtract(v1).normalize();
        let p4 = Vector.of([linkedObjects.polySezioneArchitrave[3].x, linkedObjects.polySezioneArchitrave[3].y]).add(dir.multiply(SPESSORE_ARCHITRAVE));

        linkedObjects.polySolettaArchitrave = [
            linkedObjects.polySezioneArchitrave[3],
            linkedObjects.polySezioneArchitrave[2],
            p3,
            p4
        ];

        return linkedObjects.polySolettaArchitrave;
    }

    return (
        <Group>
            <Line 
                points={Vector2dToPointsArray(buildPolySezioneArchitrave())} 
                strokeEnabled
                stroke={"000"}
                strokeWidth={1}
                fillEnabled
                fill={"#fff"}
                closed
                // onClick={() => alert("Hai cliccato l'architrave")}
            />
            
            <Line 
                points={Vector2dToPointsArray(buildPolySolettaArchitrave())} 
                strokeEnabled
                stroke={"000"}
                strokeWidth={1}
                fillEnabled
                fill={"#ccc"}
                closed
                // onClick={() => alert("Hai cliccato l'architrave")}
            />
        </Group>
    );
}

const SpallettaSinistra: FC<{linkedObjects: GarageLinkedObjects}> = ({linkedObjects}) => {
    const sezioneRef = useRef<Vector2d[]>([]);

    const buildPolySezioneSpalletta = (): Vector2d[] => {
        let dir = Vector.of([-PROFONDITA_INVOLUCRO_INTERNO, -PROFONDITA_INVOLUCRO_INTERNO]).subtract(Vector.of([0, 0])).normalize();

        let vecRef = Vector.of([0, ALTEZZA_ARCHITRAVE]);
        
        let ptClc = vecRef.add(dir.multiply(SPESSORE_SPALLETTA_SX));
        let pt1: Vector2d = {x: ptClc.x, y: ptClc.y};

        vecRef = Vector.of([LARGHEZZA_SPALLETTA_SX, ALTEZZA_ARCHITRAVE]);
        ptClc = vecRef.add(dir.multiply(SPESSORE_SPALLETTA_SX));
        let pt2: Vector2d = {x: ptClc.x, y: ptClc.y};

        dir = Vector.of([-PROFONDITA_INVOLUCRO_INTERNO, ALTEZZA_GARAGE+PROFONDITA_INVOLUCRO_INTERNO]).subtract(Vector.of([0, ALTEZZA_GARAGE])).normalize();

        vecRef = Vector.of([LARGHEZZA_SPALLETTA_SX, ALTEZZA_GARAGE]);
        ptClc = vecRef.add(dir.multiply(SPESSORE_SPALLETTA_SX));
        let pt3: Vector2d = {x: ptClc.x, y: ptClc.y};

        vecRef = Vector.of([0, ALTEZZA_GARAGE]);
        ptClc = vecRef.add(dir.multiply(SPESSORE_SPALLETTA_SX));
        let pt4: Vector2d = {x: ptClc.x, y: ptClc.y};

        sezioneRef.current = [pt1, pt2, pt3, pt4];

        return sezioneRef.current;
    }

    const buildPolySezioneMuroSpalleta = (): Vector2d[] => {
        let pt1 = sezioneRef.current[1];

        let vecPt = Vector.of([0, 0]);
        let dir = vecPt.subtract(Vector.of([-PROFONDITA_INVOLUCRO_INTERNO, -PROFONDITA_INVOLUCRO_INTERNO])).normalize();

        let ptClc = Vector.of([pt1.x, pt1.y]).add(dir.multiply(SPESSORE_SPALLETTA_SX));
        let pt2 = {x: ptClc.x, y: ptClc.y};

        let pt4 = sezioneRef.current[2];

        vecPt = Vector.of([0, ALTEZZA_GARAGE]);
        dir = vecPt
        .subtract(Vector.of([-PROFONDITA_INVOLUCRO_INTERNO, PROFONDITA_INVOLUCRO_INTERNO+ALTEZZA_GARAGE]))
        .normalize();

        ptClc = Vector.of([pt4.x, pt4.y]).add(dir.multiply(SPESSORE_SPALLETTA_SX));
        let pt3 = {x: ptClc.x, y: ptClc.y};

        return [pt1, pt2, pt3, pt4];
    }

    return (
        <Group>
            <Line 
                points={Vector2dToPointsArray(buildPolySezioneSpalletta())} 
                strokeEnabled
                stroke={"000"}
                strokeWidth={1}
                fillEnabled
                fill={"#fff"}
                closed
                // onClick={() => alert("Hai cliccato la spalletta sinistra")}
            />

            <Line 
                points={Vector2dToPointsArray(buildPolySezioneMuroSpalleta())} 
                strokeEnabled
                stroke={"000"}
                strokeWidth={1}
                fillEnabled
                fill={"#ccc"}
                closed
                // onClick={() => alert("Hai cliccato l'architrave")}
            />

            {/* {buildPolySezioneMuroSpalleta().map(p => (
                <Circle
                    x={p.x} 
                    y={p.y}
                    radius={4}
                    fillEnabled
                    fill={"#f00"}
                />
            ))} */}
        </Group>
    );
}

const SpallettaDestra: FC<{linkedObjects: GarageLinkedObjects}> = ({linkedObjects}) => {
    const sezioneRef = useRef<Vector2d[]>([]);

    const buildPolySezioneSpalletta = (): Vector2d[] => {
        let dir = Vector.of([LARGHEZZA_GARAGE + (PROFONDITA_INVOLUCRO_INTERNO), -PROFONDITA_INVOLUCRO_INTERNO]).subtract(Vector.of([LARGHEZZA_GARAGE, 0])).normalize();

        let vecRef = Vector.of([LARGHEZZA_GARAGE - LARGHEZZA_SPALLETTA_DX, ALTEZZA_ARCHITRAVE]);
        
        let ptClc = vecRef.add(dir.multiply(SPESSORE_SPALLETTA_DX));
        let pt1: Vector2d = {x: ptClc.x, y: ptClc.y};

        vecRef = Vector.of([LARGHEZZA_GARAGE, ALTEZZA_ARCHITRAVE]);
        
        ptClc = vecRef.add(dir.multiply(SPESSORE_SPALLETTA_DX));
        let pt2: Vector2d = {x: ptClc.x, y: ptClc.y};

        dir = Vector.of([LARGHEZZA_GARAGE + (PROFONDITA_INVOLUCRO_INTERNO), ALTEZZA_GARAGE+PROFONDITA_INVOLUCRO_INTERNO]).subtract(Vector.of([LARGHEZZA_GARAGE, ALTEZZA_GARAGE])).normalize();

        vecRef = Vector.of([LARGHEZZA_GARAGE, ALTEZZA_GARAGE]);
        ptClc = vecRef.add(dir.multiply(SPESSORE_SPALLETTA_DX));
        let pt3: Vector2d = {x: ptClc.x, y: ptClc.y};

        vecRef = Vector.of([LARGHEZZA_GARAGE- LARGHEZZA_SPALLETTA_DX, ALTEZZA_GARAGE]);
        ptClc = vecRef.add(dir.multiply(SPESSORE_SPALLETTA_DX));
        let pt4: Vector2d = {x: ptClc.x, y: ptClc.y};

        sezioneRef.current = [pt1, pt2, pt3, pt4];

        return sezioneRef.current;
    }

    const buildPolySezioneMuroSpalleta = (): Vector2d[] => {
        let pt1: Vector2d = {x: sezioneRef.current[1].x - LARGHEZZA_SPALLETTA_DX, y: sezioneRef.current[1].y};

        let vecPt = Vector.of([LARGHEZZA_GARAGE, 0]);
        let dir = vecPt
        .subtract(Vector.of([LARGHEZZA_GARAGE + (PROFONDITA_INVOLUCRO_INTERNO), -PROFONDITA_INVOLUCRO_INTERNO]))
        .normalize();

        let ptClc = Vector.of([pt1.x, pt1.y]).add(dir.multiply(SPESSORE_SPALLETTA_DX));
        let pt2 = {x: ptClc.x, y: ptClc.y};

        let pt4: Vector2d = {x: sezioneRef.current[2].x - LARGHEZZA_SPALLETTA_DX, y: sezioneRef.current[2].y};

        vecPt = Vector.of([LARGHEZZA_GARAGE, ALTEZZA_GARAGE]);
        dir = vecPt
        .subtract(Vector.of([LARGHEZZA_GARAGE + (PROFONDITA_INVOLUCRO_INTERNO), PROFONDITA_INVOLUCRO_INTERNO+ALTEZZA_GARAGE]))
        .normalize();

        ptClc = Vector.of([pt4.x, pt4.y]).add(dir.multiply(SPESSORE_SPALLETTA_DX));
        let pt3 = {x: ptClc.x, y: ptClc.y};

        return [pt1, pt2, pt3, pt4];
    }

    return (
        <Group>
            <Line 
                points={Vector2dToPointsArray(buildPolySezioneSpalletta())} 
                strokeEnabled
                stroke={"000"}
                strokeWidth={1}
                fillEnabled
                fill={"#fff"}
                closed
                // onClick={() => alert("Hai cliccato la spalletta sinistra")}
            />

            <Line 
                points={Vector2dToPointsArray(buildPolySezioneMuroSpalleta())} 
                strokeEnabled
                stroke={"000"}
                strokeWidth={1}
                fillEnabled
                fill={"#ccc"}
                closed
                // onClick={() => alert("Hai cliccato l'architrave")}
            />

            {/* {buildPolySezioneMuroSpalleta().map((p, idx) => (
                <>
                    <Circle
                        x={p.x} 
                        y={p.y}
                        radius={4}
                        fillEnabled
                        fill={"#f00"}                        
                    />
                    <Text text={""+(idx + 1)} x={p.x} y={p.y} />
                </>
            ))} */}
        </Group>
    );
}