import { Checkbox, FormControl, FormControlLabel, FormLabel, IconButton, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from "@mui/material";
import React, { FC, forwardRef, ReactNode, Ref, useEffect, useImperativeHandle, useState } from "react";
import { useDoorConfigurationBuilderActions } from "../../../../../../../store/reducers/door_configuration_builder";
import { useStoreDispatch, useStoreSelector } from "../../../../../../../hooks/StoreHooks";
import { Accessorio, Color, ColorSide, ColorType, IntegratedDoorConfiguration, IntegratedDoorMeasureType, IntegratedDoorOptions, Option } from "../../../../../../models/door_configuration";
import { useDialogs } from "../../../../../../providers/dialogs_provider";
import { useFormik } from "formik";
import { DoorType, FinishType, IntegratedDoorPosition, NullableString, OpeningDirection, Optional } from "../../../../../../core/common_types";
import { useConfigurationsService } from "../../../../../../services/configurations_service";
import { getCurrentUser } from "../../../../../../core/common_functions";
import { useFinishes } from "../../../../../../providers/finish_provider";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import finishes from "../../../../../../assets/finishes.json";
import rals from "../../../../../../assets/rals.json";

type FormPortinaIntegrataProps = {
    options: IntegratedDoorOptions
}

type FormPortinaIntegrataRef = {
    getData: () => Promise<IntegratedDoorConfiguration>
}

export type FormPortinaIntegrataTypeRef = React.ElementRef<typeof FormPortinaIntegrata>;

export const FormPortinaIntegrata = forwardRef((props: FormPortinaIntegrataProps, ref: Ref<FormPortinaIntegrataRef>) => {
    const { doorConfigurationBuilder } = useStoreSelector(store => store);
    const dispatcher = useStoreDispatch();
    const {setPortinaIntegrata, resetPortinaIntegrata} = useDoorConfigurationBuilderActions();
    const finishesProvider = useFinishes();
    const dialogs = useDialogs();
    
    const formik = useFormik<IntegratedDoorConfiguration>({
        initialValues: {
            portina: doorConfigurationBuilder.configuration?.portinaIntegrata?.portina ?? false,
            altezza: doorConfigurationBuilder.configuration?.portinaIntegrata?.altezza ?? null,
            larghezza: doorConfigurationBuilder.configuration?.portinaIntegrata?.larghezza ?? null,
            bbox: doorConfigurationBuilder.configuration?.portinaIntegrata?.bbox ?? null,
            externalFrameColorType: doorConfigurationBuilder.configuration?.portinaIntegrata?.externalFrameColorType ?? "ral_standard",
            externalFrameColorCode: doorConfigurationBuilder.configuration?.portinaIntegrata?.externalFrameColorCode ?? undefined,
            internalFrameColorType: doorConfigurationBuilder.configuration?.portinaIntegrata?.internalFrameColorType ?? "ral_standard",
            internalFrameColorCode: doorConfigurationBuilder.configuration?.portinaIntegrata?.internalFrameColorCode ?? undefined,
            externalHandleColorType: doorConfigurationBuilder.configuration?.portinaIntegrata?.externalHandleColorType ?? "ral_standard",
            externalHandleColorCode: doorConfigurationBuilder.configuration?.portinaIntegrata?.externalHandleColorCode ?? undefined,
            internalHandleColorType: doorConfigurationBuilder.configuration?.portinaIntegrata?.internalHandleColorType ?? "ral_standard",
            internalHandleColorCode: doorConfigurationBuilder.configuration?.portinaIntegrata?.internalHandleColorCode ?? undefined,
            apertura: doorConfigurationBuilder.configuration?.portinaIntegrata?.apertura ?? "sx",
            posizione: doorConfigurationBuilder.configuration?.portinaIntegrata?.posizione ?? "c",
            fuoriMisura: doorConfigurationBuilder.configuration?.portinaIntegrata?.fuoriMisura ?? "standard",
            accessori: doorConfigurationBuilder.configuration?.portinaIntegrata?.accessori ?? []
        },
        onSubmit: (model) => {

        }
    });

    const renderFormPortina = () => {
        return (
            <>
                <FormControl fullWidth className="mt-3">
                    <InputLabel id="posizione">Posizione</InputLabel>
                    <Select
                        labelId="posizione"
                        value={formik.values.posizione}
                        label="Posizione"
                        onChange={(e) => {
                            let values: IntegratedDoorConfiguration = {
                                ...formik.values,
                                posizione: e.target.value as IntegratedDoorPosition
                            };
                            dispatcher(setPortinaIntegrata(values));
                            formik.setValues(values);
                        }}>
                        <MenuItem value={"sx"}>Decentrata a sinistra</MenuItem>
                        <MenuItem value={"c"}>Centrata</MenuItem>
                        <MenuItem value={"dx"}>Decentrata a destra</MenuItem>
                    </Select>
                </FormControl>

                <FormControl fullWidth className="mt-3 mb-3">
                    <InputLabel id="apertura">Verso di apertura (vista esterna)</InputLabel>
                    <Select
                        labelId="apertura"
                        value={formik.values.apertura}
                        label="Verso di apertura (vista esterna)"
                        onChange={(e) => {
                            let values: IntegratedDoorConfiguration = {
                                ...formik.values,
                                apertura: e.target.value as OpeningDirection
                            };
                            dispatcher(setPortinaIntegrata(values));
                            formik.setValues(values);
                        }}>
                        <MenuItem value={"sx"}>Tirare a sinistra</MenuItem>
                        <MenuItem value={"dx"}>Tirare a destra</MenuItem>
                    </Select>
                </FormControl>

                {!doorConfigurationBuilder.isLaminato() && !doorConfigurationBuilder.isPietravera() && !doorConfigurationBuilder.isWoodstyle() && !doorConfigurationBuilder.isColoreCampione() && renderSelectExternalFrameColorType()}
                {formik.values.externalFrameColorType == "ral_non_standard" && renderInputExternalFrameNsRal()}

                {!doorConfigurationBuilder.isLaminato() && !doorConfigurationBuilder.isPietravera() && !doorConfigurationBuilder.isWoodstyle() && !doorConfigurationBuilder.isColoreCampione() && renderSelectInternalFrameColorType()}
                {formik.values.internalFrameColorType == "ral_non_standard" && renderInputInternalFrameNsRal()}

                {renderSelectExternalHandleColorType()}
                {formik.values.externalHandleColorType == "ral_non_standard" && renderInputExternalHandleNsRal()}

                {renderSelectInternalHandleColorType()}
                {formik.values.internalHandleColorType == "ral_non_standard" && renderInputInternalHandleNsRal()}

                <h6 className="text-primary mt-2">Optional</h6>
                {props.options.accessoriVari.map(a => <FormControlLabel
                    key={a?.id}
                    className="w-full mt-2"
                    control={
                        <Checkbox
                            checked={formik.values.accessori.filter(fm => fm.sku == a?.sku).length > 0}
                            onChange={(e) => {
                                let accessori = formik.values.accessori.map(a => ({...a}));
                                if (e.target.checked) {
                                    if (a && !accessori.find(fm => fm.sku == a?.sku)) {
                                        accessori.push({sku: a.sku, notes: ""});
                                    }
                                } else {
                                    accessori = accessori.filter(fm => fm.sku != a?.sku);
                                }
                                let values: IntegratedDoorConfiguration = {
                                    ...formik.values,
                                    accessori
                                };
                                formik.setValues(values);
                                //dispatcher(setColoreTelaioInternoDiverso(!e.target.checked));
                            }}
                            sx={{ marginTop: "0", marginBottom: "0" }}
                        />
                    }
                    label={
                        <span>{a?.name}</span>
                    }
                    sx={{ marginTop: "0", marginBottom: "0" }}
                />)}
            </>
        );
    }

    const renderSelectExternalFrameColorType = () => {
        if (!doorConfigurationBuilder.configuration) return null;

        return (
            <FormControl fullWidth className="mb-3">
                <InputLabel id="colore_telaio_esterno">Colore telaio esterno</InputLabel>
                <Select
                    labelId="colore_telaio_esterno"
                    value={getColorTypeValue("external")}
                    label={"Colore telaio esterno"}
                    onChange={(e) => {
                        let value = e.target.value;
                        let externalFrameColorType: ColorType = "ral_standard";
                        let externalFrameColorCode: string|undefined = undefined;
                        if (value == "ral_standard" || value == "ral_non_standard") {
                            externalFrameColorType = value as ColorType;
                            externalFrameColorCode = undefined;
                        } else {
                            externalFrameColorCode = value;
                        }

                        let values: IntegratedDoorConfiguration = {
                            ...formik.values,
                            externalFrameColorType,
                            externalFrameColorCode,
                            internalFrameColorType: externalFrameColorType,
                            internalFrameColorCode: externalFrameColorCode
                        };
                        dispatcher(setPortinaIntegrata(values));
                        formik.setValues(values);
                    }}>
                    {renderStandardColorTypesMenuItems("external")}                    
                    <MenuItem value={"ral_non_standard"}>Colore personalizzato</MenuItem>
                </Select>
            </FormControl>
        );
    }

    const renderInputExternalFrameNsRal = () => {
        return (
            <TextField
                fullWidth 
                focused={false}
                className="mb-3"
                label={"Colore telaio esterno"}
                name={"coloreTelaioEsterno"}
                value={finishesProvider?.getRalName(formik.values.externalFrameColorCode)}
                InputProps={{
                    readOnly: true, 
                    startAdornment: formik.values.externalFrameColorCode && (
                        <InputAdornment position="start">
                            {finishesProvider?.getRalPreview(formik.values.externalFrameColorCode)}
                        </InputAdornment>
                    ),

                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => dialogs?.selectRalColor({onConfirm: selectedColor => {                                
                                let values: IntegratedDoorConfiguration = {
                                    ...formik.values,
                                    externalFrameColorCode: selectedColor,
                                    internalFrameColorCode: selectedColor,
                                };
                                dispatcher(setPortinaIntegrata(values));
                                formik.setValues(values);
                            }})}>
                                <OpenInNewIcon style={{ color: "black" }} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        );
    }

    const renderSelectInternalFrameColorType = () => {
        if (!doorConfigurationBuilder.configuration) return null;

        return (
            <FormControl fullWidth className="mb-3">
                <InputLabel id="colore_telaio_interno">Colore telaio interno</InputLabel>
                <Select
                    labelId="colore_telaio_interno"
                    value={getColorTypeValue("internal")}
                    label={"Colore telaio interno"}
                    onChange={(e) => {
                        let value = e.target.value;
                        let internalFrameColorType: ColorType = "ral_standard";
                        let internalFrameColorCode: string|undefined = undefined;
                        if (value == "ral_standard" || value == "ral_non_standard") {
                            internalFrameColorType = value as ColorType;
                            internalFrameColorCode = undefined;
                        } else {
                            internalFrameColorCode = value;
                        }

                        let values: IntegratedDoorConfiguration = {
                            ...formik.values,
                            internalFrameColorType,
                            internalFrameColorCode
                        };
                        dispatcher(setPortinaIntegrata(values));
                        formik.setValues(values);
                    }}>
                    {renderStandardColorTypesMenuItems("internal")}                    
                    <MenuItem value={"ral_non_standard"}>Colore personalizzato</MenuItem>
                </Select>
            </FormControl>
        );
    }

    const renderInputInternalFrameNsRal = () => {
        return (
            <TextField
                fullWidth 
                focused={false}
                className="mb-3"
                label={"Colore telaio interno"}
                name={"coloreTelaioInternoNsRal"}
                value={finishesProvider?.getRalName(formik.values.internalFrameColorCode)}
                InputProps={{
                    readOnly: true, 
                    startAdornment: formik.values.internalFrameColorCode && (
                        <InputAdornment position="start">
                            {finishesProvider?.getRalPreview(formik.values.internalFrameColorCode)}
                        </InputAdornment>
                    ),

                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => dialogs?.selectRalColor({onConfirm: selectedColor => {                                
                                let values: IntegratedDoorConfiguration = {
                                    ...formik.values,
                                    internalFrameColorCode: selectedColor
                                };
                                dispatcher(setPortinaIntegrata(values));
                                formik.setValues(values);
                            }})}>
                                <OpenInNewIcon style={{ color: "black" }} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        );
    }

    const getColorTypeValue = (colorSide: ColorSide): string => {
        if (colorSide == "external") {
            if (formik.values.externalFrameColorType == "ral_standard") {
                return formik.values.externalFrameColorCode ?? "";
            }
            return formik.values.externalFrameColorType;
        } else {
            if (formik.values.internalFrameColorType == "ral_standard") {
                return formik.values.internalFrameColorCode ?? "";
            }
            return formik.values.internalFrameColorType;
        }
    }

    const renderStandardColorTypesMenuItems = (colorSide: ColorSide) => {
        let colors: string[] = [];

        let externalColorCode = doorConfigurationBuilder.getExternalColorCode();
        if (externalColorCode && !colors.includes(externalColorCode)) colors.push(externalColorCode);

        if (colorSide == "internal") {
            let internalColorCode = doorConfigurationBuilder.getInternalColorCode();
            if (internalColorCode && !colors.includes(internalColorCode)) colors.push(internalColorCode);
        }
        
        return colors.map(c => (
            <MenuItem value={c}>
                <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
                    {getRalName(c)}
                    {renderFinishPreview(c)}
                </div>
            </MenuItem>
        ));
    }

    const renderSelectExternalHandleColorType = () => {
        if (!doorConfigurationBuilder.configuration) return null;

        return (
            <FormControl fullWidth className="mb-3">
                <InputLabel id="tipo_colore_maniglia_esterna">Colore maniglia esterna</InputLabel>
                <Select
                    labelId="tipo_colore_maniglia_esterna"
                    value={formik.values.externalHandleColorType ?? "ral_standard"}
                    label={"Colore maniglia esterna"}
                    onChange={(e) => {
                        let values: IntegratedDoorConfiguration = {
                            ...formik.values,
                            externalHandleColorType: e.target.value as ColorType,
                            externalHandleColorCode: undefined
                        };
                        dispatcher(setPortinaIntegrata(values));
                        formik.setValues(values);
                    }}>
                    <MenuItem value={"ral_standard"}>Nero</MenuItem>
                    <MenuItem value={"ral_non_standard"}>Colore personalizzato</MenuItem>
                </Select>
            </FormControl>
        );
    }

    const renderInputExternalHandleNsRal = () => {
        return (
            <TextField
                fullWidth 
                focused={false}
                className="mb-3"
                label={"Specificare colore maniglia esterna"}
                name={"coloreManigliaEsterna"}
                value={finishesProvider?.getRalName(formik.values.externalHandleColorCode)}
                InputProps={{
                    readOnly: true, 
                    startAdornment: formik.values.externalHandleColorCode && (
                        <InputAdornment position="start">
                            {finishesProvider?.getRalPreview(formik.values.externalHandleColorCode)}
                        </InputAdornment>
                    ),

                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => dialogs?.selectRalColor({onConfirm: selectedColor => {                                
                                let values: IntegratedDoorConfiguration = {
                                    ...formik.values,
                                    externalHandleColorCode: selectedColor
                                };
                                dispatcher(setPortinaIntegrata(values));
                                formik.setValues(values);
                            }})}>
                                <OpenInNewIcon style={{ color: "black" }} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        );
    }

    const renderSelectInternalHandleColorType = () => {
        if (!doorConfigurationBuilder.configuration) return null;

        return (
            <FormControl fullWidth className="mb-3">
                <InputLabel id="tipo_colore_maniglia_interna">Colore maniglia interna</InputLabel>
                <Select
                    labelId="tipo_colore_maniglia_interna"
                    value={formik.values.internalHandleColorType ?? "ral_standard"}
                    label={"Colore maniglia interna"}
                    onChange={(e) => {
                        let values: IntegratedDoorConfiguration = {
                            ...formik.values,
                            internalHandleColorType: e.target.value as ColorType,
                            internalHandleColorCode: undefined
                        };
                        dispatcher(setPortinaIntegrata(values));
                        formik.setValues(values);
                    }}>
                    <MenuItem value={"ral_standard"}>Nero</MenuItem>
                    <MenuItem value={"ral_non_standard"}>Colore personalizzato</MenuItem>
                </Select>
            </FormControl>
        );
    }

    const renderInputInternalHandleNsRal = () => {
        return (
            <TextField
                fullWidth 
                focused={false}
                className="mb-3"
                label={"Specificare colore maniglia interna"}
                name={"coloreManigliaInterna"}
                value={finishesProvider?.getRalName(formik.values.internalHandleColorCode)}
                InputProps={{
                    readOnly: true, 
                    startAdornment: formik.values.internalHandleColorCode && (
                        <InputAdornment position="start">
                            {finishesProvider?.getRalPreview(formik.values.internalHandleColorCode)}
                        </InputAdornment>
                    ),

                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => dialogs?.selectRalColor({onConfirm: selectedColor => {                                
                                let values: IntegratedDoorConfiguration = {
                                    ...formik.values,
                                    internalHandleColorCode: selectedColor
                                };
                                dispatcher(setPortinaIntegrata(values));
                                formik.setValues(values);
                            }})}>
                                <OpenInNewIcon style={{ color: "black" }} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        );
    }

    const getRalName = (colorCode: NullableString) => {
        if (!colorCode) return "";

        let color = doorConfigurationBuilder.colors.find(c => c?.code == colorCode);        
        return color ? `${color.name} (${color.code})` : finishesProvider?.getRalName(colorCode);
    }

    const renderFinishPreview = (colorCode: NullableString) => {
        if (!colorCode) return null;
        
        let preview = finishes.find(f => f.code == colorCode)?.img;
        if (preview) return <img src={preview} style={{width: "2rem", height: "1.75rem"}} />;

        preview = rals.find(f => f.RAL == colorCode)?.HEX;
        if (preview) return <div style={{width: "1.75rem", height: "1.75rem", backgroundColor: preview}}></div>;

        return null;        
    }

    const enablePortinaIntegrata = (isEnabled: boolean) => {
        if (!doorConfigurationBuilder.configuration) return;
        
        if (!isEnabled) {
            let values: IntegratedDoorConfiguration = {
                portina: isEnabled, 
                altezza: null, 
                larghezza: null, 
                bbox: null,
                externalFrameColorType: "ral_standard",
                externalFrameColorCode: undefined,
                internalFrameColorType: "ral_standard",
                internalFrameColorCode: undefined,
                externalHandleColorType: "ral_standard",
                externalHandleColorCode: undefined,
                internalHandleColorType: "ral_standard",
                internalHandleColorCode: undefined,
                accessori: [], 
                apertura: "sx", 
                posizione: "c",
                fuoriMisura: isEnabled ? "standard" : "undefined"
            };
            formik.setValues(values);
            dispatcher(resetPortinaIntegrata());
            return;
        }

        let frameColorType: ColorType = "ral_standard";
        let frameColorCode = doorConfigurationBuilder.getExternalColorCode();
        if (doorConfigurationBuilder.isLaminato() || doorConfigurationBuilder.isPietravera() || doorConfigurationBuilder.isWoodstyle() || doorConfigurationBuilder.isColoreCampione()) {
            frameColorType = "ral_non_standard";
            frameColorCode = undefined;
        }
        
        let porta = doorConfigurationBuilder.options.find(o => o?.optionId == doorConfigurationBuilder.configuration?.porta);                                
        if (porta?.name == DoorType.classic) {
            if (doorConfigurationBuilder.configuration.altezzaPorta < 2020) {
                let values: IntegratedDoorConfiguration = {
                    ...formik.values,
                    portina: isEnabled, 
                    altezza: {esternoTelaio: 1800, internoTelaio: 1750}, 
                    larghezza: {esternoTelaio: 900, internoTelaio: 800},
                    externalFrameColorType: frameColorType,
                    externalFrameColorCode: frameColorCode,
                    internalFrameColorType: frameColorType,
                    internalFrameColorCode: frameColorCode,
                    fuoriMisura: "standard"
                };
                formik.setValues(values);
                dispatcher(setPortinaIntegrata(values));
            } else {
                let values: IntegratedDoorConfiguration = {
                    ...formik.values,
                    portina: isEnabled, 
                    altezza: {esternoTelaio: 1900, internoTelaio: 1850}, 
                    larghezza: {esternoTelaio: 900, internoTelaio: 800},
                    externalFrameColorType: frameColorType,
                    externalFrameColorCode: frameColorCode,
                    internalFrameColorType: frameColorType,
                    internalFrameColorCode: frameColorCode,
                    fuoriMisura: "standard"
                };
                formik.setValues(values);
                dispatcher(setPortinaIntegrata(values));
            }
        } else if (porta?.name == DoorType.plana) {
            if (doorConfigurationBuilder.configuration.altezzaPorta <= 2250) {
                let values: IntegratedDoorConfiguration = {
                    ...formik.values,
                    portina: isEnabled, 
                    altezza: {esternoTelaio: 1870, internoTelaio: 1820}, 
                    larghezza: {esternoTelaio: 900, internoTelaio: 800},
                    externalFrameColorType: frameColorType,
                    externalFrameColorCode: frameColorCode,
                    internalFrameColorType: frameColorType,
                    internalFrameColorCode: frameColorCode,
                    fuoriMisura: "standard"
                };
                formik.setValues(values);
                dispatcher(setPortinaIntegrata(values));
            } else {
                let values: IntegratedDoorConfiguration = {
                    ...formik.values,
                    portina: isEnabled, 
                    altezza: {esternoTelaio: 2100, internoTelaio: 2050}, 
                    larghezza: {esternoTelaio: 900, internoTelaio: 800},
                    externalFrameColorType: frameColorType,
                    externalFrameColorCode: frameColorCode,
                    internalFrameColorType: frameColorType,
                    internalFrameColorCode: frameColorCode,
                    fuoriMisura: "standard"
                };
                formik.setValues(values);
                dispatcher(setPortinaIntegrata(values));
            }
        }
    }

    useImperativeHandle(ref, () => ({
        async getData(): Promise<IntegratedDoorConfiguration> {
                        
            return formik.values;
        }   
    }));

    return (
        <div>
            {/* <FormControlLabel
                className="w-full mt-1"
                control={
                    <Checkbox
                        checked={formik.values.portina}
                        onChange={(e) => enablePortinaIntegrata(e.target.checked)}
                        sx={{ marginTop: "0", marginBottom: "0" }}
                    />
                }
                label={props.options.descrizione}
                sx={{ marginTop: "0", marginBottom: "0" }}
            /> */}

            <FormControl fullWidth className="mt-3">
                {/* <InputLabel id="fuori_misura">Dimensione</InputLabel> */}
                <Select
                    labelId="fuori_misura"
                    value={formik.values.portina ? formik.values.fuoriMisura : "undefined"}
                    label=""
                    onChange={(e) => {
                        if (e.target.value == "undefined") {
                            enablePortinaIntegrata(false);
                        } else if (e.target.value == "standard") {
                            enablePortinaIntegrata(true);
                        } else {
                            let values: IntegratedDoorConfiguration = {
                                ...formik.values, 
                                fuoriMisura: e.target.value as IntegratedDoorMeasureType
                            };
                            formik.setValues(values);
                            dispatcher(setPortinaIntegrata(values));
                        }
                    }}>
                    <MenuItem value={"undefined"}>Nessuna portina</MenuItem>
                    <MenuItem value={"standard"}>Portina standard - L passaggio {doorConfigurationBuilder.isPlana() ? "750 mm" : "800 mm"}</MenuItem>
                    <MenuItem value={"900"}>Portina non standard - L passaggio 900 mm</MenuItem>
                    {(doorConfigurationBuilder.configuration?.larghezzaPorta ?? 0) >= 2320 && (doorConfigurationBuilder.configuration?.altezzaPorta ?? 0) >= 2500 && (
                        <MenuItem value={"1200"}>Non standard - L passaggio 1200 mm</MenuItem>
                    )}
                </Select>
            </FormControl>

            {formik.values.portina && renderFormPortina()}
        </div>
    );
});