import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Grid from "@mui/material/Grid";
import React, { FC, useState } from "react";
import { Color } from "../../models/door_configuration";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { NullableNumber, NullableString } from "../../core/common_types";
import rals from "../../assets/rals.json";

export type RalColorsProps = {
    onConfirm: (value: NullableString) => void,
}

export const RalColorsDialog: FC<{data: RalColorsProps, onConfirm: (value: NullableString) => void, onCancel: () => void}> = (props) => {    
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("xl"));

    const setValueAndClose = (ral: NullableString) => {
        props.onConfirm(ral);
    }

    return (
        <Dialog fullWidth={false} fullScreen={fullScreen} maxWidth="xl" onClose={props.onCancel} open={true}>
            <DialogTitle>Colori disponibili</DialogTitle>
            <DialogContent className="py-2">
                <div className="row dialog-colors-grid my-0">
                    {rals.sort((a,b) => a.Italian.localeCompare(b.Italian)).map(ral => (
                        <div key={ral.RAL} className={"col-xl-2 p-2 dialog-colors-grid-item d-flex flex-column align-items-center"} onClick={() => setValueAndClose(ral.RAL)}>
                            <div style={{width: "4rem", height: "4rem", backgroundColor: ral.HEX}}></div>
                            <div className="p-2 text-center">
                                <h6 className="text-secondary mb-0">{ral.Italian}</h6>                                
                                <small className="badge bg-light text-dark">RAL {ral.RAL}</small>
                            </div>
                        </div>
                    ))}
                </div>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={props.onCancel}>Annulla</Button>
            </DialogActions>
        </Dialog>
    );
}